<template>
    <div class="approve-exits-container">
        <div class="approve-exits-container__header">
            <span class="title">Aprovar saídas</span>
            <div class="actions">
                <div class="search-container app-input">
                    <input
                        class="search-container__input"
                        placeholder="Pesquise um produto"
                        v-model="searchWord"
                        type="text"
                    />
                    <button
                        class="search-container__clear clickable"
                        v-show="searchWord.length > 0"
                        @click="clearSearch"
                    >
                        <i class="fal fa-times-circle"/>
                    </button>
                    <button
                        class="search-container__search"
                        @click=""
                    >
                        <i class="fas fa-search"/>
                    </button>
                </div>
                <button
                    class="btn btn-primary btn--flat"
                    :disabled="approving"
                    @click="approveSelectedActivityProducts"
                >
                    <span
                        v-if="approving"
                        class="spinner-border"
                        role="status"
                    ></span>
                    <span v-else>
                        <span>Aprovar selecionados</span>
                    </span>
                </button>
            </div>
        </div>
        <div
            v-if="!loading"
            class="approve-exits-container__content"
        >
            <table class="approve-exits-table">
                <thead>
                    <tr>
                        <th>
                            Datas
                        </th>
                        <th>
                            Atividade
                        </th>
                        <th>
                            Talhão
                        </th>
                        <th>
                            Área utilizada (ha)
                        </th>
                        <th>
                            Produto
                        </th>
                        <th>
                            Dosagem
                        </th>
                        <th>
                            Quantidade calculada
                        </th>
                        <th>
                            Quantidade usada
                        </th>
                        <th>
                            Quantidade em estoque
                        </th>
                        <th class="approve-exits-table-header__actions">
                            Selecionar
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="{
                            displayTextActivityDates,
                            displayTextActivityType,
                            displayTextActivityFieldName,
                            displayTextActivityArea,
                            displayTextStockProductName,
                            displayTextQuantityUnitDosage,
                            displayTextCalculatedTotalQuantity,
                            displayTextRealTotalQuantity,
                            displayTextQuantityStockAvailable,
                            rowColor,
                            activityProductKey,
                            activityProduct
                        } in filteredDisplayArrayActivityProducts"
                        :key="activityProductKey"
                        :class="
                            !activityProduct.isSelected && !activityProduct.hasQuantityAvailableInStock
                            ? 'approve-exits-table__row--red'
                            : ''
                        "
                    >
                        <td>
                            <div
                                class="approve-exits-table-content__dates"
                                v-for="date of displayTextActivityDates"
                            >
                                {{ date }}
                            </div>
                        </td>
                        <td>
                            {{ displayTextActivityType }}
                        </td>
                        <td>
                            {{ displayTextActivityFieldName  }}
                        </td>
                        <td>
                            {{ displayTextActivityArea }}
                        </td>
                        <td>
                            {{ displayTextStockProductName }}
                        </td>
                        <td>
                            <div class="approve-exits-table-content__actions justify-content-start">
                                <span>
                                    {{ displayTextQuantityUnitDosage }}
                                </span>
                                <button
                                    @click="openModalFixProductQuantity(activityProduct)"
                                    class="btn btn--flat btn-primary btn--rounded"
                                >
                                    <i class="fas fa-edit"></i>
                                </button>
                            </div>
                        </td>
                        <td>
                            {{ displayTextCalculatedTotalQuantity }}
                        </td>
                        <td>
                            <div class="approve-exits-table-content__actions justify-content-start">
                                <span>
                                    {{ displayTextRealTotalQuantity }}
                                </span>
                                <button
                                    @click="openModalFixRealTotalQuantity(activityProduct)"
                                    class="btn btn--flat btn-primary btn--rounded"
                                >
                                    <i class="fas fa-edit"></i>
                                </button>
                            </div>
                        </td>
                        <td>
                            {{ displayTextQuantityStockAvailable }}
                        </td>
                        <td>
                            <div class="approve-exits-table-content__actions">
                                <div
                                    class="app-checkbox d-flex"
                                    v-if="activityProduct.hasQuantityAvailableInStock || activityProduct.isSelected"
                                >
                                    <input
                                        :id="`selected-product-checkbox-${activityProduct._id}`"
                                        type="checkbox"
                                        :checked="activityProducts[activityProduct.index].isSelected"
                                        @input="activityProducts[activityProduct.index].isSelected = $event.target.checked"
                                    />
                                    <label :for="`selected-product-checkbox-${activityProduct._id}`"></label>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div
                v-show="activityProducts.length == 0"
                class="no-content"
            >
                <i class="fa fa-exclamation-circle fa-2x"/>
                <span>
                    Não foram encontrados produtos no estoque
                </span>
            </div>
        </div>
        <div v-else class="no-content">
            <span class="spinner-border" />
        </div>
        <modal-fix-product-quantity
            v-if="showModalFixProductQuantity"
            :open="showModalFixProductQuantity"
            @close="closeModalFixProductQuantity"
            @save="loadActivityProducts"
            :data="selectedActivityProduct"
        >
        </modal-fix-product-quantity>
        <modal-fix-real-total-quantity
            v-if="showModalFixRealTotalQuantity"
            :open="showModalFixRealTotalQuantity"
            @close="closeModalFixRealTotalQuantity"
            @save="saveRealTotalQuantity"
            :data="selectedActivityProduct"
        >
    </modal-fix-real-total-quantity>
    </div>
</template>

<script>

import { mapActions, mapGetters } from "vuex";
import ModalFixProductQuantity from "@/views/stock/approve-exits/components/ModalFixProductQuantity.vue"
import ModalFixRealTotalQuantity from "@/views/stock/approve-exits/components/ModalFixRealTotalQuantity.vue"

export default {
    name: 'ApproveExits',
    components: {
        ModalFixProductQuantity,
        ModalFixRealTotalQuantity
    },
    data() {
        return {
            activityProducts: [],
            selectedActivityProduct: {},
            loading: false,
            approving: false,
            showModalFixProductQuantity: false,
            showModalFixRealTotalQuantity: false,
            searchWord: '',
            payload: {
                propertyId: ''
            }
        }
    },
    computed: {
        ...mapGetters("property", ["getPropertySelected"]),
        ...mapGetters("activities", ["getActivityProducts"]),
        ...mapGetters("unit-conversions", ["getUnitConversions"]),
        selectedActivityProductsHash() {
            const selectedActivityProductsHash = {}
            for (const activityProduct of this.activityProducts) {
                if (!activityProduct.isSelected) {
                    continue;
                }

                if (!selectedActivityProductsHash[activityProduct.stockProduct._id]) {
                    selectedActivityProductsHash[activityProduct.stockProduct._id] = 0
                }

                selectedActivityProductsHash[activityProduct.stockProduct._id] += activityProduct.realTotalQuantity
            }

            return selectedActivityProductsHash
        },
        sortedActivityProducts() {
            const filterByExistingStockProduct = this.getActivityProducts.filter((a) => {return a.stockProduct !== null})
            return filterByExistingStockProduct.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1);
        },
        displayArrayActivityProducts() {
            return this.activityProducts.map((activityProduct, activityProductIndex) => ({
                displayTextActivityDates: activityProduct.activity.date.map(({ dateHour }) => this.formatDate(dateHour)),
                displayTextActivityType: this.translateActivityType(this.returnIfExists(activityProduct, "activity.type")) || '-',
                displayTextActivityFieldName: this.returnIfExists(activityProduct, "activity.subarea.field.name") || '-',
                displayTextActivityArea: this.formatNumber(this.returnIfExists(activityProduct, "activity.area")) || '-',
                displayTextStockProductName: this.returnIfExists(activityProduct, "stockProduct.product.name") || '-',
                displayTextQuantityUnitDosage: this.getDisplayTextQuantityUnitDosage(activityProduct),
                displayTextCalculatedTotalQuantity: this.getDisplayTextCalculatedTotalQuantity(activityProduct),
                displayTextRealTotalQuantity: this.getDisplayTextRealTotalQuantity(activityProduct),
                displayTextQuantityStockAvailable:  this.getDisplayTextQuantityStockAvailable(activityProduct),
                activityProductKey: activityProduct._id,
                activityProduct: {
                    ...activityProduct,
                    hasQuantityAvailableInStock: this.hasQuantityAvailableInStock(activityProduct)
                }
            }))
        },
        filteredDisplayArrayActivityProducts() {
            return this.displayArrayActivityProducts.filter(activityProduct => (
                activityProduct.displayTextActivityDates.join('').toUpperCase().includes(this.searchWord.toUpperCase()) ||
                activityProduct.displayTextActivityType.toUpperCase().includes(this.searchWord.toUpperCase()) ||
                activityProduct.displayTextActivityFieldName.toUpperCase().includes(this.searchWord.toUpperCase()) ||
                activityProduct.displayTextActivityArea.toUpperCase().includes(this.searchWord.toUpperCase()) ||
                activityProduct.displayTextStockProductName.toUpperCase().includes(this.searchWord.toUpperCase()) ||
                activityProduct.displayTextQuantityUnitDosage.toUpperCase().includes(this.searchWord.toUpperCase()) ||
                activityProduct.displayTextCalculatedTotalQuantity.toUpperCase().includes(this.searchWord.toUpperCase()) ||
                activityProduct.displayTextRealTotalQuantity.toUpperCase().includes(this.searchWord.toUpperCase()) ||
                activityProduct.displayTextQuantityStockAvailable.toUpperCase().includes(this.searchWord.toUpperCase())
            ));
        },
        requestActivityProductsToApprove() {
            return this.activityProducts
                .filter(activityProduct => activityProduct.isSelected)
                .map(activityProduct => ({
                    activityProductId: activityProduct._id,
                    realTotalQuantity: activityProduct.realTotalQuantity
                }))
        }
    },
    watch: {
        async getPropertySelected() {
            await this.loadActivityProducts()
        },
        sortedActivityProducts() {
            this.activityProducts = this.sortedActivityProducts.map((activityProduct, activityProductIndex) => {
                const calculatedTotalQuantity = this.calculateActivityProductTotalQuantityToUse(activityProduct.activity, activityProduct);
                return {
                    ...activityProduct,
                    index: activityProductIndex,
                    calculatedTotalQuantity: calculatedTotalQuantity,
                    realTotalQuantity: calculatedTotalQuantity,
                    isSelected: false
                }
            });
        }
    },
    methods: {
        ...mapActions("activities", ["findActivityProducts", "approveActivityProducts"]),
        ...mapActions("unit-conversions", ["findUnitConversions"]),
        getDisplayTextQuantityUnitDosage(activityProduct) {
            const activityProductQuantityValue = this.returnIfExists(activityProduct, "quantity.value");
            const activityProductQuantityUnitName = this.returnIfExists(activityProduct, "quantity.unit.name");
            const activityProductQuantityDosageName = this.returnIfExists(activityProduct, "quantity.dosage.name");
            if (!activityProductQuantityValue || !activityProductQuantityUnitName || !activityProductQuantityDosageName) {
                return '-';
            }

            const formattedActivityProductQuantityValue = this.formatNumber(activityProductQuantityValue);
            return `${formattedActivityProductQuantityValue} ${activityProductQuantityUnitName}/${activityProductQuantityDosageName}`;
        },
        getDisplayTextQuantityStockAvailable(activityProduct) {
            const stockProductQuantity = this.returnIfExists(activityProduct, "stockProduct.quantity");
            const stockProductUnitName = this.returnIfExists(activityProduct, "stockProduct.product.unit.name");
            if (!stockProductQuantity || !stockProductUnitName) {
                return '-';
            }

            const formattedStockProductQuantity = this.formatNumber(stockProductQuantity);
            return `${formattedStockProductQuantity} ${stockProductUnitName}`;
        },
        getDisplayTextCalculatedTotalQuantity(activityProduct) {
            const calculatedTotalQuantity = this.returnIfExists(activityProduct, "calculatedTotalQuantity");
            const stockProductUnitName = this.returnIfExists(activityProduct, "stockProduct.product.unit.name");
            if (!calculatedTotalQuantity || !stockProductUnitName) {
                return '-';
            }

            const formattedCalculatedTotalQuantity = this.formatNumber(calculatedTotalQuantity);
            return `${formattedCalculatedTotalQuantity} ${stockProductUnitName}`;
        },
        getDisplayTextRealTotalQuantity(activityProduct) {
            const realTotalQuantity = this.returnIfExists(activityProduct, "realTotalQuantity");
            const stockProductUnitName = this.returnIfExists(activityProduct, "stockProduct.product.unit.name");
            if (!realTotalQuantity || !stockProductUnitName) {
                return '-';
            }

            const formattedCalculatedTotalQuantity = this.formatNumber(realTotalQuantity);
            return `${formattedCalculatedTotalQuantity} ${stockProductUnitName}`;
        },
        openModalFixProductQuantity(activityProduct) {
            this.selectedActivityProduct = activityProduct
            this.showModalFixProductQuantity = true
        },
        closeModalFixProductQuantity() {
            this.showModalFixProductQuantity = false
        },
        openModalFixRealTotalQuantity(activityProduct) {
            this.selectedActivityProduct = activityProduct
            this.showModalFixRealTotalQuantity = true
        },
        closeModalFixRealTotalQuantity() {
            this.showModalFixRealTotalQuantity = false
        },
        saveRealTotalQuantity(activityProduct) {
            this.activityProducts[activityProduct.index].isSelected = false;
            this.activityProducts[activityProduct.index].realTotalQuantity = activityProduct.realTotalQuantity;
        },
        translateActivityType(activityType) {
            const translatedActivityTypes = {
                "planting": "Plantio",
                "pulverization": "Pulverização",
                "harvest": "Colheita",
                "soilPreparation": "Preparo de solo",
                "seedTreatment": "Tratamento de sementes",
                "fertilization": "Fertilização",
                "otherActivity": "Outra atividade"
            }
            return translatedActivityTypes[activityType]
        },
        hasQuantityAvailableInStock(activityProduct) {
            const stockProductId = this.returnIfExists(activityProduct, "stockProduct._id")
            if (!stockProductId) {
                return false;
            }

            const realTotalQuantity = this.returnIfExists(activityProduct, "realTotalQuantity") || 0;
            const newSelectedProductQuantity = this.selectedActivityProductsHash[stockProductId] || 0;
            const stockProductQuantity = this.returnIfExists(activityProduct, "stockProduct.quantity") || 0;

            return realTotalQuantity + newSelectedProductQuantity <= stockProductQuantity;
        },
        async loadActivityProducts() {
            this.loading = true

            this.activityProducts = []
            await this.findUnitConversions()
            await this.findActivityProducts({ propertyId: this.getPropertySelected._id })
            
            this.loading = false
        },
        async clearSearch() {
            this.searchWord = ''
        },
        validateProducts() {
            if (!requestActivityProductsToApprove.length) {
                throw new Error('Selecione pelo menos um produto para ser aprovado.')
            }
        },
        async approveSelectedActivityProducts() {
            try {
                this.approving = true

                const response = await this.approveActivityProducts(this.requestActivityProductsToApprove);

                this.approving = false

                const responseContainsError = response instanceof Error
                if (!responseContainsError) {
                    this.$vToastify.success(response.data.message, "Aprovar saídas");
                }

                await this.loadActivityProducts()
            } catch (error) {
                this.$vToastify.error(error.message, "Aprovar saídas")
            }
        }
    },
    async mounted() {
        await this.loadActivityProducts()
    }
}
</script>

<style lang="sass">
.stock-container
    display: flex
    flex-direction: column
    height: 100%
    width: 100%

.configurations-container__content
    flex: 1
    overflow: auto


.approve-exits-container
    display: flex
    flex-direction: column
    grid-gap: 6px
    width: 100%
    height: 100%

    &__header
        height: 76px

        .title
            font-size: 24px
            color: $theme-color-primary

        .actions
            display: flex
            justify-content: space-between

            .search-container
                display: flex
                justify-content: space-between
                width: 300px

                &__input
                    width: 250px
                    border: 0

                &__search
                    border: 0
                    width: 25px
                    background: none

                &__clear
                    border: 0
                    width: 25px
                    background: none

    &__content
        overflow: auto
        flex: 1

.approve-exits-table
    width: 100%
    table-layout: fixed

    &-header__actions
        width: 30%
        text-align: center

    &-content

        &__dates
            padding: 4px

        &__actions
            display: flex
            grid-gap: 6px
            align-items: center
            justify-content: center

            .btn
                background: none

                &:hover
                    background: $color-white-darken-1

                i
                    color: $color-blue

    th
        color: $color-blue
        padding: 0 10px
        font-size: 16px
        width: 200px

    th:nth-child(1)
        width: 150px

    th:nth-child(2)
        width: 220px

    th:nth-child(5)
        width: 300px

    th:nth-child(10)
        width: 120px

    tr
        border-radius: 20px
        height: 70px

        &:nth-child(even)
            background-color: #f2f2f2

    &__row
        &--red
            background-color: #EE9999 !important
            border-top: 2px solid #CC8080 !important
            border-bottom: 2px solid #CC8080 !important

    td
        padding: 0 10px

    thead
        position: sticky
        top: -10px
        background: $color-white
        z-index: 1

.no-content
    display: flex
    justify-content: center
    align-items: center
    height: 100px
    color: $color-grey
    grid-gap: 10px

@media (max-width: 430px)
    .approve-exits-container__header .actions .search-container__input
        width: 200px

</style>
