import NewProduct from '../../api/NewProduct';

const state = {
    newProducts: [],
    productsState: []
}

const getters = {
    getNewProducts: state => state.newProducts,
    stockProductsState: state => state.productsState,
}

const actions = {
    async findNewProducts({ commit }, { propertyId, commons, name, productCategoryId, limit = null, active }) {
        const response = await NewProduct.findNewProducts(propertyId, commons, name, productCategoryId, limit, active)
        const newProducts = response.data.data
        commit("SET_NEW_PRODUCTS", newProducts)
    },
    async createNewProduct({ commit }, newProduct) {
        const response = await NewProduct.createNewProduct(newProduct)
        return response
    },
    async updateNewProduct({ commit }, { newProductId, newProduct }) {
        const response = await NewProduct.updateNewProduct(newProductId, newProduct);
        commit("UPDATE_PRODUCTS", response.data.data)
        return response
    },
    async deleteNewProduct({ commit }, newProductId) {
        const response = await NewProduct.deleteNewProduct(newProductId);
        return response
    }
}

const mutations = {
    SET_NEW_PRODUCTS: (state, newProducts) => state.newProducts = newProducts,
    UPDATE_PRODUCTS: (state, updateProducts) => state.productsState = updateProducts
}

export default { state, getters, actions, mutations, namespaced: true }
