import Photo from '../../api/Photo';

const state = {
    photos: []
};

const getters = {
    getAllPhotos: (state) => state.photos
};

const actions = {
    getPhotos({ commit }) {
        Photo.getAll().then(response => {
            commit("setPhotos", response.data.data);
        })
    },
    async findPhotos({ commit }, payload) {
        const {
            propertyId,
            cropId,
            subareaId,
            fieldId,
            fieldIdList,
            startDate,
            endDate,
            template
        } = payload
        await Photo.findPhotos({propertyId, cropId, fieldId, fieldIdList, subareaId, startDate, endDate, template})
            .then(response => {
                commit("setPhotos", response.data.data);
            })
    },
    async updatePhoto({ commit }, photo) {
        const response = await Photo.update(photo._id, photo)
        if (!(response instanceof Error)) {
            return true
        }
        return false
    },
    getPhoto({ commit }, id) {
        Photo.get(id).then(response => {
            commit("setPhotos", response.data.data);
        })
    },
    findPhotosByInterval({ commit }, payload) {
        Photo.findByInterval(payload).then(response => {
            commit("setPhotos", response.data.data);
        })
    },
    clearPhotos({ commit }) {
        commit("setPhotos", [])
    }
};

const mutations = {
    setPhotos: (state, photos) => {
        if (photos) {
            state.photos = photos.filter(({ active }) => active);
        } else {
            state.photos = [];
        }
    }
};

export default { state, getters, actions, mutations, namespaced: true }
