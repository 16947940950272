<template>
    <div class="satellite-container">
        <div class="satellite-container__header">
            <div class="app-card filter">
                <combo-box-fields classParameter="m-0 select" @fieldSelected="selectField"
                    title="Talhão" msgFirstItem="Selecione um talhão" :listFieldsToChoose="fieldsList"/>
                <date-picker :dateSent="filter.startDate" @dateSelected="filter.startDate = $event.date"
                             label="Data Inicial"/>
                <date-picker :dateSent="filter.endDate" @dateSelected="filter.endDate = $event.date" label="Data Final"/>
                <button class="btn btn-primary btn--flat" @click="doFilter">
                    <span v-show="loading">
                        <span class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                        </span>
                    </span>
                    <span v-show="!loading">
                        <i class="fas fa-check mr-2"></i>
                        <span>Filtrar mapas</span>
                    </span>
                </button>
            </div>
            <div class="app-card subtitle">
                <ul class="list">
                    <li v-for="item in subtitles" :key="item.text">
                        <span>{{ item.text }}</span>
                        <span :class="item.class"/>
                    </li>
                </ul>
            </div>
        </div>
        <div class="satellite-container__content" v-if="!loading && ndvisList && ndvisList.length" tabindex="0"
             v-click-arrows:left="previousImage" v-click-arrows:right="nextImage">
            <div class="carousel-slide ">
                <div class="carousel-slide__title">
                    <span class="title">Mapas NDVI</span>
                    <span class="description">Selecione um mapa para ampliar</span>
                </div>
                <ul class="carousel-slide__slider">
                    <li
                        class="carousel-slide__slider__item"
                        v-for="(item, index) in ndvisList"
                        :key="item._id"
                        :class="{ active: index === indexImageActive }"
                        @click="indexImageActive = index"
                    >
                        <span>{{ item.field.name }}</span>
                        <img :src="item.image" alt=""/>
                        <span>{{ item.captured | formatDate }}</span>
                    </li>
                </ul>
            </div>
            <div class="carousel-view">
                <button class="next" @click="nextImage">
                    <i class="fas fa-chevron-right"/>
                </button>
                <div class="image-container">
                    <span>{{ imageActive.field.name }}</span>
                    <img class="image-item" :src="imageActive.image" alt=""/>
                    <span>{{ imageActive.captured | formatDate }}</span>
                </div>
                <button class="previous" @click="previousImage">
                    <i class="fas fa-chevron-left"/>
                </button>
            </div>
        </div>
        <div class="satellite-container__content no-content" v-else-if="!loading && ndvisList.length < 1">
            <i class="fa fa-exclamation-circle fa-2x"/>
            <span class="">Sem imagens para esse talhão</span>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import DatePicker from "@/components/commons/DatePicker.vue";
import ComboBoxFields from "@/components/commons/ComboBoxFields.vue";

export default {
    name: "Satellite",
    components: {
        DatePicker,
        ComboBoxFields,
    },
    data() {
        return {
            filter: {
                field: "all",
                startDate: "",
                endDate: "",
            },
            fieldsList: [],
            ndvisList: [],
            indexImageActive: 0,
            imageActive: {},
            loading: false,
            subtitles: [
                { text: 'Nuvens', class: 'color grey' },
                { text: 'Solo exposto, água ou sombra de nuvens', class: 'color red' },
                { text: 'Baixo índice fotossintético', class: 'color yellow' },
                { text: 'Alto índice fotossintético', class: 'color green' },
            ]
        };
    },
    computed: {
        ...mapGetters("fields", ["allFields"]),
        ...mapGetters("property", ["getPropertySelected", "getCropSelected"]),
        ...mapGetters("ndvis", ["getAllNdvis"])
    },
    methods: {
        ...mapActions("fields", ["getFieldsByProperty"]),
        ...mapActions("ndvis", ["clearNdvis", "findNdvis"]),
        ...mapActions("property", ["listFields"]),
        selectField({ fieldObject }) {
            if (fieldObject === 'all') {
                this.filter.field = fieldObject
            } else {
                this.filter.field = fieldObject._id
            }
        },
        async doFilter() {
            this.validate()
            this.loading = true
            const searchFilter = {
                startDate: this.filter.startDate,
                endDate: this.filter.endDate,
                cropId: this.getCropSelected._id,
                propertyId: this.getPropertySelected._id,
                fieldId: this.filter.field === 'all' ? '' : this.filter.field,
            }
            await this.findNdvis(searchFilter)
            this.ndvisList = this.getAllNdvis
            this.indexImageActive = 0
            this.loading = false
        },
        validate() {
            if (this.filter.startDate === '') {
                this.$vToastify.error('Selecione a data inicial!', 'NDVI')
                throw new Error('Selecione a data inicial!')
            }
            if (this.filter.endDate === '') {
                this.$vToastify.error('Selecione a data final!', 'NDVI')
                throw new Error('Selecione a data final!')
            }
        },
        getImageActive() {
            this.imageActive = this.ndvisList[this.indexImageActive]
        },
        nextImage() {
            this.indexImageActive = this.circularList(this.indexImageActive + 1, this.ndvisList.length)
        },
        previousImage() {
            this.indexImageActive = this.circularList(this.indexImageActive - 1, this.ndvisList.length)
        },
        async listFieldsByPropertyAndCrop() {
            const filter = {
                propertyId: this.getPropertySelected._id,
                cropId: this.getCropSelected._id
            }
            await this.getFieldsByProperty(filter)
            this.fieldsList = this.allFields
        },
    },
    watch: {
        async getPropertySelected() {
            await this.listFieldsByPropertyAndCrop()
        },
        async getCropSelected() {
            await this.listFieldsByPropertyAndCrop()
        },
        ndvisList() {
            this.getImageActive()
        },
        indexImageActive() {
            this.getImageActive()
        },
    },
    async mounted() {
        this.clearNdvis()
        await this.listFieldsByPropertyAndCrop()
    },
};
</script>

<style scoped lang="sass">
.satellite-container
    display: flex
    grid-gap: 10px
    flex-direction: column

    &__header
        display: flex
        justify-content: space-between
        grid-gap: 6px

        .filter
            grid-gap: 20px
            flex-wrap: wrap
            width: 100%

            .select
                width: 300px

            .datePicker
                margin: 0
                padding: 0
                max-width: 300px

            .btn
                margin-top: 22px
                max-width: 150px

            :nth-child(n)
                flex-grow: 1

        .subtitle
            height: 100%
            max-width: 400px
            width: 100%
            font-size: 14px
            font-weight: 300
            text-align: right

            .list
                list-style: none
                margin: 0
                padding: 0

                li
                    padding: 6px 0
                    display: flex
                    grid-gap: 10px
                    align-items: center
                    justify-content: flex-end

                .color
                    min-width: 20px
                    min-height: 20px
                    border-radius: 3px

                    &.red
                        background: #df3b31

                    &.yellow
                        background: #fef724

                    &.green
                        background: #0e8b0e

                    &.grey
                        background: #c8c8c8

    &__content
        display: flex
        justify-content: space-between
        grid-gap: 10px
        width: 100%
        height: 100%

        .carousel-slide
            width: 250px
            height: 100%
            border: $border
            border-radius: $border-radius

            &__title
                display: flex
                flex-direction: column
                text-align: center
                padding: 6px
                height: 64px

                .title
                    font-size: 20px
                    color: $color-blue

                .description
                    font-size: 14px

            &__slider
                margin: 0
                height: calc(100% - 64px)
                overflow: auto
                display: flex
                flex-direction: column
                align-items: center
                grid-gap: 20px
                text-align: center
                scroll-behavior: smooth
                padding: 4px

                &__item
                    .active
                        background: $color-blue-lighten-1
                        border-color: $color-blue-lighten-1

        .carousel-view
            width: calc(100% - 250px)
            height: 100%
            overflow: hidden
            position: relative
            display: flex
            align-items: center

            .image-container
                display: flex
                flex-direction: column
                justify-content: center
                align-items: center
                grid-gap: 6px
                font-size: 14px
                width: 100%
                height: 100%

            .image-item
                width: 500px
                height: 500px
                object-fit: contain

            .next, .previous
                position: absolute
                z-index: 10
                border-radius: 50%
                opacity: 0.7
                transition-duration: 0.3s
                background: unset
                width: 50px
                height: 50px
                border: none

                &:hover
                    background: white
                    filter: brightness(90%)

            .next
                right: 25px

            .preview
                left: 25px

    &__content.no-content
        display: flex
        justify-content: center
        align-items: center
        height: 100px
        color: $color-grey

@media (max-width: 960px)
    .satellite-container
        &__header
            flex-direction: column

            .subtitle
                max-width: unset

        &__content
            .carousel-slide
                display: none

            .carousel-view
                width: 100%

@media (max-width: 600px)
    .satellite-container
        padding: 2px

        &__content
            .carousel-view
                .image-item
                    width: 300px
                    height: 300px

                .next
                    right: 0

                .preview
                    left: 0

@media (max-height: 650px)
    .satellite-container__content
        .carousel-view
            .image-item
                width: 100%
                height: calc(100% - 44px)
</style>

<style scoped>
.carousel-slide__slider__item {
    width: 220px;
    height: 220px;
    display: flex;
    border-radius: 5px;
    background-color: hsl(0deg 0% 87%);
    cursor: pointer;
    flex-direction: column;
    justify-content: space-between;
    font-size: 14px;
}

.carousel-slide__slider__item img {
    width: 100%;
    height: calc(100% - 42px);
    object-fit: contain;
}
</style>
