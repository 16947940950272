import GeoJsonValidator from "geojson-validation"
import findPolygonIntersections from 'geojson-polygon-self-intersections'
import * as turf from '@turf/turf'

const mixin = {
    methods: {
        validatePolygons(polygons) {
            const geojson = this.formatPolygonsToGeoJson(polygons);

            this._validateGeoJsonFormat(geojson);
            this._validateGeoJsonPolygonIntersections(geojson);
            this._validateConsecutiveCoordinates(polygons);
            this._validatePolygonsLatitudeLongitude(polygons)

            return true
        },
        formatPolygonsToGeoJson(polygons) {
            if (!polygons) {
                throw new Error("O polígono é inválido");
            }
            if (!Array.isArray(polygons)) {
                throw new Error("O polígono deve ser informado em formato de array");
            }
            if (polygons.length < 3) {
                throw new Error("O polígono deve ter mais de 2 pontos")
            }

            return {
                type: "Feature",
                properties: {},
                geometry: {
                    type: "Polygon",
                    coordinates: [ polygons.map(polygon => [ parseFloat(polygon.longitude), parseFloat(polygon.latitude) ]) ]
                }
            };
        },
        getPolygonArea(polygon) {
            try {
                const geojson = this.formatPolygonsToGeoJson(polygon)
                const turfArea = turf.area(geojson)*0.0001
                
                return Math.round(turfArea * 100) / 100 
            } catch(error) {
                return 0
            }
        },
        _validateGeoJsonPolygonIntersections(geojson) {
            const intersections = findPolygonIntersections(geojson, intersection => intersection);

            if (intersections && intersections.length > 0) {
                throw new Error("O polígono não deve ter intersecções")
            }
        },
        _validateGeoJsonFormat(geojson) {
            const errors = GeoJsonValidator.isFeature(geojson,true);
            if (errors && errors.length > 0) {
                throw new Error(`O polígono apresenta erros => ${errors}`)
            }
        },
        _validateConsecutiveCoordinates(polygons) {
            if (!polygons || polygons.length < 4) {
                return;
            }

            const polygonsLength = polygons.length
            for (let i = 1; i < polygonsLength; i++) {
                if (polygons[i].latitude == polygons[i-1].latitude && polygons[i].longitude == polygons[i-1].longitude) {
                    throw new Error(`O polígono apresenta coordenadas consecutivas iguais => [${polygons[i].latitude}, ${polygons[i].longitude}]`)
                }
            }
        },
        _validateLatitude(latitude) {
            if (!isFinite(latitude) || Math.abs(latitude) > 90) {
                throw new Error(`Os valores de latitude devem estar entre -90 e 90`)
            }
        },

        _validateLongitude(longitude) {
            if (!isFinite(longitude) || Math.abs(longitude) > 180) {
                throw new Error(`Os valores de longitude devem estar entre -180 e 180`)
            }
        },

        _validatePolygonsLatitudeLongitude(polygons) {
            for (const { latitude, longitude } of polygons) {
                this._validateLatitude(latitude)
                this._validateLongitude(longitude)
            }
        },

        getPropertyCoordinates(latitude, longitude) {
            try {
                this._validateLatitude(latitude);
                this._validateLongitude(longitude);
                return { latitude, longitude }
            } catch (error) {
                return { 
                    latitude: -11.8831661, 
                    longitude: -60.1766577 
                }
            }
        }
    }
}

export default  mixin
