import ProductCategory from '../../api/ProductCategory';

const state = {
    productCategories: []
}

const getters = {
    getProductCategories: state => state.productCategories
}

const actions = {
    async findProductCategories({ commit }) {
        const response = await ProductCategory.findProductCategories()
        const productCategories = response.data.data
        commit("SET_PRODUCT_CATEGORIES", productCategories)
    }
}

const mutations = {
    SET_PRODUCT_CATEGORIES: (state, productCategories) => state.productCategories = productCategories,
}

export default { state, getters, actions, mutations, namespaced: true }
