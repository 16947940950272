<template>
    <div class="my-specific-products-container">
        <div class="my-specific-products-container__header">
            <span class="title">Meus produtos específicos</span>
            <div class="actions">
                <div class="search-container app-input">
                    <input class="search-container__input" placeholder="Pesquise um produto"
                           v-model="searchWord" type="text"/>
                    <button class="search-container__clear clickable" v-show="searchWord.length > 0"
                            @click="clearSearch">
                        <i class="fal fa-times-circle"/>
                    </button>
                    <button class="search-container__search">
                        <i class="fas fa-search"/>
                    </button>
                </div>
                <button class="btn btn-primary btn--flat" @click="openModalCreateProducts">
                    <span>Novo produto</span>
                </button>
            </div>
            <div>
                <div class="app-checkbox">
                    <input id="productFilter" type="checkbox" v-model="deativatedProducts"/>
                    <label for="productFilter">Ver produtos específicos desativados</label>
                </div>
            </div>
        </div>
        <div v-if="!loading" class="my-specific-products-container__content">
            <table class="my-specific-products-table">
                <thead>
                    <tr>
                        <th>Nome</th>
                        <th>Unidade</th>
                        <th>Categoria</th>
                        <th>Sub Categoria</th>
                        <th class="my-specific-products-table-header__actions">Ações</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="product in products" :key="product._id">
                        <td> {{ product.name }} </td>
                        <td> {{ product.unit.name }} </td>
                        <td> {{ formatProductCategory(product.productCategory) || '-' }} </td>
                        <td> {{ formatProductSubCategory(product.productCategory) || '-' }} </td>
                        <td>
                            <div class="my-specific-products-table-content__actions" v-if="product.property">
                                <button @click="openModalEditProducts(product)"
                                        class="btn btn--flat">
                                    <i class="fas fa-edit"></i>
                                    <span>Editar</span>
                                </button>
                                <button @click="removeNewProduct(product._id)"
                                        class="btn btn--flat btn--outline">
                                    <span v-show="removingNewProduct == product._id" class="spinner-border spinner-border-sm"/>
                                    <i v-show="removingNewProduct != product._id" class="fas fa-trash"/>
                                    <span v-show="removingNewProduct != product._id">Excluir</span>
                                </button>
                                <button
                                    type="checkbox"
                                    class="btn btn--flat"
                                    @click="activeDeactiveProduct(product)"
                                >
                                    <i :class="product.active ? 'fas fa-check-square' : 'far fa-square'"></i>
                                        <span>{{ product.active ? 'Desativar' : 'Reativar' }}</span>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div v-show="products.length == 0" class="no-content">
                <i class="fa fa-exclamation-circle fa-2x"/>
                <span class="">Não foram encontrados produtos</span>
            </div>
        </div>
        <div v-else class="no-content">
            <span class="spinner-border" />
        </div>
        <modal-product v-if="showModalProduct" :open="showModalProduct" @close="closeModalProduct" @save="loadNewProducts" :data="selectedProduct" :editProduct="edit">
        </modal-product>
    </div>
</template>

<script>

import { mapActions, mapGetters } from "vuex";
import ModalProduct from "@/components/stock/ModalProduct.vue"

export default {
    name: 'MySpecificProducts',
    components: {
        ModalProduct
    },
    data() {
        return {
            products: [],
            selectedProduct: {},
            loading: false,
            removingNewProduct: null,
            showModalProduct: false,
            edit: false,
            searchWord: '',
            deativatedProducts: false,
            payload: {
                propertyId: ''
            }
        }
    },
    computed: {
        ...mapGetters("property", ["getPropertySelected"]),
        ...mapGetters("new-products", ["getNewProducts"])
    },
    watch: {
        async getPropertySelected() {
            await this.loadNewProducts()
        },
        searchWord() {
            this.filterNewProducts()
        },
        async deativatedProducts(){
            this.loadNewProducts()
        }
    },
    async mounted() {
        await this.loadNewProducts()
    },
    methods: {
        ...mapActions("new-products", ["findNewProducts", "deleteNewProduct", "updateNewProduct"]),
        openModalCreateProducts(){
            this.selectedProduct = {}
            this.showModalProduct = true
            this.edit = false
        },
        openModalEditProducts(product){
            this.selectedProduct = product
            this.showModalProduct = true
            this.edit = true
        },
        closeModalProduct(){
            this.showModalProduct = false
            this.edit = !this.edit
        },
        filterNewProducts(){
            this.products = this.getNewProducts.filter((newProduct) => {
                return (
                    newProduct.name.toUpperCase().includes(this.searchWord.toUpperCase()) ||
                    newProduct.unit.name.toUpperCase().includes(this.searchWord.toUpperCase()) ||
                    newProduct.productCategory && newProduct.productCategory.name.toUpperCase().includes(this.searchWord.toUpperCase()) ||
                    newProduct.productCategory && newProduct.productCategory.parentProductCategory && newProduct.productCategory.parentProductCategory.name.toUpperCase().includes(this.searchWord.toUpperCase())
                )
            })
        },
        async removeNewProduct(newProductId) {
            const wantsToDelete = await this.$vToastify.prompt({
                body: "Deseja excluir esse item?",
                title: "ATENÇÃO!",
                answers: { Sim: true, Não: false },
            })

            if (wantsToDelete) {
                this.removingNewProduct = newProductId

                const response = await this.deleteNewProduct(newProductId)
                const responseContainsError = response instanceof Error
                if (!responseContainsError) {
                    this.$vToastify.success(response.data.message, "Produtos");
                    await this.loadNewProducts()
                }

                this.removingNewProduct = null
            }
        },
        async activeDeactiveProduct(product){
            const { newProductId, active } = {
                newProductId: product._id,
                active: !product.active,
            }
            const confirmActiveDeactive = await this.$vToastify.prompt({
                body: "Deseja confirmar está ação?",
                title: "ATENÇÃO!",
                answers: { Sim: true, Não: false },
            })
            if (confirmActiveDeactive){
                await this.updateNewProduct({ newProductId, newProduct: { active } })
                await this.loadNewProducts()
                const sucessText = product.active == true ? 'Desativado' : 'Reativado'
                this.$vToastify.success(`${sucessText} com sucesso`, "Produto")
            } else return
        },
        async loadNewProducts() {
            this.loading = true

            this.products = []
            await this.findNewProducts({ propertyId: this.getPropertySelected._id, commons: false, active: this.deativatedProducts == false ? true : false })
            this.products = this.getNewProducts

            this.loading = false
        },
        async clearSearch() {
            this.searchWord = ''
            this.products = this.getNewProducts
        }
    }
}
</script>

<style lang="sass" scoped>
.my-specific-products-container
    display: flex
    flex-direction: column
    grid-gap: 6px
    width: 100%

    &__header
        .title
            font-size: 24px
            color: $theme-color-primary

        .actions
            display: flex
            justify-content: space-between

            .search-container
                display: flex
                justify-content: space-between
                width: 300px

                &__input
                    width: 250px
                    border: 0

                &__search
                    border: 0
                    width: 25px
                    background: none

                &__clear
                    border: 0
                    width: 25px
                    background: none

.my-specific-products-table
    width: 100%

    &-header__actions
        width: 30%
        text-align: center

    &-content__actions
        display: flex
        grid-gap: 6px
        align-items: center
        justify-content: center

        .btn
            background: none

            &:hover
                background: $color-white-darken-1

            i
                color: $color-blue

    th
        color: $color-blue
        padding: 0 10px
        font-size: 16px
        width: 16.6666%

    tr
        border-radius: 20px
        height: 70px

        &:nth-child(even)
            background-color: #f2f2f2

    td
        padding: 0 10px
        width: 16.6666%

    thead
        position: sticky
        top: -10px
        background: $color-white
        z-index: 1

.no-content
    display: flex
    justify-content: center
    align-items: center
    height: 100px
    color: $color-grey
    grid-gap: 10px

@media (max-width: 430px)
    .my-specific-products-container__header .actions .search-container__input
        width: 200px

</style>
