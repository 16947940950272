<template>
    <modal :open="open" :loading="false" @close="$emit('close')">
        <div class="app-modal-header" slot="header">
            <span>{{ displayTextUser }}</span>
        </div>
        <div class="app-modal-body" slot="body">
            <div class="content">
                <div class="content__item">
                    <label class="app-label">Nome</label>
                    <input
                        class="app-input"
                        type="text"
                        placeholder="Nome do usuário"
                        v-model="userData.name"
                    />
                </div>
                <div class="content__item">
                    <label class="app-label">Sobrenome</label>
                    <input
                        class="app-input"
                        type="text"
                        placeholder="Sobrenome do Usuário"
                        v-model="userData.lastname"
                    />
                </div>
                <div class="content__item">
                    <label class="app-label">Telefone</label>
                    <input
                        class="app-input"
                        type="text"
                        v-mask="'(##) #####-####'"
                        placeholder="Telefone"
                        v-model="userData.phone"
                    />
                </div>
                <div class="content__item">
                    <label class="app-label">Email</label>
                    <input
                        class="app-input"
                        type="text"
                        placeholder="Email para acesso"
                        v-model="userData.email"
                    />
                </div>
                <div class="content__item">
                    <label class="app-label">Senha</label>
                    <input
                        class="app-input"
                        type="password"
                        placeholder="Senha para acesso"
                        v-model="userData.password"
                    />
                </div>
                <div v-if="isEdit" class="content__item">
                    <label class="app-label">Senha BI</label>
                    <input
                        class="app-input"
                        type="text"
                        placeholder="Senha para acesso ao BI"
                        v-model="userData.passwordBI"
                    />
                </div>
            </div>
                <CheckboxGroup
                    class="content__item content__item--full-width"
                    :title="'Propriedades que terá acesso'"
                    :items="userProperties"
                    :value="userData.properties"
                    :uuid="uuidProperties"
                    @input="userData.properties = $event"
                />
                <CheckboxGroup
                    class="content__item content__item--full-width"
                    :title="'Funcionalidades que terá acesso'"
                    :items="userFeatureList"
                    :value="userData.featureList"
                    :uuid="uuidFeatureList"
                    @input="userData.featureList = $event"
                />
                <CheckboxGroup
                    class="content__item content__item--full-width"
                    :title="'Tipo de usuário'"
                    :items="userRole"
                    :value="userData.role"
                    :uuid="uuidRole"
                    @input="userData.role = $event"
                />
        </div>
        <div class="app-modal-footer" slot="footer">
            <button
                class="btn btn-primary btn--flat"
                @click="saveUser()"
                :disabled="loading"
            >
                <span v-if="loading" class="spinner-border" role="status" />
                <span v-else>
                    <span>Salvar</span>
                    <i class="ml-2 fas fa-save" />
                </span>
            </button>
        </div>
    </modal>
</template>

<script>
import Modal from "@/components/systemUI/Modal.vue";
import CheckboxGroup from '@/components/commons/CheckboxGroup.vue'
import { v4 as uuidv4 } from 'uuid';
import { mapActions, mapGetters } from "vuex";

export default {
    name: "ModalUser",
    props: {
        data: Object,
        open: Boolean,
    },
    components: {
        Modal,
        CheckboxGroup
    },
    data() {
        return {
            loading: false,
            userProperties: [],
            userFeatureList: [],
            userRole: [],
            uuidProperties: uuidv4(),
            uuidFeatureList: uuidv4(),
            uuidRole: uuidv4(),
            userData: {
                properties: [],
                name: "",
                lastname: "",
                email: "",
                password: "",
                passwordBI: "",
                role: [],
                featureList: [],
                phone: "",
                emailNotification: false,
                phoneNotification: false,
            },
        };
    },
    computed: {
        ...mapGetters("users", ["profile"]),
        ...mapGetters("property", ["getProperties"]),
        displayTextUser() {
            return !this.data._id ? "Novo Usuário" : "Editar Usuário";
        },
        filteredProperties() {
            return this.getProperties.filter((property) => property.active);
        },
        formatProperties(){
            return this.userData.properties.map(property => {
                return { propertyId: property}
            })
        },
        isEdit() {
            return !!this.data._id;
        }
    },
    mounted() {
        this.copyData();
        this.profileProperties();
        this.profileFeatureList();
        this.profileRole();
    },
    watch: {
        data() {
            this.copyData();
        },
    },
    methods: {
        ...mapActions("users", ["insertUser", "updateUser", "getUser"]),
        copyData() {
            this.userData = _.cloneDeep(this.data);
        },
        profileProperties() {
            this.userProperties = this.filteredProperties.map((property) => {
                return {
                    label: property.name,
                    value: property._id,
                    _id: uuidv4(),
                };
            });
        },
        profileFeatureList(){
            const hashFeatureList = {
                "ndvi": "Satélite",
                "mip": "Monitoramento",
                "stock": "Estoque",
                "planner": "Planejamento",
                "report": "Relatórios",
                "climate": "Tempo",
                "bi": "Inteligência BI"
            }
            this.userFeatureList = this.profile.featureList.map(feature => {
                return {
                    label: hashFeatureList[feature],
                    value: feature,
                    _id: uuidv4(),
                };
            })
        },
        profileRole(){
            const adminIndex = this.profile.role.indexOf('admin')
            if(adminIndex != -1) {
                this.profile.role.splice(adminIndex)
            }
            const hashRoleList = {
                "farmer-admin": "Gerente da conta",
                "farmer": "Usuário"
            }
            this.userRole = this.profile.role.map(role => {
                return {
                    label: hashRoleList[role],
                    value: role,
                    _id: uuidv4(),
                }
            })
        },
        async saveUser() {
            const user = {
                _id: this.userData._id,
                properties: this.formatProperties,
                name: this.userData.name,
                lastname: this.userData.lastname,
                email: this.userData.email,
                password: this.userData.password || undefined,
                passwordBI: this.userData.passwordBI || undefined,
                role: this.userData.role,
                featureList: this.userData.featureList,
                phone: this.userData.phone,
                emailNotification: this.userData.emailNotification,
                phoneNotification: this.userData.phoneNotification,
            };
            this.loading = true;

            if (this.isEdit) {
                this.getUser()
                await this.updateUser(user)
                    .then((response) => {
                        return this.$vToastify.success(
                            response.data.message,
                            "Usuário"
                        );
                    })
                    .catch((error) => {
                        this.loading = false;
                        return this.$vToastify.error(
                            error.response.data.message,
                            "Usuário"
                        );
                    });
            } else {
                await this.insertUser(user)
                    .then((response) => {
                        return this.$vToastify.success(
                            response.data.message,
                            "Usuário"
                        );
                    })
                    .catch((error) => {
                        this.loading = false;
                        return this.$vToastify.error(
                            error.response.data.message,
                            "Usuário"
                        );
                    });
            }
            this.loading = false;
            this.$emit("close");
            this.$emit("inserted");
        },
    },
};
</script>

<style scoped lang="sass">
.content
    display: flex
    flex-wrap: wrap

    &__item
        width: 50%
        padding: 4px

        &--full-width
            width: 100%

@media (max-width: 500px)
    .content
        &__item
            width: 100%
</style>
