<template>
  <div slot="body">
    <div v-if="photosOrdered.length == 0">
      <div class="row d-flex align-items-center">
        <div class="col-md-12">
          <h5 class="title text-center m-0">Sem imagens para esse talhão</h5>
          <i
            class="row justify-content-center text-secondary fa fa-exclamation-circle fa-5x mt-4"
          ></i>
        </div>
      </div>
    </div>

    <div v-else class="row d-flex mt-4 mx-0">
      <div
        class="col-sm-6 col-md-3 p-2"
        v-for="(photo, index) in photosOrdered"
        :key="photo.image"
      >
        <div @click="openLightboxOnSlide(index)">
          <image-card
            :linkImage="photo.image"
            :description="photo.captured | formatDate"
            :title="(photo.subarea && photo.subarea.field && photo.subarea.field.name) || ''"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ImageCard from "../../components/commons/ImageCard.vue";
import { ordeyByDrecrescentDateCaptured } from "../../utils/orderByDate";
export default {
  components: { ImageCard },
  props: {
    photos: {
      type: Array,
    },
  },
  data() {
    return {
      toggler: false,
      slide: 0,
      controlLight: false,
    };
  },
  computed: {
    photosOrdered() {
      return ordeyByDrecrescentDateCaptured(this.photos);
    },
    photosLightBox() {
      const aux = [];
      this.photosOrdered.map((res) => {
        aux.push(res.image);
      });
      return aux;
    },
  },

  mounted() {},

  watch: {
    photosOrdered() {
      this.controlLight = !this.controlLight;
    },
  },

  methods: {
    openLightboxOnSlide(number) {
      this.slide = number;
      this.toggler = !this.toggler;
    },
  },
};
</script>
