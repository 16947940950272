<template>
    <div>
        <div class="configurations-container">
            <div class="configurations-container__tabs">
                <div class="tab">
                    <router-link v-if="hasPermissionRole('farmer-admin')" class="tab__item" tag="a" to="/bens/propriedades">Propriedades</router-link>
                    <router-link v-if="hasPermissionRole('farmer-admin')" class="tab__item" tag="a" to="/bens/usuarios">Usuários</router-link>
                    <router-link class="tab__item" tag="a" to="/bens/safras">Safras</router-link>
                    <router-link class="tab__item" tag="a" to="/bens/talhoes">Talhões</router-link>
                    <router-link class="tab__item" tag="a" to="/bens/maquinarios">Maquinários</router-link>
                    <router-link class="tab__item" tag="a" to="/bens/operadores">Operadores</router-link>
                </div>
            </div>
            <div class="configurations-container__content">
                <router-view/>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Configuration'
}
</script>

<style lang="sass" scoped>

.configurations-container
    min-width: 1000px

.tab
    height: 60px
    display: flex
    align-items: center
    justify-content: center
    border-bottom: 1px solid #ddd

    &__item
        line-height: 60px
        text-decoration: none
        padding: 0 25px
        color: $color-grey
        font-size: 20px


.router-link-exact-active
    color: $color-blue
    border-bottom: 1px solid $color-blue

</style>
