<template>
    <div>
        <label class="app-label"> {{ title }} </label>
        <div class="checkbox-group">
            <div class="app-checkbox checkbox-group__item">
                <input
                    :id="`${uuid}_checkedAllItems`"
                    v-model="checkedAllItems"
                    type="checkbox"
                    @change="$emit('input', checkedAllItems ? items.map(item => item.value) : [])"
                />
                <label class="label__options" :for="`${uuid}_checkedAllItems`"> Todos </label>
            </div>
            <div
                class="app-checkbox checkbox-group__item"
                v-for="item in items"
                :key="item._id"
            >
                <input
                    :id="`${uuid}_${item._id}`"
                    type="checkbox"
                    :value="item.value"
                    v-model="selectedItems"
                    @change="$emit('input', selectedItems)"
                />
                <label class="label__options" :for="`${uuid}_${item._id}`"> {{ item.label }} </label>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CheckboxGroup",
    props: {
        title: {
            type: String
        },
        uuid: {
            type: String,
        },
        value: {
            type: Array
        },
        items: {
            type: Array
        }
    },
    data() {
        return {
            checkedAllItems: false,
            selectedItems: []
        };
    },
    watch: {
        value(){
            this.selectedItems = [...this.value]
        },
        checkedAllItems() {
            if (this.checkedAllItems) {
                this.selectedItems = this.items.map(item => item.value)
            } else if (this.selectedItems.length == this.items.length) {
                this.selectedItems = []
            }
        },
        selectedItems() {
            this.checkedAllItems = this.selectedItems.length > 0 && this.selectedItems.length == this.items.length
        }
    },
    mounted() {
        this.selectedItems = [...this.value]
    }
};
</script>

<style lang="sass" scoped>
.checkbox-group-label
    width: 100%
    padding: 4px


.checkbox-group
    display: flex
    width: 100%
    flex-wrap: wrap

    &__item
        width: 33.333%
        display: flex
        align-items: center
        padding: 6px 6px 6px 0px

.label__options
    font-size: 14px
</style>
