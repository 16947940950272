<template>
    <Modal :open="open" :loading="false" @close="$emit('close')">
        <div class="app-modal-header" slot="header">
            <i class="fas fa-tractor"></i>
            <span>{{ !data._id ? 'Novo maquinário' : 'Editar maquinário' }}</span>
        </div>
        <div class="app-modal-body" slot="body">
            <div class="content">
                <div class="content__item">
                    <MultipleSelector
                        label="Funções"
                        placeholder="Selecione as funções"
                        v-model="machinery.types"
                        :items="machineryTypes"
                    />
                </div>
                <div class="content__item">
                    <label class="app-label" for="manufacturer">Fabricante</label>
                    <select id="manufacturer" v-model="machinery.manufacturer" class="app-select">
                        <option disabled>
                            Selecione o fabricante
                        </option>
                        <option v-for="manufacturer in machineryManufacturers" :key="manufacturer" :value="manufacturer">
                            {{ manufacturer }}
                        </option>
                    </select>
                </div>
                <div class="content__item">
                    <label class="app-label">Modelo</label>
                     <input class="app-input" type="text" placeholder="Digite o modelo" v-model="machinery.model"/>
                </div>
                <div class="content__item">
                    <label class="app-label">Chassi/Nº Série</label>
                     <input class="app-input" type="text" placeholder="Digite o chassi" v-model="machinery.chassi"/>
                </div>
                <div class="content__item">
                    <input-number id="pulverizador" label="Capacidade (L)" placeholder="Informe a capacidade"
                                  :data="machinery.capacity.value" @input="machinery.capacity.value = $event"/>
                </div>
                <p class="app-label property-selector-label"> Propriedades onde está disponível </p>
                <div class="property-selector">
                    <div class="app-checkbox property-selector__item">
                        <input id="all" type="checkbox" v-model="checkedAllProperties"/>
                        <label for="all"> Todos </label>
                    </div>
                    <div class="app-checkbox property-selector__item" v-for="property in userProperties"
                         :key="property.property._id">
                        <input :id="property.property._id" type="checkbox" :value="property" v-model="machinery.properties"/>
                        <label :for="property.property._id"> {{ property.property.name }} </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="app-modal-footer" slot="footer">
            <button class="btn btn-primary btn--flat" @click="saveMachinery" :disabled="loading">
                <span v-if="loading" class="spinner-border" role="status"/>
                <span v-else>
                    <span>Salvar</span>
                    <i class="ml-2 fas fa-save"/>
                </span>
            </button>
        </div>
    </Modal>
</template>

<script>

import { mapActions, mapGetters } from "vuex";
import Modal from "@/components/systemUI/Modal.vue"
import MultipleSelector from '@/components/commons/MultipleSelector.vue'
import InputNumber from "@/components/commons/InputNumber"

export default {
    name: 'ModalMachinery',
    props: {
        data: Object,
        open: Boolean,
    },
    components: {
        Modal,
        MultipleSelector,
        InputNumber
    },
    data() {
        return {
            loading: false,
            checkedAllProperties: false,
            machinery: {
                types: [],
                manufacturer: 'CASE IH',
                model: '',
                capacity: {
                    value: null,
                    unity: 'L'
                },
                chassi: '',
                properties: []
            },
            userProperties: [],
            machineryTypes: [
                "Adubadora automotriz",
                "Atomizador",
                "Arado",
                "Colhedora",
                "Carreta duas rodas",
                "Carreta quatro rodas",
                "Empilhadora",
                "Enfardadeira",
                "Ensiladora",
                "Grade Niveladora",
                "Grade Leve",
                "Grade Intermediária",
                "Grade Pesada",
                "Linque",
                "Máquina de Plantação",
                "Pré-podarora",
                "Pulverizador",
                "Retroescavadeira",
                "Roçadeira",
                "Semeadora",
                "Subsolador",
                "Trator",
                "Vindimadora",
                "Outros"
            ],
            machineryManufacturers: [
                "CASE IH",
                "CAT",
                "Ford",
                "Jacto",
                "Jan",
                "JCB",
                "John Deere",
                "Komatsu",
                "Massey Ferguson",
                "New Holland",
                "Planti Center",
                "STARA",
                "Tatu",
                "Valtra",
                "Outros"
            ]
        };
    },
    computed: {
        ...mapGetters("machineries", ["machineriesState", "error"]),
        ...mapGetters("property",["getPropertySelected"]),
        ...mapGetters("users", ["profile"]),
        filteredProperties(){
            return this.profile.properties.filter(property => property.property.active)
        },
    },
    mounted() {
        this.copyData()
    },
    watch: {
        data(){
            this.copyData()
        },
        profile(){
            this.copyData()
        },
        open(){
            this.resetMachinery()
            if(this.open){
                this.copyData()
            }
        },
        checkedAllProperties(){
            if(this.checkedAllProperties){
                this.machinery.properties = [...this.userProperties]
            }else if(this.machinery.properties.length == this.userProperties.length){
                this.machinery.properties = []
            }
        },
        'machinery.properties'(){
            this.checkedAllProperties = this.machinery.properties.length == this.userProperties.length
        }
    },
    methods: {
        ...mapActions("machineries", ["insertMachinery", "updateMachinery"]),
        copyData(){
            if(this.data._id){
                this.machinery = { ...this.data }
                this.machinery.capacity = { ...this.data.capacity }
                this.machinery.types = [ ...this.data.types ]
            }

            this.machinery.properties = this.machinery.properties.map((property) => {
                return { property: property.property }
            })
            this.userProperties = this.filteredProperties.map((property) => {
                return { property: property.property }
            })
        },
        resetMachinery(){
            this.machinery = {
                types: [],
                manufacturer: 'CASE IH',
                model: '',
                capacity: {
                    value: null,
                    unity: 'L'
                },
                chassi: '',
                properties: []
            }
        },
        async saveMachinery() {
            this.loading = true
            if (this.data._id) {
                this.machinery._id = this.data._id
                await this.updateMachinery(this.machinery)
                this.loading = false
                if (this.error) {
                    return this.$vToastify.error(this.error.data.message || this.error.data.errors[0].msg, "Maquinário");
                }
            } else {
                await this.insertMachinery(this.machinery)
                this.loading = false
                if (this.error) {
                    return this.$vToastify.error(this.error.data.message || this.error.data.errors[0].msg, "Maquinário");
                }
            }
            this.$vToastify.success("Salvo com sucesso!", "Maquinário");
            this.resetMachinery()
            this.$emit('close')
            this.$emit('save')
        },
    }
};
</script>

<style scoped lang="sass">
.content
    display: flex
    flex-wrap: wrap

    &__item
        width: 50%
        padding: 4px

.centered-checkbox
    display: flex
    align-items: center

.property-selector-label
    width: 100%
    padding: 4px

.property-selector
    display: flex
    flex-wrap: wrap
    width: 100%

    &__item
        width: 33.333%
        display: flex
        align-items: center
        padding: 4px

@media (max-width: 500px)
    .content
        &__item
            width: 100%

    .property-selector
        &__item
            width: 50%

</style>
