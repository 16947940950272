import API from './API';

export default {

    getAll() {
      return API.get("/subareas")
    },

    findSubareas({ propertyId, cropId, fieldId }) {
        return API.get("/subareas", {
            params: {
                ... propertyId && !fieldId ? { propertyId } : {},
                ... cropId ? { cropId } : {},
                ... fieldId ? { fieldId } : {}
            }
        })
    },
    createSubarea(subarea) {
        return API.post("/subareas", subarea)
    },
    updateSubarea(subareaId, subarea) {
        return API.put(`/subareas/${subareaId}`, subarea)
    },
    deleteSubarea(subareaId) {
        return API.delete(`/subareas/${subareaId}`)
    },

    getPhotos(id) {
      return API.get(`/photos/properties/${id}`)
    },

    findById(id) {
      return API.get(`/subareas/${id}`)
    },

    findByPropertyAndCrop(propertyId, cropId){
        return API.get(`/subareas/properties/${propertyId}/crops/${cropId}`)
            .then(response => response.data.data)
    },

    finByFieldId(fieldId) {
        return API.get(`/subareas?fieldId=${fieldId}`)
    }

}
