import Product from '../../api/Product';

const state = {
    productsState: [],
    productState: {},
    products: [],
    error: null
}

const getters = {
    productsState: state => state.productsState,
    productState: state => state.productState,
    error: state => state.error
}

const actions = {
    async getProduct({ commit }, id) {
        await Product.get(id)
            .then(response => {
                commit("setProduct", response.data.data)
            })
            .catch((error) => {
                commit("setError", error);
            });
    },
    async insertProduct({ commit }, productState) {
        const response = await Product.post(productState)
        if (response instanceof Error) {
            commit("setError", response.response)
        } else {
            commit("newProduct", response.data.data)
            commit("setError", null)
        }
    },
    async updateProduct({ commit }, productState) {
        const response = await Product.update(productState)
        if (response instanceof Error) {
            commit("setError", response.response)
        } else {
            commit("updateProduct", response.data.data)
            commit("setError", null)
        }
    },
    setProducts({ commit }, productsState) {
        commit("setProducts", productsState)
    },
    removeProduct({ commit }, productState) {
        Product.delete(productState._id).then(response => {
            commit("removeProduct", response.data.data)
        })
            .catch((error) => {
                commit("setError", error);
            });
    }
}

const mutations = {
    setError: (state, error) => state.error = error,
    setProducts: (state, productsState) => state.productsState = productsState,
    setProduct: (state, productState) => state.productState = productState,
    newProduct: (state, productState) => state.productsState.unshift(productState),
    updateProduct: (state, changedProduct) => {
        const index = state.productsState.findIndex(elem => elem._id == changedProduct._id)
        if (index !== -1)
            state.productsState.splice(index, 1, changedProduct)
    },
    removeProduct: (state, productState) => state.productsState = state.productsState.filter(elem => elem._id != productState._id)
}

export default { state, getters, actions, mutations, namespaced: true }
