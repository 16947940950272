export const calculatePeriodInterval = function(selectedInterval, cropSelected) {
  

  const periodSelected = {
    startDate: new Date(),
    endDate: new Date(),
    custom: false,
  };

  
  if (selectedInterval == "weekly") {
    periodSelected.startDate.setDate(periodSelected.endDate.getDate() - 7)
  } else if (selectedInterval == "monthly") {
    periodSelected.startDate.setDate(periodSelected.endDate.getDate() - 30)
  } else if (selectedInterval == "yearly") {
    periodSelected.startDate.setDate(periodSelected.endDate.getDate() - 365)
  } else if (selectedInterval == "currentCrop") {
    periodSelected.startDate= new Date(cropSelected.startDate);
    periodSelected.endDate = new Date(cropSelected.endDate);
  } else if (selectedInterval == "custom") {
    periodSelected.startDate = '';
    periodSelected.endDate = ''; 
    periodSelected.custom = true  
  } else {
    periodSelected.startDate = ''
  }

 
  if(periodSelected.startDate != ''){
    periodSelected.startDate.setUTCHours(0, 0, 0, 0);
    periodSelected.startDate = periodSelected.startDate.toISOString().split('T')[0];
  }
  if(periodSelected.endDate != ''){
    periodSelected.endDate.setUTCHours(0, 0, 0, 0);
    periodSelected.endDate = periodSelected.endDate.toISOString().split('T')[0];
  }


  return periodSelected;
};
