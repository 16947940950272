import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import error from '@/store/modules/error'
import property from "./modules/properties";
import fields from "./modules/fields";
import crops from "./modules/crops";
import culturePhases from "./modules/culture-phases";
import plannings from "./modules/plannings";
import users from "./modules/users";
import operators from "./modules/operators";
import machineries from "./modules/machineries";
import ndvis from './modules/ndvis';
import products from "./modules/products";
import pragues from "./modules/pragues";
import activities from './modules/activities'
import photos from './modules/photos'
import subareas from "./modules/subareas";
import weather from '@/store/modules/weather'
import productCategories from '@/store/modules/product-categories';
import newProducts from '@/store/modules/new-products';
import reports from '@/store/modules/reports';
import stockProducts from '@/store/modules/stock-products';
import stockRegister from '@/store/modules/stock-register';
import cultures from '@/store/modules/cultures';
import unities from '@/store/modules/unities';
import units from '@/store/modules/units';
import unitConversions from '@/store/modules/unit-conversions';

Vue.use(Vuex);

export default new Vuex.Store({
    plugins: [
        createPersistedState({
            storage: window.sessionStorage,
        }),
    ],
    modules: {
        "activities": activities,
        "crops": crops,
        "culture-phases": culturePhases,
        "cultures": cultures,
        "error": error,
        "fields": fields,
        "machineries": machineries,
        "ndvis": ndvis,
        "new-products": newProducts,
        "operators": operators,
        "photos": photos,
        "plannings": plannings,
        "pragues": pragues,
        "products": products,
        "product-categories": productCategories,
        "property": property,
        "reports": reports,
        "subareas": subareas,
        "stock-products": stockProducts,
        "stock-register": stockRegister,
        "unities": unities,
        "units": units,
        "unit-conversions": unitConversions,
        "users": users,
        "weather": weather,
    },
});
