<template>
    <Modal
        :open="open"
        :loading="loading"
        @close="$emit('close')"
        :disabled="isModalDisabled"
    >
        <div
            class="app-modal-header"
            slot="header"
        >
            <span>{{ "Movimentar estoque" }}</span>
        </div>
        <div
            class="app-modal-body"
            slot="body"
        >
            <div class="content">
                <div class="content__item">
                    <label class="app-label">Categoria:</label>
                    {{
                        formatProductCategory(
                            stockProduct.product.productCategory
                        ) || "-"
                    }}
                </div>
                <div class="content__item">
                    <label class="app-label">Subcategoria:</label>
                    {{
                        formatProductSubCategory(
                            stockProduct.product.productCategory
                        ) || "-"
                    }}
                </div>
                <div class="content__item content__item--full-width">
                    <label class="app-label">Produto:</label>
                    {{ stockProduct.product.name }}
                </div>
                <div class="content__item content__item--full-width">
                    <label class="app-label">Propriedade selecionada:</label>
                    {{ this.getPropertySelected.name }}
                </div>
                <div class="content__item">
                    <date-picker
                        :dataSent="stockRegister.date"
                        @dateSelected="stockRegister.date = $event.date"
                        label="Data da movimentação"
                    />
                </div>
                <div class="content__item">
                    <label class="app-label" for="movement-types"
                        >Tipo de movimentação</label
                    >
                    <select
                        id="movement-types"
                        v-model="stockRegister.action"
                        class="app-select"
                    >
                        <option disabled selected value="">
                            Selecione um tipo de movimentação
                        </option>
                        <option
                            v-for="action in actions"
                            :key="`stock-movement-${action.value}`"
                            :value="action.value"
                        >
                            {{ action.label }}
                        </option>
                    </select>
                </div>
                <div class="content__item">
                    <input-number
                        id="stock-product-quantity"
                        :label="`Quantidade (${this.stockProduct.product.unit.name})`"
                        placeholder="Informe a quantidade"
                        :data="stockRegister.quantity"
                        @input="stockRegister.quantity = $event"
                    />
                </div>
                <div class="content__item content__item--full-width">
                    <label class="app-label" for="stock-register-notes"
                        >Observações</label
                    >
                    <textarea
                        id="stock-register-notes"
                        class="app-textarea"
                        v-model="stockRegister.notes"
                        rows="4"
                    />
                </div>
            </div>
        </div>
        <div class="app-modal-footer" slot="footer">
            <button
                class="btn btn-primary btn--flat"
                @click="saveStockRegister"
                :disabled="saving || loading"
            >
                <span v-if="saving" class="spinner-border" role="status"></span>
                <span v-else>
                    <span>Salvar</span>
                    <i class="ml-2 fas fa-save" />
                </span>
            </button>
        </div>
    </Modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Modal from "@/components/systemUI/Modal.vue";
import DatePicker from "@/components/commons/DatePicker.vue";
import InputNumber from "@/components/commons/InputNumber";
import _ from "lodash";

export default {
    name: "ModalMoveStock",
    props: {
        data: Object,
        open: Boolean,
    },
    components: {
        Modal,
        DatePicker,
        InputNumber,
    },
    data() {
        return {
            loading: false,
            saving: false,
            isModalDisabled: false,
            stockProduct: {
                product: {
                    name: "",
                    productCategory: {
                        name: "",
                        parentProductCategory: null,
                    },
                    unit: {
                        name: "",
                    },
                },
            },
            stockRegister: {
                action: "entrance",
                date: this.getTodayDate(),
                quantity: 0,
                notes: "",
                device: "web",
                eventLog: "",
                user: "",
            },
            actions: [
                {
                    label: "Entrada",
                    value: "entrance",
                },
                {
                    label: "Saída",
                    value: "exit",
                },
            ],
        };
    },
    computed: {
        ...mapGetters("property", ["getPropertySelected"]),
        ...mapGetters("users", ["profile"]),
        formattedOutput() {
            return {
                stockProductId: this.stockProduct._id,
                activityType: null,
                activityId: null,
                quantity: this.stockRegister.quantity,
                action: this.stockRegister.action,
                date: this.stockRegister.date,
                notes: this.stockRegister.notes,
                origin: "web",
                eventLog: "normal",
                userId: this.profile._id,
                subareaId: null,
            }
        }
    },
    methods: {
        ...mapActions("stock-register", ["createStockRegister"]),
        clearData() {
            Object.assign(this.$data, this.$options.data.apply(this));
        },
        formatInput() {
            this.stockProduct = _.cloneDeep(this.data);
        },
        validateStockRegister(stockRegister) {
            if (!stockRegister.stockProductId) {
                throw new Error("É necessário ter um produto do estoque para movimentar"    );
            }
            if (!stockRegister.action) {
                throw new Error("É necessário o tipo de movimentação");
            }
            if (!stockRegister.date) {
                throw new Error("É necessário informar a data da movimentação");
            }
            if (!stockRegister.quantity) {
                throw new Error("É necessário informar a quantidade da movimentação");
            }
        },
        async saveStockRegister() {
            try {
                const stockRegister = this.formattedOutput;
                this.validateStockRegister(stockRegister);

                this.isModalDisabled = true
                const wantsToMoveStock = await this.$vToastify.prompt({
                    body: "Deseja movimentar o produto com esses valores? Essa operação não poderá ser desfeita.",
                    title: "ATENÇÃO!",
                    answers: { Sim: true, Não: false },
                });


                if (wantsToMoveStock) {
                    this.saving = true;

                    const response = await this.createStockRegister(
                        stockRegister
                    );

                    const responseContainsError = response instanceof Error;
                    if (!responseContainsError) {
                        this.$vToastify.success(
                            response.data.message,
                            "Movimentação"
                        );
                        this.$emit("save");
                        this.$emit("close");
                    }
                }
            } catch (error) {
                this.$vToastify.error(error.message, "Movimentação");
            }

            this.saving = false;
            this.isModalDisabled = false;
        },
    },
    mounted() {
        this.formatInput();
    },
};
</script>

<style scoped lang="sass">
.content
    display: flex
    flex-wrap: wrap

    &__item
        padding: 4px
        width: 50%

        &--full-width
            width: 100%

    .filter-content
        width: 100%
        border: 1px solid #d6d6d6
        border-radius: 12px
        margin: 4px
        padding: 10px 4px
        display: flex
        flex-wrap: wrap

        &__title
            padding: 4px
            color: #d6d6d6
            width: 100%

        &__item
            padding: 4px
            width: 50%



@media (max-width: 500px)
    .content
        &__item
            width: 100%

.vt-backdrop-hidden
    z-index: 9999

</style>
