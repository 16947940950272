<template>
  <div class="comboBoxFields" :class="classParameter">
    <label class="app-label" for="combo">{{ title }}</label>
    <div class="input-group">
      <select class="app-select" id="combo" v-model="objectFieldSelected">
        <option disabled>{{ msgFirstItem }}</option>
        <option value="all" selected>Todos</option>
        <option
          v-for="objectField in listFieldsToChoose"
          :key="objectField._id"
          :value="objectField"
        >
          {{ objectField.name }}
        </option>
      </select>
    </div>
  </div>
</template>
<script>
export default {
  name: "ComboBoxFields",
  props: {
    title: {
      type: String,
    },
    classParameter: {
      type: String,
    },
    msgFirstItem: {
      type: String,
    },
    listFieldsToChoose: {
      type: Array,
    },
  },
  data() {
    return {
      objectFieldSelected: "all",
    };
  },
  watch: {
    listFieldsToChoose(){
      this.objectFieldSelected = "all"
    },
    objectFieldSelected() {
      this.$emit("fieldSelected", { fieldObject: this.objectFieldSelected });
    },
  }
};
</script>