<template>
    <div>
        <div class="app-card recommendation-result" v-for="result in results" :key="result.field._id">
            <div class="item">
                <label class="app-label">Talhão:</label> <span/>
                <span>{{ result.field.name }}</span>
            </div>

            <div class="item">
                <div class="item">
                    <label class="app-label">Data:</label> <span/>
                    <span>{{ date.endDate | formatDate }}</span>
                </div>
                <div class="item">
                    <label class="app-label">Precipitação:</label> <span/>
                    <span>{{result.rainfall}}mm</span>
                </div>
                <div class="item">
                    <label class="app-label">Temperatura:</label> <span/>
                    <span>{{result.temperature.min}}ºC ~ {{result.temperature.max}}ºC</span>
                </div>
            </div>

            <div class="item">
                <div class="flex">
                    <label class="item app-label">Operação</label>
                    <label class="item app-label">Ação</label>
                </div>
                <div class="flex">
                    <span class="item">{{ result.operation.name }}</span>
                    <span class="item">{{ result.operation.recommendation }}</span>
                </div>
            </div>
            <div class="item" v-if="result.hasOwnProperty('rainfallAvr')">
                <label class="item app-label">
                    Precipitação média (de {{date.startDate | formatDate}} à {{date.endDate | formatDate}}):
                </label> <span/>
                <span class="item">{{ result.rainfallAvr.toFixed(3) }}mm</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RecommendationResult',
    props: {
        date: {
            type: Object,
            default: () => ({
                startDate: '',
                endDate: ''
            })
        },
        results: {
            type: Array,
            default: () => Array({
                field: {
                    _id: '',
                    name: ''
                },
                temperature: {
                    min: '',
                    max: ''
                },
                operation: {
                    name: '',
                    recommendation: ''
                },
                rainfall: '',
                rainfallAvr: 0
            })
        }
    }
}
</script>