<template>
    <div class="machineries-container">
        <div class="machineries-container__header">
            <span class="title">Maquinários</span>
            <div class="actions">
                <div class="search-container app-input">
                    <input class="search-container__input" placeholder="Pesquise um maquinário"
                           v-model="searchWord" @keyup.enter="filterMachineries" type="text"/>
                    <button class="search-container__clear clickable" v-show="searchWord.length > 0"
                            @click="clearSearch">
                        <i class="fal fa-times-circle"/>
                    </button>
                    <button class="search-container__search" @click="filterMachineries">
                        <i class="fas fa-search"/>
                    </button>
                </div>
                <button class="btn btn-primary btn--flat" @click="openModalCreateMachineries">
                    <span>Novo maquinário</span>
                </button>
            </div>
        </div>
        <div v-if="!loading" class="machineries-container__content">
            <table class="machineries-table">
                <tr>
                    <th>Funções</th>
                    <th>Fabricante</th>
                    <th>Modelo</th>
                    <th>Chassi/Nº Série</th>
                    <th>Propriedades</th>
                    <th class="machineries-table-header__actions">Ações</th>
                </tr>
                <tr v-for="machinery in machineriesShow" :key="machinery._id">
                    <td> {{ machinery.types.join(', ') }} </td>
                    <td> {{ machinery.manufacturer }} </td>
                    <td> {{ machinery.model ? machinery.model : "Não informado"}} </td>
                    <td> {{ machinery.chassi ? machinery.chassi : "Não informado"}} </td>
                    <td> {{ machinery.properties.map(property => property.property.name).join(', ') }} </td>
                    <td>
                        <div class="machineries-table-content__actions">
                            <button @click="openModalEditMachineries(machinery)"
                                    class="btn btn--flat">
                                <i class="fas fa-edit"></i>
                                <span>Editar</span>
                            </button>
                            <button
                                type="checkbox"
                                class="btn btn--flat"
                                @click="activeDeactiveMachinery(machinery)"
                            >
                            <i :class="machinery.active ? 'fas fa-check-square' : 'far fa-square'"></i>
                                <span>{{ machinery.active ? 'Desativar' : 'Reativar' }}</span>
                            </button>
                        </div>
                    </td>
                </tr>
            </table>
            <div v-show="machineriesShow.length == 0" class="no-content">
                <i class="fa fa-exclamation-circle fa-2x"/>
                <span class="">Não foram encontrados maquinários</span>
            </div>
        </div>
        <div v-else class="no-content">
            <span class="spinner-border" />
        </div>
        <modal-machinery
            :open="showModalMachinery"
            @close="closeModalMachinery"
            :data="machineryData"
            @save="loadMachineries"
        />
    </div>
</template>

<script>

import { mapActions, mapGetters } from "vuex";
import ModalMachinery from "@/views/configurations/machineries/components/ModalMachinery.vue"

export default {
    name: 'Machinery',
    components: {
        ModalMachinery
    },
    data() {
        return {
            loading: false,
            showModalMachinery: false,
            machineriesShow: [],
            machineryData: {},
            searchWord: '',
        }
    },
    computed: {
        ...mapGetters("machineries", [
            "machineriesState"
        ]),
        ...mapGetters("property",[
            "getPropertySelected"
        ]),
        ...mapGetters("users",[
            "profile"
        ])
    },
    async mounted(){
        await this.loadMachineries()
    },
    watch: {
        searchWord(){
            this.filterMachineries()
        },
        getPropertySelected(){
            this.machineriesShow = []
            this.loadMachineries()
        }
    },
    methods: {
        ...mapActions("machineries", ["getMachineries", "updateMachinery"]),
        ...mapActions("property", ["listDeactiveMachineries"]),
        async loadMachineries(){
            this.loading = true
            const propertyId = this.getPropertySelected._id
            await this.getMachineries({ propertyId })
            this.machineriesShow = [...this.machineriesState.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1)]

            this.loading = false
        },
        openModalCreateMachineries(){
            this.machineryData = {}
            this.showModalMachinery = true
        },
        openModalEditMachineries(machinery){
            this.machineryData = machinery
            this.showModalMachinery = true
        },
        closeModalMachinery(){
            this.showModalMachinery = false
        },
        async activeDeactiveMachinery(machinery){
            const { _id, active } = {
                _id: machinery._id,
                active: !machinery.active,
            }
            const confirmActiveDeactive = await this.$vToastify.prompt({
                body: "Deseja confirmar está ação?",
                title: "ATENÇÃO!",
                answers: { Sim: true, Não: false },
            })
            if (confirmActiveDeactive){
                await this.updateMachinery({ _id, active })
                await this.loadMachineries()
                const sucessText = machinery.active == true ? 'Desativado' : 'Reativado'
                this.$vToastify.success(`${sucessText} com sucesso`, "Maquinário")
            } else return
        },
        filterMachineries(){
            this.machineriesShow = this.machineriesState.filter((machinery) => {
                return (
                    machinery.types.some(type => type.toUpperCase().includes(this.searchWord.toUpperCase())) ||
                    machinery.manufacturer.toUpperCase().includes(this.searchWord.toUpperCase()) ||
                    machinery.model.toUpperCase().includes(this.searchWord.toUpperCase()) ||
                    machinery.chassi.toUpperCase().includes(this.searchWord.toUpperCase()) ||
                    machinery.properties.some(property => property.property.name.toUpperCase().includes(this.searchWord.toUpperCase()))
                )
            })
        },
        async clearSearch() {
            this.searchWord = ''
            this.machineriesShow = [...this.machineriesState.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1)]
        }
    }
}
</script>

<style lang="sass" scoped>
.machineries-container
    display: flex
    flex-direction: column
    grid-gap: 6px
    width: 100%

    &__header
        .title
            font-size: 24px
            color: $theme-color-primary

        .actions
            display: flex
            justify-content: space-between

            .search-container
                display: flex
                justify-content: space-between
                width: 300px

                &__input
                    width: 250px
                    border: 0

                &__search
                    border: 0
                    width: 25px
                    background: none

                &__clear
                    border: 0
                    width: 25px
                    background: none

.machineries-table
    width: 100%

    &-header__actions
        width: 30%
        text-align: center

    &-content__actions
        display: flex
        grid-gap: 6px
        align-items: center
        justify-content: center

        .btn
            background: none

            &:hover
                background: $color-white-darken-1

            i
                color: $color-blue

    th
        color: $color-blue
        padding: 0 10px
        font-size: 16px
        width: 16.6666%

    tr
        border-radius: 20px
        height: 70px

        &:nth-child(even)
            background-color: #f2f2f2

    td
        padding: 0 10px
        width: 16.6666%

.no-content
    display: flex
    justify-content: center
    align-items: center
    height: 100px
    color: $color-grey
    grid-gap: 10px

@media (max-width: 430px)
    .machineries-container__header .actions .search-container__input
        width: 200px

</style>
