import StockRegister from '../../api/StockRegister';

const state = {
    stockRegisters: []
}

const getters = {
    getStockRegisters: state => state.stockRegisters
}

const actions = {
    async findStockRegisters({ commit }, payload) {
        const { propertyId, stockProductId } = payload
        const response = await StockRegister.findStockRegisters(propertyId, stockProductId);
        const stockRegisters = response.data.data;
        commit("SET_STOCK_REGISTERS", stockRegisters);
        return response;
    },
    async createStockRegister({ commit }, stockRegister) {
        const response = await StockRegister.createStockRegister(stockRegister);
        return response;
    },
    async updateNotesStockRegister({}, {stockRegisterId, notes}) {
        const response = await StockRegister.updateNotesStockRegisters(stockRegisterId, notes);
        return response
    },
}

const mutations = {
    SET_STOCK_REGISTERS: (state, stockRegisters) => state.stockRegisters = stockRegisters
}

export default { state, getters, actions, mutations, namespaced: true }
