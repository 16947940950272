<template>
    <div>
        <div class="container-fluid p-0">
            <div class="header_title">
                <div class="row d-flex align-items-center">
                    <div class="col-md-6">
                        <h2 class="title m-0">Relatórios</h2>
                    </div>
                </div>
            </div>
        </div>

        <hr/>

        <div class="container-fluid p-0">
            <ul class="list-unstyled list-reports" v-if="showLinks">
                <li v-if="hasChildAccess('/relatorios', 'ndvi')">
                    <router-link tag="a" to="/relatorios/ndvi">
                        <i class="list-reports-icon fa fa-map"></i>
                        NDVIs
                        <i class="fas fa-chevron-right"></i>
                    </router-link>
                </li>
                <li v-if="hasChildAccess('/relatorios', 'fotos')">
                    <router-link tag="a" to="/relatorios/fotos">
                        <i class="list-reports-icon fa fa-image"></i>
                        Fotos gerais
                        <i class="fas fa-chevron-right"></i>
                    </router-link>
                </li>
                <li v-if="hasChildAccess('/relatorios', 'safras')">
                    <router-link tag="a" to="/relatorios/safras">
                        <i class="list-reports-icon fab fa-pagelines"></i>
                        Safras
                        <i class="fas fa-chevron-right"></i>
                    </router-link>
                </li>
                <li v-if="hasChildAccess('/relatorios', 'ordem-de-servico')">
                    <router-link tag="a" to="/relatorios/ordem-de-servico">
                        <i class="list-reports-icon fa fa-file"></i>
                        Ordem de serviço
                        <i class="fas fa-chevron-right"></i>
                    </router-link>
                </li>
                <li v-if="hasChildAccess('/relatorios', 'monitoramento')">
                    <router-link tag="a" to="/relatorios/monitoramento">
                        <i class="list-reports-icon fa fa-bug"></i>
                        Monitoramento
                        <i class="fas fa-chevron-right"></i>
                    </router-link>
                </li>
            </ul>
            <router-view/>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Reports',
    computed: {
        showLinks() {
            return !!this.$route.path.match(/^\/relatorios\/?$/);
        }
    }
}
</script>

<style scoped lang="sass">
.list-reports-icon
    padding-right: 16px

.list-reports li a:hover
    color: $color-blue !important

</style>
