import Machinery from '../../api/Machinery';

const state = {
    machineriesState: [],
    machineryState: {},
    error: null
}

const getters = {
    machineriesState: state => state.machineriesState,
    machineryState: state => state.machineryState,
    error: state => state.error
}

const actions = {
    async getMachinery({ commit }, id) {
        await Machinery.get(id)
            .then(response => {
                commit("setMachinery", response.data.data)
            }).catch((error) => {
                commit("setError", error);
            });
    },
    async getMachineries({ commit }, { propertyId, active}) {
        const response = await Machinery.getAll(propertyId, active)
        commit("setMachineries", response.data.data)
    },
    async insertMachinery({ commit }, machineryState) {
        const response = await Machinery.post(machineryState)
        commit("newMachinery", response.data.data)
        commit("setError", null)
    },
    async updateMachinery({ commit }, machineryState) {
        const response = await Machinery.update(machineryState)
        commit("updateMachinery", response.data.data)
        commit("setError", null)
    },
    setMachineries({ commit }, machineriesState) {
        commit("setMachineries", machineriesState)
    },
}

const mutations = {
    setError: (state, error) => state.error = error,
    setMachineries: (state, machineriesState) => state.machineriesState = machineriesState,
    setMachinery: (state, machineryState) => state.machineryState = machineryState,
    newMachinery: (state, machineryState) => state.machineriesState.unshift(machineryState),
    updateMachinery: (state, updateMachinery) => state.machineriesState = updateMachinery
}

export default { state, getters, actions, mutations, namespaced: true }
