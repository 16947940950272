<template>
    <div>
        <app-selector
            :items="formattedOperatorsToSelector"
            @input="addOperator"
            :value="selectedOperator"
            title="Operadores"
            placeholder="Selecione um operador"
            plus-icon
            searchable
        />
        <div class="flex-container-wrap mt-2">
            <div
                v-for="(operator, operatorIndex) in selectedOperators"
                :key="`${uuid}_${operator._id}`"
                class="chip"
            >
                <span> {{ operator.name }} </span>
                <i
                    class="chip-action material-icons"
                    @click="removeOperator(operatorIndex)"
                >
                    close
                </i>
            </div>
        </div>
    </div>
</template>
<script>

import {mapActions, mapGetters} from 'vuex'
import AppSelector from '@/components/commons/AppSelector.vue'

export default {
    name: "OperatorSelector",
    props: {
        uuid: {
            type: String
        },
        value: {
            type: Array
        }
    },
    components: {
        AppSelector
    },
    data() {
        return {
            selectedOperatorsIds: [],
            selectedOperator: null
        };
    },
    computed: {
        ...mapGetters('operators', ['operatorsState']),
        filteredOperators() {
            return this.operatorsState.filter(operator => operator.active)
        },
        formattedOperatorsToSelector() {
            return this.filteredOperators.map(operator => {
                return {
                    _id: operator._id,
                    value: operator._id,
                    label: operator.name
                }
            })
        },
        selectedOperators() {
            return this.selectedOperatorsIds.map(operatorId => this.operatorsState.find(operator => operator._id == operatorId))
        }
    },
    watch: {
        value() {
            if (this.inputOperatorIsDifferentFromSelected()) {
                this.selectedOperatorsIds = this.formattedInputOperators()
            }
        },
        selectedOperators() {
            this.emitFormattedSelectedOperators()
        }
    },
    methods: {
        hasSelectedOperators() {
            return this.selectedOperatorsIds && this.selectedOperatorsIds.length > 0;
        },
        inputOperatorIsDifferentFromSelected() {
            const formattedInputOperators = this.formattedInputOperators();

            const hasDifferentArraySize = this.selectedOperatorsIds.length != formattedInputOperators.length

            return hasDifferentArraySize
        },
        formatInputOperator(operator) {
            if (!operator.operator) {
                return operator
            }
            if (!operator.operator._id) {
                return operator.operator
            }
            return operator.operator._id
        },
        formattedInputOperators() {
            return this.value.map(operator => this.formatInputOperator(operator))
        },
        formatOutputOperator(operator) {
            return operator
        },
        formattedOutputOperators() {
            return this.selectedOperatorsIds.map(operatorId => this.formatOutputOperator(operatorId))
        },
        emitFormattedSelectedOperators() {
            this.$emit('input', this.formattedOutputOperators())
        },
        addOperator(operatorId) {
            this.selectedOperator = null

            const isRepeatedOperator = this.selectedOperatorsIds.includes(operatorId)
            if (isRepeatedOperator) {
                return this.$vToastify.info('Esse operador já foi selecionado', "Atenção!")
            }

            this.selectedOperatorsIds.push(operatorId)
        },
        removeOperator(index) {
            this.selectedOperatorsIds.splice(index, 1);
        }
    }
};
</script>
<style lang="sass" scoped>
.input-filter
    width: 100%
    padding: 7px

.flex-container
    grid-gap: 0
</style>
