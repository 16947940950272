<template>
    <div class="button-notification">
        <img class="button-notification__icon" src="/img/icons/svg/notifications.svg" alt=""/>
        <span class="button-notification__counter">{{ notificationNumber }}</span>
    </div>
</template>

<script>
export default {
    name: "ButtonNotification",
    props: {
        notificationNumber: String,
    }
}
</script>

<style scoped lang="sass">
.button-notification
    &__icon
        width: 30px
        height: 30px

    &__counter
        display: flex
        align-items: center
        justify-content: center

        width: 22px
        height:  22px
        margin: 0 0 20px -15px
        border: 2px solid #ffffff
        border-radius: 50px
        background-color: $color-blue
        font-size: 12px
        color: #ffffff

</style>
