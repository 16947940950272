<template>
    <div class="subarea-selector">
        <div>
            <app-selector
                :items="formattedSubareasToSelector"
                @input="addSubarea"
                :value="selectedSubarea"
                title="Talhão/Cultivar"
                placeholder="Selecione um cultivar"
                plus-icon
                searchable
            />
        </div>
        <div class="subarea-selector__checkbox-group">
            <table class="subarea-table">
                <tr>
                    <th> Talhão </th>
                    <th> Cultivar </th>
                    <th> Tamanho (ha) </th>
                    <th class="subarea-table-header__actions"> Ações </th>
                </tr>
                <tr v-for="(subarea, subareaIndex) in selectedSubareas" :key="subarea._id">
                    <td> {{ subarea.field.name || '-' }} </td>
                    <td> {{ subarea.seed || '-' }} </td>
                    <td>
                        <div v-show="editSubarea != subarea._id" class="subarea-table-content__edit">
                            <span
                                v-show="selectedSubareas[subareaIndex].area == undefined"
                            >
                                {{ selectedSubareas[subareaIndex].size.value | formatNumber }}
                            </span>
                            <span
                                v-show="selectedSubareas[subareaIndex]._id == subarea._id"
                            >
                                {{ selectedSubareas[subareaIndex].area | formatNumber }}
                            </span>
                            <i v-show="editSizeArea" class="far fa-pen clickable pl-2" @click="onClickEditSubarea(subarea, subareaIndex)"></i>
                        </div>
                        <div v-show="editSubarea == subarea._id" class="subarea-table-content__edit">
                            <input-number
                                id="subarea"
                                :data="subarea.area"
                                @input="subarea.area = $event"
                                :key="subareaIndex"
                            />
                            <i class="fal fa-check clickable pl-2" @click="saveEditSubarea(subarea, subareaIndex)"></i>
                            <i class="fal fa-times clickable pl-2" @click="cancelEditSubarea(subarea)"></i>
                        </div>
                    </td>
                    <td>
                        <div class="subarea-table-content__actions">
                            <button
                                @click="removeSubarea(subarea, subareaIndex)"
                                class="btn btn-primary--outlined btn--flat btn--no-background"
                            >
                                <i class="fas fa-trash"/>
                                <span>
                                    Remover
                                </span>
                            </button>
                        </div>
                    </td>
                </tr>
            </table>
            <div v-show="!hasSelectedSubareas()" class="no-content">
                <i class="fa fa-exclamation-circle fa-2x"/>
                <span class="">Não foram selecionados cultivares</span>
            </div>
        </div>
    </div>
</template>
<script>

import AppSelector from '@/components/commons/AppSelector.vue'
import InputNumber from '@/components/commons/InputNumber';
import { mapActions, mapGetters } from 'vuex'

export default {
    name: "SubareaSelector",
    props: {
        uuid: {
            type: String
        },
        value: {
            type: Array
        },
        editSizeArea: {
            type: Boolean,
            default: true
        }
    },
    components: {
        AppSelector,
        InputNumber
    },
    data() {
        return {
            selectedSubareasIds: [],
            selectedSubarea: null,
            editSubarea: null,
        };
    },
    computed: {
        ...mapGetters("property", ['getPropertySelected']),
        ...mapGetters('subareas', ['getSubareas']),
        formattedSubareasToSelector() {
            return this.getSubareas.map(subarea => {
                return {
                    _id: subarea._id,
                    value: subarea._id,
                    label: `${subarea.field.name} (${subarea.seed})`
                }
            })
        },
        selectedSubareas() {
            return this.selectedSubareasIds.map(subareaId => this.getSubareas.find(subarea => subarea._id == subareaId))
        }
    },
    watch: {
        value() {
            if (this.inputSubareaIsDifferentFromSelected()) {
                this.selectedSubareasIds = this.formattedInputSubareas()
            }
        },
        selectedSubareasIds() {
            this.emitFormattedSelectedSubareas()
        }
    },
    methods: {
        ...mapActions('subareas', ['findSubareas']),
        hasSelectedSubareas() {
            return this.selectedSubareasIds && this.selectedSubareasIds.length > 0;
        },
        inputSubareaIsDifferentFromSelected() {
            const formattedInputSubareas = this.formattedInputSubareas();

            const hasDifferentArraySize = this.selectedSubareasIds.length != formattedInputSubareas.length

            return hasDifferentArraySize
        },
        formatInputSubarea(subarea) {
            return subarea._id || subarea
        },
        formattedInputSubareas() {
            return this.value.map(subarea => this.formatInputSubarea(subarea))
        },
        formatOutputSubarea(subarea) {
            return subarea
        },
        formattedOutputSubareas() {
            return this.selectedSubareasIds.map(subareaId => this.formatOutputSubarea(subareaId))
        },
        emitFormattedSelectedSubareas() {
            this.$emit('input', this.formattedOutputSubareas())
        },
        addSubarea(subareaId) {
            this.selectedSubarea = null

            const isRepeatedSubarea = this.selectedSubareasIds.includes(subareaId)
            if (isRepeatedSubarea) {
                return this.$vToastify.info('Esse cultivar já foi selecionado', "Atenção!")
            }

            this.selectedSubareasIds.push(subareaId)
        },
        removeSubarea(subarea, subareaIndex) {
            this.selectedSubareasIds.splice(subareaIndex, 1)
            subarea.area = null
        },
        onClickEditSubarea(subarea){
            this.editSubarea = subarea._id
        },
        saveEditSubarea(subarea, subareaIndex){
            if(this.selectedSubareas[subareaIndex].area > subarea.size.value){
                return this.$vToastify.error(`A área total do cultivar é ${subarea.size.value} ${subarea.size.unity}`, "Atenção!")
            }
            this.editSubarea = null
        },
        cancelEditSubarea(subarea){
            this.editSubarea = null
            subarea.area = null
        },
    }
};
</script>
<style lang="sass" scoped>
.subarea-selector
    width: 100%

    &__text
        margin-bottom: 12px

    &__title

    &__checkbox-group
        margin-top: 6px

    &__loader
        text-align: center
        margin-top: 30px
        color: $color-blue

.subarea-table
    width: 100%

    &-content__edit
        display: flex
        align-items: center
        justify-content: flex-start

        input
            width: 50%
        i
            color: $color-blue

    &-header__actions
        width: 30%
        text-align: center

    &-content__actions
        display: flex
        grid-gap: 6px
        align-items: center
        justify-content: center

        .btn--no-background
            background: none

            &:hover
                background: $color-white-darken-1

            i
                color: $color-blue

    th
        color: $color-blue
        padding: 0 10px
        font-size: 16px

    tr
        border-radius: 20px
        height: 70px

        &:nth-child(even)
            background-color: #f2f2f2

    td
        padding: 0 10px

.no-content
    display: flex
    justify-content: center
    align-items: center
    height: 100px
    color: $color-grey
    grid-gap: 10px

</style>
