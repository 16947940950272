<template>
  <div>
    <div class="image-card">
      <div class="image-card__title">
        <strong> {{ title }} </strong>
      </div>
      <div class="image-card__image-container">
        <img class="image-card__image" :src="linkImage" alt="" />
      </div>
      <div class="image-card__description">
        <p> {{ description }} </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ImageCard",
  props: {
    title: {
      type: String,
    },
    linkImage: {
      type: String,
    },
    description: {
      type: String,
    },
  },
};
</script>
<style scoped lang="sass">

.image-card
  background: #eee
  text-align: center
  padding: 10px 0
  border-radius: 6px
  width: 100%
  height: 300px

  &__title, &__description
    text-align: center
    height: 50px
    white-space: nowrap
    line-height: 50px

  &__image-container
    height: calc(100% - 50px - 50px)
    border-radius: 6px
    padding: 10px
    display: flex
    -webkit-display: flex
    -moz-display: flex
    -ms--display: flex
    justify-content: center
    align-items: center

  &__image
    max-width: 100%
    max-height: 100%
    width: 100%
    height: auto
    object-fit: contain

</style>