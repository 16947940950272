import Operator from '../../api/Operator';

const state = {
    operatorsState: [],
    operatorState: {},
    error: null
}

const getters = {
    operatorsState: state => state.operatorsState,
    operatorState: state => state.operatorState,
    error: state => state.error
}

const actions = {
    getOperator({ commit }, id) {
        Operator.get(id).then(response => {
            return commit("setOperator", response.data.data)
        })
        .catch((error) => {
            commit("setError", error);
        });
    },
    async getOperators({ commit }, { propertyId, active}) {
        const response = await Operator.getAll(propertyId, active)
        commit("setOperators", response.data.data)
    },
    async insertOperator({ commit }, operatorState) {
        const response = await Operator.post(operatorState)
        if (response instanceof Error) {
            commit("setError", response.response)
        } else {
            commit("newOperator", response.data.data)
            commit("setError", null)
        }
    },
    async updateOperator({ commit }, operatorState) {
        const response = await Operator.update(operatorState)
        commit("updateOperator", response.data.data)
        commit("setError", null)
    },
    setOperators({ commit }, operatorsState) {
        commit("setOperators", operatorsState)
    },
}

const mutations = {
    setError: (state, error) => state.error = error,
    setOperators: (state, operatorsState) => state.operatorsState = operatorsState,
    setOperator: (state, operatorState) => state.operatorState = operatorState,
    newOperator: (state, operatorState) => state.operatorsState.unshift(operatorState),
    updateOperator: (state, updateOperators) => state.operatorsState = updateOperators,
}

export default { state, getters, actions, mutations, namespaced: true }
