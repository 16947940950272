<template>
    <div class="report-service-order">
        <div class="report-service-order__header">
            <router-link class="btn btn-primary btn--rounded btn--flat" tag="a" to="/relatorios">
                <i class="fa fa-arrow-left"/>
                <span>Voltar</span>
            </router-link>
            <h2 class="title">Ordem de serviço</h2>
        </div>
        <div class="report-service-order__filter filter-container">
            <div class="filter-container__item">
                <label class="app-label">
                    Período
                </label>
                <period-select-picker
                    :selectedInterval="selectedInterval"
                    @intervalSelected="selectedInterval = $event.interval"
                />
            </div>
            <div class="filter-container__item filter-container__date">
                <date-picker
                    :disabled="!selectedCustom"
                    :dataSent="periodSend.startDate"
                    @dateSelected="periodSend.startDate = $event.date"
                    label="Data inicial"
                />
                <date-picker
                    :disabled="!selectedCustom"
                    :dataSent="periodSend.endDate"
                    @dateSelected="periodSend.endDate = $event.date"
                    label="Data final"
                />
            </div>
            <div class="filter-container__item filter-container__action">
                <button
                    class="btn btn-primary--outlined btn--flat"
                    :disabled="loadingPDF"
                    id="fazenda"
                    @click="generatePDF()"
                >
                    <span
                        v-if="loadingPDF"
                        class="spinner-border spinner-border-sm"
                        role="status"
                    />
                    <span v-else>
                        <i class="fa fa-file-pdf mr-2"/>
                        <span>
                            Baixar PDF
                        </span>
                    </span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>

import DatePicker from "@/components/commons/DatePicker.vue";
import PeriodSelectPicker from "@/components/commons/PeriodSelectPicker.vue";
import Planning from "@/api/Planning";
import { calculatePeriodInterval } from "@/utils/calculatePeriodInterval";
import { mapGetters, mapActions } from "vuex";

export default {
    name: 'ReportPhotos',
    components: {
        DatePicker,
        PeriodSelectPicker
    },
    data() {
        return {
            loadingPDF: false,
            selectedInterval: "weekly",
            periodSend: {
                custom: false,
                startDate: "",
                endDate: ""
            }
        };
    },

  computed: {
    ...mapGetters("property", [
      "getPropertySelected",
      "getCropSelected"
    ]),
    selectedCustom(){
        return this.selectedInterval == 'custom'
    }
  },

  async mounted() {
    this.selectedInterval = "weekly"
    await this.updateFilter()
  },

  watch: {
    async selectedInterval(){
        await this.updateFilter()
    }
  },
  methods: {
    ...mapActions("fields", [
      "getFieldsByProperty"
    ]),
    updatePayload(){
        const payload = {
            propertyId: this.getPropertySelected._id,
            cropId: this.getCropSelected._id,
            startDate: this.periodSend.startDate,
            endDate: this.periodSend.endDate,
        }
        return payload
    },
    async updateFilter(){
        this.periodSend = calculatePeriodInterval(this.selectedInterval, this.getCropSelected)
    },
    async generatePDF(){
        this.loadingPDF = true

        const { propertyId, cropId, startDate, endDate } = this.updatePayload()
        const response = await Planning.convertHTMLtoPDF(propertyId, cropId, startDate, endDate)

        if (response.status === 200 && response.data.pdf) {
            this.$vToastify.success('PDF gerado com sucesso!')
            this.goToPage(response.data.pdf)
        } else {
            this.$vToastify.error(response.data.message, 'Erro')
        }

        this.loadingPDF = false
    },
    goToPage(url){
        window.open(url, '_blank')
    }

  },

};
</script>

<style scoped lang="sass">
.report-service-order
    height: 100%
    grid-gap: 10px

    &__header
        display: flex
        align-items: center
        justify-content: center
        position: relative
        padding: 20px 0
        width: 100%

        .btn
            position: absolute
            left: 0

        .title
            font-size: 16px
            margin: 0

    &__filter
        display: flex
        grid-gap: 6px
        align-items: flex-end
        padding: 20px 0
        width: 100%

    .filter-container
        display: flex
        justify-content: center
        align-items: flex-end
        padding: 10px 0
        flex-wrap: wrap
        border: $border
        border-radius: $border-radius

        &__item
            flex-grow: 1
            padding: 10px

            &--multiple-selector
                width: 200px

        &__action
            display: flex
            grid-gap: 10px
            justify-content: center
            min-width: 350px

        &__date
            display: flex
            grid-gap: 10px
            min-width: 350px

            .datePicker
                width: 100%

            #date
                height: 40px

    .no-content
        display: flex
        justify-content: center
        align-items: center
        height: 100px
        color: $color-grey
        grid-gap: 10px
</style>
