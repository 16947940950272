import API from './API';

export default {
    get(id) {
        return API.get(`products/${id}`)
    },

    post(data) {
        return API.post("products", data)
    },

    update(data) {
        return API.put(`products/${data._id}`, data)
    },

    delete(id) {
        return API.delete(`products/${id}`)
    }
}