import User from '../../api/User'
import Logo from '../../api/Logo'

const state = {
    user: {
        name: '',
        lastname: '',
        properties: [
            {
                property: [
                    {
                        _id: '',
                        name: ''
                    }
                ]
            }
        ],
        featureList: []
    },
    logo: '',
    users: []
}

const getters = {
    profile: state => state.user,
    logo: state => state.logo,
    getUsers: state => state.users
}

const actions = {
    async getUser({commit, dispatch}) {
        await User.get().then(async response => {
            const result = response.data.data
            await commit("setUser", result)
            await dispatch("property/listPropertySelected", result.properties[0].property._id, {root: true})
            await dispatch("property/listCrops", result.properties[0].property._id, {root: true})
            await dispatch("getDataProperty", result.properties[0].property._id)
        })
    },
    async getDataProperty({dispatch}, id) {
        await Logo.getAccountToProperty(id)
            .then(async (response) => {
                let _id = response.data.data.account._id;
                await dispatch('getLogo', _id)
            })
    },
    async getLogo({commit}, id) {
        const response = await Logo.getLogoToAccount(id)
        let logo = response.data.data ? response.data.data.logo : ''
        commit('setLogo', logo)
    },
    async authenticate(context, data) {
        const response = await User.auth(data)

        if (!(response instanceof Error)) {
            localStorage.setItem('token', response.data.token)
            return true
        }
        return false
    },
    async listUser({ commit }, {accountId, active}) {
        const response = await User.getAll(accountId, active)
        commit("setUsers", response.data.data)
    },
    async insertUser({ commit }, user) {
        const response = await User.createUser(user)
        return response
    },

    async updateUser({ commit }, user) {
        const response = await User.updateUser(user)
        return response
    },
    
    logout({commit, dispatch}) {
        commit("setClearUser");
        commit("setClearLogo");
        localStorage.setItem("token", "");
        dispatch("plannings/clearAll", {}, {root: true});
    }
}

const mutations = {
    setUser: (state, user) => state.user = user,
    setLogo: (state, logo) => state.logo = logo,
    setClearUser: (state) => state.user = {},
    setClearLogo: (state) => state.logo = "",
    setUsers: (state, users) => state.users = users
}

export default {state, getters, actions, mutations, namespaced: true}
