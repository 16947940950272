<template>
    <div class="stock-product-selector">
        <div class="stock-product-selector__item stock-product-selector__item--category">
            <label
                class="app-label"
                for="product-category"
            >
                Categoria
            </label>
            <select
                id="product-category"
                v-model="selectedCategory"
                class="app-select"
            >
                <option
                    selected
                    value="all"
                >
                    Todos
                </option>
                <option
                    v-for="productCategory in getProductCategories"
                    :key="productCategory._id"
                    :value="productCategory._id"
                >
                    {{ productCategory.name }}
                </option>
            </select>
        </div>
        <div
            class="stock-product-selector__item stock-product-selector__item--sub-category"
            v-if="getProductSubCategoriesByProductCategoryId(selectedCategory).length > 0"
        >
            <label
                class="app-label"
                for="product-sub-category"
            >
                Sub Categoria
            </label>
            <select
                id="product-sub-category"
                v-model="selectedSubCategory"
                class="app-select"
            >
                <option
                    selected
                    value="all"
                >
                    Todos
                </option>
                <option
                    v-for="productSubCategory in getProductSubCategoriesByProductCategoryId(selectedCategory)"
                    :key="productSubCategory._id"
                    :value="productSubCategory._id"
                >
                    {{ productSubCategory.name }}
                </option>
            </select>
        </div>
        <div class="stock-product-selector__item stock-product-selector__item--full-width">
            <app-selector
                :items="formattedStockProductsToSelector"
                @input="addStockProduct"
                title="Produto"
                placeholder="Selecione um produto"
                plus-icon
                searchable
            />
        </div>
        <div class="stock-product-selector__selected-products">
            <div class="stock-product-container">
                <div
                    class="stock-product-container__item"
                    v-for="(stockProduct, stockProductIndex) in selectedStockProducts"
                    :key="`${uuid}_${stockProductIndex}_stockProduct`"
                >
                    <div class="d-flex justify-content-end ma-0">
                        <i
                            class="fas fa-times-circle clickable"
                            @click="removeStockProduct(stockProductIndex)"
                        />
                    </div>
                    <div class="row m-0">
                        <span style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden;">
                            {{ stockProduct.displayTextStockProductLabel }}
                        </span>
                    </div>
                    <app-selector-number-unit
                        :id="`${uuid}_${stockProductIndex}_product`"
                        v-model="stockProduct.quantity"
                        placeholder="Ex: 5"
                        :units="stockProduct.possibleUnits"
                        :dosages="stockProduct.possibleDosages"
                        hasDosage
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import {mapActions, mapGetters} from 'vuex'
import InputNumberSelectable from "@/components/systemUI/InputNumberSelectable.vue"
import AppSelectorNumberUnit from "@/components/commons/AppSelectorNumberUnit.vue"
import AppSelector from "@/components/commons/AppSelector"

export default {
    name: "StockProductSelector",
    props: {
        uuid: {
            type: String
        },
        cultureId: {
            type: String
        },
        activityType: {
            type: String
        },
        selectedSeedUnitId: {
            type: String,
            default: ''
        },
        value: {
            type: Array
        }
    },
    components: {
        InputNumberSelectable,
        AppSelectorNumberUnit,
        AppSelector
    },
    data() {
        return {
            selectedCategory: "all",
            selectedSubCategory: "all",
            searchWord: '',
            selectedStockProducts: []
        };
    },
    computed: {
        ...mapGetters("product-categories", ["getProductCategories"]),
        ...mapGetters("stock-products", ["getStockProducts"]),
        ...mapGetters("units", ["getUnits"]),
        ...mapGetters("unit-conversions", ["getUnitConversions"]),
        stockProductsPossibleDosages() {
            if (this.selectedSeedUnitId) {
                const selectedSeedUnit = this.getUnits.find(unit => unit._id == this.selectedSeedUnitId)
                if (!selectedSeedUnit) {
                    return []
                }

                return [ selectedSeedUnit ]
            }

            const permissions = this.possibleDosagesPermissions

            return this.getUnits.filter(unit => permissions.some(permission => unit.dosageOf.includes(permission)))
        },
        possibleDosagesPermissions() {
            if (this.activityType && this.activityType == 'planting') {
                return ['product', 'plantingProduct']
            }

            return ['product']
        },
        filteredStockProducts() {
            return this.getStockProducts.filter(stockProduct => {
                if (!stockProduct.active) {
                    return false
                }

                const productBelongsToSelectedCulture = stockProduct.product.cultures.find(culture => culture.culture == this.cultureId)
                if (!productBelongsToSelectedCulture) {
                    return false
                }

                if (this.selectedCategory == "all") {
                    return true
                }

                if (!stockProduct.product.productCategory) {
                    return false
                }

                if (this.selectedSubCategory == "all") {
                    return (
                        stockProduct.product.productCategory._id == this.selectedCategory ||
                        stockProduct.product.productCategory.parentProductCategory && stockProduct.product.productCategory.parentProductCategory._id == this.selectedCategory
                    )
                }

                return stockProduct.product.productCategory._id == this.selectedSubCategory
            })
        },
        formattedStockProductsToSelector() {
            return this.filteredStockProducts.map(stockProduct => {
                return {
                    _id: stockProduct._id,
                    value: stockProduct._id,
                    label: `${stockProduct.product.name} (${stockProduct.product.unit.name})`
                }
            })
        },
        formattedOutput() {
            return this.selectedStockProducts.map(stockProduct => ({
                stockProductId: stockProduct.stockProductId,
                quantity: {
                    value: stockProduct.quantity.value,
                    unitId: stockProduct.quantity.unitId,
                    dosageId: stockProduct.quantity.dosageId
                },
                approved: stockProduct.approved
            }))
        },
        inputStockProductIsDifferentFromSelected() {
            const inputStockProducts = this.value

            const hasDifferentArraySize = this.formattedOutput.length != inputStockProducts.length
            const hasDifferentPropertyValues = !this.formattedOutput.every((stockProduct, stockProductIndex) => {
                if (!inputStockProducts[stockProductIndex]) {
                    return false;
                }

                if (inputStockProducts[stockProductIndex].quantity.value != stockProduct.quantity.value) {
                    return false;
                }

                const unitId = this.returnIfExists(stockProduct, "quantity.unitId") || null
                if (inputStockProducts[stockProductIndex].quantity.unitId != unitId) {
                    return false;
                }

                const dosageId = this.returnIfExists(stockProduct, "quantity.dosageId") || null
                if (inputStockProducts[stockProductIndex].quantity.dosageId != dosageId) {
                    return false;
                }

                return true;
            })

            return hasDifferentArraySize || hasDifferentPropertyValues
        },
    },
    watch: {
        value() {
            if (this.inputStockProductIsDifferentFromSelected) {
                this.syncInput()
            }
        },
        selectedSeedUnitId() {
            this.syncInput()
        },
        formattedOutput() {
            this.$emit('input', this.formattedOutput)
        },
        selectedCategory() {
            this.selectedSubCategory = "all"
        }
    },
    methods: {
        getStockProductById(stockProductId) {
            return this.getStockProducts.find(stockProduct => stockProduct._id == stockProductId)
        },
        getStockProductPossibleUnits(stockProduct) {
            const possibleUnitConversions = this.getUnitConversions.filter(unitConversion => stockProduct.product.unit._id == unitConversion.toUnit._id)
            const units = possibleUnitConversions.map(unitConversion => unitConversion.fromUnit)

            return units
        },
        getProductSubCategoriesByProductCategoryId(productCategoryId) {
            const productCategory = this.getProductCategories.find(productCategory => productCategory._id == productCategoryId)
            return productCategory ? productCategory.productSubCategories : []
        },
        hasFilteredProducts() {
            return this.filteredProducts && this.filteredProducts.length > 0
        },
        addStockProduct(stockProductId) {
            const isRepeatedStockProduct = this.selectedStockProducts.some(stockProduct => stockProduct.stockProductId == stockProductId)
            if (isRepeatedStockProduct) {
                return this.$vToastify.info('Esse produto já foi selecionado', "Atenção!")
            }

            const selectedStockProduct = this.getStockProductById(stockProductId)
            const stockProductPossibleUnits = this.getStockProductPossibleUnits(selectedStockProduct)

            this.selectedStockProducts.push({
                stockProductId: stockProductId,
                quantity: {
                    value: null,
                    unitId: this.returnIfExists(stockProductPossibleUnits, '0._id') || null,
                    dosageId: this.returnIfExists(this.stockProductsPossibleDosages, '0._id') || null
                },
                possibleUnits: stockProductPossibleUnits,
                possibleDosages: this.stockProductsPossibleDosages,
                approved: false,
                displayTextStockProductLabel: `${selectedStockProduct.product.name} (${selectedStockProduct.product.unit.name})`,
            })
        },
        removeStockProduct(stockProductIndex) {
            this.selectedStockProducts.splice(stockProductIndex, 1);
        },
        setValueDosage(event, stockProduct, key) {
            const { value, unitId, dosageId } = event

            stockProduct[key].value = value
            stockProduct[key].unitId = unitId
            stockProduct[key].dosageId = dosageId

            return stockProduct
        },
        syncInput() {
            const stockProducts = this.value
            this.selectedStockProducts = stockProducts.map(stockProduct => {
                const selectedStockProduct = this.getStockProductById(stockProduct.stockProductId)

                return {
                    stockProductId: stockProduct.stockProductId,
                    quantity: {
                        value: stockProduct.quantity.value,
                        unitId: stockProduct.quantity.unitId,
                        dosageId: this.selectedSeedUnitId || stockProduct.quantity.dosageId,
                    },
                    possibleUnits: this.getStockProductPossibleUnits(selectedStockProduct),
                    possibleDosages: this.stockProductsPossibleDosages,
                    approved: stockProduct.approved,
                    displayTextStockProductLabel: `${selectedStockProduct.product.name} (${selectedStockProduct.product.unit.name})`
                }
            })
        }
    }
};
</script>
<style lang="sass" scoped>
.stock-product-selector
    display: flex
    flex-wrap: wrap

    &__item
        width: 50%

        &--category
            padding-right: 4px

        &--sub-category
            padding-left: 4px

        &--full-width
            width: 100%

    &__selected-products
        width: 100%

.stock-product-container
    display: flex
    flex-wrap: wrap

    &__item
        margin-top: 16px
        width: 50%

        &:nth-child(2n-1)
            padding-right: 4px

        &:nth-child(2n)
            padding-left: 4px

@media (max-width: 600px)
    .stock-product-selector
        &__item
            width: 100%

            &--category
                padding: 0

            &--sub-category
                padding: 0

    .stock-product-container
        &__item
            width: 100%

            &:nth-child(2n-1)
                padding-right: 0

            &:nth-child(2n)
                padding-left: 0
</style>
