/* eslint-disable no-unused-vars */
import Planning from '../../api/Planning';
import Activities from '../../api/Activities';
import Property from '../../api/Property';

const state = {
    plannings: [],
    dataToUpdate: null,
}

const getters = {
    plannings: state => state.plannings,
    dataToUpdate: state => state.dataToUpdate,
}

const actions = {
   async getPlannings({commit}, data) {
        const response = await Planning.getAllAcitivities(data.propertyId, data.cropId);
        if(response.status === 200) {
            commit("setPlannings", response.data.data)
        }
        return response;
    },
    async completeActivityPlanting({state ,commit}, {data}) {
        const { pulverizations, coverFertilizer, harvests, plantings } = state.plannings;
        plantings.map(item => {
            if (item._id === data.planning._id) {
                item = data.planning;
                return item;
            }
            return item;
        })

        const newPlannings = {
            pulverizations,
            coverFertilizer,
            harvests,
            plantings
        }
        const response = await Planning.completeActivityPlanting(data.planning);
        if (response.status === 200) {
            commit("setPlannings", newPlannings)
        }
        return response;
    },
    async completeActivityHarvest({state ,commit}, {data}) {
        const { pulverizations, coverFertilizer, harvests, plantings } = state.plannings;
        harvests.map(item => {
            if (item._id === data.planning._id) {
                item = data.planning;
                return item;
            }
            return item;
        })

        const newPlannings = {
            pulverizations,
            coverFertilizer,
            harvests,
            plantings
        }
        const response = await Planning.completeActivityHarvest(data.planning);
        if (response.status === 200) {
            commit("setPlannings", newPlannings)
        }
        return response;
    },
    async completeActivityPulverization({state ,commit}, {data}) {
        const { pulverizations, coverFertilizer, harvests, plantings } = state.plannings;
        pulverizations.map(item => {
            if (item._id === data.planning._id) {
                item = data.planning;
                return item;
            }
            return item;
        })

        const newPlannings = {
            pulverizations,
            coverFertilizer,
            harvests,
            plantings
        }
        
        const response = await Planning.completeActivityPulverization(data.planning);
        if (response.status === 200) {
            commit("setPlannings", newPlannings)
        }
        return response;
    },
    ToUpdate({commit}, data) {
       commit('setDataToUpdate', data);
    },
    async updateHarvest({state ,commit}, data) {
        const response = await Activities.putHarvests(data)
        return response;
    },
    async updatePlanting({state ,commit}, data) {
        const response = await Activities.putPlanting(data)
        return response;
    },
    async updatePulverization({state ,commit}, data) {
        const response = await Activities.putPulverization(data)
        return response;
    },
    clearAll({commit}) {
        commit('setPlannings', []);
    }
}

const mutations = {
    setPlannings: (state, plannings) => state.plannings = plannings,
    setDataToUpdate: (state, dataToUpdate) => state.dataToUpdate = dataToUpdate,
}

export default { state, getters, actions, mutations, namespaced: true }