import StockProduct from '../../api/StockProduct';

const state = {
    stockProducts: [],
    stockProductsState: []
}

const getters = {
    getStockProducts: state => state.stockProducts,
    stockProductsState: state => state.stockProductsState,
}

const actions = {
    async findStockProducts({ commit }, payload) {
        const { propertyId, active } = payload
        const response = await StockProduct.findStockProducts({ propertyId, active });
        const stockProducts = response.data.data;
        commit("SET_STOCK_PRODUCTS", stockProducts);
        return response;
    },
    async createStockProduct({ commit }, stockProduct) {
        const response = await StockProduct.createStockProduct(stockProduct);
        return response;
    },
    async deleteStockProduct({ commit }, stockProductId) {
        const response = await StockProduct.deleteStockProduct(stockProductId);
        return response;
    },
    async updateStockProduct({ commit }, stockProductsState) {
        const response = await StockProduct.updateStockProduct(stockProductsState)
        commit("UPDATE_STOCK_PRODUCTS", response.data.data)
    },
}

const mutations = {
    SET_STOCK_PRODUCTS: (state, stockProducts) => state.stockProducts = stockProducts,
    UPDATE_STOCK_PRODUCTS: (state, updateStockProducts) => state.stockProductsState = updateStockProducts
}

export default { state, getters, actions, mutations, namespaced: true }
