import API from './API';

export default {

    findStockProducts({ propertyId, active }) {
        return API.get("/stock/products", {
            params: {
                ... propertyId ? { propertyId } : {},
                ... active !== undefined ? { active } : {}
            }
        })
    },

    createStockProduct(stockProduct) {
        return API.post("/stock/products", stockProduct)
    },

    deleteStockProduct(stockProductId) {
        return API.delete(`/stock/products/${stockProductId}`)
    },
    updateStockProduct(data) {
        return API.put(`/stock/products/${data._id}`, data)
    }

}
