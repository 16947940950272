<template>
    <Modal :open="open" :loading="false" @close="$emit('close')">
        <div class="app-modal-header" slot="header">
            <i class="fas fa-leaf"></i>
            <span>Cultivares</span>
        </div>
        <div class="app-modal-body" slot="body">
            <div>
                <label class="app-label"> Safra selecionada: </label>
                {{ this.data.name }}
            </div>
            <div>
                <label class="app-label" for="field">Selecione um talhão</label>
                <select class="app-select" id="field" v-model="selectedField" placeholder="s">
                    <option disabled selected :value="{}">Selecione um talhão</option>
                    <option v-for="field in allFields" :value="field" :key="field._id">
                        {{ field.name }} {{ field.size.value ? `(${formatNumber(field.size.value.toFixed(2))} ${field.size.unity})` : ''}}
                    </option>
                </select>
            </div>
            <div>
                <label class="app-label" for="subarea-name">Nome do cultivar</label>
                <input class="app-input" id="subarea-name" placeholder="Ex: Cultivar 01" type="text"
                       v-model="newSubarea.seed"/>
            </div>
            <div>
                <input-number
                    id="subarea-size"
                    :label="`Tamanho (max:
                        ${this.newSubarea._id ? formatNumber(this.maxSubareaSizeOnEdit.toFixed(2)) : formatNumber(this.maxSubareaSizeOnCreate.toFixed(2))}
                        ${this.selectedField.size.unity})`"
                    placeholder="Ex: 250"
                    :data="newSubarea.size.value" @input="newSubarea.size.value = $event"
                />
            </div>
            <div class="text-center">
                <button
                    @click="saveSubarea"
                    class="btn rounded btn-primary btn--flat d-inline-block"
                    type="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    <span v-show="savingSubarea" class="spinner-border spinner-border-sm"/>
                    <span v-show="!savingSubarea"> {{ !this.newSubarea._id ? 'Adicionar' : 'Salvar' }} </span>
                </button>
                <button
                    v-if="this.newSubarea._id"
                    @click="cancelEditSubarea"
                    class="btn btn btn-primary--outlined btn--flat d-inline-block ml-2"
                    type="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    <span> Cancelar </span>
                </button>
            </div>
            <h4 class="text-center mt-4">
                Lista de Cultivares ({{formatNumber(this.subareasTotalArea.toFixed(2))}}/{{this.selectedField.size && this.selectedField.size.value ? formatNumber(this.selectedField.size.value.toFixed(2)) : formatNumber(parseFloat(0).toFixed(2))}} {{selectedField.size.unity}})</h4>
            <table class="subareas-table">
                <tr>
                    <th>Nome</th>
                    <th>Tamanho</th>
                    <th class="subareas-table-header__actions">Ações</th>
                </tr>
                <tr v-for="subarea in getSubareas" :key="subarea._id">
                    <td>{{ subarea.seed }}</td>
                    <td>{{ subarea.size.value ? `${formatNumber(subarea.size.value.toFixed(2))} ${subarea.size.unity}` : '-'}}</td>
                    <td>
                        <div class="subareas-table-content__actions">
                            <button
                                @click="editSubarea(subarea)"
                                class="btn btn--flat btn--no-background"
                            >
                                <i class="fas fa-edit"></i>
                                <span>Editar</span>
                            </button>
                            <button
                                class="btn btn-primary--outlined btn--flat btn--no-background"
                                @click="removeSubarea(subarea)"
                            >
                                <span v-show="removingSubarea == subarea._id" class="spinner-border spinner-border-sm"/>
                                <i v-show="removingSubarea != subarea._id" class="fas fa-trash"/>
                                <span v-show="removingSubarea != subarea._id">Excluir</span>
                            </button>
                        </div>
                    </td>
                </tr>
            </table>
            <div v-if="getSubareas.length == 0" class="text-center pb-2">
                Não possui dados
            </div>
        </div>
    </Modal>
</template>
<script>
import ComboBoxFields from "@/components/commons/ComboBoxFields.vue";
import InputNumber from '@/components/commons/InputNumber';
import Modal from '@/components/systemUI/Modal';

import {mapActions, mapGetters} from "vuex";

import _ from "lodash";

export default {
    name: 'ModalSubarea',
    components: {
        ComboBoxFields,
        InputNumber,
        Modal
    },
    props: {
        data: Object,
        open: Boolean,
    },
    data() {
        return {
            loadingSubareas: false,
            savingSubarea: false,
            removingSubarea: null,
            selectedField: {
                size: {
                    value: null,
                    unity: 'hectare'
                }
            },
            newSubarea: {
                seed: '',
                field: null,
                crop: null,
                size: {
                    value: null,
                    unity: 'hectare'
                }
            }
        };
    },
    computed: {
        ...mapGetters("property", ["getPropertySelected"]),
        ...mapGetters("fields", ["allFields"]),
        ...mapGetters("subareas", ["getSubareas"]),
        subareasTotalArea() {
            return this.getSubareas.reduce((totalArea, subarea) => totalArea + subarea.size.value, 0)
        },
        maxSubareaSizeOnEdit() {
            if (!this.newSubarea._id) {
                return 0
            }

            const subarea = this.getSubareas.find(subarea => subarea._id == this.newSubarea._id)
            const fieldSize = this.selectedField.size && this.selectedField.size.value ? this.selectedField.size.value : 0
            if (subarea) {
                return Math.max(fieldSize - this.subareasTotalArea + subarea.size.value, 0)
            }

            return Math.max(fieldSize - this.subareasTotalArea, 0)
        },
        maxSubareaSizeOnCreate() {
            const fieldSize = this.selectedField.size && this.selectedField.size.value ? this.selectedField.size.value : 0
            return Math.max(fieldSize - this.subareasTotalArea, 0)
        }
    },
    watch: {
        async selectedField() {
            if (this.selectedField._id) {
                await this.loadSubareas()
            }
        }
    },
    methods: {
        ...mapActions("crops", ["postCrop", "getCultures", "updateCrop"]),
        ...mapActions("fields", ["getFieldsByProperty"]),
        ...mapActions("subareas", ["findSubareas", "createSubarea", "updateSubarea", "deleteSubarea"]),
        editSubarea(subarea) {
            this.newSubarea = _.cloneDeep(subarea)
        },
        async loadSubareas() {
            this.loadingSubareas = true;
            await this.findSubareas({ cropId: this.data._id, fieldId: this.selectedField._id });
            this.loadingSubareas = false;
        },
        async saveSubarea() {
            if (!this.selectedField._id) {
                return this.$vToastify.error("Você precisa escolher um talhão antes de adicionar!", "Cultivar");
            }

            if (!this.data._id) {
                return this.$vToastify.error("Você precisa escolher uma safra antes de adicionar!", "Cultivar");
            }

            if (this.newSubarea._id && this.newSubarea.size.value > this.maxSubareaSizeOnEdit) {
                return this.$vToastify.error("A soma dos tamanhos dos cultivares não pode ultrapassar o tamanho do talhão", "Cultivar");
            }

            if (!this.newSubarea._id && this.newSubarea.size.value > this.maxSubareaSizeOnCreate) {
                return this.$vToastify.error("A soma dos tamanhos dos cultivares não pode ultrapassar o tamanho do talhão", "Cultivar");
            }

            if (!this.newSubarea.seed) {
                return this.$vToastify.error("É necessário informar o nome/grão dessa subárea", "Cultivar");
            }

            this.newSubarea.field = this.selectedField._id
            this.newSubarea.crop = this.data._id
            this.newSubarea.name = this.newSubarea.seed

            const subarea = _.cloneDeep(this.newSubarea)
            const subareaId = this.newSubarea._id
            this.savingSubarea = true;
            if (subareaId) {
                await this.updateSubarea({ subareaId, subarea });
            } else {
                await this.createSubarea(subarea)
            }
            this.savingSubarea = false;

            this.resetNewSubarea();
            await this.loadSubareas();
        },
        cancelEditSubarea() {
            this.resetNewSubarea()
        },
        async removeSubarea(subarea) {
            const subareaId = subarea._id
            const wantsToDelete = await this.$vToastify.prompt({
                body: "Deseja excluir esse cultivar?",
                title: "ATENÇÃO!",
                answers: { Sim: true, Não: false },
            })

            if (wantsToDelete) {
                this.removingSubarea = subareaId
                await this.deleteSubarea(subareaId)
                await this.loadSubareas();
                this.resetNewSubarea();
                this.removingSubarea = null
            }
        },
        resetNewSubarea() {
            this.newSubarea = {
                seed: '',
                name: '',
                field: null,
                crop: null,
                size: {
                    value: null,
                    unity: 'hectare'
                }
            }
        }
    },
    async mounted() {
        await this.getFieldsByProperty({ propertyId: this.getPropertySelected._id })
        if (this.allFields.length > 0) {
            this.selectedField = this.allFields[0]
        }
    }
}
</script>
<style lang="sass">
.subareas-table
    width: 100%

    &-header__actions
        width: 30%
        text-align: center

    &-content__actions
        display: flex
        grid-gap: 6px
        align-items: center
        justify-content: center

        .btn--no-background
            background: none

            &:hover
                background: $color-white-darken-1

            i
                color: $color-blue

.subareas-table th
    color: $color-blue
    padding: 0 10px
    font-size: 16px

.subareas-table tr
    border-radius: 20px
    height: 70px

.subareas-table td
    padding: 0 10px

.subareas-table tr:nth-child(even)
    background-color: #f2f2f2

.spinner-border
    vertical-align: sub
</style>
