<template>
    <div class="report-crops">
        <div class="report-crops__header">
            <router-link
                class="btn btn-primary btn--rounded btn--flat"
                tag="a"
                to="/relatorios"
            >
                <i class="fa fa-arrow-left"/>
                <span>Voltar</span>
            </router-link>
            <h2 class="title">Relatório Safras</h2>
        </div>
        <div class="filter-container">
            <div class="filter-container__item">
                <label class="app-label">
                    Período
                </label>
                <period-select-picker
                    :selectedInterval="selectedInterval"
                    @intervalSelected="selectedInterval = $event.interval"
                />
            </div>
            <div class="filter-container__item filter-container__item--multiple-selector">
                <multiple-selector
                    label="Talhão(ões)"
                    placeholder="Selecione o(s) talhão(ões)"
                    :items="allFields"
                    item-text="name"
                    item-value="_id"
                    v-model="selectedFields"
                />
            </div>
            <div class="filter-container__item filter-container__date">
                <date-picker
                    :disabled="!selectedCustom"
                    :dataSent="periodSend.startDate"
                    @dateSelected="periodSend.startDate = $event.date"
                    label="Data inicial"
                />
                <date-picker
                    :disabled="!selectedCustom"
                    :dataSent="periodSend.endDate"
                    @dateSelected="periodSend.endDate = $event.date"
                    label="Data final"
                />
            </div>
            <div class="filter-container__item filter-container__action">
                <button
                    class="btn btn-primary--outlined btn--flat"
                    @click="generateReport"
                >
                    <span
                        v-if="loadingActivity"
                        class="spinner-border spinner-border-sm" role="status"
                    />
                    <span v-else>
                        <i class="fa fa-download mr-2"/>
                        <span>
                            Gerar relatorio
                        </span>
                    </span>
                </button>
                <button
                    class="btn btn-primary--outlined btn--flat"
                    :disabled="activitiesShow.length == 0"
                    id="fazenda"
                    @click="generatePDF"
                >
                    <span
                        v-if="loadingPDF"
                        class="spinner-border spinner-border-sm"
                        role="status"
                    />
                    <span v-else>
                        <i class="fa fa-file-pdf mr-2"/>
                        <span>
                            Baixar PDF
                        </span>
                    </span>
                </button>
            </div>
        </div>
        <div class="reports-crops__content">
            <div
                v-for="activity in activitiesShow"
                class="card-container"
                :key="generateUUID()"
            >
                <div class="activity-card">
                    <div class="activity-card__info">
                        <div class="activity-info">
                            <div class="activity-info__header">
                                <div class="activity-info__field">
                                    <strong> {{ activity.subarea.field.name }} </strong>
                                </div>
                                <div class="activity-info__subarea">
                                    <strong> {{ activity.subarea.seed }} </strong>
                                </div>
                            </div>
                            <div class="activity-info__content">
                                <div class="activity-info__date">
                                    <div>
                                        <i class="fas fa-calendar-check mr-2"></i>
                                        {{ activity.activityDate.dateHour | formatDate }}
                                    </div>
                                    <div v-if="activity.type == 'pulverization'">
                                        <i class="fas fa-clock mr-2"></i>
                                        {{ activity.activityDate.dateHour | formatTime }}
                                    </div>
                                    <div>
                                        <p v-if="activity.area || activity.area === 0">
                                            Área utilizada (ha): {{ activity.area | formatNumber }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="activity-info__content">
                                <div v-if="activity.activityDate.done" class="activity-info-status activity-info-status--green">Concluído</div>
                                <div v-else-if="new Date(activity.activityDate.dateHour) < new Date()" class="activity-info-status activity-info-status--red">Pendente</div>
                                <div v-else class="activity-info-status activity-info-status--yellow">Aguardando</div>
                            </div>
                        </div>
                        <div class="activity-info-background">
                            <img
                                v-if="lastNdvis[activity.subarea.field._id]"
                                class="activity-info-background__image"
                                :src="lastNdvis[activity.subarea.field._id].image"
                                onerror="this.style.display='none'"
                            />
                        </div>
                    </div>
                    <div class="activity-card__content">
                        <div class="activity-content">
                            <div class="activity-content__type">
                                <span v-if="activity.type == 'harvest'"> Colheita </span>
                                <span v-else-if="activity.type == 'planting'"> Plantio </span>
                                <span v-else-if="activity.type == 'pulverization'"> Pulverização </span>
                                <span v-else-if="activity.type == 'fertilization'"> Fertilização </span>
                                <span v-else-if="activity.type == 'soilPreparation'"> Preparo de solo </span>
                                <span v-else-if="activity.type == 'seedTreatment'"> Tratamento de sementes </span>
                                <span v-else-if="activity.type == 'otherActivity'"> Outra atividade </span>
                            </div>
                            <div
                                v-if="activity.title"
                                class="activity-content__title"
                            >
                                ({{ activity.title }})
                            </div>
                            <div class="activity-content__subtitle">
                                <span v-for="(operator, operatorIndex) in activity.operators">
                                    <span v-if="operatorIndex < activity.operators.length - 1"> {{ operator.operator.name }}, </span>
                                    <span v-else> {{ operator.operator.name }} </span>
                                </span>
                            </div>
                            <div class="activity-content__content">
                                <div
                                    v-if="activity.culturePhase"
                                    class="chip-list"
                                >
                                    <p class="chip-list__title"> Fase da cultura: </p>
                                    <p class="chip-list__item">
                                        {{ activity.culturePhase.abbreviation }}
                                    </p>
                                </div>
                                <div
                                    v-if="activity.seedToTreat && activity.seedToTreat.stockProduct"
                                    class="chip-list"
                                >
                                    <p class="chip-list__title">
                                        Semente a ser tratada:
                                    </p>
                                    <div
                                        v-if="activity.seedToTreat.quantity && activity.seedToTreat.quantity.value"
                                        class="chip-list__item"
                                    >
                                        {{ activity.seedToTreat.stockProduct.product.name }} {{ activity.seedToTreat.quantity && activity.seedToTreat.quantity.value ? `- ${activity.seedToTreat.quantity.value} ${activity.seedToTreat.quantity.unit.name}` : '' }}
                                    </div>
                                </div>
                                <div
                                    v-if="activity.stockProducts && activity.stockProducts.length > 0"
                                    class="chip-list"
                                >
                                    <p class="chip-list__title"> Produtos: </p>
                                    <div
                                        v-for="product in activity.stockProducts"
                                        class="chip-list__item"
                                    >
                                        {{ returnIfExists(product, "stockProduct.product.name") || '-' }}
                                        <span v-if="product.quantity && product.quantity.value && product.quantity.unit && product.quantity.dosage">
                                            {{ ` - ${product.quantity.value} ${product.quantity.unit.name }/${product.quantity.dosage.name }` }}
                                        </span>
                                    </div>
                                </div>
                                <div
                                    v-if="activity.machineries && activity.machineries.length > 0"
                                    class="chip-list"
                                >
                                    <p class="chip-list__title">
                                        Maquinários:
                                    </p>
                                    <div
                                        v-for="machinery in activity.machineries"
                                        class="chip-list__item"
                                    >
                                        <span>
                                            {{ machinery.machinery.model }}
                                        </span>
                                    </div>
                                </div>
                                <div
                                    v-if="activity.notes"
                                    class="chip-list"
                                >
                                    <p class="chip-list__title"> Observações: </p>
                                    <p class="chip-list__item">
                                        {{ activity.notes }}
                                    </p>
                                </div>
                                <div v-for="activityKey in Object.keys(activity)" v-if="isNumberDisplayKey(activityKey) && activity[activityKey].value" class="number-display">
                                    <div class="number-display__key">
                                        {{ translateKey(activityKey) }}
                                    </div>
                                    <div class="number-display__value">
                                        {{ activity[activityKey].value }} {{ activity[activityKey].unity }}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="no-content" v-show="activitiesShow.length < 1">
            <i class="fa fa-exclamation-circle fa-2x"/>
            <span class=""> Sem atividades nessa safra </span>
        </div>
    </div>
</template>

<script>

import DatePicker from "@/components/commons/DatePicker.vue";
import PeriodSelectPicker from "@/components/commons/PeriodSelectPicker.vue";
import MultipleSelector from '@/components/commons/MultipleSelector.vue';
import { calculatePeriodInterval } from "@/utils/calculatePeriodInterval";
import { mapGetters, mapActions } from "vuex";

export default {
    name: 'ReportCrops',
    components: {
        DatePicker,
        PeriodSelectPicker,
        MultipleSelector
    },
    data() {
        return {
        	loadingActivity: false,
        	loadingPDF: false,
        	activitiesShow: [],
            selectedInterval: "weekly",
            lastNdvis: {},
            periodSend: {
                custom: false,
                startDate: "",
                endDate: ""
            },
            selectedFields: [],
        	payload: {
        		propertyId: "",
        		cropId: "",
                subareaId: "",
                fieldIdList: [],
                startDate: "",
                endDate: ""
        	}
        };
    },
    computed: {
      	...mapGetters("property", [
      		"getPropertySelected",
      		"getCropSelected",
            "getPropertyFields"
      	]),
        ...mapGetters("fields", [
            "allFields"
        ]),
        ...mapGetters("activities", ["getActivities"]),
        ...mapGetters("ndvis", ["getLastNdvis"]),
        selectedCustom(){
            return this.selectedInterval == 'custom'
        }
    },
    watch: {
        async getPropertySelected() {
            await this.updateFilter()
        },
        async getCropSelected() {
          await this.updateFilter()
        },
        async selectedInterval(){
          await this.updateFilter()
        }
    },
    methods: {
        ...mapActions("fields", [
            "getFieldsByProperty"
        ]),
        ...mapActions("activities", ["findActivities"]),
        ...mapActions("ndvis", ["findLastNdvis"]),
        ...mapActions("reports", ["findCropReport"]),
        async updateFilter(){
            this.periodSend = calculatePeriodInterval(this.selectedInterval, this.getCropSelected)
            const payload = this.updatePayload()
            await this.getFieldsByProperty(payload)
        },
      	updatePayload(){
            const payload = {
                propertyId: this.getPropertySelected._id,
                cropId: this.getCropSelected._id,
                fieldIdList: this.selectedFields,
                subareaId: '',
                startDate: this.periodSend.startDate,
                endDate: this.periodSend.endDate,
                template: this.selectedFormat
            }
            return payload
      	},
      	formulaIsNotEmpty(formula){
      		for(let key of Object.keys(formula)){
      			if(formula[key] && key != '_id') {
      				return true
      			}
      		}
      		return false
      	},
      	hasFormula(activity){
      		return activity.formula ? this.formulaIsNotEmpty(activity.formula) : false
      	},
      	isNumberDisplayKey(key){
      		const keys = [
      			'flowRate',
      			'productivity',
      			'production',
      			'humidityGrains',
                'spaceline',
                'plantStand',
                'plantPopulation',
                'expectedProductivity'
      		]
      		return keys.includes(key)
      	},
      	translateKey(key){
      		const translation = {
      			flowRate: 'Vazão',
      			productivity: 'Produtividade',
      			production: 'Produção',
      			humidityGrains: 'Umidade',
                spaceline: 'Espaçamento entre linhas',
                plantStand: 'Estande final de plantas',
                plantPopulation: 'Distribuição de sementes',
                expectedProductivity: 'Produtividade esperada'
      		}
      		return translation[key]
      	},
      	async generateReport(){
      		this.loadingActivity = true

      		const payload = this.updatePayload()

            await this.findActivities(payload)
            await this.findLastNdvis(payload)

            this.activitiesShow = this.getActivities
            this.lastNdvis = this.getLastNdvis

      		this.loadingActivity = false
      	},
      	async generatePDF(){
            this.loadingPDF = true

            const payload = this.updatePayload()
      		const response = await this.findCropReport(payload)

      		if (response.data && response.data.pdf) {
                this.$vToastify.success('PDF gerado com sucesso!')
                console.log(response.data.pdf)
                this.goToPage(response.data.pdf)
            }

            this.loadingPDF = false
      	},
      	goToPage(url){
      		window.open(url, '_blank')
      	}
    },
    async mounted(){
        this.selectedInterval = "weekly"
  	    this.updateFilter()
    }
}
</script>

<style scoped lang="sass">
.report-crops
    height: 100%
    grid-gap: 10px
    padding: 5px

    &__header
        display: flex
        align-items: center
        justify-content: center
        position: relative
        padding: 20px 0
        width: 100%

        .btn
            position: absolute
            left: 0

        .title
            font-size: 16px
            margin: 0

    &__action
        display: flex
        justify-content: left
        grid-gap: 10px
        margin: 15px 0

.filter-container
    display: flex
    justify-content: center
    align-items: flex-end
    padding: 10px 0
    flex-wrap: wrap
    border: $border
    border-radius: $border-radius

    &__item
        flex-grow: 1
        padding: 10px

        &--multiple-selector
            width: 200px

    &__action
        display: flex
        grid-gap: 10px
        justify-content: center
        min-width: 350px


    &__date
        display: flex
        grid-gap: 10px
        min-width: 350px

        .datePicker
            width: 100%

        #date
            height: 40px

.activity-card
    margin: auto
    display: table
    width: 100%
    max-width: 1000px
    border-radius: 50px
    margin-bottom: 10px
    border-collapse: separate

    &__info
        position: relative
        display: table-cell
        border: 2px solid #000000
        width: 50%
        border-radius: 5px 0 0 5px

    &__content
        width: 50%
        display: table-cell
        vertical-align: top
        padding: 0 5px

    .activity-info
        width: 100%
        display: table
        padding: 5px

        &__header
            display: table-row
            padding: 5px

        &__field
            width: 50%
            padding: 5px
            text-align: left
            word-break: break-all
            display: table-cell
            border-bottom: 5px solid #5ad67d

        &__subarea
            width: 50%
            padding: 5px
            text-align: right
            word-break: break-all
            display: table-cell

        &__content
            display: table-row

        &__date
            display: table-cell
            text-align: left
            padding: 20px 5px 5px 5px

    .activity-info-background
        position: relative
        top: 0
        left: 0
        padding: 10px
        z-index: -1

        &__image
            object-fit: contain
            width: 100%
            position: relative
            height: 100%
            max-height: 300px

    .activity-info-status
        padding: 5px 10px

        &--red
            background-color: #F6D7D7

        &--yellow
            background-color: #FFF3AE

        &--green
            background-color: #D4F2D4


    .activity-content
        width: 100%

        &__type
            font-size: 20px
            text-align: left
            border-radius: 5px
            padding: 10px
            padding-bottom: 0

        &__title
            font-size: 20px
            text-align: left
            border-radius: 5px
            padding: 10px
            padding-top: 0

        &__subtitle
            font-size: 16px
            color: #bbb
            padding: 10px
            padding-top: 0

        &__content
            margin-top: 5px
            width: 100%

.chip-list
    padding: 0 10px
    border-radius: 5px
    margin-bottom: 10px

    &__title
        text-align: left
        margin: 0

    &__item
        display: inline-block
        padding: 5px 20px
        color: #696969
        margin: 4px 4px 0 0
        background: #eeeeee
        border-radius: 5px
        white-space: pre-line


.number-display
    display: table
    width: 100%
    margin-top: 10px
    padding: 0 10px

    &__key
        display: table-cell
        background: #f0ad4e
        color: white
        width: 50%
        padding: 5px
        border-radius: 5px 0 0 5px


    &__value
        display: table-cell
        background: #656565
        color: white
        width: 50%
        padding: 5px
        border-radius: 0 5px 5px 0


.capitalize
    text-transform: capitalize

.no-content
    display: flex
    justify-content: center
    align-items: center
    height: 100px
    color: $color-grey
    grid-gap: 10px

</style>
