<template>
    <div class="login-container">
        <div class="login-container__preload" v-show="loading">
            <p>Carregando...</p>
        </div>
        <div class="login-container__form">
            <div class="col-sm-6 col-lg-3">
                <form @submit.prevent="auth">
                    <img class="login-container__form--img img-fluid" src="/img/icons/svg/agrofarmer-white.png" alt=""/>
                    <hr class="space"/>
                    <hr class="space"/>
                    <input type="text" class="form-control" placeholder="E-mail" v-model="email">
                    <hr class="space"/>
                    <input type="password" class="form-control" placeholder="Senha" v-model="password">
                    <hr class="space"/>
                    <button type="submit" class="btn btn-primary btn--rounded btn-login">
                        <span>Entrar</span>
                        <i class="fas fa-chevron-right"/>
                    </button>
                </form>
            </div>
            <div class="footer">{{footerText}}</div>
        </div>
    </div>
</template>

<script>
import {mapActions} from 'vuex';
import { version } from '../../../package.json';

export default {
    data() {
        return {
            loading: false,
            email: "",
            password: ""
        }
    },
    computed: {
        footerText() {
            return `© 2022 Agrointeli - versão: ${version}`;
        }
    },
    methods: {
        ...mapActions("users", ["authenticate", "getUser"]),
        async auth() {
            this.loading = true
            const success = await this.authenticate({email: this.email.trim(), password: this.password});
            if (success) {
                await this.getUser()
                await this.$router.push({path: '/'})
            }
            this.loading = false
        }
    }
}
</script>

<style scoped lang="sass">
.login-container
    height: 100vh
    width: 100%

    &__preload
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        width: 100%
        height: 100%
        position: absolute
        z-index: 1
        background: $color-blue-darken-2
        color: $color-blue-lighten-1

    &__form
        display: flex
        justify-content: center
        align-items: center
        width: 100%
        height: 100%
        background: url("../../assets/img/bg_login.png") no-repeat
        background-size: cover
        position: relative

        form
            display: flex
            flex-direction: column
            justify-content: center
            align-items: center

        &--img
            width: 300px
            height: auto
            max-width: 100%

        .form-control
            border-radius: 20px
            border: none
            padding: .375rem 1rem
            box-shadow: $box-shadow

        .footer
            position: absolute
            bottom: 10px
            right: 10px
            color: $color-white

.btn-login
    background: $color-blue-normal-1
    font-weight: bold
    padding: 8px 16px
    width: 100%
    margin-top: 16px

@media (max-width: 690px)
    .login-container .footer
        right: auto
</style>
