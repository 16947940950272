const mixin = {
    methods: {
        returnIfExists(object, path, defaultValue) {
            if (typeof path !== 'string') {
                throw new Error("Path must be string.")
            }

            const keys = path.split('.')
            if (keys.length == 0) {
                throw new Error("Invalid path.")
            }

            let currentObject = object
            for (const key of keys) {
                if (!currentObject) {
                    return defaultValue
                }

                currentObject = currentObject[key]
            }

            if (currentObject === undefined) {
                return defaultValue
            }

            return currentObject
        }
    }
}

export default mixin
