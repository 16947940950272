<template>
    <div>
        <div class="image-card-ndvi">
            <div class="image-card-ndvi__title">
                <strong class="image-card-ndvi__title-text">
                    {{ ndvi.field.name }}
                </strong>
                <div class="image-card-ndvi__checkbox-container">
                    <div
                        class="app-checkbox d-flex"
                    >
                        <input
                            :id="`ndvi-image-card_${ndvi._id}`"
                            type="checkbox"
                            v-model="ndvi.active"
                            @change="$emit('activateOrDeactivateNdvi', ndvi._id)"
                        />
                        <label
                            :for="`ndvi-image-card_${ndvi._id}`"
                            class="image-card-ndvi__checkbox-label"
                        >
                        </label>
                    </div>
                </div>
            </div>
            <div class="image-card-ndvi__image-container">
                <img
                    class="image-card-ndvi__image"
                    :src="ndvi.image"
                    alt=""
                />
            </div>
            <div class="image-card-ndvi__description">
                <p>
                    {{ ndvi.captured | formatDate }}
                </p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "ImageCardNdvi",
    props: {
        ndvi: {
            type: Object
        }
    }
};
</script>
<style scoped lang="sass">

.image-card-ndvi
    background: #eee
    text-align: center
    padding: 10px 0
    border-radius: 6px
    width: 100%
    height: 300px

    &__title, &__description
        text-align: center
        height: 50px
        white-space: nowrap
        line-height: 50px

    &__title
        display: flex
        padding: 0 16px
        align-items: center
        justify-content: center

    &__title-text
        flex: 1
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis

    &__image-container
        height: calc(100% - 50px - 50px)
        border-radius: 6px
        padding: 10px
        display: flex
        -webkit-display: flex
        -moz-display: flex
        -ms--display: flex
        justify-content: center
        align-items: center

    &__image
        max-width: 100%
        max-height: 100%
        width: 100%
        height: auto
        object-fit: contain

    &__checkbox
        display: flex
        width: 25px

    &__checkbox-container
        display: flex
        grid-gap: 6px
        align-items: center
        justify-content: center

    &__checkbox-label
        line-height: 25px

</style>
