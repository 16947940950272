const mixin = {
    methods: {
        calculateActivityProductTotalQuantityToUse(activity, activityProduct) {
            const unitConversions = this.$store.state["unit-conversions"].unitConversions

            const activityProductValue = activityProduct && activityProduct.quantity && activityProduct.quantity.value
                ? activityProduct.quantity.value
                : 0

            const activityProductUnitId = activityProduct && activityProduct.quantity && activityProduct.quantity.unit
                ? activityProduct.quantity.unit._id
                : undefined

            const activityProductStockProductUnitId = activityProduct && activityProduct.stockProduct && activityProduct.stockProduct.product && activityProduct.stockProduct.product.unit
                ? activityProduct.stockProduct.product.unit._id
                : undefined

            const unitConversion = unitConversions.find(unitConversion => unitConversion.fromUnit._id == activityProductUnitId && unitConversion.toUnit._id == activityProductStockProductUnitId)
            const { factor } = unitConversion

            const convertedValue = (activityProductValue || 0) * (factor || 1)

            if (activity.type == 'seedTreatment') {
                const seedToTreatQuantityValue = activity && activity.seedToTreat && activity.seedToTreat.quantity && activity.seedToTreat.quantity.value
                    ? activity.seedToTreat.quantity.value
                    : 1

                return convertedValue * seedToTreatQuantityValue
            }

            const area = activity.area || 0

            return convertedValue * area
        },

    }
}

export default mixin
