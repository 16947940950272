<template>
    <modal :open="open" :loading="false" @close="$emit('close')">
        <div class="app-modal-header" slot="header">
            <span>{{ displayTextProperty }}</span>
        </div>
        <div class="app-modal-body" slot="body">
            <div class="content">
                <div class="content__item">
                    <label class="app-label">Nome</label>
                    <input
                        class="app-input"
                        type="text"
                        placeholder="nome da propriedade"
                        v-model="propertyData.name"
                    />
                </div>
                <div class="content__item">
                    <label class="app-label">País</label>
                    <select class="app-select" v-model="propertyData.country">
                        <option disabled>Selecione um país</option>
                        <option
                            v-for="country in countries"
                            :key="country"
                            :value="country"
                        >
                            {{ country }}
                        </option>
                    </select>
                </div>
                <div class="content__item">
                    <label class="app-label">Estado</label>
                    <select
                        class="app-select"
                        v-if="propertyData.country != 'Brasil'"
                        v-model="propertyData.state"
                    >
                        <option disabled>Selecione o estado</option>
                        <option value="Outro">Outro</option>
                    </select>
                    <select
                        v-else
                        class="app-select"
                        v-model="propertyData.state"
                    >
                        <option disabled>Selecione o estado</option>
                        <option
                            v-for="state in states"
                            :key="state.value"
                            :value="state.value"
                        >
                            {{ state.name }}
                        </option>
                    </select>
                </div>
                <div class="content__item">
                    <input-number
                        id="tamanho"
                        label="Tamanho (ha)"
                        placeholder="Ex: 250"
                        :data="propertyData.size"
                        @input="propertyData.size = $event"
                    />
                </div>
                <div class="content__item">
                    <span class="app-label">Latitude</span>
                    <input
                        class="app-input"
                        placeholder="Entre -90 e 90"
                        v-model="propertyData.coordinates.latitude"
                    />
                </div>
                <div class="content__item">
                    <span class="app-label">Longitude</span>
                    <input
                        class="app-input"
                        placeholder="Entre -180 e 180"
                        v-model="propertyData.coordinates.longitude"
                    />
                </div>
            </div>
        </div>
        <div class="app-modal-footer" slot="footer">
            <button
                class="btn btn-primary btn--flat"
                @click="saveProperty()"
                :disabled="loading"
            >
                <span v-if="loading" class="spinner-border" role="status" />
                <span v-else>
                    <span>Salvar</span>
                    <i class="ml-2 fas fa-save" />
                </span>
            </button>
        </div>
    </modal>
</template>

<script>
import Modal from "@/components/systemUI/Modal.vue";
import MultipleSelector from "@/components/commons/MultipleSelector.vue";
import InputNumber from "../../../../components/commons/InputNumber.vue";
import { mapActions, mapGetters } from "vuex";

export default {
    name: "ModalProperty",
    props: {
        data: Object,
        open: Boolean,
    },
    components: {
        Modal,
        MultipleSelector,
        InputNumber,
    },
    data() {
        return {
            loading: false,
            propertyData: {
                name: "",
                country: "",
                state: "",
                size: "",
                coordinates: {
                    latitude: "",
                    longitude: "",
                },
            },
            countries: [
                "Argentina",
                "Bolívia",
                "Brasil",
                "Chile",
                "Colômbia",
                "Equador",
                "Guiana Francesa",
                "Guiana",
                "Paraguai",
                "Peru",
                "Suriname",
                "Uruguai",
                "Venezuela",
            ],
            states: [
                { value: "AC", name: "Acre" },
                { value: "AL", name: "Alagoas" },
                { value: "AP", name: "Amapá" },
                { value: "AM", name: "Amazonas" },
                { value: "BA", name: "Bahia" },
                { value: "CE", name: "Ceará" },
                { value: "DF", name: "Distrito Federal" },
                { value: "ES", name: "Espírito Santo" },
                { value: "GO", name: "Goiás" },
                { value: "MA", name: "Maranhão" },
                { value: "MT", name: "Mato Grosso" },
                { value: "MS", name: "Mato Grosso do Sul" },
                { value: "MG", name: "Minas Gerais" },
                { value: "PA", name: "Pará" },
                { value: "PB", name: "Paraíba" },
                { value: "PR", name: "Paraná" },
                { value: "PE", name: "Pernambuco" },
                { value: "PI", name: "Piauí" },
                { value: "RJ", name: "Rio de Janeiro" },
                { value: "RN", name: "Rio Grande do Norte" },
                { value: "RS", name: "Rio Grande do Sul" },
                { value: "RO", name: "Rondônia" },
                { value: "RR", name: "Roraima" },
                { value: "SC", name: "Santa Catarina" },
                { value: "SP", name: "São Paulo" },
                { value: "SE", name: "Sergipe" },
                { value: "TO", name: "Tocantins" },
            ],
        };
    },
    computed: {
        ...mapGetters("property", ["getPropertySelected"]),
        displayTextProperty() {
            return !this.data._id ? "Nova Propriedade" : "Editar Propriedade";
        },
    },
    mounted() {
        this.copyData();
    },
    watch: {
        data() {
            this.copyData();
        },
    },
    methods: {
        ...mapActions("property", ["insertProperty", "updateProperty"]),
        ...mapActions("users", ["getUser"]),
        copyData() {
            this.propertyData = _.cloneDeep(this.data);
        },
        async saveProperty() {
            const property = {
                accountId: this.getPropertySelected.account._id,
                _id: this.propertyData._id,
                name: this.propertyData.name,
                coordinates: {
                    latitude: this.propertyData.coordinates.latitude,
                    longitude: this.propertyData.coordinates.longitude,
                },
                size: this.propertyData.size,
                state: this.propertyData.state,
                country: this.propertyData.country,
            };

            this.loading = true;
            if (property._id) {
                this.getUser()
                await this.updateProperty(property)
                    .then((response) => {
                        return this.$vToastify.success(
                            response.data.message,
                            "Propriedade"
                        );
                    })
                    .catch((error) => {
                        this.loading = false;
                        return this.$vToastify.error(
                            error.response.data.message,
                            "Propriedade"
                        );
                    });
            } else {
                await this.insertProperty(property)
                    .then((response) => {
                        return this.$vToastify.success(
                            response.data.message,
                            "Propriedade"
                        );
                    })
                    .catch((error) => {
                        this.loading = false;
                        return this.$vToastify.error(
                            error.response.data.message,
                            "Propriedade"
                        );
                    });
            }
            this.loading = false;
            this.$emit("close");
            this.$emit("inserted");
        },
    },
};
</script>

<style scoped lang="sass">
.content
    display: flex
    flex-wrap: wrap

    &__item
        width: 50%
        padding: 4px

.centered-checkbox
    display: flex
    align-items: center

.property-selector-label
    width: 100%
    padding: 4px

.property-selector
    display: flex
    flex-wrap: wrap
    width: 100%

    &__item
        width: 33.333%
        display: flex
        align-items: center
        padding: 4px

@media (max-width: 500px)
    .content
        &__item
            width: 100%

    .property-selector
        &__item
            width: 50%
</style>
