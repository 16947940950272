import API from './API';

export default {
    getAll() {
        return API.get("ndvis")
    },
    get(id) {
        return API.get(`ndvis/${id}`)
    },
    findNdvis(propertyId, cropId, fieldId, startDate, endDate, fieldIdList) {
    	return API.get("ndvis", {
            params: {
                ... propertyId ? { propertyId } : {},
                ... cropId ? { cropId } : {},
                ... fieldId ? { fieldId } : {},
                ... startDate ? { startDate } : {},
                ... endDate ? { endDate } : {},
                ... fieldIdList && fieldIdList.length ? { fieldIdList: JSON.stringify(fieldIdList) } : {}
            }
        })
    },
    findLastNdvis(propertyId) {
        return API.get("ndvis/last", {
            params: {
                ... propertyId ? { propertyId } : {},
            }
        })
    },
    getNdviReport(propertyId, cropId, fieldIdList, startDate, endDate, template) {
    	return API.get(`reports/ndvis/`, {
            params: {
                ... propertyId ? { propertyId } : {},
                ... cropId ? { cropId } : {},
                ... fieldIdList && fieldIdList.length ? { fieldIdList: JSON.stringify(fieldIdList) } : {},
                ... startDate ? { startDate } : {},
                ... endDate ? { endDate } : {},
                ... template ? { template } : {}
            }
        })
    },
    findByInterval(interval) {
        return API.get(`ndvis/${interval.startDate}/${interval.endDate}`)
    },
    updateNdvi(ndviId, ndvi) {
        return API.put(`ndvis/${ndviId}`, ndvi)
    }
}
