<template>
    <div class="app-selector">
        <label class="app-label">{{ title }}</label>
        <div class="dropdown">
            <button
                class="dropdown-toggle app-select"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="false"
                aria-expanded="false"
            >
                <span class="app-selector__text">
                    {{ selectedItem ? selectedItem.label : placeholder }}
                </span>
            </button>
            <div class="dropdown-menu " aria-labelledby="dropdownMenuButton">
                <div v-if="searchable" class="dropdown-menu__search-container">
                    <div class="search-container app-input">
                        <input class="search-container__input" :placeholder="searchBarPlaceholder"
                               v-model="searchWord" type="text"/>
                        <button class="search-container__search" disabled>
                            <i class="fas fa-search"/>
                        </button>
                    </div>
                </div>
                <div class="dropdown-menu__item-list">
                    <div class="item-list" v-if="filteredItems.length > 0">
                        <a class="item-list__item" v-for="item in filteredItems" @click="selectItem(item)">
                            <span>
                                {{ item.label }}
                            </span>
                            <i v-if="plusIcon" class="fas fa-plus"/>
                        </a>

                    </div>
                    <div v-else class="item-list__empty-message">
                        {{ emptyItemsMessage }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "AppSelector",
    props: {
        title: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        searchable: {
            type: Boolean,
            default: false
        },
        searchBarPlaceholder: {
            type: String,
            default: 'Digite para filtrar'
        },
        emptyItemsMessage: {
            type: String,
            default: 'Não foram encontrados itens na lista'
        },
        plusIcon: {
            type: Boolean,
            default: false
        },
        items: {
            type: Array,
            default: []
        },
        value: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            selectedItem: null,
            searchWord: "",
            filteredItems: []
        };
    },
    watch: {
        value() {
            this.selectedItem = this.items.find(item => item.value == this.value)
        },
        searchWord() {
            this.filterItems()
            this.$forceUpdate()
            this.$emit('changeSearchWord', this.searchWord)
        },
        items() {
            this.filterItems()
            this.$forceUpdate()
        }
    },
    methods: {
        filterItems() {
            this.filteredItems = this.searchWord ? this.items.filter(item => item.label.toUpperCase().includes(this.searchWord.toUpperCase())) : this.items
        },
        selectItem(item) {
            this.selectedItem = { ...item }
            this.$emit("input", item.value)
        }
    },

};
</script>
<style scoped lang="sass">
.app-selector
    width: 100%

    &__text
        width: calc(100% - 10px)
        text-overflow: ellipsis
        white-space: nowrap
        overflow: hidden

.dropdown
    width: 100%

.dropdown-toggle
    width: 100%
    display: flex
    justify-content: space-between
    align-items: center
    text-align: left
    border: $border
    text-overflow: ellipsis
    white-space: nowrap
    overflow: hidden
    font-family: inherit
    font-size: 14px
    color: #000
    height: 40px

    &:hover
        filter: none

.dropdown-menu
    width: 100%
    background: $color-grey-lighten-3
    overflow-x: hidden
    overflow-y: auto
    max-height: 150px
    position: absolute
    z-index: 999
    padding: 0px

    &__search-container
        padding: 4px


.item-list
    width: 100%
    font-size: 14px
    color: black

    &__item
        width: 100%
        padding: 8px
        display: flex
        -webkit-user-select: none
        -khtml-user-select: none
        -moz-user-select: none
        -ms-user-select: none
        -o-user-select: none
        user-select: none

        &:hover
            background: $color-grey-lighten-2
            cursor: pointer

        span
            display: flex
            width: calc(100% - 20px)

        i
            display: flex
            justify-content: center
            align-items: center
            width: 20px
            color: $color-blue

    &__empty-message
        width: 100%
        padding: 8px
        display: block

.search-container
    display: flex
    background: white
    justify-content: space-between
    width: 100%

    &__input
        width: calc(100% - 25px)
        border: 0

    &__search
        border: 0
        width: 25px
        background: none
</style>
