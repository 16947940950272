<template>
    <div>
        <div class="machinery-selector__app-selector">
            <app-selector
                :items="formattedMachineriesToSelector"
                @input="addMachinery"
                :value="selectedMachinery"
                title="Maquinários"
                placeholder="Selecione um maquinário"
                plus-icon
                searchable
            />
        </div>
        <div class="machinery-selector__table">
            <table class="machinery-table">
                <tr>
                    <th> Modelo </th>
                    <th> Fabricante </th>
                    <th> Funções </th>
                    <th class="machinery-table-header__actions"> Ações </th>
                </tr>
                <tr v-for="(machinery, machineryIndex) in selectedMachineries" :key="machinery._id">
                    <td> {{ machinery.model || '-' }} </td>
                    <td> {{ machinery.manufacturer || '-' }} </td>
                    <td> {{ machinery.types.join(', ') || '-' }} </td>
                    <td>
                        <div class="machinery-table-content__actions">
                            <button
                                @click="removeMachinery(machineryIndex)"
                                class="btn btn-primary--outlined btn--flat btn--no-background"
                            >
                                <i class="fas fa-trash"/>
                                <span>
                                    Remover
                                </span>
                            </button>
                        </div>
                    </td>
                </tr>
            </table>
            <div v-show="!hasSelectedMachineries()" class="no-content">
                <i class="fa fa-exclamation-circle fa-2x"/>
                <span class="">Não foram selecionados maquinários</span>
            </div>
        </div>
    </div>
</template>
<script>

import AppSelector from '@/components/commons/AppSelector.vue'
import {mapActions, mapGetters} from 'vuex'

export default {
    name: "MachinerySelector",
    props: {
        uuid: {
            type: String
        },
        value: {
            type: Array
        }
    },
    components: {
        AppSelector
    },
    data() {
        return {
            selectedMachineriesIds: [],
            selectedMachinery: null
        };
    },
    computed: {
        ...mapGetters('machineries', ['machineriesState']),
        filteredMachineries() {
            return this.machineriesState.filter(machinery => machinery.active)
        },
        formattedMachineriesToSelector() {
            return this.filteredMachineries.map(machinery => {
                return {
                    _id: machinery._id,
                    value: machinery._id,
                    label: `MODELO: ${machinery.model}; FABRICANTE: ${machinery.manufacturer}; FUNÇÕES: ${machinery.types.join(', ')};`
                }
            })
        },
        selectedMachineries() {
            return this.selectedMachineriesIds.map(machineryId => this.machineriesState.find(machinery => machinery._id == machineryId))
        }
    },
    watch: {
        value() {
            if (this.inputMachineryIsDifferentFromSelected()) {
                this.selectedMachineriesIds = this.formattedInputMachineries()
            }
        },
        selectedMachineriesIds() {
            this.emitFormattedSelectedMachineries()
        }
    },
    methods: {
        hasSelectedMachineries() {
            return this.selectedMachineriesIds && this.selectedMachineriesIds.length > 0;
        },
        inputMachineryIsDifferentFromSelected() {
            const formattedInputMachineries = this.formattedInputMachineries();

            const hasDifferentArraySize = this.selectedMachineriesIds.length != formattedInputMachineries.length

            return hasDifferentArraySize
        },
        formatInputMachinery(machinery) {
            if (!machinery.machinery) {
                return machinery
            }
            if (!machinery.machinery._id) {
                return machinery.machinery
            }
            return machinery.machinery._id
        },
        formattedInputMachineries() {
            return this.value.map(machinery => this.formatInputMachinery(machinery))
        },
        formatOutputMachinery(machinery) {
            return machinery
        },
        formattedOutputMachineries() {
            return this.selectedMachineriesIds.map(machineryId => this.formatOutputMachinery(machineryId))
        },
        emitFormattedSelectedMachineries() {
            this.$emit('input', this.formattedOutputMachineries())
        },
        addMachinery(machineryId) {
            this.selectedMachinery = null

            const isRepeatedMachinery = this.selectedMachineriesIds.includes(machineryId)
            if (isRepeatedMachinery) {
                return this.$vToastify.info('Esse maquinário já foi selecionado', "Atenção!")
            }

            this.selectedMachineriesIds.push(machineryId)
        },
        removeMachinery(machineryIndex) {
            this.selectedMachineriesIds.splice(machineryIndex, 1)
        }
    }
};
</script>
<style lang="sass" scoped>
.machinery-selector
    &__app-selector
        width: 100%

.machinery-table
    width: 100%

    &-header__actions
        width: 30%
        text-align: center

    &-content__actions
        display: flex
        grid-gap: 6px
        align-items: center
        justify-content: center

        .btn--no-background
            background: none

            &:hover
                background: $color-white-darken-1

            i
                color: $color-blue

    th
        color: $color-blue
        padding: 0 10px
        font-size: 16px

    tr
        border-radius: 20px
        height: 70px

        &:nth-child(even)
            background-color: #f2f2f2

    td
        padding: 0 10px

.no-content
    display: flex
    justify-content: center
    align-items: center
    height: 100px
    color: $color-grey
    grid-gap: 10px
</style>
