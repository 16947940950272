import { v4 as uuidv4 } from 'uuid';

const mixin = {
    methods: {
        generateUUID() {
            return uuidv4();    
        }
    }
}

export default mixin;
