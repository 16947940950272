<template>
    <div class="multiple-selector">
        <label class="app-label">
            {{ label }}
        </label>
        <div class="dropdown">
            <button
                id="dropdownMenuButton"
                class="dropdown-toggle app-select"
                type="button"
                data-toggle="dropdown"
                aria-haspopup="false"
                aria-expanded="false"
            >
                <span class="multiple-selector__text">
                    {{
                        selectedItems.length > 0
                        ? (
                            selectedItems.length === items.length && items.length > 0
                            ? 'Todos'
                            : showSelectedItems
                        )
                        : placeholder
                    }}
                </span>
            </button>
            <div class="dropdown-menu " aria-labelledby="dropdownMenuButton">
                <form>
                    <label v-if="items.length" :class="`dropdown-menu__item ${hasSelectedAll ? 'dropdown-menu__item--selected' : ''}`">
                        Todos
                        <input
                            class="dropdown-menu__checkbox"
                            type="checkbox"
                            v-model="hasSelectedAll"
                            :value="hasSelectedAll"
                        >
                        <span class="dropdown-menu__checkmark"/>
                    </label>
                    <label
                        class="dropdown-menu__item"
                        v-for="item in items"
                        :key="itemValue ? item[itemValue] : item"
                        :style="itemStyle(itemValue ? item[itemValue] : item)"
                    >
                        {{ itemText ? item[itemText] : item }}
                        <input
                            class="dropdown-menu__checkbox"
                            type="checkbox"
                            v-model="selectedItems"
                            :value="itemValue ? item[itemValue] : item"
                            @change="$emit('input', selectedItems)"
                        >
                        <span class="dropdown-menu__checkmark"/>
                    </label>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "MultipleSelector",
    props: {
        label: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        items: {
            type: Array,
            default: []
        },
        itemText: {
            type: String
        },
        itemValue: {
            type: String
        },
        value: {
            type: Array,
            default: []
        }
    },
    computed: {
        hasSelectedAll: {
            get() {
                return this.selectedItems.length === this.items.length && this.items.length > 0;
            },
            set(hasSelectedAll) {
                if (!hasSelectedAll) {
                    this.selectedItems = []
                } else {
                    this.selectedItems = this.items.map(item => this.itemValue ? item[this.itemValue] : item)
                }
                this.$emit('input', this.selectedItems)
            }
        },
        showSelectedItems() {
            if (this.itemText) {
                return this.items
                    .filter(item => this.selectedItems.includes(item[this.itemValue]))
                    .map(item => item[this.itemText])
                    .join(', ');
            } else {
                return this.selectedItems.join(', ')
            }
        }
    },
    data() {
        return {
            selectedItems: []
        }
    },
    methods: {
        itemStyle(item) {
            return this.selectedItems.includes(item) ?
                {background: '#AEFFFF', color: '#0D7F7B'} :
                {background: '#F2F2F2', color: '#000000'}
        }
    },
    watch: {
        value() {
            this.selectedItems = [...this.value]
        },
        items() {
            this.selectedItems = [
                ...this.items
                    .filter(item => this.value.includes(this.itemValue ? item[this.itemValue] : item))
                    .map(item => this.itemValue ? item[this.itemValue] : item)
            ]

        }
    },
    mounted() {
        this.selectedItems = [
            ...this.items
                .filter(item => this.value.includes(this.itemValue ? item[this.itemValue] : item))
                .map(item => this.itemValue ? item[this.itemValue] : item)
        ]
    }
};
</script>
<style scoped lang="sass">

.multiple-selector
    width: 100%

    &__text
        width: calc(100% - 10px)
        text-overflow: ellipsis
        white-space: nowrap
        overflow: hidden

.dropdown
    width: 100%

.dropdown-toggle
    width: 100%
    display: flex
    justify-content: space-between
    align-items: center
    text-align: left
    border: 1px solid #D6D6D6
    text-overflow: ellipsis
    white-space: nowrap
    overflow: hidden
    font-family: inherit
    font-size: 14px
    color: #000
    height: 40px

.dropdown-menu
    width: 100%
    background: #F2F2F2
    overflow-x: hidden
    overflow-y: auto
    max-height: 250px


.dropdown-menu__item
    display: flex
    background: #F2F2F2
    margin: 0
    position: relative
    padding: 8px 0
    color: black
    padding-left: 48px
    overflow: hidden
    text-overflow: ellipsis
    cursor: pointer
    font-size: 14px
    -webkit-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none


.dropdown-menu__item input
    position: absolute
    opacity: 0
    cursor: pointer
    height: 0
    width: 0


.dropdown-menu__checkmark
    position: absolute
    top: 10px
    left: 8px
    height: 20px
    width: 20px
    border: 2px solid #bbb
    background-color: #eee


.dropdown-menu__item:hover input ~ .dropdown-menu__checkmark
    background-color: #ccc


.dropdown-menu__item input:checked ~ .dropdown-menu__checkmark
    background-color: $color-blue
    border: 0


.dropdown-menu__checkmark:after
    content: ""
    position: absolute
    display: none


.dropdown-menu__item input:checked ~ .dropdown-menu__checkmark:after
    display: block


.dropdown-menu__item .dropdown-menu__checkmark:after
    margin: auto
    left: 7px
    top: 4px
    width: 6px
    height: 12px
    border: solid white
    border-width: 0 3px 3px 0
    -webkit-transform: rotate(45deg)
    -ms-transform: rotate(45deg)
    transform: rotate(45deg)

.dropdown-menu__item
    background-color: #F2F2F2
    color: #000000

.dropdown-menu__item--selected
    background-color: #AEFFFF
    color: $color-blue

</style>
