import API from "./API";

export default {
  getAllSamplesOfProperty(propertyId, cultureId) {
    return API.get(`/samples/property/${propertyId}` + (cultureId ? `?cultureId=${cultureId}` : ""));
  },

  getSamplesByInterval(propertyId, cultureId, interval) {
    return API.get(`/samples/property/${propertyId}/${interval.startDate}/${interval.endDate}` + (cultureId ? `?cultureId=${cultureId}` : ""));
  },

  getSamplesByPropertyByCropByPeriod(propertyId, cropId, startDate, endDate){
  	return API.get(`/samples/properties/${propertyId}/crops/${cropId}/${startDate}/${endDate}`)
  },

  getPragueById(pragueId){
  	return API.get(`/pragues/${pragueId}`)
  }
};
