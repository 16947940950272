<template>
    <div class="current-stock-container">
        <div class="current-stock-container__header">
            <span class="title">Estoque atual</span>
            <div class="actions">
                <div class="search-container app-input">
                    <input class="search-container__input" placeholder="Pesquise um produto"
                           v-model="searchWord" type="text"/>
                    <button class="search-container__clear clickable" v-show="searchWord.length > 0"
                            @click="clearSearch">
                        <i class="fal fa-times-circle"/>
                    </button>
                    <button class="search-container__search">
                        <i class="fas fa-search"/>
                    </button>
                </div>
                <div class="button-container">
                    <button class="btn btn-primary--outlined btn--flat mr-2" @click="generateStockReport">
                        <span
                            v-if="isLoadingPDF"
                            class="spinner-border spinner-border-sm"
                            role="status"
                        />
                        <span v-else>
                            <i class="fa fa-file-pdf mr-2"/>
                            <span>
                                Baixar PDF
                            </span>
                        </span>
                    </button>
                    <button class="btn btn-primary btn--flat" @click="openModalAddProductCurrentStock">
                        <span>Adicionar produto</span>
                    </button>
                </div>
            </div>
            <div>
                <div class="app-checkbox">
                    <input id="stockProductFilter" type="checkbox" v-model="deativatedProducts"/>
                    <label for="stockProductFilter">Ver produtos desativados</label>
                </div>
            </div>
        </div>
        <div v-if="!loading" class="current-stock-container__content">
            <table class="current-stock-table">
                <thead>
                    <tr>
                        <th>Nome</th>
                        <th>Quantidade</th>
                        <th>Unidade</th>
                        <th>Categoria</th>
                        <th>Sub Categoria</th>
                        <th class="current-stock-table-header__actions">Ações</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="stockProduct in stockProducts" :key="stockProduct._id">
                        <td> {{ stockProduct.product.name }} </td>
                        <td> {{ stockProduct.quantity ? `${formatNumber(stockProduct.quantity)} ${stockProduct.product.unit.name}` : '-' }} </td>
                        <td> {{ stockProduct.product.unit && stockProduct.product.unit.name ? stockProduct.product.unit.name : '-' }} </td>
                        <td> {{ formatProductCategory(stockProduct.product.productCategory) || '-' }} </td>
                        <td> {{ formatProductSubCategory(stockProduct.product.productCategory) || '-' }} </td>
                        <td>
                            <div class="current-stock-table-content__actions">
                                <button @click="removeStockProduct(stockProduct._id)"
                                        class="btn btn--flat btn--outline">
                                    <span v-show="removingStockProduct == stockProduct._id" class="spinner-border spinner-border-sm"/>
                                    <i v-show="removingStockProduct != stockProduct._id" class="fas fa-trash"/>
                                    <span v-show="removingStockProduct != stockProduct._id">Excluir</span>
                                </button>
                                <button
                                    type="checkbox"
                                    class="btn btn--flat"
                                    @click="activeDeactiveStockProduct(stockProduct)"
                                >
                                    <i :class="stockProduct.active ? 'fas fa-check-square' : 'far fa-square'"></i>
                                        <span>{{ stockProduct.active ? 'Desativar' : 'Reativar' }}</span>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div v-show="stockProducts.length == 0" class="no-content">
                <i class="fa fa-exclamation-circle fa-2x"/>
                <span class="">Não foram encontrados produtos</span>
            </div>
        </div>
        <div v-else class="no-content">
            <span class="spinner-border" />
        </div>
        <modal-add-product-current-stock v-if="showModalAddProductCurrentStock" :open="showModalAddProductCurrentStock" @close="closeModalAddProductCurrentStock" @save="loadStockProducts">
        </modal-add-product-current-stock>
    </div>
</template>

<script>

import { mapActions, mapGetters } from "vuex";
import ModalAddProductCurrentStock from "@/views/stock/current-stock/components/ModalAddProductCurrentStock.vue"

export default {
    name: 'CurrentStock',
    components: {
        ModalAddProductCurrentStock
    },
    data() {
        return {
            isLoadingPDF: false,
            stockProducts: [],
            selectedStockProduct: {},
            loading: false,
            removingStockProduct: null,
            showModalAddProductCurrentStock: false,
            searchWord: '',
            deativatedProducts: false,
            payload: {
                propertyId: ''
            }
        }
    },
    computed: {
        ...mapGetters("property", ["getPropertySelected", "getCropSelected"]),
        ...mapGetters("stock-products", ["getStockProducts"])
    },
    watch: {
        async getPropertySelected() {
            await this.loadStockProducts()
        },
        searchWord() {
            this.filterStockProducts()
        },
        async deativatedProducts(){
            await this.loadStockProducts()
        }
    },
    methods: {
        ...mapActions("stock-products", ["findStockProducts", "deleteStockProduct", "updateStockProduct"]),
        ...mapActions("reports", ["findStockReport"]),
        openModalAddProductCurrentStock() {
            this.showModalAddProductCurrentStock = true
        },
        closeModalAddProductCurrentStock() {
            this.showModalAddProductCurrentStock = false
        },
        updatePayload() {
            this.payload = {
                propertyId: ''
            }
        },
        async generateStockReport() {
            const payload = {
                propertyId: (this.getPropertySelected && this.getPropertySelected._id) || null,
                cropId: (this.getCropSelected && this.getCropSelected._id) || null
            }

            if (!payload.propertyId || !payload.cropId) {
                return;
            }

            this.isLoadingPDF = true;

            const response = await this.findStockReport(payload);
            if (response.status === 200 && response.data.pdf) {
                this.$vToastify.success('PDF gerado com sucesso!')
                this.goToPage(response.data.pdf)
            }

            this.isLoadingPDF = false
        },
        goToPage(url) {
            window.open(url, '_blank')
        },
        filterStockProducts(){
            this.stockProducts = this.getStockProducts.filter((stockProduct) => {
                const stockProductQuantityString =  stockProduct.quantity ? `${this.formatNumber(stockProduct.quantity)} ${stockProduct.product.unit.name}` : "-"
                return (
                    stockProduct.product.name.toUpperCase().includes(this.searchWord.toUpperCase()) ||
                    stockProduct.product.unit.name.toUpperCase().includes(this.searchWord.toUpperCase()) ||
                    stockProduct.product.productCategory && stockProduct.product.productCategory.name.toUpperCase().includes(this.searchWord.toUpperCase()) ||
                    stockProduct.product.productCategory && stockProduct.product.productCategory.parentProductCategory && stockProduct.product.productCategory.parentProductCategory.name.toUpperCase().includes(this.searchWord.toUpperCase()) ||
                    stockProductQuantityString.toUpperCase().includes(this.searchWord.toUpperCase())
                )
            })
        },
        async removeStockProduct(stockProductId) {
            const wantsToDelete = await this.$vToastify.prompt({
                body: "Deseja excluir esse produto do estoque atual?",
                title: "ATENÇÃO!",
                answers: { Sim: true, Não: false },
            })

            if (wantsToDelete) {
                this.removingStockProduct = stockProductId

                const response = await this.deleteStockProduct(stockProductId)
                const responseContainsError = response instanceof Error
                if (!responseContainsError) {
                    this.$vToastify.success(response.data.message, "Estoque atual");
                    await this.loadStockProducts()
                }

                this.removingStockProduct = null
            }
        },
        async activeDeactiveStockProduct(product){
            const { _id, active } = {
                _id: product._id,
                active: !product.active,
            }
            const confirmActiveDeactive = await this.$vToastify.prompt({
                body: "Deseja confirmar está ação?",
                title: "ATENÇÃO!",
                answers: { Sim: true, Não: false },
            })
            if (confirmActiveDeactive){
                await this.updateStockProduct({ _id, active })
                await this.loadStockProducts()
                const sucessText = product.active == true ? 'Desativado' : 'Reativado'
                this.$vToastify.success(`${sucessText} com sucesso`, "Produto")
            } else return
        },
        async loadStockProducts() {
            this.loading = true

            this.stockProducts = []
            await this.findStockProducts({ propertyId: this.getPropertySelected._id, active: this.deativatedProducts == false ? true : false })
            this.stockProducts = this.getStockProducts

            this.loading = false
        },
        async clearSearch() {
            this.searchWord = ''
            this.stockProducts = [... this.getStockProducts.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1)]
        }
    },
    async mounted() {
        await this.loadStockProducts()
    }
}
</script>

<style lang="sass" scoped>
.current-stock-container
    display: flex
    flex-direction: column
    grid-gap: 6px
    width: 100%

    &__header
        .title
            font-size: 24px
            color: $theme-color-primary

        .actions
            display: flex
            justify-content: space-between

            .search-container
                display: flex
                justify-content: space-between
                width: 300px

                &__input
                    width: 250px
                    border: 0

                &__search
                    border: 0
                    width: 25px
                    background: none

                &__clear
                    border: 0
                    width: 25px
                    background: none

        .button-container
            display: flex
            flex-wrap: wrap

            button
                width: 200px

.current-stock-table
    width: 100%

    &-header__actions
        width: 30%
        text-align: center

    &-content__actions
        display: flex
        grid-gap: 6px
        align-items: center
        justify-content: center

        .btn
            background: none

            &:hover
                background: $color-white-darken-1

            i
                color: $color-blue

    th
        color: $color-blue
        padding: 0 10px
        font-size: 16px
        width: 15.15vw

    tr
        border-radius: 20px
        height: 70px

        &:nth-child(even)
            background-color: #f2f2f2

    td
        padding: 0 10px
        width: 16.6666%

    thead
        position: sticky
        top: -10px
        background: $color-white
        z-index: 1

.no-content
    display: flex
    justify-content: center
    align-items: center
    height: 100px
    color: $color-grey
    grid-gap: 10px

@media (max-width: 430px)
    .current-stock-container__header .actions .search-container__input
        width: 200px

</style>
