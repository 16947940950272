<template>
  <div class="notifications">
    <div class="container-fluid">
      <div class="header_title">
        <div class="row d-flex align-items-center">
          <div class="col-md-6">
            <h2 class="title m-0">Lista Geral de Notificações</h2>
          </div>
          <div class="col-md-6 text-right">&nbsp;</div>
        </div>
      </div>
      <hr class="m-0" />
      <div
        class="table-responsive table-timeline"
        v-if="allNotifications.length > 0"
      >
        <table class="table">
          <tr>
            <th>Data</th>
            <th>Notificação</th>
            <th class="text-center">Ações</th>
          </tr>

          <NotificationItem
            :notification="notification"
            v-for="notification in allNotifications"
            :key="notification._id"
            :propertySelectedId="getPropertySelected._id"
          />
        </table>
      </div>
      <div v-else class="container-fluid">
        <div class="row d-flex align-items-center mt-2" v-if="loading == true">
          <div class="col-md-12">
            <div class="row d-flex align-self-center align-items-center">
              <div class="col text-center">
                <h5 class="title text-center m-0">Buscando notificações</h5>
                <div class="spinner-border text-secondary" role="status"></div>
              </div>
            </div>
          </div>
        </div>
          <div class="flex-container" v-if="!loading">
              <i class="fa fa-exclamation-circle fa-2x text-secondary"/>
              <span>Sem notificações para essa propriedade</span>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import NotificationItem from "@/components/notifications/notificationItem";
import { mapGetters, mapActions } from "vuex";
import { ordeyByDecrescentCreatedAt } from "../../utils/orderByDate";
export default {
  components: {
    NotificationItem,
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapGetters("property", ["getPropertySelected", "getNotifications"]),
    allNotifications() {
      return this.orderByDateDesc(this.getNotifications);
    },
  },
  watch: {},
  mounted() {
    this.listPropertyNotifications(this.getPropertySelected._id);
    setTimeout(() => {
      this.loading = false;
    }, 1500);
  },
  methods: {
    ...mapActions("property", ["listPropertyNotifications"]),

    orderByDateDesc(list) {
      const notificationsOrdered = ordeyByDecrescentCreatedAt(list);

      return notificationsOrdered;
    },
  },
};
</script>

<style scoped lang="sass">
.notifications
    .container-fluid
        .flex-container
            height: 100px
</style>