<template>
  <div class="form-group custom-selector" :class="classParameter">
    <label class="app-label" for="selector">{{ title }} </label>
    <div class="input-group">

      <select
          class="app-select"
          v-model="selectedChoice"
          id="selector"
      >
        <option disabled>{{ hint }}</option>
        <option
          v-for="choice in choices"
          :key="choice"
          :value="choice"
        >
          {{ choice }}
        </option>
      </select>
    </div>
  </div>
</template>
<script>
export default {
  name: "CustomSelector",
  props: {
    title: {
      type: String,
    },
    classParameter: {
      type: String,
    },
    hint: {
      type: String,
    },
    choices: {
      type: Array
    }
  },
  data() {
    return {
      selectedChoice: this.choices[0]
    };
  },
  watch: {
    selectedChoice() {
      this.$emit("choice", this.selectedChoice);
    },
    choices() {
      this.selectedChoice = this.choices[0]
    }
  }
};
</script>
<style scoped>

</style>