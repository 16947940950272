import API from './API';

export default {

    findNewProducts(propertyId, commons, name, productCategoryId, limit, active) {
        return API.get("/new-products", {
            params: {
                ... propertyId ? { propertyId } : {},
                ... commons ? { commons } : {},
                ... name ? { name } : {},
                ... productCategoryId ? { productCategoryId } : {},
                ... limit ? { limit } : {},
                ... active !== undefined ? { active } : {}
            }
        })
    },

    createNewProduct(newProduct) {
        return API.post("/new-products", newProduct)
    },

    updateNewProduct(newProductId, newProduct) {
        return API.put(`/new-products/${newProductId}`, newProduct)
    },

    deleteNewProduct(newProductId) {
        return API.delete(`/new-products/${newProductId}`)
    }

}
