<template>
    <div>
        <label class="app-label" v-show="label" :for="`${id}-input`">{{ label }}</label>
        <div class="selectable-container">
            <select class="app-select" :id="`${id}-select`" v-model="select" @blur="blurEvent">
                <option v-for="item in items" :key="item" :value="item">{{ item }}</option>
            </select>
            <input class="app-input" type="text" :id="`${id}-input`" :placeholder="placeholder" v-model="input"
                   @blur="blurEvent">
        </div>
    </div>
</template>

<script>
export default {
    name: "InputNumberSelectable",
    props: {
        id: {type: String, required: true},
        label: {type: String, default: ''},
        placeholder: {type: String, required: true},
        items: {type: Array, required: true},
        itemSelected: { default: '' },
        value: { default: '' }
    },
    data() {
        return {
            select: this.itemSelected ? this.itemSelected : this.items[0],
            input: this.value ? this.formatNumber(this.value) : ''
        }
    },
    watch: {
        itemSelected() {
            this.select = this.itemSelected ? this.itemSelected : this.items[0]
        },
        value() {
            this.input = this.value ? this.formatNumber(this.value) : ''
        },
        input() {
            this.$nextTick(() => {
                this.input = this.formatInputNumber(this.input)
            })
        }
    },
    methods: {
        blurEvent() {
            this.$emit('input', {
                select: this.select,
                input: this.unformatNumber(this.input)
            })
        }
    }
}
</script>

<style scoped lang="sass">
.selectable-container
    display: flex

    .app-select
        border-top-right-radius: unset
        border-bottom-right-radius: unset
        background-color: $color-blue-lighten-1
        flex-basis: 40%

    .app-input
        border-top-left-radius: unset
        border-bottom-left-radius: unset
        border-left: unset
</style>