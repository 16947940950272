<template>
    <div class="pragues-list__container list-group">
        <a class="list-group-header" @click="openList()">
            <div class="list-group-title">
                <span class="list-group-title__icon">
                    <i :class="listTitleIcon"/>
                </span>
                <span class="list-group-title__text">
                    {{ title }}
                </span>
            </div>
            <div class="list-group-spacer"/>
            <div class="list-group-description">
                <div class="list-group-description__title">
                    {{ displayTextListGroupTitle }}
                </div>
                <div class="list-group-description__subtitle">
                    {{ displayTextListGroupSubtitle }}
                </div>
            </div>
            <div class="list-group-chevron">
                <span :class="chevronIcon"/>
            </div>
        </a>
        <div v-if="showList" class="pragues-list__content">
            <div
                v-for="element in filteredList"
                :key="element._id"
                :value="element"
                class="list-group-item"
            >
                <div class="list-group-item__icon">
                    <i :class="getIcon(getSampleType(element))"></i>
                </div>
                <div class="list-group-item__text">
                    {{ getSampleName(element) }}
                </div>
                <div class="list-group-item__info">
                    {{ getListGroupItemInfo(element) }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ListPrague",
    props: {
        fields: {
            type: Array
        },
        samples: {
            type: Array
        },
        title: {
            type: String
        },
        selectedField: {
            type: Object
        }
    },
    data() {
        return {
            filteredList: [],
            showList: false
        }
    },
    watch: {
        samples() {
            this.filterAll()
        }
    },
    computed: {

        listTitleIcon() {
            const icons = {
                "Pragas": "bug",
                "Doenças": "bacterium",
                "Plantas daninhas": "seedling"
            }


            return `fas fa-${icons[this.title] || 'cog'}`
        },
        displayTextListGroupTitle() {
            const quantity = this.selectedAllFields()
                ? this.fieldsTotal().length
                : this.filteredList.length

            const textTitle = this.selectedAllFields()
                ? this.fieldsTotal().length == 1
                    ? "talhão"
                    : "talhões"
                : this.title != "Ver todos"
                    ? quantity == 1
                        ? this.formatSingular(this.title.toLowerCase())
                        : this.title.toLowerCase()
                    : "total"

            return `${quantity} ${textTitle}`
        },
        displayTextListGroupSubtitle() {
            const quantity = this.selectedAllFields()
                ? this.filteredList.length
                : this.samplesCount()

            const textTitle = this.selectedAllFields()
                ? this.title != "Ver todos"
                    ? quantity == 1
                        ? this.formatSingular(this.title.toLowerCase())
                        : this.title.toLowerCase()
                    : "total"
                : quantity == 1
                    ? "ponto"
                    : "pontos"

            return `${quantity} ${textTitle}`
        },
        chevronIcon() {
            return `fas fa-chevron-${this.showList ? 'down' : 'right'}`
        }
    },
    methods: {
        formatSingular(pluralSampleType) {
            const singularPlural = {
                "doenças": "doença",
                "plantas daninhas": "planta daninha",
                "pragas": "praga"
            }

            return singularPlural[pluralSampleType.toLowerCase()]
        },
        getSampleType(sample) {
            if (sample.prague) {
                return "prague"
            } else if (sample.disease) {
                return "disease"
            } else if (sample.plant) {
                return "plant"
            }
        },
        getIcon(sampleType) {
            const icons = {
                "disease": "bacterium",
                "prague": "bug",
                "plant": "seedling"
            }

            return `fas fa-${icons[sampleType]}`
        },
        getListGroupItemInfo(element) {
            let quantity = ""
            let text = ""

            if (this.selectedAllFields()) {
                if (element.disease) {
                    quantity = this.fieldsThatDiseaseIsPresent(element.disease._id).length
                } else if (element.prague) {
                    quantity = this.fieldsThatPragueIsPresent(element.prague._id).length
                } else if (element.plant) {
                    quantity = this.fieldsThatPlantIsPresent(element.plant._id).length
                }

                text = quantity == 1 ? "talhão" : "talhões"
            } else {
                if (element.disease) {
                    quantity = this.samplesThatDiseaseIsPresent(element.disease._id).length
                } else if (element.prague) {
                    quantity = this.samplesThatPragueIsPresent(element.prague._id).length
                } else if (element.plant) {
                    quantity = this.samplesThatPlantIsPresent(element.plant._id).length
                }

                text = quantity == 1 ? "ponto" : "pontos"
            }

            return `${quantity} ${text}`
        },
        getSampleName(sample) {
            const sampleType = this.getSampleType(sample)

            return sample[sampleType].name
        },
        selectedAllFields() {
            return this.selectedField.fieldObject == "all" || this.selectedField.fieldObject._id == undefined
        },
        getRandomColor() {
            var letters = '0123456789';
            var color = '#';
            for (var i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 10)];
            }
            return color;
        },
        openList() {
            this.showList = !this.showList
        },
        filterDiseases() {
            var filteredSamples = this.filterSamplesByField()
            var filteredDiseases = []
            filteredSamples.forEach((sample) => {
                sample.sampleDiseases.forEach((sampleDisease) => {
                    let hasBeenInserted = filteredDiseases.some((filteredDisease) => {
                        return filteredDisease.disease._id == sampleDisease.disease._id
                    })
                    if (!hasBeenInserted) {
                        filteredDiseases.push({disease: sampleDisease.disease})
                    }
                })
            })
            return filteredDiseases
        },
        filterPlants() {
            var filteredSamples = this.filterSamplesByField()
            var filteredPlants = []
            filteredSamples.forEach((sample) => {
                sample.samplePlants.forEach((samplePlant) => {
                    let hasBeenInserted = filteredPlants.some((filteredPlant) => {
                        return filteredPlant.plant._id == samplePlant.plant._id
                    })
                    if (!hasBeenInserted) {
                        filteredPlants.push({plant: samplePlant.plant})
                    }
                })
            })
            return filteredPlants

        },
        filterPragues() {
            var filteredSamples = this.filterSamplesByField()
            var filteredPragues = []
            filteredSamples.forEach((sample) => {
                sample.samplePragues.forEach((samplePrague) => {
                    let hasBeenInserted = filteredPragues.some((filteredPrague) => {
                        return filteredPrague.prague._id == samplePrague.prague._id
                    })
                    if (!hasBeenInserted) {
                        filteredPragues.push({prague: samplePrague.prague})
                    }
                })
            })
            return filteredPragues
        },
        filterSamplesByField() {
            if (this.selectedAllFields()) {
                return this.samples
            } else {
                return this.samples.filter(this.sampleIsPresentInTheSelectedField)
            }
        },
        sampleIsPresentInTheSelectedField(sample) {
            return this.selectedField.fieldObject._id == sample.subarea.field._id
        },
        filterAll() {
            this.filteredList = []
            if (this.title === "Ver todos") {
                this.filteredList = this.filteredList.concat(this.filterDiseases())
                this.filteredList = this.filteredList.concat(this.filterPragues())
                this.filteredList = this.filteredList.concat(this.filterPlants())
            } else if (this.title === "Doenças") {
                this.filteredList = this.filterDiseases()
            } else if (this.title === "Plantas daninhas") {
                this.filteredList = this.filterPlants()
            } else {
                this.filteredList = this.filterPragues()
            }
        },
        fieldsThatDiseaseIsPresent(diseaseId) {
            let fields = []
            this.samples.forEach((sample) => {
                let fieldHasDisease = sample.sampleDiseases.some((sampleDisease) => {
                    return sampleDisease.disease._id == diseaseId
                })
                if (fieldHasDisease) {
                    let fieldOnList = fields.some((field) => {
                        return field._id == sample.subarea.field._id
                    })
                    if (!fieldOnList) {
                        fields.push({
                            _id: sample.subarea.field._id,
                            name: sample.subarea.field.name
                        })
                    }
                }
            })

            return fields
        },
        fieldsThatPragueIsPresent(pragueId) {
            let fields = []
            this.samples.forEach((sample) => {
                let fieldHasPrague = sample.samplePragues.some((samplePrague) => {
                    return samplePrague.prague._id == pragueId
                })
                if (fieldHasPrague) {
                    let fieldOnList = fields.some((field) => {
                        return field._id == sample.subarea.field._id
                    })
                    if (!fieldOnList) {
                        fields.push({
                            _id: sample.subarea.field._id,
                            name: sample.subarea.field.name
                        })
                    }
                }
            })

            return fields
        },
        fieldsThatPlantIsPresent(plantId) {
            let fields = []
            this.samples.forEach((sample) => {
                let fieldHasPlant = sample.samplePlants.some((samplePlant) => {
                    return samplePlant.plant._id == plantId
                })
                if (fieldHasPlant) {
                    let fieldOnList = fields.some((field) => {
                        return field._id == sample.subarea.field._id
                    })
                    if (!fieldOnList) {
                        fields.push({
                            _id: sample.subarea.field._id,
                            name: sample.subarea.field.name
                        })
                    }
                }
            })

            return fields
        },
        fieldsTotal(){
            let fields = []
            this.samples.forEach((sample) => {
                let fieldOnList = fields.some((field) => {
                    return field._id == sample.subarea.field._id
                })
                if (!fieldOnList) {
                    fields.push({
                        _id: sample.subarea.field._id,
                        name: sample.subarea.field.name
                    })
                }
            })
            return fields
        },
        samplesThatDiseaseIsPresent(diseaseId) {
            let _samples = []
            var filteredSamples = this.filterSamplesByField()
            filteredSamples.forEach((sample) => {
                let sampleHasDisease = sample.sampleDiseases.some((sampleDisease) => {
                    return sampleDisease.disease._id == diseaseId
                })
                if (sampleHasDisease) {
                    let sampleOnList = _samples.some((_sample) => {
                        return _sample._id == sample._id
                    })
                    if(!sampleOnList){
                        _samples.push(sample)
                    }

                }
            })

            return _samples
        },
        samplesThatPlantIsPresent(plantId) {
            let _samples = []
            var filteredSamples = this.filterSamplesByField()
            filteredSamples.forEach((sample) => {
                let sampleHasPlant = sample.samplePlants.some((samplePlant) => {
                    return samplePlant.plant._id == plantId
                })
                if (sampleHasPlant) {
                    let sampleOnList = _samples.some((_sample) => {
                        return _sample._id == sample._id
                    })
                    if(!sampleOnList){
                        _samples.push(sample)
                    }

                }
            })

            return _samples
        },
        samplesThatPragueIsPresent(pragueId) {
            let _samples = []
            var filteredSamples = this.filterSamplesByField()
            filteredSamples.forEach((sample) => {
                let sampleHasPrague = sample.samplePragues.some((samplePrague) => {
                    return samplePrague.prague._id == pragueId
                })
                if (sampleHasPrague) {
                    let sampleOnList = _samples.some((_sample) => {
                        return _sample._id == sample._id
                    })
                    if(!sampleOnList){
                        _samples.push(sample)
                    }

                }
            })

            return _samples
        },
        samplesCount(){
            let count = 0
            var filteredSamples = this.filterSamplesByField()
            filteredSamples.forEach((sample) => {
                count += sample.sampleDiseases.length + sample.samplePlants.length + sample.samplePragues.length
            })
            return count

        }
    }
}
</script>

<style scoped lang="sass">
.pragues-list
    &__container
        width: 100%
        padding: 0 8px

    &__content
        max-height: calc(100vh - 547px - 64px)
        overflow: auto

.list-group-header
    display: flex
    width: 100%
    height: 60px
    border-bottom: 1px solid #CCCCCC
    color: #444444
    padding: 8px

    &:hover
        cursor: pointer

.list-group-title
    display: flex
    align-items: center
    width: 180px
    color: #444444

    &__icon
        display: flex
        width: 20px
        font-weight: bold

    &__text
        display: flex
        width: calc(100% - 20px)
        font-weight: bold
        padding: 0 8px

.list-group-spacer
    width: calc(100% - 380px)

.list-group-description
    display: flex
    flex-direction: column
    width: 180px
    padding: 0 8px
    align-items: center
    justify-content: center
    color: #444444

    &__title
        font-weight: bold

    &__subtitle
        font-size: 12px

.list-group-chevron
    display: flex
    align-items: center
    width: 20px
    color: #444444

.list-group-item
    display: flex
    width: 100%
    align-items: center
    padding: 16px 8px
    border: 0

    &__icon
        color: #EE3333
        font-weight: bold
        width: 20px

    &__text
        color: #EE3333
        font-weight: bold
        width: calc(100% - 140px)
        padding: 0 8px

    &__info
        width: 120px
        padding: 0 8px

@media (max-width: 992px)
    .pragues-list__content
        max-height: 400px

@media (max-height: 990px)
    .pragues-list__content
        max-height: 400px

.icon-pragas
    background: url("./../../../public/img/icons/svg/pragas.svg") no-repeat 50% 50%
    background-size: 28px
    height: 38px
    width: 38px

.borderless
    border-left: 0
    border-right: 0
</style>
