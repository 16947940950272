<template>
    <div class="weather-forecast">
        <div class="weather-forecast__actions actions">
            <div class="actions__title">
                <span> Baixar relatório </span>
            </div>
            <div class="actions__button">
                <button
                    @click="generateReports()"
                    class="btn btn-primary--outlined btn--flat"
                >
                    <span>
                        <i class="fa fa-download mr-2" />
                        <span>Gerar Relatório</span>
                    </span>
                    <span
                        v-if="loadingPDF"
                        class="spinner-border spinner-border-sm"
                        role="status"
                    />
                </button>
            </div>
        </div>
        <div v-if="loading" class="weather-forecast__spinner">
            <span class="spinner-border text-success" role="status" />
        </div>
        <div class="weather-forecast__table" v-else>
            <table class="weather-table">
                <tr class="weather-table__row">
                    <th class="weather-table__title"></th>

                    <td
                        v-for="(dates, i) in arrWeather"
                        :key="i"
                        class="weather-table__title"
                    >
                        {{ formatDate(dates.date) }}
                    </td>
                </tr>
                <tr class="weather-table__row">
                    <th class="weather-table__title">Pluviometria (mm)</th>
                    <td
                        v-for="(rainfalls, i) in arrWeather"
                        :key="i"
                        class="weather-table__rainfall-column"
                    >
                        {{ formatNumber(rainfalls.rainfall) || "0,00" }}
                    </td>
                </tr>
                <tr class="weather-table__row">
                    <th class="weather-table__title">Temperatura (°C)</th>
                    <td
                        v-for="(avgTemp, i) in arrWeather"
                        :key="i"
                        class="weather-table__temperature-column"
                    >
                        {{ formatNumber(avgTemp.temperature.avg) || "0,00" }}
                    </td>
                </tr>
            </table>
            <div class="weather-table__source">
                <span>Fonte: WithLeaf</span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    name: "Weather",
    data() {
        return {
            loadingPDF: false,
            loading: false,
            arrWeather: [],
        };
    },
    computed: {
        ...mapGetters("property", ["getPropertySelected"]),
        ...mapGetters("weather", ["getWeather"]),
    },
    mounted() {
        this.weatherForecastTable();
    },
    methods: {
        ...mapActions("weather", ["getWatherReports", "findWeatherForecast"]),
        async generateReports() {
            this.loadingPDF = true;
            const {
                data: { pdf },
            } = await this.getWatherReports(this.getPropertySelected._id);
            window.open(pdf, "_blank");
            this.loadingPDF = false;
        },
        async weatherForecastTable() {
            this.loading = true;
            const { propertyId, startDate, endDate } = {
                propertyId: this.getPropertySelected._id,
                startDate: this.getTodayDate(),
                endDate: this.getNextDate(this.getTodayDate(), 6),
            };
            this.forecast = await this.findWeatherForecast({
                propertyId,
                startDate,
                endDate,
            });
            this.loading = false;
            this.arrWeather = this.getWeather[0].weather;
        },
    },
    watch: {
        getPropertySelected() {
            this.weatherForecastTable();
        },
    },
};
</script>

<style lang="sass" scoped>
.weather-table
    border-collapse: separate

    &__row
        text-align: center
        background: $color-blue-darken-1

    &__title
        padding: 16px
        background: $color-blue-darken-1
        color: $color-blue-lighten-1
        font-weight: bold

    &__rainfall-column
        background: #CDCDCD

    &__temperature-column
        background: #E6E6E6

    &__source
        font-size: 12px
        text-align: right
        padding: 8px 0

.weather-forecast
    height: 100%
    overflow-x: hidden
    margin: auto
    max-width: 1000px

    &__table
        width: 100%
        overflow-x: auto

    &__actions
        margin: 8px 0
        padding: 8px 0

    &__spinner
        text-align: center

.actions
    &__title
        text-align: center
        margin-bottom: 8px

    &__button
        display: flex
        justify-content: center
        align-items: center
        border: 1px solid #D6D6D6
        border-radius: 12px
        padding: 16px
</style>
