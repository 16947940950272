import Unit from '../../api/Unit';

const state = {
    units: []
}

const getters = {
    getUnits: state => state.units
}

const actions = {
    async findUnits({ commit }) {
        const response = await Unit.findUnits()
        const units = response.data.data
        commit("SET_UNITS", units)
    }
}

const mutations = {
    SET_UNITS: (state, units) => state.units = units,
}

export default { state, getters, actions, mutations, namespaced: true }
