<template>
    <Modal :open="open" :loading="false" @close="$emit('close')">
        <div class="app-modal-header" slot="header">
            <i class="fas fa-user"></i>
            <span>{{ !data._id ? 'Novo operador' : 'Editar operador' }}</span>
        </div>
        <div class="app-modal-body" slot="body">
            <div class="content">
                <div>
                    <label class="app-label">Nome</label>
                    <input class="app-input" placeholder="Nome do operador" v-model="operator.name"/>
                </div>
                <p class="app-label property-selector-label"> Propriedades onde está disponível </p>
                <div class="property-selector">
                    <div class="app-checkbox property-selector__item">
                        <input id="all" type="checkbox" v-model="checkedAllProperties"/>
                        <label for="all"> Todos </label>
                    </div>
                    <div class="app-checkbox property-selector__item" v-for="property in userProperties"
                         :key="property.property._id">
                        <input :id="property.property._id" type="checkbox" :value="property" v-model="operator.properties"/>
                        <label :for="property.property._id"> {{ property.property.name }} </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="app-modal-footer" slot="footer">
            <button class="btn btn-primary btn--flat" @click="saveOperator" :disabled="loading">
                <span v-if="loading" class="spinner-border" role="status"/>
                <span v-else>
                    <span>Salvar</span>
                    <i class="ml-2 fas fa-save"/>
                </span>
            </button>
        </div>
    </Modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Modal from "@/components/systemUI/Modal.vue"

export default {
    name: 'ModalOperator',
    props: {
        data: Object,
        open: Boolean,
    },
    components: {
        Modal
    },
    data() {
        return {
            loading: false,
            checkedAllProperties: false,
            userProperties: [],
            operator: {
                name: '',
                properties: []
            },
        };
    },
    computed: {
        ...mapGetters("users", ["profile"]),
        ...mapGetters("operators", ["error"]),
        filteredProperties(){
            return this.profile.properties.filter(property => property.property.active)
        },
    },
    mounted() {
        this.copyData()
    },
    watch: {
        data(){
            this.copyData()
        },
        profile(){
            this.copyData()
        },
        open(){
            this.resetOperator()
            if(this.open){
                this.copyData()
            }
        },
        checkedAllProperties(){
            if(this.checkedAllProperties){
                this.operator.properties = [...this.userProperties]
            }else if(this.operator.properties.length == this.userProperties.length){
                this.operator.properties = []
            }
        },
        'operator.properties'(){
            this.checkedAllProperties = this.operator.properties.length == this.userProperties.length
        }
    },
    methods: {
        ...mapActions("operators", ["updateOperator", "insertOperator"]),
        copyData(){
            this.operator = this.data._id ? { ...this.data } : this.operator
            this.operator.properties = this.operator.properties.map((property) => {
                return { property: property.property }
            })
            this.userProperties = this.filteredProperties.map((property) => {
                return { property: property.property }
            })
        },
        resetOperator(){
            this.operator = {
                name: '',
                properties: []
            }
        },
        async saveOperator(){
            this.loading = true
            if (this.data._id) {
                this.operator._id = this.data._id
                await this.updateOperator(this.operator)
                this.loading = false
                if (this.error) {
                    return this.$vToastify.error(this.error.data.message || this.error.data.errors[0].msg, "Operador");
                }
            } else {
                await this.insertOperator(this.operator)
                this.loading = false
                if (this.error) {
                    return this.$vToastify.error(this.error.data.message || this.error.data.errors[0].msg, "Operador");
                }
            }
            this.$vToastify.success("Salvo com sucesso!", "Operador");
            this.resetOperator()
            this.$emit('close')
            this.$emit('save')
        }
    }
};
</script>

<style scoped lang="sass">
.content
    display: flex
    flex-direction: column
    grid-gap: 8px

.property-selector-label
    width: 100%
    padding: 4px

.property-selector
    display: flex
    width: 100%
    flex-wrap: wrap

    &__item
        width: 33.333%
        display: flex
        align-items: center
        padding: 4px

@media (max-width: 500px)
    .content
        &__item
            width: 100%

    .property-selector
        &__item
            width: 50%

</style>