const directive = {
    bind(el, binding, vnode) {
        el.clickLeftOrRightArrows = (event) => {
            if ((binding.arg === 'left' && event.keyCode === 37) || (binding.arg === 'right' && event.keyCode === 39)) {
                vnode.context[binding.expression]()
            }
        }

        el.addEventListener('keydown', el.clickLeftOrRightArrows)
    },
    unbind(el) {
        el.removeEventListener('keydown', el.clickLeftOrRightArrows)
    }
}

export default directive