<template>
    <div class="planning-container">
        <div class="planning-container__filter sidebar-calendar" :class="{'hide': !showFilter}">
            <button class="btn btn--flat btn-close" @click="showFilter = !showFilter">
                <i class="material-icons">close</i>
            </button>
            <div>
                <h2>Talhões</h2>
                <form class="app-form" action="">
                    <div v-if="hasFields()">
                        <div class="app-form__item app-checkbox" v-for="field in allFields" :key="field._id">
                            <input :id="`field_${field._id}`" v-model="checkedFields" :value="field._id" type="checkbox" checked="true"/>
                            <label :for="`field_${field._id}`" :title="field.name"> {{ field.name }} </label>
                        </div>
                    </div>
                    <p v-else>Talhão não encontrado</p>
                </form>
            </div>
            <div>
                <h2>Atividades</h2>
                <form class="app-form" action="">
                    <div class="app-form__item app-checkbox" v-for="activityType in allActivitiesTypes" :key="activityType._id">
                        <input :id="`activityType_${activityType._id}`" v-model="checkedActivitiesTypes" :value="activityType.value" type="checkbox" checked="true"/>
                        <label :for="`activityType_${activityType._id}`" :title="activityType.name"> {{ activityType.name }} </label>
                        <img :src="`/img/icons/svg/${activityType.icon}.svg`" width="20" height="20" alt=""/>
                    </div>
                </form>
            </div>
            <div>
                <h2>Situação</h2>
                <form class="app-form" action="">
                    <div class="app-form__item app-checkbox" v-for="status in allStatus" :key="status._id">
                        <input :id="`sit_${status._id}`" v-model="checkedStatus" :value="status.value" type="checkbox" checked="true"/>
                        <label :for="`sit_${status._id}`" :title="status.name">
                            <span class="circle" :class="`circle-${status.color}`"/>
                            <span>{{ status.name }}</span>
                        </label>
                    </div>
                </form>
            </div>
        </div>
        <div class="planning-container__filter-activator">
            <button class="btn btn--outlined" @click="showFilter = !showFilter">
                <i class="material-icons">filter_list</i>
                <span>Filtrar</span>
            </button>
        </div>
        <div class="planning-container__calendar">
            <planning-calendar
                btnLeft="Nova OS"
                btnRight="Nova Atividade"
                @btnLeftClick="openModalServiceOrder"
                @btnRightClick="openModalActivity"
                @eventClick="handleClickOnActivity"
                @monthChange="loadCalendarOnMonthChange"
                :events="events"
            />
        </div>
        <modal-activity-select :open="modalNewActivity" @done="loadCalendar(activitiesPayload)" @close="closeModalActivity"/>
        <modal-activity-resume :open="modalNewResume" @done="loadCalendar(activitiesPayload)" :data="modalResumeData" :event="modalResumeEvent" @close="closeModalResume"/>
        <modal-service-order :open="showModalOrderService" @close="closeModalServiceOrder"/>
    </div>
</template>

<script>
import PlanningCalendar from '@/views/planning/components/PlanningCalendar.vue'
import ModalServiceOrder from '@/views/planning/components/ModalServiceOrder.vue'
import ModalActivitySelect from '@/views/planning/components/ModalActivitySelect.vue'
import ModalActivityResume from '@/views/planning/components/ModalActivityResume.vue'
import {mapActions, mapGetters} from 'vuex'

export default {
    name: 'Planning',
    components: {
        PlanningCalendar,
        ModalActivitySelect,
        ModalActivityResume,
        ModalServiceOrder
    },
    data() {
        return {
            searchField: "",
            checkedFields: [],
            checkedActivitiesTypes: [],
            checkedStatus: [],
            events: [],
            modalResumeEvent: {
                extendedProps: {
                    type: '',
                    name: '',
                    headerBigColor: '',
                    field: '',
                    date: ''
                }
            },
            modalResumeData: {
                planning: {
                    subarea: {},
                    products: {}
                }
            },
            allStatus: [
                {
                    _id: 1,
                    name: "Pendente",
                    value: 'pending',
                    color: 'red'
                },
                {
                    _id: 2,
                    name: "Aguardando",
                    value: 'waiting',
                    color: 'yellow'
                },
                {
                    _id: 3,
                    name: "Realizada",
                    value: 'done',
                    color: 'green'
                },
            ],
            allActivitiesTypes: [
                {
                    _id: 1,
                    name: "Plantio",
                    value: "planting",
                    icon: "planting"
                },
                {
                    _id: 2,
                    name: "Pulverização",
                    value: "pulverization",
                    icon: "pulverization"
                },
                {
                    _id: 3,
                    name: "Colheita",
                    value: "harvest",
                    icon: "harvest"
                },
                {
                    _id: 4,
                    name: "Preparo de solo",
                    value: "soilPreparation",
                    icon: "soil-preparation"
                },
                {
                    _id: 5,
                    name: "Tratamento de sementes",
                    value: "seedTreatment",
                    icon: "seed-treatment"
                },
                {
                    _id: 6,
                    name: "Fertilização",
                    value: "fertilization",
                    icon: "fertilization"
                },
                {
                    _id: 7,
                    name: "Outra atividade",
                    value: "otherActivity",
                    icon: "other-activity"
                }
            ],
            activitiesPayload: {
                propertyId: '',
                cropId: '',
                startDate: '',
                endDate: ''
            },
            fieldsPayload: {
                propertyId: '',
                cropId: ''
            },
            loading: false,
            showFilter: false,
            modalNewActivity: false,
            modalNewResume: false,
            showModalOrderService: false
        };
    },
    async mounted() {
        this.loading = true;

        await this.findUnitConversions();

        this.updateFieldsPayload();
        await this.getFieldsByPropertyByCrop(this.fieldsPayload);
        this.checkAllFilters();

        this.updateActivitiesPayload();
        await this.findActivities(this.activitiesPayload);

        this.loading = false;

        this.filterActivities();
        this.formatEvents();
    },
    computed: {
        ...mapGetters("property", ["getPropertySelected", "getCropSelected"]),
        ...mapGetters("fields", ["allFields"]),
        ...mapGetters("activities", ["getActivities"])
    },
    watch: {
        async getCropSelected() {
            this.filtered = [];
            this.loading = true;

            this.updateFieldsPayload();
            await this.getFieldsByPropertyByCrop(this.fieldsPayload);
            this.checkAllFilters();

            this.updateActivitiesPayload();
            await this.findActivities(this.activitiesPayload);

            this.loading = false;

            this.filterActivities();
            this.formatEvents();

        },
        getActivities() {
            this.filterActivities();
            this.formatEvents();
        },
        checkedStatus() {
            this.filterActivities();
            this.formatEvents();
        },
        checkedFields() {
            this.filterActivities();
            this.formatEvents();
        },
        checkedActivitiesTypes() {
            this.filterActivities();
            this.formatEvents();
        }
    },
    methods: {
        ...mapActions("unit-conversions", ["findUnitConversions"]),
        ...mapActions("fields", ["getFieldsByPropertyByCrop"]),
        ...mapActions("activities", ["findActivities"]),
        ...mapActions("property", ["listFields", "listOperators", "listProducts", "listMachineries"]),
        ...mapActions("operators", [ "getOperators" ]),
        hasFields() {
            return this.allFields && this.allFields.length > 0;
        },
        checkAllFilters() {
            this.checkedFields = this.allFields.map(field => field._id);
            this.checkedActivitiesTypes = this.allActivitiesTypes.map(activityType => activityType.value);
            this.checkedStatus = this.allStatus.map(status => status.value);
        },
        updateFieldsPayload(){
            this.fieldsPayload = {
                propertyId: this.getPropertySelected._id,
                cropId: this.getCropSelected._id
            }
        },
        updateActivitiesPayload(startDate, endDate) {
            this.activitiesPayload = {
                propertyId: this.getPropertySelected._id,
                cropId: this.getCropSelected._id,
                ... startDate ? { startDate } : {},
                ... endDate ? { endDate } : {}
            }
        },
        openModalServiceOrder() {
            this.showModalOrderService = true
        },
        closeModalServiceOrder() {
            this.showModalOrderService = false
        },
        openModalActivity() {
            this.modalNewActivity = true
        },
        closeModalActivity() {
            this.modalNewActivity = false
        },
        openModalResume() {
            this.modalNewResume = true
        },
        closeModalResume() {
            this.modalNewResume = false
        },
        handleClickOnActivity(arg) {
            const selectedActivity = this.getActivities.find(activity => {
                return activity.activityDate._id == arg.event.extendedProps.date._id
            })
            this.modalResumeData.planning = selectedActivity
            this.modalResumeEvent = arg.event
            this.openModalResume()
        },
        formatEvents() {
            this.events = this.events.map(activity => this.formatEvent(activity));
        },
        formatEvent(activity) {
            return {
                id: activity.activityDate._id,
                title: this.getTitle(activity),
                date: this.formatDate(activity.activityDate.dateHour, 'default'),
                status: this.getStatus(activity),
                className: [this.getStatus(activity), this.getIcon(activity)],
                headerBigColor: this.getColor(activity),
                extendedProps: {
                    field: activity.subarea.field._id,
                    planning: activity._id,
                    date: activity.activityDate,
                    type: activity.type
                }
            }
        },
        getTitle(activity) {
            return !activity.title
                ? `${activity.subarea.field.name}\n${activity.subarea.seed}`
                : `${activity.subarea.field.name}\n${activity.subarea.seed}\n${activity.title}`;
        },
        getActivityStatus(activity) {
            const { dateHour, done } = activity.activityDate;

            if (done) {
                return 'done'
            } else if (new Date(dateHour) < new Date()) {
                return 'pending'
            } else if (new Date(dateHour) >= new Date()) {
                return 'waiting'
            }
        },
        getColor(activity) {
            const status = this.getActivityStatus(activity);
            const statusColor = {
                done: 'green',
                pending: 'red',
                waiting: 'yellow'
            }
            return statusColor[status]
        },
        getStatus(activity) {
            return `status-${this.getColor(activity)}`;
        },
        getIcon(activity) {
            const activityTypeObject = this.allActivitiesTypes.find(activityTypeObject => activity.type == activityTypeObject.value);

            return `icon-${activityTypeObject.icon}`;
        },
        async loadCalendarOnMonthChange(interval) {
            const { startDate, endDate } = interval;

            if (startDate != this.activitiesPayload.startDate || endDate != this.activitiesPayload.endDate) {
                await this.loadCalendar(interval)
            }
        },
        async loadCalendar(interval) {
            this.loading = true;

            const { startDate, endDate } = interval;
            this.updateActivitiesPayload(startDate, endDate);
            await this.findActivities(this.activitiesPayload);

            this.loading = false;
        },
        filterActivities() {
            this.events = this.getActivities.filter(activity => {
                if (!this.checkedFields.includes(activity.subarea.field._id)) {
                    return false;
                }
                if (!this.checkedActivitiesTypes.includes(activity.type)) {
                    return false;
                }
                if (!this.checkedStatus.includes(this.getActivityStatus(activity))) {
                    return false;
                }

                return true;
            })
        }
    }
};
</script>

<style scoped lang="sass">
.planning-container
    display: flex
    grid-gap: 4px
    width: 100%
    height: 100%

    &__filter
        width: 300px
        overflow: auto

        &-activator, .btn-close
            display: none

        .app-form__item
            max-height: 36px

            label
                display: flex
                align-items: center

    &__calendar
        width: 100%
        text-align: center

    .circle
        width: 7px
        height: 7px
        border-radius: 10px
        margin-right: 5px

        &-green
            background: $color-blue

        &-red
            background: $color-red

        &-yellow
            background: $color-yellow

@media (max-width: 1500px)
    .planning-container
        flex-direction: column
        //grid-gap: unset
        position: relative

        &__filter
            position: absolute
            z-index: 2
            height: calc(100% - 20px)
            padding: 10px
            background: $color-white
            box-shadow: $box-shadow
            border-radius: $border-radius

            &-activator, .btn-close
                display: flex

            .btn-close
                margin: 0 0 0 auto

        &__calendar
            height: 100%

        .hide
            display: none

// rever
.sidebar-calendar h2
    font-size: 18px
    font-weight: bold
    color: #505052
    border-bottom: 1px solid #dcdde1
    padding: 15px 0 15px 20px


form
    overflow: auto
    max-height: 200px
    border-bottom: 1px solid #dcdde1
    margin-top: 10px

h2
    color: #1f1f1f
    font-size: 25px
    font-weight: initial
    border-bottom: none
    padding: 10px 0px

.p-0
    padding: 0px 8px !important


.input-group
    width: 98%

.form-options
    padding: 10px 0px
    border-bottom: none


.form-options label
    text-overflow: ellipsis
    white-space: nowrap
    overflow: hidden
    width: 133px
    margin: 0

.form-group
    display: block
    margin-bottom: 15px


.form-group input
    padding: 0
    height: initial
    width: initial
    margin-bottom: 0
    display: none
    cursor: pointer


.form-group label
    position: relative
    cursor: pointer

</style>
