<template>
    <div>
        <div class="stock-container">
            <div class="stock-container__tabs">
                <div class="tab">
                    <router-link class="tab__item" tag="a" to="/estoque/meus-produtos-especificos">Meus produtos específicos</router-link>
                    <router-link class="tab__item" tag="a" to="/estoque/estoque-atual">Estoque atual</router-link>
                    <router-link class="tab__item" tag="a" to="/estoque/movimentacoes">Movimentação</router-link>
                    <router-link class="tab__item" tag="a" to="/estoque/aprovar-saidas">Aprovar saídas</router-link>
                    <router-link class="tab__item" tag="a" to="/estoque/historico">Histórico</router-link>
                </div>
            </div>
            <div class="configurations-container__content">
                <router-view/>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Stock'
}
</script>

<style lang="sass" scoped>

.stock-container
    min-width: 1000px

.tab
    height: 60px
    display: flex
    align-items: center
    justify-content: center
    border-bottom: 1px solid #ddd

    &__item
        line-height: 60px
        text-decoration: none
        padding: 0 25px
        color: $color-grey
        font-size: 20px


.router-link-exact-active
    color: $color-blue
    border-bottom: 1px solid $color-blue

</style>
