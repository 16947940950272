<template>
    <div class="app-backdrop" :class="{ 'hidden': !open }">
        <div class="app-modal" :class="{ 'app-modal--disabled': disabled }">
            <header class="app-modal__header">
                <slot name="header" class="slot"/>
                <button class="btn-close" @click="close">
                    <i class="material-icons">close</i>
                </button>
            </header>
            <section class="app-modal__body">
                <div v-show="loading" class="loader app-modal__loader">
                    <span class="spinner-border" role="status"></span>
                </div>
                <div v-show="!loading" class="app-modal__body-slot">
                    <slot name="body"/>
                </div>
            </section>
            <footer class="app-modal__footer">
                <slot name="footer"/>
            </footer>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'Modal',
    props: {
        open: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    methods: {
      close() {
        this.$emit('close');
      },
    }
  };
</script>

<style scoped lang="sass">
.app-backdrop
    position: fixed
    z-index: 9999
    top: 0
    right: 0
    bottom: 0
    left: 0
    background: rgba(0, 0, 0, 0.2)
    display: grid
    grid-template-columns: repeat(12, 1fr)
    justify-content: center
    align-items: center

.app-modal
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    max-height: calc(100vh - 100px)
    margin: 10px
    background: #fff
    border-radius: 10px
    box-shadow: 6px 6px 4px rgba(0, 0, 0, 0.2)
    max-width: 100%

    &--closed
        display: none

    &--disabled
        &::before
            content: ''
            position: fixed
            border-radius: 10px
            background: rgba(0, 0, 0, 0.2)
            width: 100%
            height: 100%

    &__header
        display: flex
        align-items: center
        width: 100%
        padding: 6px
        background-color: $color-blue
        color: #fff
        border-top-left-radius: inherit
        border-top-right-radius: inherit

    &__body
        display: flex
        width: 100%
        max-width: 100%
        max-height: 60vh

    &__footer
        display: flex
        justify-content: center
        align-items: center
        width: 100%
        padding: 10px 12px
        border-top: 0.5px solid #bbbbbb4f

    &__loader
        color: $color-blue
        display: flex
        justify-content: center
        align-items: center
        height: 100px
        width: 100%

    &__body-slot
        width: 100%
        padding: 10px 12px
        overflow: auto

    .btn-close
        display: flex
        justify-content: center
        align-items: center
        max-height: 30px
        max-width: 30px
        padding: 8px
        border-radius: 50%
        color: #fff
        cursor: pointer
        border: none
        outline: none
        background-color: unset

        &:hover
            background-color: rgba(0, 0, 0, 0.1)

.hidden
    display: none

@media (min-width: 1265px)
    .app-modal
        grid-column: 4 / span 6

@media (max-width: 1264px)
    .app-modal
        grid-column: 3 / span 8

@media (max-width: 960px)
    .app-modal
        grid-column: 2 / span 10
        max-width: 100%

@media (max-width: 600px)
    .app-modal
        grid-column: span 12
        margin: 4px
        max-width: 100%
        max-height: none !important

</style>
