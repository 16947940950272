<template>
    <div class="calendar-container">
        <full-calendar ref="fullCalendar" :options="calendarOptions"/>
    </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import {mapActions, mapGetters} from 'vuex'

export default {
    name: 'PlanningCalendar',
    components: {
        FullCalendar
    },
    props: {
        events: Array,
        btnLeft: String,
        btnRight: String
    },
    data() {
        return {
            calendarOptions: {
                plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
                initialView: 'dayGridMonth',
                customButtons: {
                    newOS: {
                        text: this.btnLeft,
                        click: () => this.$emit('btnLeftClick')
                    },
                    newActivity: {
                        text: this.btnRight,
                        click: () => this.$emit('btnRightClick')
                    }

                },
                headerToolbar: {
                    left: 'today prev,next title',
                    right: 'newOS newActivity'
                },
                locale: 'pt-br',
                buttonText: {
                    today: 'Hoje'
                },
                height: '100%',
                selectable: false,
                eventDisplay: 'list-item',
                eventClick: (item) => this.$emit('eventClick', item),
                events: this.events,
                datesSet: this.handleMonthChange
            },
        }
    },
    methods: {
        handleMonthChange(dateInfo){
            const startDate = this.formatDate(dateInfo.startStr, 'default');
            const endDate = this.formatDate(dateInfo.endStr, 'default');
            this.$emit('monthChange', { startDate, endDate })
        }
    },
    watch: {
        events() {
            this.calendarOptions.events = [ ...this.events ]
        }
    }
}
</script>

<style scoped lang="sass">
.calendar-container
    height: 100%

.fc-right .fc-prev-button,
.fc-right .fc-next-button
    background-color: #2e5d0c
    background-image: none
</style>

<style lang="sass">
.status // cor de fundo e dot do evento
    &-green
        background: #d4f2d4

        &:hover
            cursor: pointer
            background: #d4f2d4

        .fc-daygrid-event-dot
            border: 3px solid #3c7b34

    &-red
        background: #f6d7d7

        &:hover
            cursor: pointer
            background: #f6d7d7

        .fc-daygrid-event-dot
            border: 3px solid #e60000

    &-yellow
        background: #fff3ae

        &:hover
            cursor: pointer
            background: #fff3ae

        .fc-daygrid-event-dot
            border: 3px solid #f5d92f

@mixin container
    content: ''
    @include icon-mask-sm
    background-color: $color-white-darken-2

.icon
    &-harvest:after
        mask: url("/img/icons/svg/harvest.svg") no-repeat 100% 100%
        @include container

    &-planting:after
        mask: url("/img/icons/svg/planting.svg") no-repeat 100% 100%
        @include container

    &-pulverization:after
        mask: url("/img/icons/svg/pulverization.svg") no-repeat 100% 100%
        @include container

    &-soil-preparation:after
        mask: url("/img/icons/svg/soil-preparation.svg") no-repeat 100% 100%
        @include container

    &-seed-treatment:after
        mask: url("/img/icons/svg/seed-treatment.svg") no-repeat 100% 100%
        @include container

    &-fertilization:after
        mask: url("/img/icons/svg/fertilization.svg") no-repeat 100% 100%
        @include container

    &-other-activity:after
        mask: url("/img/icons/svg/other-activity.svg") no-repeat 100% 100%
        @include container

.fc
    .fc-daygrid-day-top // dia alinhado ao centro
        justify-content: center

    .fc-daygrid-dot-event .fc-event-title // fonte do evento e quebra de linha
        color: #454545
        font-size: 12px
        font-weight: normal
        display: block
        white-space: pre-wrap

    .fc-daygrid-day-events
        display: flex
        flex-direction: column
        grid-gap: 6px
        height: 200px
        overflow: auto

        a
            padding: 3px

    .fc-toolbar.fc-header-toolbar
        margin: 0
        padding-bottom: 10px

    .fc-header-toolbar
        .fc-toolbar-chunk
            display: flex
            justify-content: center
            align-items: center
            padding: 6px
            font-size: 14px

        .fc-today-button, .fc-next-button, .fc-prev-button, .fc-newOS-button, .fc-newActivity-button
            @extend .btn, .clickable

            &:active
                color: unset
                background: unset

                &:focus
                    outline: unset
                    box-shadow: unset

        .fc-today-button
            @extend .btn--outlined

        .fc-next-button, .fc-prev-button
            @extend .btn--flat, .btn--rounded

        .fc-newActivity-button
            @extend .btn-primary, .btn--flat

        .fc-newOS-button
            @extend .btn-primary--outlined, .btn--flat

    .fc-view-harness
        .fc-daygrid-day.fc-day-today
            background: $color-white-darken-1

@media (max-width: 966px)
    .fc
        .fc-header-toolbar
            flex-direction: column

            .fc-toolbar-chunk
                width: 100%
                justify-content: flex-start

                &:nth-child(3)
                    justify-content: flex-end

</style>
