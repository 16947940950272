import Ndvi from '../../api/Ndvi';

const state = {
    ndvis: [],
    lastNdvis: {}
};

const getters = {
    getAllNdvis: (state) => state.ndvis,
    getLastNdvis: (state) => state.lastNdvis
};

const actions = {
    getNdvis({ commit }) {
        Ndvi.getAll().then(response => {
            commit("setNdvis", response.data.data);
        })
    },
    async findNdvis({ commit }, payload) {
        const {
            propertyId,
            cropId,
            fieldId,
            startDate,
            endDate,
            fieldIdList
        } = payload
        await Ndvi.findNdvis(propertyId, cropId, fieldId, startDate, endDate, fieldIdList).then(response => {
            commit("setNdvis", response.data.data);
        })
    },
    async findLastNdvis({ commit }, payload) {
        const {
            propertyId
        } = payload
        await Ndvi.findLastNdvis(propertyId).then(response => {
            commit("setLastNdvis", response.data.data);
        })
    },
    getNdvi({ commit }, id) {
        Ndvi.get(id).then(response => {
            commit("setNdvis", response.data.data);
        })
    },
    findNdvisByInterval({ commit }, payload) {
        Ndvi.findByInterval(payload).then(response => {
            commit("setNdvis", response.data.data);
        })
    },
    async updateNdvi({ commit }, { ndviId, ndvi }) {
        const response = await Ndvi.updateNdvi(ndviId, ndvi);
        return response
    },
    clearNdvis({ commit }) {
        commit("setNdvis", [])
    },
    clearLastNdvis({ commit }) {
        commit("setLastNdvis", {})
    }
};

const mutations = {
    setNdvis: (state, ndvis) => { state.ndvis = ndvis },
    setLastNdvis: (state, lastNdvis) => { state.lastNdvis = lastNdvis }
};

export default { state, getters, actions, mutations, namespaced: true }
