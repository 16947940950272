<template>
    <div>
        <div class="panel-container">
            <panel-map class="panel-container__map"/>
            <panel-scheduled-activities class="panel-container__scheduled-activities"/>
        </div>
    </div>
</template>

<script>
import PanelMap from "@/views/panel/components/PanelMap.vue";
import PanelScheduledActivities from "@/views/panel/components/PanelScheduledActivities.vue";
import {mapActions, mapGetters} from "vuex";

export default {
    name: "Panel",
    components: {
        PanelMap,
        PanelScheduledActivities
    }
};
</script>

<style lang="sass" scoped>

.panel-container
    width: 100%
    height: 100%
    position: relative

    &__map
        width: 100%
        height: 100%

    &__scheduled-activities
        position: absolute
        width: 100%
        padding: 16px
        right: 0
        top: 0
        pointer-events: none
        display: flex
        justify-content: flex-end
        z-index: 400


</style>
