import Prague from "../../api/Prague";

const state = {
  error: "",
  pragues: [],
  praguesKeep: []
};

const getters = {
  getPragues: (state) => state.pragues,
  getPraguesKeep: (state) => state.praguesKeep,
  getError: (state) => state.error,
};

const actions = {
  async listAllSamples({ commit }, payload) {
    await Prague.getAllSamplesOfProperty(payload.propertyId, payload.culture)
      .then((response) => {
        commit("setPragues", response.data.data);
      })
      .catch((error) => {
        commit("setError", error);
      });
  },

  async getSamplesByPropertyByCropByPeriod({ commit }, payload){
    await Prague.getSamplesByPropertyByCropByPeriod(payload.propertyId, payload.cropId, payload.interval.startDate, payload.interval.endDate)
      .then((response) => {
        commit("setPragues", response.data.data);
      })
      .catch((error) => {
        commit("setError", error);
      });

  },

  async listSamplesByInterval({ commit }, payload) {
    await Prague.getSamplesByInterval(
      payload.propertyId,
      payload.culture,
      payload.interval
    )
      .then((response) => {
        commit("setPragues", response.data.data);
      })
      .catch((error) => {
        commit("setError", error);
      });
  },

  getPragueById({ commit }, payload) {
    Prague.getPragueById(
      payload.pragueId
    )
      .then((response) => {
        commit("setPraguesKeep", response.data.data);
      })
      .catch((error) => {
        commit("setError", error);
      });

  },

  clearError({ commit }) {
    return commit("setError", "");
  },
};

const mutations = {
  setPragues: (state, pragues) => (state.pragues = pragues),
  setPraguesKeep: (state, praguesKeep) => (state.praguesKeep = praguesKeep),
  setError: (state, error) => (state.error = error),
};

export default { state, getters, actions, mutations, namespaced: true };
