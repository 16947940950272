import API from './API';
import querystring from 'querystring'

export default {
    findActivities({ propertyId, cropId, fieldId, fieldIdList, startDate, endDate }) {
        return API.get('activities', {
            params: {
                ... propertyId ? { propertyId } : {},
                ... cropId ? { cropId } : {},
                ... fieldId ? { fieldId } : {},
                ... fieldIdList && fieldIdList.length ? { fieldIdList: JSON.stringify(fieldIdList) } : {},
                ... startDate ? { startDate } : {},
                ... endDate ? { endDate } : {}
            }
        })
    },
    findActivityProducts({ propertyId }) {
        return API.get(`activities/activity-products/properties/${propertyId}`)
    },
    putPlanting({ plantingId, planting }) {
        return API.put(`activities/planting/${plantingId}`, planting)
    },
    postPlanting(planting) {
        return API.post(`activities/planting`, planting)
    },
    completePlanting({ plantingId, dateId }) {
        return API.put(`activities/planting/${plantingId}/complete/${dateId}`)
    },
    deactivatePlanting({ plantingId }) {
        return API.delete(`activities/planting/${plantingId}`)
    },
    putHarvest({ harvestId, harvest }) {
        return API.put(`activities/harvest/${harvestId}`, harvest)
    },
    postHarvest(harvest) {
        return API.post(`activities/harvest`, harvest)
    },
    completeHarvest({ harvestId, dateId }) {
        return API.put(`activities/harvest/${harvestId}/complete/${dateId}`)
    },
    deactivateHarvest({ harvestId }) {
        return API.delete(`activities/harvest/${harvestId}`)
    },
    putPulverization({ pulverizationId, pulverization }) {
        return API.put(`activities/pulverization/${pulverizationId}`, pulverization)
    },
    postPulverization(pulverization) {
        return API.post(`activities/pulverization`, pulverization)
    },
    completePulverization({ pulverizationId, dateId }) {
        return API.put(`activities/pulverization/${pulverizationId}/complete/${dateId}`)
    },
    deactivatePulverization({ pulverizationId }) {
        return API.delete(`activities/pulverization/${pulverizationId}`)
    },
    putSoilPreparation({ soilPreparationId, soilPreparation }) {
        return API.put(`activities/soil-preparation/${soilPreparationId}`, soilPreparation)
    },
    postSoilPreparation(soilPreparation) {
        return API.post(`activities/soil-preparation`, soilPreparation)
    },
    completeSoilPreparation({ soilPreparationId, dateId }) {
        return API.put(`activities/soil-preparation/${soilPreparationId}/complete/${dateId}`)
    },
    deactivateSoilPreparation({ soilPreparationId }) {
        return API.delete(`activities/soil-preparation/${soilPreparationId}`)
    },
    putSeedTreatment({ seedTreatmentId, seedTreatment }) {
        return API.put(`activities/seed-treatment/${seedTreatmentId}`, seedTreatment)
    },
    postSeedTreatment(seedTreatment) {
        return API.post(`activities/seed-treatment`, seedTreatment)
    },
    completeSeedTreatment({ seedTreatmentId, dateId }) {
        return API.put(`activities/seed-treatment/${seedTreatmentId}/complete/${dateId}`)
    },
    deactivateSeedTreatment({ seedTreatmentId }) {
        return API.delete(`activities/seed-treatment/${seedTreatmentId}`)
    },
    putFertilization({ fertilizationId, fertilization }) {
        return API.put(`activities/fertilization/${fertilizationId}`, fertilization)
    },
    postFertilization(fertilization) {
        return API.post(`activities/fertilization`, fertilization)
    },
    completeFertilization({ fertilizationId, dateId }) {
        return API.put(`activities/fertilization/${fertilizationId}/complete/${dateId}`)
    },
    deactivateFertilization({ fertilizationId }) {
        return API.delete(`activities/fertilization/${fertilizationId}`)
    },
    putOtherActivity({ otherActivityId, otherActivity }) {
        return API.put(`activities/other-activity/${otherActivityId}`, otherActivity)
    },
    postOtherActivity(otherActivity) {
        return API.post(`activities/other-activity`, otherActivity)
    },
    completeOtherActivity({ otherActivityId, dateId }) {
        return API.put(`activities/other-activity/${otherActivityId}/complete/${dateId}`)
    },
    deactivateOtherActivity({ otherActivityId }) {
        return API.delete(`activities/other-activity/${otherActivityId}`)
    },
    approveActivityProducts(activityProducts) {
        return API.post(`activities/activity-products/approve`, activityProducts)
    },
    updateActivityProduct({ activityProductId, value }) {
        return API.put(`activities/activity-products/${activityProductId}`, value)
    }
}
