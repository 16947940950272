import Culture from '../../api/Culture';

const state = {
    cultures: []
}

const getters = {
    getCultures: state => state.cultures
}

const actions = {
    async findCultures({ commit }) {
        const response = await Culture.findCultures()
        const cultures = response.data.data
        commit("SET_CULTURES", cultures)
    }
}

const mutations = {
    SET_CULTURES: (state, cultures) => state.cultures = cultures,
}

export default { state, getters, actions, mutations, namespaced: true }
