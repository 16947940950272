<template>
    <div>
        <div class="app-container" v-if="show">
            <sidebar class="app-container__sidebar" ref="sidebar" @resize="setMainContainerWidth"/>
            <div class="app-container__main" :style="mainContainerWidth">
                <navbar class="app-container__navbar"/>
                <router-view class="app-container__content"/>
            </div>
        </div>
        <div v-else>
            <router-view/>
        </div>
    </div>
</template>

<script>
import Sidebar from "@/views/main/components/sidebar/Sidebar.vue";
import Navbar from "@/views/main/components/navbar/Navbar.vue";
import {mapActions, mapGetters} from "vuex";

export default {
    name: "App",
    components: {
        Sidebar,
        Navbar,
    },
    data() {
        return {
            mainContainerWidth: ''
        }
    },
    async created() {
        await this.loadApp()
    },
    computed: {
        ...mapGetters('error', ['getError']),
        show() {
            return !this.$route.path.match(/^\/login\/?$/)
        }
    },
    watch: {
        getError() {
            if (this.getError) {
                this.$vToastify.error(this.getError.message, 'Ops...')
            }
        }
    },
    methods: {
        ...mapActions("users", ["getUser"]),
        async loadApp() {
            if (this.show) {
                await this.getUser()
            }
        },
        async setMainContainerWidth() {
            await this.$nextTick(() => {
                this.mainContainerWidth = `width: calc(100% - ${this.$refs.sidebar.$el.clientWidth}px)`
            })
        }
    }
};
</script>

<style lang="sass">
.app-container
    display: flex
    height: 100vh
    overflow: hidden

    &__main
        display: flex
        flex-direction: column
        height: 100vh
        width: 100%

    &__content
        height: 100%
        overflow: auto
        padding: 10px

@media (max-width: 768px)
    .app-container
        &__content
            padding: 2px

        &__main
            width: 100% !important

input:focus, textarea:focus, select:focus
    outline: none

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button
    -webkit-appearance: none
    margin: 0

input[type=number]
    -moz-appearance: textfield

</style>
