const mixin = {
    methods: {
        formatProductCategory(productCategory) {
            if (productCategory) {
                if (productCategory.parentProductCategory) {
                    return productCategory.parentProductCategory.name
                }
                return productCategory.name
            }
            return ''
        },
        formatProductSubCategory(productCategory) {
            if (productCategory) {
                if (productCategory.parentProductCategory) {
                    return productCategory.name
                }
                return ''
            }
            return ''
        }
    }
}

export default mixin
