<template>
    <div>
        <label class="app-label"> {{ onlyDate ? 'Data' : 'Data e Hora' }} </label>
        <div class="flex-container">
            <input
                class="form-control"
                type="date"
                v-model="selectedDate"
            >
            <input
                class="form-control"
                type="time"
                v-model="selectedHour"
                v-if="!onlyDate"
            >
            <button
                class="btn btn--flat btn--rounded"
                @click="addDateAndHour"
            >
                <i class="material-icons"> add_circle_outline </i>
            </button>
        </div>
        <div class="flex-container-wrap mt-2">
            <div
                v-for="(date, dateIndex) in selectedDates"
                :key="`${uuid}_date_${dateIndex}`"
                class="chip"
            >

                <span v-if="onlyDate"> {{ date.dateHour | formatDate }} </span>
                <span v-else> {{ date.dateHour | formatDateTime }} </span>
                <i class="chip-action material-icons" @click="removeDate(dateIndex)">close</i>
            </div>
        </div>
    </div>
</template>
<script>

import {mapActions, mapGetters} from 'vuex'

export default {
    name: "DateSelector",
    props: {
        uuid: {
            type: String
        },
        value: {
            type: Array
        },
        onlyDate: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            selectedDate: '',
            selectedHour: '',
            selectedDates: []
        };
    },
    watch: {
        value() {
            if (this.inputDateIsDifferentFromSelected()) {
                this.selectedDates = this.formattedInputDates()
            }
        },
        selectedDates() {
            this.emitFormattedSelectedDates()
        }
    },
    methods: {
        joinDateAndHour(date, hour) {
            if (!hour) {
                return `${date}`
            } else {
                return `${date}T${hour}Z`
            }
        },
        addDateAndHour() {
            if (!this.selectedDate) {
                return this.$vToastify.info('Selecione uma data', "Atenção!")
            }

            const selectedDate = this.joinDateAndHour(this.selectedDate, this.selectedHour);
            const isRepeatedDate = this.selectedDates.find(date => {
                return new Date(date.dateHour).toISOString() == new Date(selectedDate).toISOString()
            })
            if (isRepeatedDate) {
                return this.$vToastify.info('Essa data já foi selecionada', "Atenção!")
            }

            this.selectedDates.push({
                dateHour: selectedDate,
                done: false
            })
        },
        inputDateIsDifferentFromSelected() {
            const formattedInputDates = this.formattedInputDates();

            const hasDifferentArraySize = this.selectedDates.length != formattedInputDates.length

            return hasDifferentArraySize
        },
        removeDate(index) {
            this.selectedDates.splice(index, 1);
        },
        formatInputDate(date) {
            return date
        },
        formattedInputDates() {
            return this.value.map(date => this.formatInputDate(date))
        },
        formatOutputDate(date) {
            return date
        },
        formattedOutputDates() {
            return this.selectedDates.map(date => this.formatOutputDate(date))
        },
        emitFormattedSelectedDates() {
            this.$emit('input', this.formattedOutputDates())
        }
    },
    mounted() {
        this.selectedProducts = []
    }
};
</script>
<style lang="sass" scoped>
.input-filter
    width: 100%
    padding: 7px

.flex-container
    grid-gap: 0
</style>
