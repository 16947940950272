<template>
    <div>
        <div class="flex-container-column">
            <button class="btn btn-primary--outlined btn--flat" @click="back">
                <i class="far fa-arrow-alt-circle-left"/>
                <span>Voltar</span>
            </button>
            <p>Desculpe, você não tem acesso a esta página.</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NotAuthorized',
    methods: {
        back() {
            this.$router.go(-1)
        }
    }
}
</script>

<style scoped lang="sass">
.flex-container-column
    @extend .flex
    flex-direction: column

    .btn
        max-width: 100px

    p
        margin: 0
        text-align: center

</style>