/* eslint-disable no-unused-vars */
import Report from '../../api/Report';

const state = {}

const getters = {}

const actions = {
    async findCropReport({ commit }, { propertyId, cropId, fieldIdList, startDate, endDate }) {
        const response = await Report.findCropReport({ propertyId, cropId, fieldIdList, startDate, endDate });
        return response;
    },
    async findMonitoringReport({ commit }, { propertyId, cropId, fieldIdList, startDate, endDate, pestilenceTypes, includePhotos }) {
        const response = await Report.findMonitoringReport({ propertyId, cropId, fieldIdList, startDate, endDate, pestilenceTypes, includePhotos });
        return response;
    },
    async findStockReport({ commit }, { propertyId, cropId }) {
        const response = await Report.findStockReport({ propertyId, cropId });
        return response;
    }
}

const mutations = {}

export default { state, getters, actions, mutations, namespaced: true }
