<template>
    <div class="scrollable-container">
        <div class="field-photos">
            <div class="field-photos__breadcrumb app-breadcrumb">
                <span class="app-breadcrumb__item" @click="$router.push('/bens/talhoes')">Talhões</span>
                <span class="app-breadcrumb__divider">></span>
                <span class="app-breadcrumb__item app-breadcrumb__item--disabled">Fotos</span>
            </div>

            <div class="field-photos__header">
                <span class="title">{{ fieldSelected.name }}</span>
                <button class="btn btn-primary--outlined btn--flat" @click="$router.push('/bens/talhoes')">
                    <i class="fa fa-arrow-left"/>
                    <span>Voltar</span>
                </button>
            </div>

            <div class="field-photos__filter">
                <div>
                    <label class="app-label">Subarea</label>
                    <select class="app-select" v-model="filter.subareaId">
                        <option value="">Todas</option>
                        <option v-for="subarea in subareas" :key="subarea._id" :value="subarea._id">
                            {{subarea.seed}}
                        </option>
                    </select>
                </div>
                <date-picker label="Data Inicial" :dataSent="filter.startDate"
                             @dateSelected="filter.startDate = $event.date"/>
                <date-picker label="Data Final" :dataSent="filter.endDate"
                             @dateSelected="filter.endDate = $event.date"/>
                <button class="btn btn-primary btn--flat" @click="fetchPhotos">
                    <span>Buscar</span>
                </button>
            </div>

            <div class="flex-container text-center text-secondary pt-4" v-if="loading">
                <span class="spinner-border"/>
            </div>

            <div class="flex-container text-center text-secondary pt-4" v-else-if="!loading && myPhotos.length < 1">
                <i class="fa fa-exclamation-circle fa-2x"/>
                <span>Fotos não encontradas para o talhão no período escolhido.</span>
            </div>

            <div class="field-photos__carousel" v-else tabindex="0">
                <div class="carousel-slide">
                    <div class="carousel-slide__title">
                        <span class="title">Fotos do talhão</span>
                        <span class="description">Selecione uma foto para ampliar</span>
                    </div>

                    <ul class="carousel-slide__slider">
                        <li class="carousel-slide__slider__item" :class="{ active: index === indexImageActive }"
                            v-for="(item, index) in myPhotos" :key="item._id" @click="selectImageByIndex(index)">
                            <img class="thumbnail" :src="item.image" alt="">
                            <span>{{ item.captured | formatDate }}</span>
                        </li>
                    </ul>
                </div>

                <div class="carousel-view">
                    <div class="image-view">
                        <button class="previous" @click="previousImage">
                            <i class="fas fa-chevron-left"/>
                        </button>
                        <div class="image-container">
                            <img class="image-item" id="image" :src="imageActive.image" alt=""/>
                        </div>
                        <button class="next" @click="nextImage">
                            <i class="fas fa-chevron-right"/>
                        </button>
                        <button class="delete" @click="deletePhoto">
                            <i class="fas fa-trash"/>
                        </button>
                        <button class="zoom-out" @click="zoomOut">
                            <i class="far fa-search-minus"/>
                        </button>
                        <button class="zoom-in" @click="zoomIn">
                            <i class="far fa-search-plus"/>
                        </button>
                    </div>
                    <div class="image-details">
                        <div>
                            <span><b class="app-label">Capturado em:</b> {{ imageActive.captured | formatDateTime }}</span>
                        </div>
                        <div>
                            <span v-show="imageActive.pointName"><b class="app-label">Ponto:</b> {{ imageActive.pointName }}</span>
                            <span v-show="showCoordinates">
                                <b class="app-label">Latitude:</b> {{ imageActive.coordinates.latitude }},
                                <b class="app-label">Longitude:</b> {{ imageActive.coordinates.longitude }}</span>
                        </div>
                        <div>
                            <span v-show="!edit">
                                <b class="app-label">Observações:</b>
                                <p>
                                    {{ imageActive.notes }}
                                </p>
                                <i class="far fa-pen clickable pl-2" @click.stop="openEditNotes"></i>
                            </span>
                            <span v-show="edit">
                                <b class="app-label">Observações:</b>
                                <div>
                                    <textarea
                                        class="app-textarea app-textarea--resizable"
                                        v-model="notes"
                                    />
                                    <i class="fal fa-check clickable pl-2" @click="saveNotes"></i>
                                    <i class="fal fa-times clickable pl-2 text-danger" @click="closeEditNotes"></i>
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DatePicker from '@/components/commons/DatePicker'
import {mapActions, mapGetters} from "vuex";
import photos from "@/api/Photo";
import _ from 'lodash'

export default {
    name: 'FieldPhotos',
    components: {
        DatePicker
    },
    data() {
        return {
            imageActive: {},
            indexImageActive: 0,
            latitude: "",
            longitude: "",
            loading: false,
            zoom: 1,
            slideIndex: 0,
            myPhotos: [],
            filter: {
                propertyId: '',
                fieldId: '',
                subareaId: '',
                startDate: this.getFirstDayOfMonth(),
                endDate: this.getTodayDate()
            },
            edit: false,
            notes: '',
            subareas: []
        };
    },
    computed: {
        ...mapGetters("fields", ["getPhotos", "getFieldSelected"]),
        ...mapGetters("subareas", ["getSubareas"]),
        ...mapGetters("property", ["getPropertySelected"]),
        ...mapGetters("photos", ["getAllPhotos"]),
        photos() {
            return this.getPhotos;
        },
        fieldSelected() {
            return this.getFieldSelected;
        },
        showCoordinates() {
            return this.imageActive.coordinates &&
                this.imageActive.coordinates.latitude &&
                this.imageActive.coordinates.longitude
        }
    },
    async mounted() {
        this.loading = true
        await this.fetchField(this.$route.params.id);
        this.prepareFilter()
        await this.findSubareasByFieldId(this.fieldSelected._id)
        this.subareas = this.getSubareas
        this.loading = false
    },
    watch: {
        indexImageActive() {
            this.imageActive = this.myPhotos[this.indexImageActive]
            this.zoom = 1
        },
        zoom() {
            document.getElementById('image').style = `transform: scale(${this.zoom}); transform-origin: 50% 0`
        }
    },
    methods: {
        ...mapActions("fields", ["getField"]),
        ...mapActions("subareas", ["findSubareasByFieldId"]),
        ...mapActions('photos', ['findPhotos', 'updatePhoto']),
        async deletePhoto() {
            const id = this.myPhotos[this.indexImageActive]._id
            return this.$vToastify.prompt({
                body:
                    "Deseja mesmo excluir esta foto ?",
                title: "ATENÇÃO!",
                answers: {Sim: true, Não: false}
            })
                .then(async (resp) => {
                    if (resp) {
                        await photos.delete(id).then(async (res) => {
                            await this.fetchPhotos()
                            this.$vToastify.success(
                                "Foto excluida com sucesso.",
                                "Talhão"
                            );
                            this.indexImageActive = 0
                        }).catch((err) => {
                            this.$vToastify.error(
                                "Não foi possivel realizar esta operação,tente novamente",
                                "Talhão"
                            )
                        })
                    }
                })
        },
        openEditNotes() {
            this.notes = this.imageActive.notes
            this.edit = true
        },
        closeEditNotes() {
            this.edit = false
            this.notes = ''
        },
        async saveNotes() {
            const photo = _.cloneDeep(this.imageActive)
            photo.notes = this.notes
            const success = await this.updatePhoto(photo)
            if (success) {
                this.imageActive.notes = this.notes
                this.closeEditNotes()
                this.$vToastify.success('Observação atualizada com sucesso', 'Sucesso')
            }
        },
        fetchField(id) {
            return this.getField(id)
        },
        async fetchPhotos() {
            this.loading = true
            if (this.filter.subareaId) {
                this.filter.fieldId = ''
            }
            await this.findPhotos(this.filter)
            this.myPhotos = this.getAllPhotos
            this.indexImageActive = 0
            this.imageActive = this.myPhotos[this.indexImageActive]
            this.loading = false
        },
        zoomIn() {
            this.zoom += 0.5
        },
        zoomOut() {
            if (this.zoom > 1) {
                this.zoom -= 0.5
            }
        },
        prepareFilter() {
            this.filter.propertyId = this.getPropertySelected._id
            this.filter.fieldId = this.getFieldSelected._id
        },
        nextImage() {
            this.validateEdit(() => {
                this.indexImageActive = this.circularList(this.indexImageActive + 1, this.myPhotos.length)
            })
        },
        previousImage() {
            this.validateEdit(() => {
                this.indexImageActive = this.circularList(this.indexImageActive - 1, this.myPhotos.length)
            })
        },
        selectImageByIndex(index) {
            this.validateEdit(() => {
                this.indexImageActive = index;
            });
        },
        validateEdit(callback) {
            if (this.edit) {
                return this.$vToastify.error('Conclua a edição da observação antes de trocar para outra imagem.', 'Atenção');
            }

            return callback();
        }
    }
}
</script>

<style scoped lang="sass">
.scrollable-container
    width: 100%
    height: 100%

.field-photos
    margin-bottom: 150px
    min-width: 1000px
    width: 100%
    display: flex
    grid-gap: 10px
    flex-direction: column

    &__header
        display: flex
        flex-wrap: wrap
        justify-content: space-between
        align-items: flex-end

        .btn
            max-height: 40px

        .title
            font-size: 22px
            color: $theme-color-primary

    &__filter
        width: 100%
        display: flex
        flex-wrap: wrap
        align-items: flex-end
        grid-gap: 10px

    &__carousel
        display: flex
        grid-gap: 6px
        height: 100%

        .carousel-slide
            height: 100%
            width: 250px
            border: $border
            border-radius: $border-radius

            &__title
                display: flex
                flex-direction: column
                text-align: center
                padding: 10px 0

                .title
                    font-size: 20px
                    color: $color-blue

                .description
                    font-size: 14px

            &__slider
                margin: 0
                padding: 4px
                height: calc(100% - 71px)
                overflow: auto
                text-align: center
                scroll-behavior: smooth

                &__item
                    display: flex
                    flex-direction: column
                    padding: 6px
                    font-size: 14px
                    cursor: pointer

                    &.active
                        background: $color-blue-lighten-1
                        border-color: $color-blue-lighten-1

                    .thumbnail
                        max-width: 230px
                        max-height: 230px
                        object-fit: contain


        .carousel-view
            width: calc(100% - 250px)
            height: 100%
            display: flex
            flex-direction: column
            grid-gap: 6px

            .image-view
                justify-self: center
                width: 100%
                height: 100%
                position: relative
                display: flex
                flex-direction: column
                align-items: center

                .image-container
                    display: flex
                    flex-direction: column
                    justify-content: center
                    align-items: center
                    grid-gap: 6px
                    font-size: 14px
                    width: 100%
                    height: 100%
                    overflow: auto

                .image-item
                    width: 600px
                    max-height: 600px
                    object-fit: contain

                .next, .previous, .delete, .zoom-out, .zoom-in
                    position: absolute
                    z-index: 10
                    border-radius: 50%
                    opacity: 0.7
                    transition-duration: 0.3s
                    background: $color-white-darken-1-opaque
                    width: 50px
                    height: 50px
                    border: none

                    &:hover
                        background: white
                        filter: brightness(90%)

                .next
                    right: 25px
                    top: 50%

                .previous
                    left: 25px
                    top: 50%

                .delete
                    top: 25px
                    right: 25px

                .zoom-out
                    bottom: 25px
                    right: 75px

                .zoom-in
                    bottom: 25px
                    right: 25px

            .image-details
                justify-self: flex-end
                width: 100%
                display: flex
                flex-direction: column
                text-align: left
                grid-gap: 6px
                padding: 4px

                div
                    display: flex
                    grid-gap: 6px

                span
                    padding: 2px
                    background: $color-blue-lighten-1
                    border-radius: $border-radius
                    border: $border

                p
                    text-align: justify
                    white-space: pre-line

</style>
