<template>
    <div>
        <Modal
            :open="open"
            :loading="loadingData"
            @close="$emit('close')"
        >
            <div
                class="app-modal-header"
                slot="header"
            >
                <div class="icon-soil-preparation"/>
                <span >
                    {{ displayTextActivityType }}
                </span>
            </div>
            <div
                class="app-modal-body"
                slot="body"
            >
                <div class="progress">
                    <div
                        class="progress-bar"
                        role="progressbar"
                        :style="`width: ${getPercentPage()}%`"
                        :aria-valuenow="getPercentPage()"
                        aria-valuemin="0"
                        aria-valuemax="100"
                    />
                    <div
                        class="progress-counter"
                        :style="`margin-left: ${getPercentPage(true)}%`"
                    >
                        {{ displayTextPage }}
                    </div>
                </div>
                <div v-show="page == 1">
                    <div>
                        <label
                            for="crop"
                            class="app-label subarea-selector__title"
                        >
                            Safra:
                        </label>
                        <span
                            id="crop"
                            class="subarea-selector__text"
                        >
                            {{ this.getCropSelected.name }}
                        </span>
                    </div>
                    <div class="content">
                        <div v-if="!isUpdateModal">
                            <subarea-selector
                                :uuid="UUIDSubareaSelector"
                                v-model="selectedSubareas"
                            />
                        </div>
                        <div v-else>
                            <label
                                class="app-label"
                                for="subareaSize"
                            >
                                Área utilizada (máx: {{ subareaSizeValue }} ha):
                            </label>
                            <input-number
                                id="subareaSize"
                                :data="fertilizationData.area"
                                @input="fertilizationData.area = $event"
                            />
                        </div>
                    </div>
                </div>
                <div
                    class="content"
                    v-show="page == 2"
                >
                    <div>
                        <label
                            class="app-label"
                            for="title"
                        >
                            Título
                        </label>
                        <input
                            class="app-input"
                            type="text"
                            id="title"
                            v-model="fertilizationData.title"
                            placeholder="Ex: 1ª Pulverização, 2ª Fungicida + 1ª Fertilizante"
                        >
                    </div>
                    <div>
                        <date-selector
                            :uuid="UUIDDateSelector"
                            v-model="fertilizationData.date"
                        />
                    </div>
                    <div>
                        <operator-selector
                            :uuid="UUIDOperatorSelector"
                            v-model="fertilizationData.operators"
                        />
                    </div>
                </div>
                <div
                    class="content"
                    v-show="page == 3"
                >
                    <div>
                        <culture-phase-selector
                            v-model="fertilizationData.culturePhaseId"
                        />
                    </div>
                    <div>
                        <stock-product-selector
                            :uuid="UUIDStockProductSelector"
                            :cultureId="this.getCropSelected.culture"
                            v-model="fertilizationData.stockProducts"
                        />
                    </div>
                </div>
                <div
                    class="content"
                    v-show="page == 4"
                >
                    <div>
                        <machinery-selector
                            :uuid="UUIDMachinerySelector"
                            v-model="fertilizationData.machineries"
                        />
                    </div>
                    <div>
                        <label
                            class="app-label"
                            for="obs"
                        >
                            Observações
                        </label>
                        <textarea
                            id="obs"
                            class="app-textarea"
                            v-model="fertilizationData.notes"
                            rows="4"
                        />
                    </div>
                </div>
            </div>
            <div
                class="app-modal-footer"
                slot="footer"
            >
                <button
                    class="btn btn-primary--outlined btn--flat"
                    v-show="!saving"
                    :disabled="this.page == 1"
                    @click="previousPage"
                >
                    <i class="far fa-arrow-alt-circle-left"/>
                    <span>
                        Voltar
                    </span>
                </button>
                <button
                    class="btn btn-primary btn--flat"
                    v-show="!saving"
                    :disabled="loadingData"
                    v-if="page < pages"
                    @click="nextPage"
                >
                    <span>Proximo</span>
                    <i class="far fa-arrow-alt-circle-right"/>
                </button>
                <button
                    class="btn btn-primary btn--flat"
                    v-show="!saving"
                    v-else
                    @click="saveFertilization"
                >
                    <span>
                        Salvar
                    </span>
                    <i class="fas fa-save"/>
                </button>
                <div
                    v-if="saving"
                    style="width: 200px; display: flex; justify-content: center"
                >
                    <div style="display: flex; justify-content: center">
                        <div
                            style="margin-bottom: 6px;"
                            class="spinner-border text-success"
                            role="status"
                        >
                            <span class="sr-only">
                                Saving...
                            </span>
                        </div>
                    </div>
                    <div style="margin-left: 10px; margin-top: 4px">
                        Salvando
                    </div>
                </div>
            </div>
        </Modal>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

import Modal from '@/components/systemUI/Modal'

import AppSelector from '@/components/commons/AppSelector.vue'
import MachinerySelector from '@/components/activities/components/machineries/MachinerySelector.vue'
import SubareaSelector from '@/components/activities/components/subareas/SubareaSelector.vue'
import DateSelector from '@/components/activities/components/dates/DateSelector.vue'
import OperatorSelector from '@/components/activities/components/operators/OperatorSelector.vue'
import StockProductSelector from '@/components/activities/components/stock-products/StockProductSelector.vue'
import CulturePhaseSelector from '@/components/activities/components/culture-phases/CulturePhaseSelector.vue'
import InputNumber from '@/components/commons/InputNumber.vue'

import _ from 'lodash';

export default {
    name: 'ModalFertilization',
    props: {
        data: Object,
        event: Object,
        open: Boolean,
        updateDate: String
    },
    components: {
        Modal,
        AppSelector,
        SubareaSelector,
        MachinerySelector,
        DateSelector,
        OperatorSelector,
        StockProductSelector,
        CulturePhaseSelector,
        InputNumber
    },
    data() {
        return {
            loadingData: false,
            saving: false,
            page: 1,
            pages: 4,
            selectedSubareas: [],
            fertilizationData: {
                date: [],
                area: null,
                subareaId: "",
                title: "",
                operators: [],
                machineries: [],
                notes: "",
                culturePhaseId: null,
                stockProducts: []
            },
            fertilizationOriginalData: {
                date: [],
                area: null,
                subareaId: "",
                title: "",
                operators: [],
                machineries: [],
                notes: "",
                culturePhaseId: null,
                stockProducts: []
            },
            originalActivityDates: [],
            originalActivityId: ''
        };
    },
    watch: {
        async open() {
            this.clearData()
            if (this.open) {
                await this.loadData();
                if (this.isUpdateModal) {
                    this.copyData();
                }
            }
        }
    },
    computed: {
        ...mapGetters("property", [ "getPropertySelected", "getCropSelected" ]),
        ...mapGetters('subareas', ['getSubareas']),
        displayTextActivityType() {
            if (this.isUpdateModal) {
                return "Edição de Fertilização"
            }

            return "Nova Fertilização"
        },
        subareaSizeValue() {
            return this.returnIfExists(this.data, "subarea.size.value") || 0;
        },
        displayTextPage() {
            return `${this.page}/${this.pages}`
        },
        hasSelectedSubareas() {
            return this.selectedSubareas && this.selectedSubareas.length > 0;
        },
        hasSelectedDates() {
            return this.fertilizationData.date && this.fertilizationData.date.length > 0;
        },
        isUpdateModal() {
            return this.data && this.data._id
        },
        UUIDMachinerySelector() {
            return this.isUpdateModal
                ? 'fertilization_edit_machinery'
                : 'fertilization_create_machinery'
        },
        UUIDOperatorSelector() {
            return this.isUpdateModal
                ? 'fertilization_edit_operator'
                : 'fertilization_create_operator'
        },
        UUIDDateSelector() {
            return this.isUpdateModal
                ? 'fertilization_edit_date'
                : 'fertilization_create_date'
        },
        UUIDSubareaSelector() {
            return this.isUpdateModal
                ? 'fertilization_edit_subarea'
                : 'fertilization_create_subarea'
        },
        UUIDStockProductSelector() {
            return this.isUpdateModal
                ? 'fertilization_edit_product'
                : 'fertilization_create_product'
        }
    },
    methods: {
        ...mapActions("activities", ['postFertilization', 'updateFertilization']),
        ...mapActions("units", ["findUnits"]),
        ...mapActions("unit-conversions", ["findUnitConversions"]),
        ...mapActions("culture-phases", ["findCulturePhases"]),
        ...mapActions('operators', ['setOperators', 'getOperators']),
        ...mapActions('machineries', ['setMachineries', 'getMachineries']),
        ...mapActions("product-categories", ['findProductCategories']),
        ...mapActions('stock-products', ['findStockProducts']),
        ...mapActions('subareas', ['setSubareas', 'findSubareas']),
        async loadData() {
            this.loadingData = true;

            const propertyId = this.getPropertySelected._id
            const cropId = this.getCropSelected._id

            await this.getOperators({ propertyId });
            await this.getMachineries({ propertyId });
            await this.findProductCategories({ propertyId });
            await this.findStockProducts({ propertyId });
            await this.findSubareas({ propertyId, cropId });
            await this.findCulturePhases();
            await this.findUnits();
            await this.findUnitConversions();

            this.loadingData = false;
        },
        nextPage() {
            if (this.page === 1) {
                if (!this.hasSelectedSubareas && !this.isUpdateModal) {
                    return this.$vToastify.info("Selecione uma safra e um talhão", "Atenção!")
                }

                if (this.isUpdateModal && (this.fertilizationData.area || 0) > this.subareaSizeValue) {
                    return this.$vToastify.info(`A área não pode ser maior que a subarea ${this.subareaSizeValue}ha.`, "Atenção!")
                }
            }

            if (this.page === 2) {
                if (!this.hasSelectedDates) {
                    return this.$vToastify.info("Selecione pelo menos uma data", "Atenção!")
                }
            }

            if (this.page < this.pages) {
                this.page++;
            }
        },
        previousPage() {
            if (this.page > 1) {
                this.page--;
            }
        },
        getPercentPage(counter = false) {
            if (!counter) {
                return (this.page / this.pages) * 100;
            } else {
                if (this.page == this.pages) {
                    return (this.page / this.pages) * 100 - 4;
                } else {
                    return (this.page / this.pages) * 100 - 2;
                }
            }
        },
        async saveFertilization() {
            this.saving = true;

            if (this.isUpdateModal) {
                if (this.originalActivityDates.length > 1) {
                    let formatDatesWithoutEditedDate = this.originalActivityDates.filter((dte)=>{
                        return dte.dateHour != this.updateDate.dateHour
                    })
                    
                    let activityToEdit = {date: formatDatesWithoutEditedDate, stockProducts: this.fertilizationOriginalData.stockProducts}
                    
                    this.fertilizationData._id = undefined

                    const requests = [this.updateFertilization({fertilizationId: this.originalActivityId, fertilization: activityToEdit}), this.postFertilization(this.fertilizationData)]
                    await Promise.all(requests)
                    .then(() => {
                        this.saving = false;
                        this.$vToastify.success("Atividade editada com sucesso!", "Fertilização")
                        this.$emit('done')
                    })
                    .catch(() => {
                        this.saving = false;
                        this.$vToastify.error("Não foi possivel editar a atividade, tente novamente!", "Fertilização")
                    });
                } else {
                    const response = await this.updateFertilization({ fertilizationId: this.fertilizationData._id, fertilization: this.fertilizationData })
                    const responseContainsError = response instanceof Error
    
                    if (!responseContainsError) {
                        this.$vToastify.success("Atividade salva com sucesso!", "Fertilização")
                        this.$emit('done')
                    }
                }
            }

            this.saving = false
        },
        copyData() {
            const subareaId = this.returnIfExists(this.data, "subarea._id") || null
            const culturePhaseId = this.returnIfExists(this.data, "culturePhase._id") || null

            const dataClone = _.cloneDeep(this.data)

            this.originalActivityDates = dataClone.date
            this.originalActivityId = dataClone._id

            this.fertilizationData = {
                _id: dataClone._id,
                date: [this.updateDate],
                subareaId: subareaId,
                operators: dataClone.operators,
                machineries: dataClone.machineries,
                notes: dataClone.notes,
                area: dataClone.area,
                culturePhaseId: culturePhaseId,
                title: dataClone.title,
                stockProducts: dataClone.stockProducts.map(stockProduct => ({
                    stockProductId: stockProduct.stockProduct._id,
                    quantity: {
                        value: stockProduct.quantity.value,
                        unitId: stockProduct.quantity.unit._id,
                        dosageId: stockProduct.quantity.dosage._id
                    },
                    approved: stockProduct.approved
                }))
            }
            this.fertilizationOriginalData = {
                _id: dataClone._id,
                date: dataClone.date,
                subareaId: subareaId,
                operators: dataClone.operators,
                machineries: dataClone.machineries,
                notes: dataClone.notes,
                area: dataClone.area,
                culturePhaseId: culturePhaseId,
                title: dataClone.title,
                stockProducts: dataClone.stockProducts.map(stockProduct => ({
                    stockProductId: stockProduct.stockProduct._id,
                    quantity: {
                        value: stockProduct.quantity.value,
                        unitId: stockProduct.quantity.unit._id,
                        dosageId: stockProduct.quantity.dosage._id
                    },
                    approved: stockProduct.approved
                }))
            }
        },
        clearData() {
            this.setSubareas([]);
            this.setOperators([]);
            this.setMachineries([]);
            Object.assign(this.$data, this.$options.data.apply(this));
        }
    },
    async mounted() {
        this.clearData()
        if (this.open) {
            await this.loadData();
            if (this.isUpdateModal) {
                this.copyData();
            }
        }
    }
};
</script>

<style scoped lang="sass">
.icon-fertilization
    mask: url("/img/icons/svg/fertilization.svg") no-repeat 50% 50%
    @include icon-mask-sm

.content
    display: flex
    flex-direction: column
    grid-gap: 20px

    .description
        font-size: 16px

    .loading
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        color: $color-blue

    .label-description
        margin-left: 6px
        font-size: 10px

    .box
        display: flex
        flex-direction: row
        justify-content: space-between
        align-items: flex-start
        flex-wrap: wrap
        width: 100%

    .box-c
        display: flex
        flex-direction: row
        height: 30px
        width: 180px
        margin-bottom: 10px

</style>
