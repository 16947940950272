<template>
    <div class="dropdown">
        <label class="app-label" v-if="title">{{ title }}</label>
        <button class="app-select dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
            <span v-if="hint">{{ hint }}</span>
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <slot name="body"/>
            <div v-for="item in items" :key="`droplist_${item._id}`" class="drop-item"
                 @click="selectItem(item)">
                <span>{{ item.name }}</span>
                <i class="material-icons">add</i>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Droplist',
    props: {
        title: String,
        hint: String,
        items: Array
    },
    methods: {
        selectItem(item) {
            this.$emit('select', item)
        }
    }
}
</script>

<style scoped lang="sass">
.dropdown-toggle
    display: flex
    justify-content: space-between
    align-items: center

.dropdown-menu
    width: 100%
    padding: 8px 6px
    max-height: 200px
    overflow: auto

.drop-item
    display: flex
    justify-content: space-between
    align-items: center
    height: 30px

    &:hover
        cursor: pointer
        background: $color-white-darken-1

    span
        text-overflow: ellipsis
        white-space: nowrap
        overflow: hidden

    i
        font-size: 20px
        color: $color-blue

</style>
