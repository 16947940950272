import API from './API';

export default {

    getAll(id) {
      return API.get(`/properties/${id}/activities`)
    },

    getAllAcitivities(propertyId, cropId) {
      return API.get(`/properties/${propertyId}/crops/${cropId}/activities`)
    },

    getActivityReport(propertyId, cropId){
      return API.get(`/reports/crops/properties/${propertyId}/crops/${cropId}`)
    },

    completeActivityPlanting(data) {
      return API.put(`plantings/${data._id}`, data);
    },
    completeActivityHarvest(data) {
      return API.put(`harvests/${data._id}`, data);
    },
    completeActivityPulverization(data) {
      return API.put(`pulverizations/${data._id}`, data);
    },

    convertHTMLtoPDF(propertyId, cropId, startDate, endDate){
        return API.get(`reports/activities/properties/${propertyId}/crops/${cropId}?startDate=${startDate}&endDate=${endDate}`)
            .then(response => response)
            .catch(({ response }) => response)
    }

}