<template>
    <Modal :open="open" :loading="loading" @close="$emit('close')">
        <div class="app-modal-header" slot="header">
            <i class="fas fa-eye"></i>
            <span>{{ 'Visualizar' }}</span>
        </div>
        <div class="app-modal-body" slot="body">
            <div class="main-content content">
                <div class="main-content__card content">
                    <h4 class="content__title">
                        Produto
                    </h4>
                    <div class="content__item content-item">
                        <label class="app-label content-item__title">
                            Categoria:
                        </label>
                        <span class="content-item__text">
                            {{ displayTextProductCategory }}
                        </span>
                    </div>
                    <div class="content__item content-item">
                        <label class="app-label content-item__title">
                            Subcategoria:
                        </label>
                        <span class="content-item__text">
                            {{ displayTextProductSubCategory }}
                        </span>
                    </div>
                    <div class="content__item content-item">
                        <label class="app-label content-item__title">
                            Nome:
                        </label>
                        <span class="content-item__text">
                            {{ displayTextProductName }}
                        </span>
                    </div>
                </div>
                <div class="main-content__card content">
                    <h4 class="content__title">
                        Movimentação
                    </h4>
                    <div class="content__item content-item">
                        <label class="app-label content-item__title">
                            Data da movimentação:
                        </label>
                        <span class="content-item__text">
                            {{ displayTextDate }}
                        </span>
                    </div>
                    <div class="content__item content-item">
                        <label class="app-label content-item__title">
                            Origem da movimentação:
                        </label>
                        <span class="content-item__text">
                            {{ displayTextMovementType }}
                        </span>
                    </div>
                    <div
                        v-if="!isManualMovementType"
                        class="content"
                    >
                        <div class="content__item content-item">
                            <label class="app-label content-item__title">
                                Talhão:
                            </label>
                            <span class="content-item__text">
                                {{ displayTextField }}
                            </span>
                        </div>
                        <div class="content__item content-item">
                            <label class="app-label content-item__title">
                                Área utilizada (ha):
                            </label>
                            <span class="content-item__text">
                                {{ displayTextArea }}
                            </span>
                        </div>
                        <div class="content__item content-item">
                            <label class="app-label content-item__title">
                                Atividade:
                            </label>
                            <span class="content-item__text">
                                {{ displayTextActivityType  }}
                            </span>
                        </div>
                        <div class="content__item content-item">
                            <label class="app-label content-item__title">
                                Datas da atividade:
                            </label>
                            <span class="content-item__text">
                                {{ displayTextActivityDates }}
                            </span>
                        </div>
                        <div
                            v-if="isSeedTreatmentActivity"
                            class="content__item content-item"
                        >
                            <label class="app-label content-item__title">
                                Semente tratada:
                            </label>
                            <span class="content-item__text">
                                {{ displayTextSeedToTreatName }}
                            </span>
                        </div>
                        <div
                            v-if="isSeedTreatmentActivity"
                            class="content__item content-item"
                        >
                            <label class="app-label content-item__title">
                                Quantidade de sementes:
                            </label>
                            <span class="content-item__text">
                                {{ displayTextSeedToTreatQuantity }}
                            </span>
                        </div>
                        <div class="content__item content-item">
                            <label class="app-label content-item__title">
                                Dosagem:
                            </label>
                            <span class="content-item__text">
                                {{ displayTextDosage  }}
                            </span>
                        </div>
                    </div>
                    <div class="content__item content-item">
                        <label class="app-label content-item__title">
                            Tipo:
                        </label>
                        <span class="content-item__text">
                            {{ displayTextAction }}
                        </span>
                    </div>
                    <div class="content__item content-item">
                        <label class="app-label content-item__title">
                            Quantidade calculada:
                        </label>
                        <span class="content-item__text">
                            {{ displayTextCalculatedTotalQuantity  }}
                        </span>
                    </div>
                    <div class="content__item content-item">
                        <label class="app-label content-item__title">
                            Quantidade movimentada:
                        </label>
                        <span
                            class="content-item__text"
                            :style="colorRealTotalQuantity"
                        >
                            {{ displayTextRealTotalQuantity  }}
                        </span>
                    </div>
                    <div class="content__item content-item">
                        <label class="app-label content-item__title">
                            Saldo:
                        </label>
                        <span class="content-item__text">
                            {{ displayTextBalance }}
                        </span>
                    </div>
                    <div v-if="isManualMovementType" class="content__item content-item">
                        <label class="app-label content-item__title">
                            Observações:
                        <i class="far fa-pen clickable" @click.stop="openEditNotes"></i>
                        </label>
                        <span v-if="!isEditingNotes" class="content-item__text content-item__text--notes">
                            {{ stockRegister.notes }}
                        </span>
                        <span v-else class="content-item__text content-item__text--notes">
                            <textarea class="app-inline-input" v-model="stockRegister.notes" autofocus></textarea>
                            <i class="fal fa-check clickable pl-2" @click="saveNotes"></i>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </Modal>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Modal from "@/components/systemUI/Modal.vue";

import _ from "lodash"

export default {
    name: 'ModalHistoryDetail',
    props: {
        data: Object,
        open: Boolean,
    },
    components: {
        Modal
    },
    data() {
        return {
            loading: false,
            stockRegister: {},
            isEditingNotes: false,
        };
    },
    computed: {
        ...mapGetters("unit-conversions", ["getUnitConversions"]),
        ...mapGetters("property", ["getPropertySelected"]),
        displayTextMovementType() {
            return  this.isManualMovementType
                ? 'Manual'
                : 'Lançamento'
        },
        displayTextProductCategory() {
            const productCategory = this.returnIfExists(this.stockRegister, 'stockProduct.product.productCategory');
            return this.formatProductCategory(productCategory) || '-'
        },
        displayTextProductSubCategory() {
            const productCategory = this.returnIfExists(this.stockRegister, 'stockProduct.product.productCategory');
            return this.formatProductSubCategory(productCategory) || '-'
        },
        displayTextProductName() {
            const productName = this.returnIfExists(this.stockRegister, 'stockProduct.product.name');

            return productName || '-'
        },
        displayTextDate() {
            const date = this.returnIfExists(this.stockRegister, 'date')
            if (!date) {
                return '-'
            }

            return this.formatDate(date)
        },
        displayTextField() {
            const fieldName = this.returnIfExists(this.stockRegister, 'subarea.field.name');
            if (!fieldName) {
                return '-'
            }

            const fieldSize = this.returnIfExists(this.stockRegister, 'subarea.field.size.value') || 0;
            const fieldSizeFormatted = this.formatNumber(fieldSize)

            if (!fieldSize) {
                return fieldName
            }

            return `${fieldName} (${fieldSizeFormatted} ha)`
        },
        displayTextArea() {
            const activityArea = this.returnIfExists(this.stockRegister, 'area');
            if (!activityArea && activityArea !== 0) {
                return '-'
            }

            return this.formatNumber(activityArea);
        },
        displayTextActivityType() {
            const activityType = this.returnIfExists(this.stockRegister, 'activityType');
            if (!activityType) {
                return '-'
            }

            const activityTypes = {
                'pulverization': 'Pulverização',
                'harvest': 'Colheita',
                'planting': 'Plantio',
                'soilPreparation': 'Preparo de solo',
                'seedTreatment': 'Tratamento de sementes',
                'fertilization': 'Fertilização',
                'otherActivity': 'Outra atividade'
            }

            return activityTypes[activityType] || '-'
        },
        displayTextActivityDates() {
            const activityDates = this.returnIfExists(this.stockRegister, 'activity.date') || []
            if (!activityDates || !activityDates.length) {
                return '-'
            }

            return activityDates.map(({ dateHour }) => this.formatDate(dateHour)).join(', ')
        },
        displayTextDosage () {
            const quantityValue = this.returnIfExists(this.stockRegister, 'quantity.value') || 0;
            if (!quantityValue) {
                return '-'
            }

            const unitName = this.returnIfExists(this.stockRegister, 'quantity.unit.name');
            if (!unitName) {
                return '-'
            }

            const dosageName = this.returnIfExists(this.stockRegister, 'quantity.dosage.name');
            if (!dosageName) {
                return '-'
            }

            const quantityValueFormatted = this.formatNumber(quantityValue);
            return `${quantityValueFormatted} ${unitName}/${dosageName}`
        },
        displayTextCalculatedTotalQuantity() {
            if (!this.calculatedTotalQuantity && this.calculatedTotalQuantity !== 0) {
                return '-'
            }

            const productUnit = this.returnIfExists(this.stockRegister, 'stockProduct.product.unit.name')
            if (!productUnit) {
                return '-'
            }

            const calculatedTotalQuantityFormatted = this.formatNumber(this.calculatedTotalQuantity)

            return `${calculatedTotalQuantityFormatted} ${productUnit}`
        },
        displayTextRealTotalQuantity() {
            if (!this.realTotalQuantity && this.realTotalQuantity !== 0) {
                return '-'
            }

            const productUnit = this.returnIfExists(this.stockRegister, 'stockProduct.product.unit.name')
            if (!productUnit) {
                return '-'
            }

            const sign = this.stockRegister.action === 'exit' ? '-' : '+';

            const realTotalQuantityFormatted = this.formatNumber(this.realTotalQuantity)

            return `${sign} ${realTotalQuantityFormatted} ${productUnit}`
        },
        displayTextSeedToTreatName() {
            const seedToTreatName = this.returnIfExists(this.stockRegister, 'activity.seedToTreat.stockProduct.product.name');
            if (!seedToTreatName) {
                return '-'
            }

            return seedToTreatName
        },
        displayTextSeedToTreatQuantity() {
            const seedToTreatQuantity = this.returnIfExists(this.stockRegister, 'activity.seedToTreat.quantity.value');
            const seedToTreatUnit = this.returnIfExists(this.stockRegister, 'activity.seedToTreat.quantity.unit.name');
            if (!seedToTreatQuantity || !seedToTreatUnit) {
                return '-'
            }

            return `${seedToTreatQuantity} ${seedToTreatUnit}`
        },
        displayTextAction() {
            const action = this.returnIfExists(this.stockRegister, 'action');
            if (!action) {
                return '-'
            }

            const actions = {
                'entrance': 'Entrada',
                'exit': 'Saída',
                'Entrada': 'entrance',
                'Saída': 'exit'
            }

            return actions[action] || '-';
        },
        displayTextBalance() {
            const balance = this.returnIfExists(this.stockRegister, 'balance') || 0;
            if (!balance) {
                return '-'
            }

            const productUnit = this.returnIfExists(this.stockRegister, 'stockProduct.product.unit.name')
            if (!productUnit) {
                return '-'
            }

            const balanceFormatted = this.formatNumber(balance)

            return `${balanceFormatted} ${productUnit}`
        },
        calculatedTotalQuantity() {
            return this.stockRegister.calculatedTotalQuantity;
        },
        realTotalQuantity() {
            return this.stockRegister.realTotalQuantity;
        },
        isManualMovementType() {
            return !this.returnIfExists(this.stockRegister, 'activityType');
        },
        isSeedTreatmentActivity() {
            const activityType = this.returnIfExists(this.stockRegister, 'activityType')
            return activityType == 'seedTreatment'
        },
        colorRealTotalQuantity() {
            return {
                color: (
                    this.stockRegister.action === 'entrance'
                    ? 'green'
                    : 'red'
                )
            }
        }
    },
    methods: {
        ...mapActions("unit-conversions", ["findUnitConversions"]),
        ...mapActions("stock-register", ["updateNotesStockRegister", "findStockRegisters"]),
        async openEditNotes(){
            this.isEditingNotes = true

        },
        async saveNotes(){
            this.isEditingNotes = false
            const { stockRegisterId, notes } = {
                stockRegisterId: this.stockRegister._id,
                notes: this.stockRegister.notes
            }
            this.loading = true
            await this.updateNotesStockRegister({stockRegisterId, notes})
            await this.findStockRegisters({ propertyId: this.getPropertySelected._id })
            this.loading = false
        }
    },
    async mounted() {
        this.loading = true

        await this.findUnitConversions();

        this.stockRegister = _.cloneDeep(this.data)

        this.stockRegister.notes = this.stockRegister.notes || '-'

        this.loading = false
    }
};
</script>

<style scoped lang="sass">
hr
    margin: 16px 0

.main-content
    min-width: 500px
    width: 100%

    &__card
        margin: 8px
        padding: 8px
        box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.1)
        transition: 0.5s

        &:hover
            box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.3)

.content
    display: flex
    width: 100%
    flex-wrap: wrap

    &__title
        width: 100%
        padding: 4px

    &__item
        padding: 4px
        width: 50%

        &--full-width
            width: 100%

    .filter-content
        width: 100%
        border: 1px solid #d6d6d6
        border-radius: 12px
        margin: 4px
        padding: 10px 4px
        display: flex
        flex-wrap: wrap

        &__title
            padding: 4px
            color: #d6d6d6
            width: 100%

        &__item
            padding: 4px
            width: 50%

.stock-products-history-table
    width: 100%

    th
        color: $color-blue
        padding: 0 10px
        font-size: 16px
        width: 16.6666%

    tr
        border-radius: 20px
        height: 70px

        &:nth-child(even)
            background-color: #f2f2f2

    td
        padding: 0 10px
        width: 16.6666%

.content-item
    width: 100%
    display: flex

    &__text
        flex: 1

        &--notes
            white-space: pre-line

    &__title
        flex: 1
        text-overflow: ellipsis
        white-space: nowrap
        overflow: hidden

.no-content
    display: flex
    justify-content: center
    align-items: center
    height: 100px
    color: $color-grey
    grid-gap: 10px

@media (max-width: 500px)
    .content
        &__item
            width: 100%


</style>
