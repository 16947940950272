<template>
    <div id="map" style="height: 85vh;"></div>
</template>

<script>
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import BingMapPlugin from "@/utils/bingMapPlugin.js"; 
import { drawPolygon } from "@/utils/drawPolygons";

export default {
    name: "FieldOverviewMap",
    props: {
        field: {
            type: Object,
        },
    },
    data() {
        return {
            map: null,
            polygonLayer: null,
            latitude: "",
            longitude: "",
            zoom: 14,
        };
    },
    mounted() {
        this.initializeMap();
    },
    methods: {
        initializeMap() {
            if (this.field.polygons && this.field.polygons.length > 0) {
                this.latitude = this.field.polygons[0].latitude;
                this.longitude = this.field.polygons[0].longitude;
            } else {
                this.latitude = 0;
                this.longitude = 0;
            }

            this.map = L.map('map').setView([this.latitude, this.longitude], this.zoom);

            const bingLayer = new BingMapPlugin({
                bingMapsKey: process.env.VUE_APP_BING_MAPS_KEY,
                imagerySet: 'Aerial',
            }).addTo(this.map);

            if (this.field.polygons && this.field.polygons.length > 0) {
                const polygonData = drawPolygon(this.field);
                this.polygonLayer = L.polygon(polygonData.latlngs, {
                    color: polygonData.color,
                    fillColor: polygonData.fillColor,
                }).addTo(this.map);
                this.polygonLayer.bindPopup(polygonData.text);
            }
        },
    },
    watch: {
        field() {
            if (this.field.polygons && this.field.polygons.length > 0) {
                this.latitude = this.field.polygons[0].latitude;
                this.longitude = this.field.polygons[0].longitude;
                this.map.setView([this.latitude, this.longitude], this.zoom);

                if (this.polygonLayer) {
                    this.polygonLayer.remove();
                }

                const polygonData = drawPolygon(this.field);
                this.polygonLayer = L.polygon(polygonData.latlngs, {
                    color: polygonData.color,
                    fillColor: polygonData.fillColor,
                }).addTo(this.map);
                this.polygonLayer.bindPopup(polygonData.text);
            }
        },
    }
};
</script>
