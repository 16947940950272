import API from './API';
import querystring from 'querystring';

export default {
    getAll(propertyId, active) {
        const query = querystring.encode({
            propertyId,
            ...active != undefined ? { active } : {}
        })
        return API.get(`operators?${query}`)
    },

    get(id) {
        return API.get(`operators/${id}`)
    },

    post(data) {
        return API.post("operators", data)//, {params: {token}})
    },

    update(data) {
        return API.put(`operators/${data._id}`, data)
    }
}