const mixin = {
    methods: {
        circularList(newIndex, length) {
            const mod = newIndex % length
            newIndex = mod >= 0 ? mod : length + mod
            return newIndex
        }
    }
}

export default mixin