import API from './API';
import querystring from 'querystring'

export default {
    findCropReport({ propertyId, cropId, fieldIdList, startDate, endDate }) {
        return API.get('/reports/crops', {
            params: {
                ... propertyId ? { propertyId } : {},
                ... cropId ? { cropId } : {},
                ... fieldIdList && fieldIdList.length ? { fieldIdList: JSON.stringify(fieldIdList) } : {},
                ... startDate ? { startDate } : {},
                ... endDate ? { endDate } : {}
            }
        })
    },
    findMonitoringReport({ propertyId, cropId, fieldIdList, startDate, endDate, pestilenceTypes, includePhotos }) {
        return API.get('/reports/monitoring', {
            params: {
                ... propertyId ? { propertyId } : {},
                ... cropId ? { cropId } : {},
                ... fieldIdList && fieldIdList.length ? { fieldIdList: JSON.stringify(fieldIdList) } : {},
                ... startDate ? { startDate } : {},
                ... endDate ? { endDate } : {},
                ... pestilenceTypes && pestilenceTypes.length ? { pestilenceTypes: JSON.stringify(pestilenceTypes) } : {},
                ... includePhotos != undefined ? { includePhotos: !!includePhotos } : {}
            }
        })
    },
    findStockReport({ propertyId, cropId }) {
        return API.get('/reports/stock', {
            params: {
                ... propertyId ? { propertyId } : {},
                ... cropId ? { cropId } : {}
            }
        })
    }
}
