<template>
    <div class="stock-movement-container">
        <div class="stock-movement-container__header">
            <span class="title">Movimentação</span>
            <div class="actions">
                <div class="search-container app-input">
                    <input class="search-container__input" placeholder="Pesquise um produto do estoque"
                           v-model="searchWord" type="text"/>
                    <button class="search-container__clear clickable" v-show="searchWord.length > 0"
                            @click="clearSearch">
                        <i class="fal fa-times-circle"/>
                    </button>
                    <button class="search-container__search" @click="">
                        <i class="fas fa-search"/>
                    </button>
                </div>
            </div>
        </div>
        <div v-if="!loading" class="stock-movement-container__content">
            <table class="stock-products-table">
                <thead>
                    <tr>
                        <th>Nome</th>
                        <th>Quantidade</th>
                        <th>Categoria</th>
                        <th>Sub Categoria</th>
                        <th class="stock-products-table-header__actions">Ações</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="stockProduct in stockProducts" :key="stockProduct._id">
                        <td> {{ stockProduct.product.name }} </td>
                        <td> {{ stockProduct.quantity ? `${formatNumber(stockProduct.quantity)} ${stockProduct.product.unit.name}` : '-' }} </td>
                        <td> {{ formatProductCategory(stockProduct.product.productCategory) || '-' }} </td>
                        <td> {{ formatProductSubCategory(stockProduct.product.productCategory) || '-' }} </td>
                        <td>
                            <div class="stock-products-table-content__actions">
                                <button @click="openModalMoveStock(stockProduct)"
                                        class="btn btn--flat">
                                    <i class="fas fa-edit"></i>
                                    <span>Movimentar</span>
                                </button>
                                <button @click="openModalStockProductHistory(stockProduct)"
                                        class="btn btn--flat">
                                    <i class="fas fa-list"></i>
                                    <span>Histórico</span>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div v-show="stockProducts.length == 0" class="no-content">
                <i class="fa fa-exclamation-circle fa-2x"/>
                <span class="">Não foram encontrados produtos no estoque</span>
            </div>
        </div>
        <div v-else class="no-content">
            <span class="spinner-border" />
        </div>
        <modal-move-stock v-if="showModalMoveStock" :open="showModalMoveStock" @close="closeModalMoveStock" @save="loadStockProducts" :data="selectedStockProduct">
        </modal-move-stock>
        <modal-stock-product-history v-if="showModalStockProductHistory" :open="showModalStockProductHistory" @close="closeModalStockProductHistory" :data="selectedStockProduct">
        </modal-stock-product-history>
    </div>
</template>

<script>

import { mapActions, mapGetters } from "vuex";
import ModalMoveStock from "@/views/stock/movement/components/ModalMoveStock.vue"
import ModalStockProductHistory from "@/views/stock/movement/components/ModalStockProductHistory.vue"

export default {
    name: 'Movement',
    components: {
        ModalMoveStock,
        ModalStockProductHistory
    },
    data() {
        return {
            stockProducts: [],
            selectedStockProduct: {},
            loading: false,
            showModalMoveStock: false,
            showModalStockProductHistory: false,
            searchWord: ''
        }
    },
    computed: {
        ...mapGetters("property", ["getPropertySelected"]),
        ...mapGetters("stock-products", ["getStockProducts"])
    },
    watch: {
        async getPropertySelected() {
            await this.loadStockProducts()
        },
        searchWord() {
            this.filterStockProducts()
        }
    },
    methods: {
        ...mapActions("stock-products", ["findStockProducts"]),
        ...mapActions("unit-conversions", ["findUnitConversions"]),
        openModalStockProductHistory(stockProduct) {
            this.selectedStockProduct = stockProduct
            this.showModalStockProductHistory = true
        },
        closeModalStockProductHistory() {
            this.showModalStockProductHistory = false
        },
        openModalMoveStock(stockProduct) {
            this.selectedStockProduct = stockProduct
            this.showModalMoveStock = true
        },
        closeModalMoveStock() {
            this.showModalMoveStock = false
        },
        filterStockProducts(){
            this.stockProducts = this.getStockProducts.filter((stockProduct) => {
                const stockProductQuantityString =  stockProduct.quantity ? `${this.formatNumber(stockProduct.quantity)} ${stockProduct.product.unit.name}` : "-"
                return (
                    stockProduct.product.name.toUpperCase().includes(this.searchWord.toUpperCase()) ||
                    stockProduct.product.unit.name.toUpperCase().includes(this.searchWord.toUpperCase()) ||
                    stockProduct.product.productCategory && stockProduct.product.productCategory.name.toUpperCase().includes(this.searchWord.toUpperCase()) ||
                    stockProduct.product.productCategory && stockProduct.product.productCategory.parentProductCategory && stockProduct.product.productCategory.parentProductCategory.name.toUpperCase().includes(this.searchWord.toUpperCase()) ||
                    stockProductQuantityString.toUpperCase().includes(this.searchWord.toUpperCase())

                )
            })
        },
        async loadStockProducts() {
            this.loading = true

            this.stockProducts = []
            await this.findUnitConversions()
            await this.findStockProducts({ propertyId: this.getPropertySelected._id, active: true })
            this.stockProducts = this.getStockProducts

            this.loading = false
        },
        async clearSearch() {
            this.searchWord = ''
            this.stockProducts = [... this.getStockProducts.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1)]
        }
    },
    async mounted() {
        await this.loadStockProducts()
    }
}
</script>

<style lang="sass" scoped>
.stock-movement-container
    display: flex
    flex-direction: column
    grid-gap: 6px
    width: 100%

    &__header
        .title
            font-size: 24px
            color: $theme-color-primary

        .actions
            display: flex
            justify-content: space-between

            .search-container
                display: flex
                justify-content: space-between
                width: 300px

                &__input
                    width: 250px
                    border: 0

                &__search
                    border: 0
                    width: 25px
                    background: none

                &__clear
                    border: 0
                    width: 25px
                    background: none

.stock-products-table
    width: 100%

    &-header__actions
        width: 30%
        text-align: center

    &-content__actions
        display: flex
        grid-gap: 6px
        align-items: center
        justify-content: center

        .btn
            background: none

            &:hover
                background: $color-white-darken-1

            i
                color: $color-blue

    th
        color: $color-blue
        padding: 0 10px
        font-size: 16px
        width: 16.6666%

    tr
        border-radius: 20px
        height: 70px

        &:nth-child(even)
            background-color: #f2f2f2

    td
        padding: 0 10px
        width: 16.6666%

    thead
        position: sticky
        top: -10px
        background: $color-white
        z-index: 1

.no-content
    display: flex
    justify-content: center
    align-items: center
    height: 100px
    color: $color-grey
    grid-gap: 10px

@media (max-width: 430px)
    .stock-movement-container__header .actions .search-container__input
        width: 200px

</style>
