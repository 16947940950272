<template>
    <div class="weather-container">
        <div class="tab">
            <router-link class="tab__item" tag="a" to="/tempo/mapa-windy">
                Mapa
            </router-link>
            <router-link class="tab__item" tag="a" to="/tempo/previsao-do-tempo">
                Previsão do tempo
            </router-link>
        </div>
        <div class="weather-container__content">
            <router-view />
        </div>
    </div>
</template>
<script>
export default {
    name: 'Weather',
};
</script>

<style scoped lang="sass">
.tab
    height: 45px
    display: flex
    align-items: center
    justify-content: center
    margin-bottom: 5px
    border-bottom: 1px solid #D6D6D6

    &__item
        line-height: 40px
        padding: 0 25px
        color: $color-grey
        font-size: 20px
        height: 100%
        margin-bottom: -1px

.weather-container
    &__content
        height: calc(100% - 50px)

.router-link-exact-active
    color: $color-blue
    border-bottom: 1px solid $color-blue
</style>
