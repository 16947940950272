<template>
    <div>
        <carousel :per-page="1">
            <slide v-for="(ndvi,index) in imagesNDVI" :key="ndvi._id">
                <img @click="openLightboxOnSlide(index)" :src="ndvi.image" class="ndvi-image" alt=""/>
                <div>
                    <p>Capturado em {{ ndvi.captured | formatDate }}</p>
                </div>
            </slide>
        </carousel>

        <FsLightbox :toggler="toggler" :sourceIndex="slide" :sources="imagesNDVI.image"/>
    </div>
</template>

<script>
import {Carousel, Slide} from "vue-carousel";
import FsLightbox from "fslightbox-vue";

export default {
    name: "FieldOverviewNDVI",
    components: {Carousel, Slide, FsLightbox},
    props: {
        imagesNDVI: {
            type: Array,
        },
    },
    data() {
        return {
            toggler: false,
            slide: 0,
        };
    },
    methods: {
        openLightboxOnSlide(number) {
            this.slide = number;
            this.toggler = !this.toggler;
        },
    },
};
</script>

<style scoped lang="sass">
.ndvi-image
    width: 300px
    height: 300px
    object-fit: contain
</style>
