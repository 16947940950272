<template>
    <div class="activity-container">
        <modal
            :open="open"
            :loading="false"
            @close="$emit('close')"
        >
            <div
                class="app-modal-header"
                slot="header"
            >
                <i class="fas fa-tasks"/>
                <span>
                    Nova Atividade
                </span>
            </div>
            <div
                class="app-modal-body activity-container__modal-body"
                slot="body"
            >
                <span class="body-title">
                    Para criar uma nova atividade, escolha dentre as opções abaixo:
                </span>
                <div class="body-container">
                    <div
                        class="app-card clickable"
                        @click="openModalSoilPreparation"
                    >
                        <div class="app-card__icon">
                            <div class="icon icon-soil-preparation icon-mask-size"/>
                        </div>
                        <div class="app-card__title">
                            <span>
                                Preparo de solo
                            </span>
                        </div>
                    </div>
                    <div
                        class="app-card clickable"
                        @click="openModalSeedTreatment"
                    >
                        <div class="app-card__icon">
                            <div class="icon icon-seed-treatment icon-mask-size"/>
                        </div>
                        <div class="app-card__title">
                            <span>
                                Tratamento de sementes
                            </span>
                        </div>
                    </div>
                    <div
                        class="app-card clickable"
                        @click="openModalFertilization"
                    >
                        <div class="app-card__icon">
                            <div class="icon icon-fertilization icon-mask-size"/>
                        </div>
                        <div class="app-card__title">
                            <span>
                                Fertilização
                            </span>
                        </div>
                    </div>
                    <div
                        class="app-card clickable"
                        @click="openModalPlanting"
                    >
                        <div class="app-card__icon">
                            <div class="icon icon-planting icon-mask-size"/>
                        </div>
                        <div class="app-card__title">
                            <span>
                                Plantio
                            </span>
                        </div>
                    </div>
                    <div
                        class="app-card clickable"
                        @click="openModalPulverization"
                    >
                        <div class="app-card__icon">
                            <div class="icon icon-pulverization icon-mask-size"/>
                        </div>
                        <div class="app-card__title">
                            <span>
                                Pulverização
                            </span>
                        </div>
                    </div>
                    <div
                        class="app-card clickable"
                        @click="openModalHarvest"
                    >
                        <div class="app-card__icon">
                            <div class="icon icon-harvest icon-mask-size"/>
                        </div>
                        <div class="app-card__title">
                            <span>
                                Colheita
                            </span>
                        </div>
                    </div>
                    <div
                        class="app-card clickable"
                        @click="openModalOtherActivity"
                    >
                        <div class="app-card__icon">
                            <div class="icon icon-other-activity icon-mask-size"/>
                        </div>
                        <div class="app-card__title">
                            <span>
                                Outra atividade
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </modal>
        <modal-planting
            v-if="showModalPlanting"
            :open="showModalPlanting"
            @done="emitDoneAndClose"
            @close="closeModalPlanting"
        />
        <modal-pulverization
            v-if="showModalPulverization"
            :open="showModalPulverization"
            @done="emitDoneAndClose"
            @close="closeModalPulverization"
        />
        <modal-harvest
            v-if="showModalHarvest"
            :open="showModalHarvest"
            @done="emitDoneAndClose"
            @close="closeModalHarvest"
        />
        <modal-soil-preparation
            v-if="showModalSoilPreparation"
            :open="showModalSoilPreparation"
            @done="emitDoneAndClose"
            @close="closeModalSoilPreparation"
        />
        <modal-seed-treatment
            v-if="showModalSeedTreatment"
            :open="showModalSeedTreatment"
            @done="emitDoneAndClose"
            @close="closeModalSeedTreatment"
        />
        <modal-fertilization
            v-if="showModalFertilization"
            :open="showModalFertilization"
            @done="emitDoneAndClose"
            @close="closeModalFertilization"
        />
        <modal-other-activity
            v-if="showModalOtherActivity"
            :open="showModalOtherActivity"
            @done="emitDoneAndClose"
            @close="closeModalOtherActivity"
        />
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import ModalPlanting from '@/components/activities/ModalPlanting'
import ModalPulverization from '@/components/activities/ModalPulverization'
import ModalHarvest from '@/components/activities/ModalHarvest'
import ModalSoilPreparation from '@/components/activities/ModalSoilPreparation'
import ModalSeedTreatment from '@/components/activities/ModalSeedTreatment'
import ModalFertilization from '@/components/activities/ModalFertilization'
import ModalOtherActivity from '@/components/activities/ModalOtherActivity'
import Modal from '@/components/systemUI/Modal'

export default {
    name: 'ModalSelectActivity',
    components: {
        Modal,
        ModalPlanting,
        ModalPulverization,
        ModalHarvest,
        ModalSoilPreparation,
        ModalSeedTreatment,
        ModalFertilization,
        ModalOtherActivity
    },
    props: {
        open: Boolean
    },
    data() {
        return {
            showModalPlanting: false,
            showModalPulverization: false,
            showModalHarvest: false,
            showModalSoilPreparation: false,
            showModalSeedTreatment: false,
            showModalFertilization: false,
            showModalOtherActivity: false
        }
    },
    methods: {
        openModalPlanting() {
            this.showModalPlanting = true
        },
        closeModalPlanting() {
            this.showModalPlanting = false
        },
        openModalPulverization() {
            this.showModalPulverization = true
        },
        closeModalPulverization() {
            this.showModalPulverization = false
        },
        openModalHarvest() {
            this.showModalHarvest = true
        },
        closeModalHarvest() {
            this.showModalHarvest = false
        },
        openModalSoilPreparation() {
            this.showModalSoilPreparation = true
        },
        closeModalSoilPreparation() {
            this.showModalSoilPreparation = false
        },
        openModalSeedTreatment() {
            this.showModalSeedTreatment = true
        },
        closeModalSeedTreatment() {
            this.showModalSeedTreatment = false
        },
        openModalFertilization() {
            this.showModalFertilization = true
        },
        closeModalFertilization() {
            this.showModalFertilization = false
        },
        openModalOtherActivity() {
            this.showModalOtherActivity = true
        },
        closeModalOtherActivity() {
            this.showModalOtherActivity = false
        },
        closeAll() {
            this.closeModalHarvest()
            this.closeModalPlanting()
            this.closeModalPulverization()
            this.closeModalSoilPreparation()
            this.closeModalSeedTreatment()
            this.closeModalFertilization()
            this.closeModalOtherActivity()

        },
        emitDoneAndClose() {
            this.closeAll();
            this.$emit('done');
            this.$emit('close');
        }
    }
};
</script>

<style scoped lang="sass">
.activity-container
    p
        margin: 0

    &__modal-body
        display: flex
        justify-content: center
        align-items: center

        .body-title
            padding-bottom: 20px
            text-align: center

        .body-container
            display: flex
            justify-content: center
            flex-wrap: wrap
            align-items: center
            grid-gap: 10px
            min-height: 200px
            width: 100%
            max-width: 600px

            .app-card
                display: block
                width: 120px
                height: 120px
                padding: 0
                border-radius: $border-radius
                border: $border

                &:hover
                    box-shadow: 2px 2px 8px 4px rgba(0, 0, 0, 0.1)

                &__icon
                    display: flex
                    align-items: center
                    justify-content: center
                    width: 100%
                    height: 70px
                    padding: 8px

                &__title
                    display: flex
                    align-items: center
                    justify-content: center
                    height: 50px
                    padding: 8px
                    width: 100%
                    font-size: 14px

                    & span
                        text-align: center

    .icon
        height: 50px
        width: 50px
        background-color: $color-blue

        &-planting
            mask: url("/img/icons/svg/planting.svg") no-repeat 50% 50%

        &-harvest
            mask: url("/img/icons/svg/harvest.svg") no-repeat 50% 50%

        &-pulverization
            mask: url("/img/icons/svg/pulverization.svg") no-repeat 50% 50%

        &-soil-preparation
            mask: url("/img/icons/svg/soil-preparation.svg") no-repeat 50% 50%

        &-seed-treatment
            mask: url("/img/icons/svg/seed-treatment.svg") no-repeat 50% 50%

        &-fertilization
            mask: url("/img/icons/svg/fertilization.svg") no-repeat 50% 50%

        &-other-activity
            mask: url("/img/icons/svg/other-activity.svg") no-repeat 50% 50%

        &-planning
            background: url("/img/icons/svg/planejamento.svg") no-repeat 50% 50%

        &-mask-size
            mask-size: 40px

@media (max-width: 370px)
    .activity-container__modal-body
        .body-container .app-card
            width: 100%

</style>
