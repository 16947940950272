<template>
    <div>
        <Modal :open="open" :loading="loading" @close="$emit('close')">
            <div class="app-modal-header" slot="header">
                <i class="fas fa-flask-potion"></i>
                <span>{{ 'Adicionar produto' }}</span>
            </div>
            <div class="app-modal-body" slot="body">
                <div class="content">
                    <div class="content__item content__item--full-width">
                        <label class="app-label">Propriedade selecionada:</label>
                        {{ getPropertySelected.name }}
                    </div>
                    <div class="content__item">
                        <label class="app-label" for="product-category">Categoria</label>
                        <select id="product-category" v-model="selectedCategory" class="app-select">
                            <option selected value="all">
                                Todos
                            </option>
                            <option v-for="productCategory in getProductCategories" :key="productCategory._id" :value="productCategory._id">
                                {{ productCategory.name }}
                            </option>
                        </select>
                    </div>
                    <div class="content__item" v-if="getProductSubCategoriesByProductCategoryId(selectedCategory).length > 0">
                        <label class="app-label" for="product-sub-category">Sub Categoria</label>
                        <select id="product-sub-category" v-model="selectedSubCategory" class="app-select">
                            <option selected value="all">
                                Todos
                            </option>
                            <option v-for="productSubCategory in getProductSubCategoriesByProductCategoryId(selectedCategory)" :key="productSubCategory._id" :value="productSubCategory._id">
                                {{ productSubCategory.name }}
                            </option>
                        </select>
                    </div>
                    <div class="content__item content__item--full-width">
                        <app-selector
                            :items="formattedNewProducts"
                            v-model="stockProduct.productId"
                            title="Produto"
                            placeholder="Selecione um produto"
                            searchable
                            @changeSearchWord="searchWord = $event"
                        />
                    </div>
                    <CheckboxGroup
                        class="content__item content__item--full-width"
                        :title="'Propriedades onde está disponível'"
                        :items="userProperties"
                        :value="propertiesIds"
                        @input="propertiesIds = $event"
                    />
                    <div class="content__item content__item--full-width d-flex justify-content-end">
                        <button class="btn btn-primary btn--flat" @click="openModalProduct" :disabled="saving || loading">
                            <span>
                                <span>Novo item</span>
                                <i class="ml-2 fas fa-plus"/>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="app-modal-footer" slot="footer">
                <button class="btn btn-primary btn--flat" @click="saveStockProduct" :disabled="saving || loading">
                    <span v-if="saving" class="spinner-border" role="status"></span>
                    <span v-else>
                        <span>Salvar</span>
                        <i class="ml-2 fas fa-save"/>
                    </span>
                </button>
            </div>
        </Modal>
        <modal-product
            v-if="showModalProduct"
            :open="showModalProduct"
            :data="{}"
            @close="closeModalProduct"
            @save="loadProducts"
        >
        </modal-product>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Modal from "@/components/systemUI/Modal.vue"
import MultipleSelector from '@/components/commons/MultipleSelector.vue'
import InputNumber from "@/components/commons/InputNumber"
import AppSelector from "@/components/commons/AppSelector"
import ModalProduct from "@/components/stock/ModalProduct.vue"
import CheckboxGroup from '@/components/commons/CheckboxGroup.vue'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid';

export default {
    name: 'ModalAddProductCurrentCatalog',
    props: {
        open: Boolean,
    },
    components: {
        Modal,
        MultipleSelector,
        InputNumber,
        AppSelector,
        ModalProduct,
        CheckboxGroup
    },
    data() {
        return {
            loading: false,
            saving: false,
            showModalProduct: false,
            stockProduct: {
                productId: "",
                quantity: 0,
                propertyId: ""
            },
            searchWord: "",
            selectedCategory: "all",
            selectedSubCategory: "all",
            userProperties: [],
            propertiesIds: [],
        };
    },
    computed: {
        ...mapGetters("product-categories", ["getProductCategories"]),
        ...mapGetters("cultures", ["getCultures"]),
        ...mapGetters("property", ["getPropertySelected"]),
        ...mapGetters("new-products", ["getNewProducts"]),
        ...mapGetters("users", ["profile"]),
        formattedNewProducts() {
            return this.getNewProducts.map(newProduct => {
                return {
                    _id: newProduct._id,
                    value: newProduct._id,
                    label: `${newProduct.name} (${newProduct.unit.name})`
                }
            })
        },
        filteredProperties(){
            return this.profile.properties.filter(property => property.property.active)
        },
    },
    async mounted() {
        await this.loadProducts()
        await this.profileProperties()
    },
    watch: {
        async selectedCategory() {
            this.selectedSubCategory = "all"
            this.stockProduct.productId = ""

            const payload = this.getNewProductsPayload();
            await this.findNewProducts(payload);
        },
        async selectedSubCategory() {
            const payload = this.getNewProductsPayload();
            await this.findNewProducts(payload);
        },
        async searchWord() {
            const payload = this.getNewProductsPayload();
            await this.findNewProducts(payload);
        },

    },
    methods: {
        ...mapActions("product-categories", ["findProductCategories"]),
        ...mapActions("new-products", ["findNewProducts"]),
        ...mapActions("stock-products", ["createStockProduct"]),
        getNewProductsPayload() {
            const selectedCategoryId = this.selectedCategory == "all" ? null : this.selectedCategory
            const selectedSubCategoryId = this.selectedSubCategory == "all" ? null : this.selectedSubCategory

            return {
                propertyId: this.getPropertySelected._id,
                commons: true,
                name: this.searchWord,
                productCategoryId: selectedSubCategoryId || selectedCategoryId,
                limit: 20
            }
        },
        profileProperties() {
            this.userProperties = this.filteredProperties.map((property) => {
                return {
                    label: property.property.name,
                    value: property.property._id,
                    _id: uuidv4()
                }
            })
        },
        openModalProduct(){
            this.showModalProduct = true
        },
        closeModalProduct(){
            this.showModalProduct = false
        },
        getProductSubCategoriesByProductCategoryId(productCategoryId) {
            const productCategory = this.getProductCategories.find(productCategory => productCategory._id == productCategoryId)
            return productCategory ? productCategory.productSubCategories : []
        },
        clearData() {
            Object.assign(this.$data, this.$options.data.apply(this));
        },
        formatOutput() {
            return {
                propertyIdList: this.propertiesIds,
                productId: this.stockProduct.productId,
                quantity: 0
            }
        },
        validateStockProduct(stockProduct) {
            if (!stockProduct.productId) {
                throw new Error('Selecione um produto para adicionar ao estoque');
            }
        },
        async saveStockProduct() {
            try {
                const stockProduct = this.formatOutput()
                this.validateStockProduct(stockProduct)

                this.saving = true

                const response = await this.createStockProduct(stockProduct)

                this.saving = false

                const responseContainsError = response instanceof Error
                if (!responseContainsError) {
                    this.$vToastify.success(response.data.message, "Estoque atual");
                    this.$emit('save')
                    this.$emit('close')
                }
            } catch (error) {
                this.$vToastify.error(error.message, "Estoque atual");
            }
        },
        async loadProducts() {
            this.loading = true;

            await this.findProductCategories();

            const payload = this.getNewProductsPayload();
            await this.findNewProducts(payload);

            this.loading = false;
        }
    }
};
</script>

<style scoped lang="sass">
.content
    display: flex
    flex-wrap: wrap

    &__title
        padding: 4px
        width: 100%

    &__item
        padding: 4px
        width: 50%

        &--full-width
            width: 100%

@media (max-width: 500px)
    .content
        &__item
            width: 100%
</style>
