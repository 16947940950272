import API from "./API";

export default {

    createField(field) {
        return API.post("fields", field);
    },

    updateField(fieldId, field) {
        return API.put(`fields/${fieldId}`, field);
    },
    
  getAll() {
    return API.get("fields");
  },

  get(id) {
    return API.get(`fields/${id}`);
  },

    getFieldsByProperty(propertyId, cropId) {
        return API.get(`fields/properties/${propertyId}`, {
            params: {
                ... cropId ? { cropId } : {}
            }
        })
    },

  getFieldsByPropertyByCrop(propertyId, cropId) {
    return API.get(`fields/properties/${propertyId}/crops/${cropId}`)
  },

  getImagesNDVIField(id) {
    return API.get(`fields/${id}/ndvis`);
  },

  getImagesNDVIByInterval(data) {
    return API.get(
      `/ndvis/fields/${data.fieldId}/crops/${data.cropId}/${data.startDate}/${data.endDate}`
    );
  },
  getImagesNDVIByIntervalAllFields(data) {
    return API.get(
      `/ndvis/properties/${data.propertyId}/crops/${data.cropId}/${data.startDate}/${data.endDate}`
    );
  },

  getAllPropertyImagesNDVI(id) {
    return API.get(`properties/${id}/ndvis`);
  },

  getPropertyImagesNDVIByInterval(id, interval) {
    return API.get(
      `properties/${id}/ndvis/${interval.startDate}/${interval.endDate}`
    );
  },

  getPhotos(id) {
    return API.get(`fields/${id}/photos`);
  },

  getSubAreas(id) {
    return API.get(`fields/${id}/subareas`);
  },



  delete(id) {
    return API.delete(`fields/${id}`);
  },

  postSubArea(data) {
    return API.post("subareas", data);
  },

  updateSubArea(data) {
    const id = data._id;
    delete data["_id"];

    return API.put(`subareas/${id}`, data);
  },

  deleteSubArea(id) {
    return API.delete(`subareas/${id}`);
  },
};
