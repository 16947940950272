<template>
    <div class="datePicker" :class="classParameter">
        <label class="app-label" for="date">{{label}}</label>
        <input :disabled="disabled" class="form-control" id="date" type="date" @change="dateChanged" v-model="date" />
    </div>
</template>

<script>
export default {
    name: "DatePicker",
    props: {
        label:{
            type: String
        },
        dataSent:{
            type: String
        },
        classParameter:{
            type: String
        },
        disabled: {
            type: Boolean
        }
    },
    data(){
        return {
            date: this.dataSent
        }
    },
    watch: {
        dataSent() {
            this.date = this.dataSent
        }
    },
    mounted() {
        this.date = this.dataSent
    },

    methods: {
        dateChanged () {
            this.$emit('dateSelected', {date:this.date})
        }
    }
}
</script>