import API from "./API";
import querystring from 'querystring'


export default {
  getAll(accountId, userId) {
    const query = querystring.encode({
      accountId,
      userId 
    })
    return API.get(`properties?${query}`)
  },

  getProperty(id) {
    return API.get(`properties/${id}`);
  },

  async getPropertyFields(id) {
    return await API.get(`properties/${id}/fields`);
  },

  getPropertyCrops(id) {
    return API.get(`properties/${id}/crops`);
  },

  async getPropertyOperators(id) {
    return await API.get(`properties/${id}/operators`);
  },

  getPropertyMachineries(id) {
    return API.get(`properties/${id}/machineries`);
  },

  getPropertyProducts(id) {
    return API.get(`properties/${id}/products`);
  },

  getPropertyNotifications(id) {
    return API.get(`properties/${id}/notifications`);
  },

  markNotificationAsReadOrUnread(id, isRead) {
    return API.put(
      `notifications/${id}`,
      { read: isRead }
    );
  },

  getAllPropertyActivities(id) {
    return API.get(`properties/${id}/activities`);
  },

  getPropertyLastActivities(propertyId, cropId) {
    return API.get(`properties/${propertyId}/${cropId}/lastactivitiesbycrop`);
  },

  createProperty(property){
    return  API.post(`properties`, property)
  },

  updateProperty(property){
    return API.put(`properties/${property._id}`, property)
  }
};
