<template>
    <nav class="navbar navbar-expand-lg navbar-light">
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarContent"
                aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="fas fa-caret-down"/>
        </button>
        <div class="navbar-collapse collapse navbar-content navbar-nav" id="navbarContent">
            <div class="navbar-content--left">
                <navbar-dropdown
                    class="navbar-content__item"
                    icon="fazenda"
                    title="Escolha a propriedade"
                    search="Pesquise uma propriedade"
                    @clickEvent="propertySelect"
                    :dropList="listProperty"
                    :subtitle="getPropertySelected.name"
                    :loading="loadingProperty"
                />
                <navbar-dropdown
                    class="navbar-content__item"
                    icon="planting"
                    title="Escolha a safra"
                    search="Pesquise uma safra"
                    @clickEvent="cropSelect"
                    :dropList="_listCrops"
                    :subtitle="getCropSelected.name"
                    :loading="loadingCrop"
                />
            </div>
            <div class="navbar-content--right">
                <a
					class="help-btn"
					href="https://ajuda.agrointeli.com.br/agrofarmer/"
					label="Ajuda"
					target="blank"
				>
					<i class="action fas fa-question" />
					<span size="13px" color="#585858">Ajuda</span>
				</a>
                <navbar-button-notification
                    class="navbar-content__item nav-button"
                    @click.native="$router.push('/notificacoes')"
                    :notificationNumber="`${this.getNumberNotificationUnread}`"
                />
                <navbar-dropdown
                    class="navbar-content__item"
                    :title="`Olá, ${profile.name}`"
                    @clickEvent="profileSelect"
                    :dropList="dropItems"
                    :subtitle="profile.email"
                />
                <navbar-button-configuration class="navbar-content__item nav-button config " @click.native="$router.push('/bens')"/>
            </div>
        </div>
    </nav>
</template>

<script>
import NavbarDropdown from "@/views/main/components/navbar/components/NavbarDropdown.vue";
import NavbarButtonConfiguration from "@/views/main/components/navbar/components/NavbarButtonConfiguration.vue";
import NavbarButtonNotification from "@/views/main/components/navbar/components/NavbarButtonNotification.vue";
import {mapActions, mapGetters} from "vuex";

export default {
    components: {
        NavbarDropdown,
        NavbarButtonConfiguration,
        NavbarButtonNotification,
    },
    data() {
        return {
            dropProperty: [],
            dropCrop: [],
            dropItems: [{title: "Sair", url: this.signout}],
            sidebarShow: true,
            currentRoute: "",
            loadingProperty: false,
            loadingCrop: false
        };
    },
    computed: {
        ...mapGetters('sideBar', ['getSideBar']),
        ...mapGetters("property", [
            "getNumberNotificationUnread",
            "getPropertySelected",
            "getCropSelected",
            "getCrops",
        ]),
        ...mapGetters('crops', ['allCrops']),
        ...mapGetters("users", ["profile"]),
        propertyFields() {
            return this.getPropertyFields;
        },
        propertySelected() {
            return this.getPropertySelected;
        },
        filteredProperties(){
            return this.profile.properties.filter(property => property.property.active)
        },
        listProperty() {
            return this.filteredProperties.map(property => {
                return { id: property.property._id, title: property.property.name } 
            })
        },
        _listCrops() {
            const data = this.getCrops.map(crop => {
                return {title: crop.name, id: crop._id}
            });

            return data.sort(function (a, b) {
                return a.title < b.title ? -1 : a.title > b.title ? 1 : 0;
            });
        },
    },
    watch: {
        $route() {
            const _target = this.$route;
            this.currentRoute = _target.fullPath;
        },

    },
    async created() {
        this.profile.properties.map((property) => {
            this.dropProperty.push({
                title: property.property.name,
                id: property.property._id,
            });
        });
        this.getCrops.map((crop) => {
            this.dropCrop.push({title: crop.name, id: crop._id});
        });
    },
    mounted() {
        if(this._listCrops.length > 0){
            this.setCrop(this._listCrops[0].id)
        }
    },
    methods: {
        ...mapActions("users", ["logout"]),
        ...mapActions("property", ["listCrops", "listPropertySelected", "listCropSelected",]),
        async signout() {
            await this.logout();
            await this.$router.push("/login");
        },
        async reloadData(property) {
            await this.listPropertySelected(property);
            await this.listCrops(property);
        },
        async setCrop(crop) {
            this.loadingCrop = true
            await this.listCropSelected(crop)
            this.loadingCrop = false
        },
        optionsChanged(change) {
            this.$emit("sidebar", change);
        },
        async propertySelect(data) {
            this.loadingProperty = true
            await this.reloadData(data.id);
            if(this._listCrops.length > 0){
                await this.setCrop(this._listCrops[0].id)
            }
            this.loadingProperty = false
        },
        cropSelect(data) {
            this.setCrop(data.id);
        },
        profileSelect(data) {
            data.url();
        },
    },
};
</script>

<style scoped lang="sass">
.help-btn
    display: flex
    flex-direction: column
    align-items: center
    height: 70px
    width: 60px
    border: $border
    border-radius: 4px
    padding: 11px
    color: #0D7F7B
.navbar
    display: flex
    flex-direction: row-reverse
    height: 82px
    padding: 6px
    border-bottom: 1px solid #dedede
    z-index: 999

    &-toggler
        border: none

    &-content
        display: flex
        grid-gap: 6px
        justify-content: space-between

        &--left, &--right
            display: flex
            grid-gap: 6px
            align-items: center

        &__item
            &:hover
                cursor: pointer
                background: whitesmoke

.nav-button
    display: flex
    justify-content: center
    align-items: center
    max-width: 100px
    height: 70px
    background: $color-white
    border: $border
    border-radius: 4px
    padding: 6px
    color: $color-black
    font-size: 12px

@media (max-width: 1050px)
    .navbar
        height: 40px
        align-items: flex-start
        justify-content: flex-start

        .show
            display: flex !important

        &-toggler
            display: block !important

        &-content
            display: none !important
            flex-direction: column
            justify-content: flex-start
            align-items: flex-start
            background-color: $color-white
            max-width: 270px
            border-radius: 10px
            padding: 10px
            box-shadow: $box-shadow

            &--left
                flex-direction: column
                justify-content: flex-start
                align-items: flex-start

            &--right
                flex-wrap: wrap
                justify-content: space-between

                .navbar-content__item.nav-button
                    order: -1
                    width: 50%

                .navbar-content__item.config
                    align-self: flex-end

</style>