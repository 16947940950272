import API from './API';
import querystring from 'querystring'

export default {
    getAll(propertyId, active) {
        const query = querystring.encode({
            propertyId,
            ...active != undefined ? { active } : {}
        })
        return API.get(`machineries?${query}`)
    },

    get(id) {
        return API.get(`machineries/${id}`)
    },

    post(data) {
        return API.post("machineries", data)
    },

    update(data) {
        return API.put(`machineries/${data._id}`, data)
    }
}