const mixin = {
    methods: {
        formatDate(date, language) {
            return this.$options.filters.formatDate(date, language)
        },
        formatTime(date, language) {
            return this.$options.filters.formatTime(date, language)
        },
        formatDateTime(date, language) {
            return this.$options.filters.formatDate(date, language)
        },
        getTodayDate() {
            const today = new Date()
            return this.formatDate(today, 'default')
        },
        getNextDate(date, daysNext) {
            date = new Date(date)
            const datePlusDaysNext = date.getDate() + daysNext
            const nextDate = new Date(date.setDate(datePlusDaysNext))
            return this.formatDate(nextDate, 'default')
        },
        getPreviousDate(date, daysNext) {
            date = new Date(date)
            const datePlusDaysNext = date.getDate() - daysNext
            const nextDate = new Date(date.setDate(datePlusDaysNext))
            return this.formatDate(nextDate, 'default')
        },
        getPreviousDate(date, daysPrevious) {
            date = new Date(date)
            const dateLessDaysPrevious = date.getDate() - daysPrevious
            const previousDate = new Date(date.setDate(dateLessDaysPrevious))
            return this.formatDate(previousDate, 'default')
        },
        getFirstDayOfMonth(date) {
            date = date ? new Date(date) : new Date()
            date.setDate(0)
            date = new Date(date)
            return this.formatDate(date, 'default')
        },
        isDateBetweenMonths(date, startMonth, endMonth) {
            date = new Date(date);
            const dateMonth = date.getUTCMonth() + 1;
            return dateMonth >= startMonth && dateMonth <= endMonth;
        },
        dateDiffInDays(firstDate, secondDate) {
            const _MS_PER_DAY = 1000 * 60 * 60 * 24;

            const utc1 = Date.UTC(firstDate.getFullYear(), firstDate.getMonth(), firstDate.getDate());
            const utc2 = Date.UTC(secondDate.getFullYear(), secondDate.getMonth(), secondDate.getDate());

            return Math.floor((utc2 - utc1) / _MS_PER_DAY);
        },
        getFirstDayOfWeek(date) {
            const firstDayOfWeek = new Date(date);
            var day = firstDayOfWeek.getDay() || 7;
            if( day !== 1 ) {
                firstDayOfWeek.setHours(-24 * (day - 1));
            }
            return firstDayOfWeek;
        },
        getLastDayOfWeek(date) {
            const lastDayOfWeek = this.getFirstDayOfWeek(date);
            lastDayOfWeek.setDate(date.getDate() + 6);

            return lastDayOfWeek;
        }
    }
}

export default mixin
