<template>
    <div>
        <div class="activities-container" v-show="showLastActivities">
            <div class="activities-container__header">
                <span>Atividades</span>
                <div class="img clickable" @click="toggleActivities()"/>
            </div>
            <div class="activities-container__content">
                <p class="no-activities" v-if="activities.length === 0 && !loading">
                    Sem atividades recentes
                </p>
                <div v-else>
                    <div class="row d-flex align-items-center mt-2" v-if="loading">
                        <div class="col-md-12">
                            <div class="row d-flex align-self-center align-items-center">
                                <div class="col text-center">
                                    <div class="spinner-border text-secondary" role="status"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ul class="activities list-group" v-else>
                        <li class="list-group-item border-right-0 border-left-0 rounded-0"
                            v-for="(activity, i) in activities" :key="i">
                            <a href="#" class="mb-0 d-block text-decoration-none" style="color: #4a4a4a">
                                <div class="row d-flex flex-nowrap align-items-center">
                                    <div :class="`col-2 d-block text-center p-0 icon-${activityIcon(activity.type)}`" />
                                    <div class="col-10 text-left" style="font-size: 14px">
                                        <div v-if="!activity.date[0].done && !activity.status" class="activity-status-pending">
                                            <span class="status-text">Pendente</span>
                                        </div>
                                        <div v-if="!activity.date[0].done && activity.status && activity.status === 'waiting'" class="activity-status-waiting">
                                            <span class="status-text">Aguardando</span>
                                        </div>
                                        <div v-if="activity.date[0].done === true" class="activity-status-done">
                                            <span class="status-text">Realizada</span>
                                        </div>
                                        <strong style="color: #4a4a4a; font-size: 14px">Talhão:</strong>
                                        <span class="ml-2">{{ activity.subarea.field.name ? activity.subarea.field.name : '-' }}</span>
                                        <br/>
                                        <strong style="color: #4a4a4a; font-size: 14px">Cultivar:</strong>
                                        <span class="ml-2">{{ activity.subarea.seed ? activity.subarea.seed : '-' }}</span>
                                        <br/>
                                        <strong style="color: #4a4a4a; font-size: 14px">Atividade:</strong>
                                        <span class="ml-2">{{ activityTranslation(activity.type) }} em {{ activity.activityDate.dateHour | formatDate }}</span>
                                    </div>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="activities-container--colapsed clickable" v-show="!this.showLastActivities"
             @click="toggleActivities()">
            <div class="img"/>
        </div>
    </div>
</template>

<script>
import {ordeyByDecrescentDate} from "@/utils/orderByDate";
import {mapGetters, mapActions} from 'vuex';

export default {
    name: "PanelScheduledActivities",
    data() {
        return {
            showLastActivities: true,
            loading: false,
            today: new Date()
        };
    },
    computed: {
        ...mapGetters("activities", ["getActivities"]),
        ...mapGetters("property", [ "getPropertySelected", "getCropSelected" ]),
        activities() {
            return this.getActivities.reverse()
        }
    },
    watch: {
        async getCropSelected() {
            this.loading = true;
            await this.loadWeekActivities();
            this.loading = false;
        }
    },
    methods: {
        ...mapActions("activities", ["findActivities", "setActivitiesStatus"]),
        toggleActivities() {
            this.showLastActivities = !this.showLastActivities
        },
        activityIcon(type){
            switch(type){
                case 'planting':
                    return 'planting';
                case 'pulverization':
                    return 'pulverization';
                case 'harvest':
                    return 'harvest';
                case 'soilPreparation':
                    return 'soil-preparation';
                case 'seedTreatment':
                    return 'seed-treatment';
                case 'fertilization':
                    return 'fertilization';
                case 'otherActivity':
                    return 'other-activity';
            }
        },
        activityTranslation(type){
            switch(type){
                case 'planting':
                    return 'Plantio';
                case 'pulverization':
                    return 'Pulverização';
                case 'harvest':
                    return 'Colheita';
                case 'soilPreparation':
                    return 'Preparo de solo';
                case 'seedTreatment':
                    return 'Tratamento de sementes';
                case 'fertilization':
                    return 'Fertilização';
                case 'otherActivity':
                    return 'Outra atividade';
            }
        },
        async loadWeekActivities() {
            const propertyId = this.getPropertySelected._id;
            const cropId = this.getCropSelected._id;
            //const startDate = this.formatDate(new Date(), "default");
            const startDate = this.formatDate(this.getPreviousDate(new Date(), 7), "default");
            const endDate = this.formatDate(this.getNextDate(new Date(), 7), "default");
            
            await this.findActivities({ propertyId, cropId, startDate, endDate });

            let formatActivitiesStatus = this.activities
            formatActivitiesStatus = await formatActivitiesStatus.map((act)=>{
                let activityDate = this.formatDate(act.date[0].dateHour, 'default')
                let todayDate = this.getTodayDate()
                
                if (act.date[0].done === true){
                    return {...act}
                }
                if (new Date(activityDate).getTime() > new Date(todayDate).getTime()){
                    return {...act, status: 'waiting'}
                } else {
                    return {...act}
                }
            })
            
            this.setActivitiesStatus(formatActivitiesStatus)
        },
    },
    async mounted() {
        this.loading = true;
        await this.loadWeekActivities();
        this.loading = false;
    }
};
</script>

<style scoped lang="sass">
.icon
    &-pulverization
        mask: url("/img/icons/svg/pulverization.svg") no-repeat 100% 100%
        @include icon-mask-lg
        background-color: $color-blue
        min-width: 50px

    &-planting
        mask: url("/img/icons/svg/planting.svg") no-repeat 100% 100%
        @include icon-mask-lg
        background-color: $color-blue
        min-width: 50px

    &-harvest
        mask: url("/img/icons/svg/harvest.svg") no-repeat 100% 100%
        @include icon-mask-lg
        background-color: $color-blue
        min-width: 50px

    &-soil-preparation
        mask: url("/img/icons/svg/soil-preparation.svg") no-repeat 100% 100%
        @include icon-mask-lg
        background-color: $color-blue
        min-width: 50px

    &-seed-treatment
        mask: url("/img/icons/svg/seed-treatment.svg") no-repeat 100% 100%
        @include icon-mask-lg
        background-color: $color-blue
        min-width: 50px

    &-fertilization
        mask: url("/img/icons/svg/fertilization.svg") no-repeat 100% 100%
        @include icon-mask-lg
        background-color: $color-blue
        min-width: 50px

    &-other-activity
        mask: url("/img/icons/svg/other-activity.svg") no-repeat 100% 100%
        @include icon-mask-lg
        background-color: $color-blue
        min-width: 50px


.activities-container
    background: $color-white
    border-radius: $border-radius
    box-shadow: $box-shadow
    pointer-events: auto
    padding: 10px
    width: 100%
    max-width: 400px

    &__header
        display: flex
        justify-content: space-between
        align-items: center
        padding: 6px
        font-size: 20px
        font-weight: 500
        border-bottom: $border

        .img
            background: $color-white-darken-2
            mask: url('/img/icons/svg/arrow_right.svg') 50% 50% no-repeat

    &__content
        p
            margin: 0

        .no-activities
            text-align: center
            margin: 10px 0

        .activities
            height: 320px
            overflow: auto

    &--colapsed
        display: flex
        align-items: center
        justify-content: center
        width: 50px
        height: 50px
        pointer-events: auto
        background: $color-white
        box-shadow: $box-shadow
        border-radius: 50%

        .img
            background: $color-white-darken-2
            mask: url('/img/icons/svg/arrow_left.svg') 50% 50% no-repeat

.activity-status-pending
    background: $color-red
    width: 100%
    height: 20px

.activity-status-waiting
    background: $color-yellow
    width: 100%
    height: 20px

.activity-status-done
    background: rgba(11, 156, 49, 1)
    width: 100%
    height: 20px

.status-text
    color: $color-white
    margin-left: 5px
</style>
