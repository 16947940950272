<template>
    <div class="seed-selector">
        <div class="seed-selector__item seed-selector__item--full-width">
            <app-selector
                :items="formattedSeedsToSelector"
                title="Semente a ser tratada"
                placeholder="Selecione a semente a ser tratada"
                v-model="seedToTreat.stockProductId"
                searchable
            />
        </div>
        <div v-show="seedToTreat.stockProductId" class="seed-selector__item seed-selector__item--left">
            <label
                :for="`${uuid}_seed_selector_product`"
                class="app-label"
            >
                Quantidade
            </label>
            <app-selector-number-unit
                :id="`${uuid}_seed_selector_product`"
                v-model="seedToTreat.quantity"
                placeholder="Ex: 5"
                :units="selectedProductPossibleUnits"
            />
        </div>
    </div>
</template>
<script>

import {mapActions, mapGetters} from 'vuex'
import AppSelector from "@/components/commons/AppSelector"
import AppSelectorNumberUnit from "@/components/commons/AppSelectorNumberUnit"

import _ from 'lodash';

export default {
    name: "SeedSelector",
    props: {
        uuid: {
            type: String
        },
        cultureId: {
            type: String
        },
        value: {
            type: Object
        }
    },
    components: {
        AppSelector,
        AppSelectorNumberUnit
    },
    data() {
        return {
            seedToTreat: {
                stockProductId: null,
                quantity: {
                    value: null,
                    unitId: null
                }
            }
        };
    },
    computed: {
        ...mapGetters('stock-products', ['getStockProducts']),
        ...mapGetters('unit-conversions', ['getUnitConversions']),
        selectedProductPossibleUnits() {
            const selectedSeed = this.filteredSeedsFromStockProducts.find(stockProduct => stockProduct._id == this.seedToTreat.stockProductId)
            if (!selectedSeed) {
                return []
            }

            const possibleUnitConversions = this.getUnitConversions.filter(unitConversion => selectedSeed.product.unit._id == unitConversion.toUnit._id)
            const units = possibleUnitConversions.map(unitConversion => unitConversion.fromUnit)

            return units
        },
        filteredSeedsFromStockProducts() {
            return this.getStockProducts.filter(stockProduct => {
                const productBelongsToSelectedCulture = stockProduct.product.cultures.find(culture => culture.culture == this.cultureId)
                if (!productBelongsToSelectedCulture) {
                    return false
                }

                const stockProductCategoryName = this.returnIfExists(stockProduct, "product.productCategory.name")
                if (stockProductCategoryName != "CULTIVAR") {
                    return false
                }

                return true
            })
        },
        formattedSeedsToSelector() {
            return this.filteredSeedsFromStockProducts.map(stockProduct => {
                return {
                    _id: stockProduct._id,
                    value: stockProduct._id,
                    label: `${stockProduct.product.name} (${stockProduct.product.unit.name})`
                }
            })
        },
        formattedOutput() {
            return {
                stockProductId: this.seedToTreat.stockProductId,
                quantity: {
                    value: this.seedToTreat.quantity.value,
                    unitId: this.seedToTreat.quantity.unitId
                }
            }
        }
    },
    watch: {
        value() {
            if (this.inputSeedIsDifferentFromSelected()) {
                this.syncInput()
            }
        },
        selectedProductPossibleUnits() {
            this.seedToTreat.quantity = {
                unitId: this.returnIfExists(this.selectedProductPossibleUnits, '0._id') || null,
                value: this.seedToTreat.quantity.value
            }
        },
        formattedOutput() {
            this.$emit('input', this.formattedOutput)
        }
    },
    methods: {
        inputSeedIsDifferentFromSelected() {
            const seedToTreat = this.value

            if (seedToTreat.stockProductId != this.seedToTreat.stockProductId) {
                return true
            }

            if (seedToTreat.quantity.value != this.seedToTreat.quantity.value) {
                return true
            }

            if (seedToTreat.quantity.unitId != this.seedToTreat.quantity.unitId) {
                return true
            }

            return false
        },
        syncInput() {
            const seedToTreat = _.cloneDeep(this.value)
            this.seedToTreat = {
                stockProductId: seedToTreat.stockProductId,
                quantity: {
                    value: seedToTreat.quantity.value,
                    unitId: seedToTreat.quantity.unitId
                }
            }
        }
    }
};
</script>
<style lang="sass" scoped>
.seed-selector
    display: flex
    flex-wrap: wrap

    &__item
        width: 50%

        &--left
            padding-right: 4px

        &--right
            padding-left: 4px

        &--full-width
            width: 100%

@media (max-width: 600px)
    .seed-selector
        &__item
            width: 100%

            &--left
                padding: 0

            &--right
                padding: 0
</style>
