export const ordeyByDecrescentCreatedAt = function(arrayToOrder) {
  return arrayToOrder.sort(function(a, b) {
    return new Date(b.createdAt) - new Date(a.createdAt);
  });
};

export const ordeyByDecrescentDate = function(arrayToOrder) {
  return arrayToOrder.sort(function(a, b) {
    if (a.date === undefined || b.date === undefined) {
      return new Date(b.samplingDate) - new Date(a.samplingDate);
    } else {
      return new Date(b.date) - new Date(a.date);
    }
  });
};

export const ordeyByCrescentDateHour = function(arrayToOrder) {
  return arrayToOrder.sort(function(a, b) {
    return new Date(a.date.dateHour) - new Date(b.date.dateHour);
  });
};

export const ordeyByCrescentDateHourCard = function(arrayToOrder) {
  return arrayToOrder.sort(function(a, b) {
    return new Date(a.dateHour) - new Date(b.dateHour);
  });
};

export const ordeyByDrecrescentDateHourReportCrop = function(arrayToOrder) {
  return arrayToOrder.sort(function(a, b) {
    return new Date(b.date[0].dateHour) - new Date(a.date[0].dateHour);
  });
};

export const ordeyByDrecrescentDateCaptured = function(arrayToOrder) {
  return arrayToOrder.sort(function(a, b) {
    return new Date(b.captured) - new Date(a.captured);
  });
};
