<template>
    <div class="stock-movement-history-container">
        <div class="stock-movement-history-container__header">
            <span class="title">
                Histórico de movimentações
            </span>
            <div class="actions">
                <div class="search-container app-input">
                    <input
                        class="search-container__input"
                        placeholder="Pesquise uma movimentação"
                        v-model="searchWord"
                        type="text"
                    />
                    <button
                        class="search-container__clear clickable"
                        v-show="searchWord.length > 0"
                        @click="clearSearch"
                    >
                        <i class="fal fa-times-circle"/>
                    </button>
                    <button
                        class="search-container__search"
                        @click=""
                    >
                        <i class="fas fa-search"/>
                    </button>
                </div>
            </div>
        </div>
        <div
            v-if="!loading"
            class="stock-movement-history-container__content"
        >
            <table class="stock-movement-history-table">
                <thead>
                    <tr>
                        <th>
                            Datas
                        </th>
                        <th>
                            Produto
                        </th>
                        <th>
                            Tipo
                        </th>
                        <th>
                            Talhão
                        </th>
                        <th>
                            Área (ha)
                        </th>
                        <th>
                            Quantidade calculada
                        </th>
                        <th>
                            Quantidade movimentada
                        </th>
                        <th>
                            Saldo
                        </th>
                        <th class="stock-movement-history-table-header__actions">
                            Ações
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="{
                            displayTextStockRegisterDate,
                            displayTextStockRegisterProductName,
                            displayTextStockRegisterAction,
                            displayTextStockRegisterField,
                            displayTextStockRegisterArea,
                            displayTextStockRegisterCalculatedTotalQuantity,
                            displayTextStockRegisterRealTotalQuantity,
                            displayTextStockRegisterBalance,
                            realTotalQuantityTextColor,
                            stockRegisterKey,
                            stockRegister
                        } in displayArrayFilteredStockRegisters"
                        :key="stockRegisterKey"
                    >
                        <td>
                            {{ displayTextStockRegisterDate }}
                        </td>
                        <td>
                            {{ displayTextStockRegisterProductName }}
                        </td>
                        <td>
                            {{ displayTextStockRegisterAction }}
                        </td>
                        <td>
                            {{ displayTextStockRegisterField }}
                        </td>
                        <td>
                            {{ displayTextStockRegisterArea }}
                        </td>
                        <td>
                            {{ displayTextStockRegisterCalculatedTotalQuantity }}
                        </td>
                        <td :style="realTotalQuantityTextColor">
                            {{ displayTextStockRegisterRealTotalQuantity }}
                        </td>
                        <td>
                            {{ displayTextStockRegisterBalance }}
                        </td>
                        <td>
                            <div class="stock-movement-history-table-content__actions">
                                <button
                                    @click="openModalHistoryDetail(stockRegister)"
                                    class="btn btn--flat btn-primary--outlined"
                                >
                                    <i class="fas fa-eye"/>
                                    <span>
                                        Visualizar
                                    </span>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div
                v-show="!hasStockRegisters"
                class="no-content"
            >
                <i class="fa fa-exclamation-circle fa-2x"/>
                <span>
                    Não foram encontradas movimentações
                </span>
            </div>
        </div>
        <div
            v-else
            class="no-content"
        >
            <span class="spinner-border"/>
        </div>
        <modal-history-detail
            v-if="showModalHistoryDetail"
            :open="showModalHistoryDetail"
            @close="closeModalHistoryDetail"
            :data="selectedStockRegister"
        />
    </div>
</template>

<script>

import { mapActions, mapGetters } from "vuex";

import ModalHistoryDetail from "@/components/stock/ModalHistoryDetail.vue";

export default {
    name: 'History',
    components: {
        ModalHistoryDetail
    },
    data() {
        return {
            loading: false,
            searchWord: '',
            selectedStockRegister: {},
            showModalHistoryDetail: false
        }
    },
    computed: {
        ...mapGetters("property", ["getPropertySelected"]),
        ...mapGetters("stock-register", ["getStockRegisters"]),
        ...mapGetters("unit-conversions", ["getUnitConversions"]),
        displayArrayFilteredStockRegisters() {
            if (!this.searchWord) {
                return this.displayArrayStockRegisters
            }

            return this.displayArrayStockRegisters.filter(__ => this.compareDisplayTextArrayToSearchWord([
                __.displayTextStockRegisterDate,
                __.displayTextStockRegisterProductName,
                __.displayTextStockRegisterAction,
                __.displayTextStockRegisterField,
                __.displayTextStockRegisterArea,
                __.displayTextStockRegisterCalculatedTotalQuantity,
                __.displayTextStockRegisterRealTotalQuantity,
                __.displayTextStockRegisterBalance
            ]))
        },
        displayArrayStockRegisters() {
            return this.getStockRegisters.map(stockRegister => ({
                displayTextStockRegisterDate: this.getDisplayTextStockRegisterDate(stockRegister),
                displayTextStockRegisterProductName: this.returnIfExists(stockRegister, "stockProduct.product.name") || '-',
                displayTextStockRegisterAction: this.translateAction(stockRegister.action) || '-',
                displayTextStockRegisterField: this.getDisplayTextStockRegisterField(stockRegister),
                displayTextStockRegisterArea: this.getDisplayTextStockRegisterArea(stockRegister),
                displayTextStockRegisterCalculatedTotalQuantity: this.getDisplayTextStockRegisterCalculatedTotalQuantity(stockRegister),
                displayTextStockRegisterRealTotalQuantity: this.getDisplayTextStockRegisterRealTotalQuantity(stockRegister),
                displayTextStockRegisterBalance: this.getDisplayTextStockRegisterBalance(stockRegister),
                realTotalQuantityTextColor: this.getRealTotalQuantityTextColor(stockRegister),
                stockRegisterKey: stockRegister._id,
                stockRegister
            }))
        },
        hasStockRegisters() {
            return !!this.displayArrayStockRegisters.length;
        }
    },
    watch: {
        async getPropertySelected() {
            await this.loadStockRegisters()
        }
    },
    methods: {
        ...mapActions("stock-register", ["findStockRegisters"]),
        ...mapActions("unit-conversions", ["findUnitConversions"]),
        compareDisplayTextArrayToSearchWord(displayTextArray) {
            return displayTextArray.some(displayText => displayText.toUpperCase().includes(this.searchWord.toUpperCase()))
        },
        openModalHistoryDetail(stockRegister) {
            this.selectedStockRegister = stockRegister
            this.showModalHistoryDetail = true
        },
        closeModalHistoryDetail() {
            this.showModalHistoryDetail = false
        },
        translateAction(action) {
            const actions = {
                'entrance': 'Entrada',
                'exit': 'Saída',
                'Entrada': 'entrance',
                'Saída': 'exit'
            }
            return actions[action];
        },
        getDisplayTextStockRegisterDate(stockRegister) {
            if (!stockRegister.activity) {
                return this.formatDate(stockRegister.date)
            }

            return stockRegister.activity.date.map(({ dateHour }) => this.formatDate(dateHour)).join(', ')
        },
        getDisplayTextStockRegisterField(stockRegister) {
            const fieldName = this.returnIfExists(stockRegister, 'subarea.field.name')
            if (!fieldName) {
                return '-'
            }

            return fieldName
        },
        getDisplayTextStockRegisterArea(stockRegister) {
            const stockRegisterArea = stockRegister.area
            if (!stockRegisterArea && stockRegisterArea !== 0) {
                return '-'
            }

            return this.formatNumber(stockRegisterArea)
        },
        getDisplayTextStockRegisterRealTotalQuantity(stockRegister) {
            const stockRegisterRealTotalQuantity = this.getStockRegisterRealTotalQuantity(stockRegister)
            if (!stockRegisterRealTotalQuantity && stockRegisterRealTotalQuantity !== 0) {
                return '-'
            }

            const stockProductUnit = this.returnIfExists(stockRegister, "stockProduct.product.unit.name")
            if (!stockProductUnit) {
                return '-'
            }

            const sign = stockRegister.action === 'entrance' ? '+' : '-';

            return `${sign} ${this.formatNumber(stockRegisterRealTotalQuantity)} ${stockProductUnit}`
        },
        getDisplayTextStockRegisterCalculatedTotalQuantity(stockRegister) {
            const stockRegisterCalculatedTotalQuantity = this.getStockRegisterCalculatedTotalQuantity(stockRegister)
            if (!stockRegisterCalculatedTotalQuantity && stockRegisterCalculatedTotalQuantity !== 0) {
                return '-'
            }

            const stockProductUnit = this.returnIfExists(stockRegister, "stockProduct.product.unit.name")
            if (!stockProductUnit) {
                return '-'
            }

            return `${this.formatNumber(stockRegisterCalculatedTotalQuantity)} ${stockProductUnit}`
        },
        getDisplayTextStockRegisterBalance(stockRegister) {
            const stockRegisterBalance = stockRegister.balance
            if (!stockRegisterBalance) {
                return '-'
            }

            const stockProductUnit = this.returnIfExists(stockRegister, "stockProduct.product.unit.name")
            if (!stockProductUnit) {
                return '-'
            }

            return `${this.formatNumber(stockRegisterBalance)} ${stockProductUnit}`
        },
        getRealTotalQuantityTextColor(stockRegister) {
            return {
                color: (
                    stockRegister.action === 'entrance'
                    ? 'green'
                    : 'red'
                )
            }
        },
        getStockRegisterRealTotalQuantity(stockRegister) {
            return stockRegister.realTotalQuantity;
        },
        getStockRegisterCalculatedTotalQuantity(stockRegister) {
            return stockRegister.calculatedTotalQuantity;
        },
        async loadStockRegisters() {
            this.loading = true

            await this.findStockRegisters({ propertyId: this.getPropertySelected._id })

            this.loading = false
        },
        async clearSearch() {
            this.searchWord = ''
        },
    },
    async mounted() {
        this.loading = true

        await this.findUnitConversions()
        await this.loadStockRegisters()

        this.loading = false
    }
}
</script>

<style lang="sass" scoped>
.stock-movement-history-container
    display: flex
    flex-direction: column
    grid-gap: 6px
    width: 100%

    &__header
        .title
            font-size: 24px
            color: $theme-color-primary

        .actions
            display: flex
            justify-content: space-between

            .search-container
                display: flex
                justify-content: space-between
                width: 300px

                &__input
                    width: 250px
                    border: 0

                &__search
                    border: 0
                    width: 25px
                    background: none

                &__clear
                    border: 0
                    width: 25px
                    background: none

.stock-movement-history-table
    width: 100%
    min-width: 1000px

    &-header__actions
        width: 30%
        text-align: center

    &-content__actions
        display: flex
        grid-gap: 6px
        align-items: center
        justify-content: center

        .btn
            background: none

            &:hover
                background: $color-white-darken-1

            i
                color: $color-blue

    th
        color: $color-blue
        padding: 0 10px
        font-size: 16px
        width: calc(1/9*100%)

    tr
        border-radius: 20px
        height: 70px

        &:nth-child(even)
            background-color: #f2f2f2

    td
        padding: 0 10px

    thead
        position: sticky
        top: -10px
        background: $color-white
        z-index: 1

.no-content
    display: flex
    justify-content: center
    align-items: center
    height: 100px
    color: $color-grey
    grid-gap: 10px

@media (max-width: 430px)
    .stock-movement-history-container__header .actions .search-container__input
        width: 200px

</style>
