<template>
    <Modal
        :open="open"
        :loading="loading"
        @close="$emit('close')"
    >
        <div
            class="app-modal-header"
            slot="header"
        >
            <i class="fas fa-flask-potion"/>
            <span>
                {{ 'Editar produto' }}
            </span>
        </div>
        <div
            class="app-modal-body"
            slot="body"
        >
            <div class="content">
                <div class="content__item">
                    <label class="app-label">
                        Produto:
                    </label>
                    <span>
                        {{ displayTextStockProductName }}
                    </span>
                </div>
                <div class="content__item">
                    <label class="app-label">
                        Atividade:
                    </label>
                    <span>
                        {{ displayTextStockProductActivityType }}
                    </span>
                </div>
                <div class="content__item">
                    <label class="app-label">
                        Quantidade a ser usada:
                    </label>
                    <span>
                        {{ displayTextStockProductUsedQuantity }}
                    </span>
                </div>
                <div class="content__item">
                    <label class="app-label">
                        Quantidade no estoque:
                    </label>
                    <span>
                        {{ displayTextStockProductQuantity }}
                    </span>
                </div>
                <div class="content__item">
                    <input-number
                        id="activity-product-quantity"
                        :label="displayTextInputNumberQuantityLabel"
                        placeholder="Informe a quantidade"
                        :data="activityProduct.quantity.value"
                        @input="activityProduct.quantity.value = $event"/>
                </div>
            </div>
        </div>
        <div class="app-modal-footer" slot="footer">
            <button class="btn btn-primary btn--flat" @click="saveActivityProduct" :disabled="saving || loading">
                <span v-if="saving" class="spinner-border" role="status"></span>
                <span v-else>
                    <span>Salvar</span>
                    <i class="ml-2 fas fa-save"/>
                </span>
            </button>
        </div>
    </Modal>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Modal from "@/components/systemUI/Modal.vue"
import InputNumber from "@/components/commons/InputNumber"
import _ from 'lodash'

export default {
    name: 'ModalFixProductQuantity',
    props: {
        data: Object,
        open: Boolean,
    },
    components: {
        Modal,
        InputNumber
    },
    data() {
        return {
            loading: false,
            saving: false,
            activityProduct: {
                quantity: {
                    value: null,
                    unit: {
                        _id: '',
                        name: ''
                    },
                    dosage: {
                        _id: '',
                        name: ''
                    }
                },
                stockProduct: {
                    product: {
                        name: '',
                        unit: ''
                    },
                    quantity: 0
                }
            }
        };
    },
    computed: {
        displayTextStockProductName() {
            return this.returnIfExists(this.activityProduct, "stockProduct.product.name") || "-"
        },
        displayTextStockProductActivityType() {
            const activityType = this.returnIfExists(this.activityProduct, "activity.type")
            return this.translateActivityType(activityType) || '-'
        },
        displayTextStockProductUsedQuantity() {
            const stockProductQuantityValue = this.returnIfExists(this.activityProduct, "quantity.value")
            if (!stockProductQuantityValue) {
                return '-'
            }

            const stockProductQuantityUnitName = this.returnIfExists(this.activityProduct, "quantity.unit.name")
            const stockProductQuantityDosageName = this.returnIfExists(this.activityProduct, "quantity.dosage.name")
            if (!stockProductQuantityUnitName || !stockProductQuantityDosageName) {
                return this.formatNumber(stockProductQuantityValue)
            }

            return `${this.formatNumber(stockProductQuantityValue)} ${stockProductQuantityUnitName}/${stockProductQuantityDosageName}`
        },
        displayTextStockProductQuantity() {
            const stockProductQuantity = this.returnIfExists(this.activityProduct, "stockProduct.quantity")
            if (!stockProductQuantity) {
                return '-'
            }

            const stockProductUnit = this.returnIfExists(this.activityProduct, "stockProduct.product.unit.name")
            if (!stockProductUnit) {
                return this.formatNumber(stockProductQuantity)
            }

            return `${this.formatNumber(stockProductQuantity)} ${stockProductUnit}`
        },
        displayTextInputNumberQuantityLabel() {
            const stockProductQuantityUnit = this.returnIfExists(this.activityProduct, "quantity.unit.name")
            const stockProductQuantityDosage = this.returnIfExists(this.activityProduct, "quantity.dosage.name")

            if (!stockProductQuantityUnit || !stockProductQuantityDosage) {
                return 'Quantidade'
            }

            return `Quantidade (${stockProductQuantityUnit}/${stockProductQuantityDosage})`
        }
    },
    methods: {
        ...mapActions("activities", ["updateActivityProduct"]),
        clearData() {
            Object.assign(this.$data, this.$options.data.apply(this));
        },
        translateActivityType(activityType) {
            const translatedActivityTypes = {
                "planting": "Plantio",
                "pulverization": "Pulverização",
                "harvest": "Colheita",
                "soilPreparation": "Preparo de solo",
                "seedTreatment": "Tratamento de sementes",
                "fertilization": "Fertilização",
                "otherActivity": "Outra atividade"
            }
            return translatedActivityTypes[activityType]
        },
        formatInput() {
            this.activityProduct = _.cloneDeep(this.data)
        },
        formatOutput() {
            return {
                value: this.activityProduct.quantity.value
            }
        },
        validateValue(value) {
            if (!value) {
                return this.$vToastify.error('É necessário digitar a quantidade para editar o produto', 'Produto');
            }
        },
        async saveActivityProduct() {
            try {
                const value = this.formatOutput()
                this.validateValue(value)

                this.saving = true

                const activityProductId = this.activityProduct._id
                const response = await this.updateActivityProduct({ activityProductId, value })

                this.saving = false

                const responseContainsError = response instanceof Error
                if (!responseContainsError) {
                    this.$vToastify.success(response.data.message, "Produtos");
                    this.$emit('save')
                    this.$emit('close')
                }
            } catch (error) {
                this.$vToastify.error(error.message, "Produtos")
            }
        }
    },
    mounted() {
        this.formatInput()
    }
};
</script>

<style scoped lang="sass">
.content
    display: flex
    flex-wrap: wrap

    &__item
        padding: 4px
        width: 50%

        &--full-width
            width: 100%

@media (max-width: 500px)
    .content
        &__item
            width: 100%


</style>
