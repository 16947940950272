import CulturePhase from '../../api/CulturePhase';

const state = {
    culturePhases: []
}

const getters = {
    getCulturePhases: state => state.culturePhases
}

const actions = {
    async findCulturePhases({ commit }) {
        const response = await CulturePhase.findCulturePhases()
        const culturePhases = response.data.data
        commit("SET_CULTURE_PHASES", culturePhases)
    }
}

const mutations = {
    SET_CULTURE_PHASES: (state, culturePhases) => state.culturePhases = culturePhases,
}

export default { state, getters, actions, mutations, namespaced: true }
