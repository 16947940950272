<template>
<div>
    <Modal :open="open" :loading="false" @close="$emit('close')">
        <div class="app-modal-header" slot="header">
            <i class="fa fa-file fa-lg"/>
            <span>Gerar Ordem de Serviço</span>
        </div>
        <div class="app-modal-body content" slot="body">
            <span>Selecione o período para gerar sua ordem de serviço:</span>
            <div class="date">
                <date-picker label="Data inicial" @dateSelected="getFirstDate"/>
                <date-picker label="Data final" @dateSelected="getSecondDate"/>
            </div>
        </div>
        <div class="app-modal-footer" slot="footer">
            <button class="btn btn-primary btn--flat" @click="generatePDF" :disabled="loadingPDF">
                <span v-if="loadingPDF" class="spinner-border" role="status"/>
                <span v-else>
                    <i class="fa fa-check"/>
                    Gerar OS
                </span>
            </button>
        </div>
    </Modal>
</div>
</template>

<script>
import DatePicker from '@/components/commons/DatePicker'
import Planning from "@/api/Planning";
import Modal from '@/components/systemUI/Modal'
import { mapGetters } from 'vuex'

export default {
    name: 'ModalServiceOrder',
    components: {
        Modal,
        DatePicker
    },
    props: {
        open: Boolean
    },
    data() {
        return {
            startDate: '',
            endDate: '',
            loadingPDF: false
        }
    },
    computed: {
        ...mapGetters("property", [
            "getPropertySelected",
            "getCropSelected",
        ]),
    },
    methods: {
        validateConvertHTMLtoPDFPayload(){
            const navBarValidation = this.validateNavBarChoices()
            const datesValidation = this.validateDates()
            if(!navBarValidation.success){
                return navBarValidation
            }
            return datesValidation
        },
        validateDates(){
            if (this.startDate === '') {
               return {
                    success: false,
                    message: "Selecione a data inicial"
               }
            }else if (this.endDate === '') {
               return {
                    success: false,
                    message: "Selecione a data final"
               }
            }

            if(new Date(this.startDate) > new Date(this.endDate)){
                return {
                    sucesso: false,
                    message: "A data inicial não pode ser maior que a final"
                }
            }

            return {
                success: true,
                message: ""
           }

        },
        validateNavBarChoices(){
            if(Object.keys(this.getPropertySelected).length == 0){
                return {
                    success: false,
                    message: "Selecione uma propriedade"
                }
            }
            if(Object.keys(this.getCropSelected).length == 0){
                return {
                    success: false,
                    message: "Selecione uma safra"
                }
            }
            return {
                success: true,
                message: ""
            }
        },
        async generatePDF() {
            const validationResult = this.validateConvertHTMLtoPDFPayload()
            if(validationResult.success){
                const propertyId = this.getPropertySelected._id
                const cropId = this.getCropSelected._id
                const startDate = this.startDate
                const endDate = this.endDate

                this.loadingPDF = true
                const response = await Planning.convertHTMLtoPDF(propertyId, cropId, startDate, endDate)
                this.loadingPDF = false

                if (response.status === 200 && response.data.pdf) {
                    this.$vToastify.success('PDF gerado com sucesso!')
                    this.goToPage(response.data.pdf)
                } else {
                    this.$vToastify.error(response.data.message, 'Erro')
                }
            }else{
                this.$vToastify.error(validationResult.message)
            }
        },
        goToPage(url){
            window.open(url, '_blank')
        },
        getFirstDate(event) {
            this.startDate = event.date;
        },
        getSecondDate(event) {
            this.endDate = event.date;
        }
    }
}
</script>

<style scoped lang="sass">
.content
    display: flex
    flex-direction: column
    grid-gap: 20px
    height: 100%
    align-items: center
    justify-content: center

    .date
        display: flex
        grid-gap: 20px

        .datePicker
            margin: 0
            padding: 0

@media (max-width: 490px)
    .content
        .date
            flex-direction: column
            width: 100%
</style>
