<template>
    <Modal :open="open" :loading="false" @close="$emit('close')">
        <div class="app-modal-header" slot="header">
            <div class="icon-planting"></div>
            <span>{{ !data._id ? 'Nova safra' : 'Editar safra' }}</span>
        </div>
        <div class="app-modal-body" slot="body">
            <div class="content">
                <div>
                    <label class="app-label" for="culture">Cultura</label>
                    <select class="app-select" id="culture" v-model="cropData.culture">
                        <option value="undefined" :selected="true">
                            Selecione a cultura
                        </option>
                        <option v-for="option in cultures" :key="option.name" :value="option._id">
                            {{ option.name }}
                        </option>
                    </select>
                </div>
                <div class="flex">
                    <DatePicker :dataSent="cropData.startDate" @dateSelected="cropData.startDate = $event.date"
                        label="Data inicial"/>
                    <DatePicker :dataSent="cropData.endDate" @dateSelected="cropData.endDate = $event.date"
                                label="Data final"/>
                </div>
                <div>
                    <label class="app-label">Nome</label>
                    <input class="app-input" placeholder="Nome da Safra" v-model="cropData.name"/>
                </div>
            </div>
        </div>
        <div class="app-modal-footer" slot="footer">
            <button class="btn btn-primary btn--flat" @click="saveCrop">
                <span v-if="loading" class="spinner-border" role="status"></span>
                <span v-else>Salvar<i class="ml-2 fas fa-save"/></span>
            </button>
        </div>
    </Modal>
</template>

<script>
import DatePicker from "@/components/commons/DatePicker";
import Modal from '@/components/systemUI/Modal';

import {mapActions, mapGetters} from "vuex";

export default {
    name: 'ModalCrop',
    props: {
        data: Object,
        open: Boolean,
    },
    components: {
        DatePicker,
        Modal
    },
    data() {
        return {
            loading: false,
            cropData: {
                properties: [],
                name: "",
                culture: "",
                startDate: "",
                endDate: "",
            },
        };
    },
    computed: {
        ...mapGetters("crops", ["cultures", "allCrops", "error"]),
        ...mapGetters("property", ["getPropertySelected",]),
        propertySelected() {
            return this.getPropertySelected;
        },
    },
    watch: {
        data() {
            this.copyData()
        }
    },
    mounted() {
        this.getCultures();
        this.copyData()
    },
    methods: {
        ...mapActions("crops", ["postCrop", "getCultures", "updateCrop"]),
        ...mapActions("property", ["listCrops"]),
        copyData() {
            this.cropData = {...this.data}
            if (!this.data._id) {
                this.cropData.properties = [{property: this.getPropertySelected}];
            } else {
                this.cropData.startDate = this.cropData.startDate.split('T')[0]
                this.cropData.endDate = this.cropData.endDate.split('T')[0]
            }
        },
        async saveCrop() {
            if (!this.cropData.startDate) {
                return this.$vToastify.error("Você precisa definir uma data incial para continuar!", "Safra");
            }
            if (!this.cropData.endDate) {
                return this.$vToastify.error("Você precisa definir uma data final para continuar!", "Safra");
            }
            if (new Date(this.cropData.startDate) > new Date(this.cropData.endDate)) {
                return this.$vToastify.error("A data inicial não pode ser maior que a data final!", "Safra");
            }
            if (!this.cropData.name) {
                return this.$vToastify.error("O campo nome é obrigatorio!", "Safra");
            }

            this.loading = true
            if (!this.data._id) {
                await this.postCrop(this.cropData)
                    .then(response => {
                        return this.$vToastify.success(response.data.message, "Safra");
                    })
                    .catch(error => {
                        return this.$vToastify.error(error.response.data.message, "Safra");
                    })
            } else {
                await this.updateCrop(this.cropData)
                    .then(response => {
                        return this.$vToastify.success(response.data.message, "Safra");
                    })
                    .catch(error => {
                        return this.$vToastify.error(error.response.data.message, "Safra");
                    })
            }
            this.loading = false

            this.$emit('save')
            this.$emit('close')
        },
    },
};
</script>

<style scoped lang="sass">
.container
    padding-left: 0
    padding-right: 0

.content
    display: flex
    flex-direction: column
    grid-gap: 20px

    .datePicker
        width: 100%

.icon-planting
    mask: url("../../../../../public/img/icons/svg/planting.svg") no-repeat 100% 100%
    @include icon-mask-sm
</style>
