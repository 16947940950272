<template>
    <div class="button-configuration">
        <img class="button-configuration__icon" src="/img/icons/svg/surface1.svg" alt=""/>
        <span>Configurações</span>
    </div>
</template>

<script>
export default {
    name: "ButtonConfiguration"
}
</script>

<style scoped lang="sass">
.button-configuration
    flex-direction: column

    &__icon
        width: 30px
        height: 30px

</style>
