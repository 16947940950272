import store from '@/store';

const formatDate = (date, language) => {
    date = getDate(date)
    if(!date){
        return ''
    }

    const loggedUserLanguage = getLoggedUserLanguage();
    const chosenLanguage = language || loggedUserLanguage;

    const { day, month, year } = getDateValues(date)

    switch(chosenLanguage){
        case 'pt-BR':
            return (day < 10 ? '0' + day : day) + '/' + (month < 10 ? '0' + month : month) + '/' + year;
        default:
            return year + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day)
    }
}

const formatTime = (date, language) => {
    date = getDate(date)
    if(!date){
        return ''
    }

    const loggedUserLanguage = getLoggedUserLanguage();
    const chosenLanguage = language || loggedUserLanguage;

    const { hour, minute, second } = getDateValues(date)

    switch(chosenLanguage){
        case 'pt-BR':
            return (hour < 10 ? '0' + hour : hour) + ':' + (minute < 10 ? '0' + minute : minute);
        default:
            return (hour < 10 ? '0' + hour : hour) + ':' + (minute < 10 ? '0' + minute : minute) + ':' + (second < 10 ? '0' + second : second);
    }
}

const formatDateTime = (date) => {
    date = getDate(date)
    if(!date){
        return ''
    }

    return formatDate(date) + ' ' + formatTime(date);
}

const getDateValues = (date) => {
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1;
    const year = date.getUTCFullYear();
    const hour = date.getUTCHours();
    const minute = date.getUTCMinutes();
    const second = date.getUTCSeconds();

    return { day, month, year, hour, minute, second }
}

const getDate = (date) => {
    try{
        return date ? new Date(date) : ''
    }catch(e){
        return ''
    }
}

const getLoggedUserLanguage = () => {
    const { lang } = store.state.users.user
    return lang || 'default'
}

export { formatDate, formatTime, formatDateTime }
