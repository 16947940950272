import API from './API'
import querystring from 'querystring'

export default {

    findWeatherHistorical(fieldsIds, startDate, endDate) {
        fieldsIds = JSON.stringify(fieldsIds)
        const query = querystring.encode({
            fieldsIds,
            startDate,
            endDate
        })
        return API.get(`weather/historical?${query}`)
    },

    findWeatherForecast(propertyId, fieldsIds, startDate, endDate) {
        const query = querystring.encode({
            propertyId,
            fieldsIds,
            startDate,
            endDate
        })
        return API.get(`weather/forecast?${query}`)
    },

    getWatherReports(propertyId) {
        const query = querystring.encode({
            propertyId,
        })
        return API.get(`reports/weather?${query}`)
    }

}