<template>
  <tr
    :class="{
      'alert alert-secondary': notification.read === false,
      'alert alert': notification.read === true,
    }"
  >
    <td>{{ notification.createdAt | formatDate }}</td>
    <td>
      <strong>{{ notification.title }}</strong>
      <p class="mb-0">{{ notification.text }}</p>
      <br />

      <router-link
        v-if="notification.title === 'Previsão do tempo'"
        tag="a"
        to="/tempo"
        style="color: #619b34; text-decoration: none"
        >Ver previsão{{ " " }}</router-link
      >

      <router-link
        v-if="
          notification.title === 'Atividade Agendada' ||
          notification.title === 'Atividades realizadas e atrasadas'
        "
        tag="a"
        to="/planejamento"
        style="color: #619b34; text-decoration: none"
        >Ver planejamento{{ " " }}</router-link
      >

      <router-link
        v-if="notification.title === 'Relatório NDVI'"
        tag="a"
        to="/relatorio/ndvi"
        style="color: #619b34; text-decoration: none"
        >Ver relatórios NDVI{{ " " }}</router-link
      >
      <i
        class="fas fa-arrow-right"
        style="color: #619b34; text-decoration: none"
      ></i>
    </td>

    <td v-if="notification.read === true" class="text-center">
      <a class="btn-default" href="#" @click="markNotification(notification)">
        <i class="fas fa-check-double"></i>
        Lida
      </a>
    </td>

    <td v-else class="text-center">
      <a class="btn-default" href="#" @click="markNotification(notification)">
        <i class="fas fa-check"></i>
        Marcar Lida
      </a>
    </td>
  </tr>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    notification: {
      type: Object,
    },
    propertySelectedId: {
      type: String,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {
    ...mapGetters("property", ["getError", "getNotifications"]),
  },
  watch: {
    getError(to) {
      this.$vToastify.error(to, "Ops!");
      this.clearError();
    },
  },
  methods: {
    ...mapActions("property", ["markNotificationAsReadOrUnread", "clearError"]),
    markNotification(notificationParam) {
      const payload = {
        notification: notificationParam,
        propertySelectedId: this.propertySelectedId,
      };
      this.markNotificationAsReadOrUnread(payload)
        .then(() => {
          if (payload.notification.read === true) {
            this.$vToastify.success("Marcada como não lida!", "Notificação");
          } else {
            this.$vToastify.success("Lida com sucesso!", "Notificação");
          }
        })
        .catch((err) => {
          this.$vToastify.error(
            "Não foi possivel realizar esta operação, tente novamente",
            "Notificação"
          );
        });
    },
  },
};
</script>
