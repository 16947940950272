import UnitConversion from '../../api/UnitConversion';

const state = {
    unitConversions: []
}

const getters = {
    getUnitConversions: state => state.unitConversions
}

const actions = {
    async findUnitConversions({ commit }) {
        const response = await UnitConversion.findUnitConversions()
        const unitConversions = response.data.data
        commit("SET_UNIT_CONVERSIONS", unitConversions)
    }
}

const mutations = {
    SET_UNIT_CONVERSIONS: (state, unitConversions) => state.unitConversions = unitConversions,
}

export default { state, getters, actions, mutations, namespaced: true }
