import API from './API'

export default {
    getAllByProperty(propertyId, fieldId = '') {
        return API.get(`photos/properties/${propertyId}?fieldId=${fieldId}`)
    },
    findPhotos({propertyId, cropId, fieldId, fieldIdList, subareaId, startDate, endDate}){
        return API.get(`photos`, {
            params: {
                ... propertyId ? { propertyId } : {},
                ... cropId ? { cropId } : {},
                ... fieldId ? { fieldId } : {},
                ... fieldIdList && fieldIdList.length ? { fieldIdList: JSON.stringify(fieldIdList) } : {},
                ... subareaId ? { subareaId } : {},
                ... startDate ? { startDate } : {},
                ... endDate ? { endDate } : {},
            }
        })
    },
    getPhotosReport(propertyId, cropId, fieldIdList, subareaId, startDate, endDate, template){
    	return API.get(`reports/photos/`, {
            params: {
                ... propertyId ? { propertyId } : {},
                ... cropId ? { cropId } : {},
                ... fieldIdList && fieldIdList.length ? { fieldIdList: JSON.stringify(fieldIdList) } : {},
                ... subareaId ? { subareaId } : {},
                ... startDate ? { startDate } : {},
                ... endDate ? { endDate } : {},
                ... template ? { template } : {}
            }
        })
    },
	update(id, photo) {
		return API.put(`photos/${id}`, photo)
	},
    delete(id) {
        return API.delete(`photos/${id}`)
    }
}
