<template>
    <div class="report-monitoring">
        <div class="report-container">
            <router-link
                class="report-container__btn btn btn-primary btn--rounded btn--flat"
                tag="a"
                to="/relatorios"
            >
                <i class="fa fa-arrow-left"/>
                <span>
                    Voltar
                </span>
            </router-link>
            <h2 class="report-container__title">
                Relatório Monitoramento
            </h2>
        </div>
        <div class="filter-container">
            <div class="filter-container__item">
                <label class="app-label">
                    Período
                </label>
                <period-select-picker
                    :selectedInterval="selectedInterval"
                    @intervalSelected="selectedInterval = $event.interval"
                />
            </div>
            <div class="filter-container__item filter-container__item--multiple-selector">
                <multiple-selector
                    label="Talhão(ões)"
                    placeholder="Selecione o(s) talhão(ões)"
                    :items="allFields"
                    item-text="name"
                    item-value="_id"
                    v-model="selectedFields"
                />
            </div>
            <div class="filter-container__item filter-container__item--multiple-selector">
                <multiple-selector
                    label="Tipos de agentes danosos"
                    placeholder="Selecione os agenes danosos"
                    :items="pestilenceTypes"
                    item-text="text"
                    item-value="value"
                    v-model="selectedPestilenceTypes"
                />
            </div>
            <div class="filter-container__item">
                <label class="app-label">
                    Incluir fotos no relatório
                </label>
                <select
                    class="app-select"
                    v-model="selectedIncludePhotos"
                >
                    <option :value="true">
                        Sim
                    </option>
                    <option :value="false">
                        Não
                    </option>
                </select>
           </div>
            <div class="filter-container__item filter-container__date">
                <date-picker
                    :disabled="!selectedCustom"
                    :dataSent="periodSend.startDate"
                    @dateSelected="periodSend.startDate = $event.date"
                    label="Data inicial"
                />
                <date-picker
                    :disabled="!selectedCustom"
                    :dataSent="periodSend.endDate"
                    @dateSelected="periodSend.endDate = $event.date"
                    label="Data final"
                />
            </div>
            <div class="filter-container__item filter-container__action">
                <button
                    class="btn btn-primary--outlined btn--flat"
                    :disabled="isLoadingPDF"
                    @click="generatePDF()"
                >
                    <span
                        v-if="isLoadingPDF"
                        class="spinner-border spinner-border-sm" role="status"
                    />
                    <span v-else>
                        <i class="fa fa-file-pdf mr-2"/>
                        <span>
                            Baixar PDF
                        </span>
                    </span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import DatePicker from "@/components/commons/DatePicker.vue";
import PeriodSelectPicker from "@/components/commons/PeriodSelectPicker.vue";
import MultipleSelector from '@/components/commons/MultipleSelector.vue';
import { calculatePeriodInterval } from "@/utils/calculatePeriodInterval";
import { mapGetters, mapActions } from "vuex";

export default {
    name: 'ReportMonitoring',
  components: {
    DatePicker,
    PeriodSelectPicker,
    MultipleSelector
  },
  data() {
    return {
        isLoadingPDF: false,
        selectedInterval: "weekly",
        periodSend: {
            custom: false,
            startDate: "",
            endDate: "",
        },
        pestilenceTypes: [
            {
                text: 'Praga',
                value: 'prague'
            },
            {
                text: 'Doença',
                value: 'disease'
            },
            {
                text: 'Planta daninha',
                value: 'plant'
            }
        ],
        selectedPestilenceTypes: [
            'prague',
            'disease',
            'plant'
        ],
        selectedFields: [],
        selectedIncludePhotos: false,
        monitoringReportPayload: {
            propertyId: "",
            cropId: "",
            startDate: "",
            endDate: "",
            fieldIdList: [],
            pestilenceTypes: [],
            includePhotos: false
        }
    };
  },

  async mounted() {
    this.selectedInterval = "weekly"
    await this.updateFilter()
  },

  computed: {
    ...mapGetters("property", [
      "getCropSelected",
      "getPropertySelected",
    ]),
    ...mapGetters("fields", [
        "allFields"
    ]),
    selectedCustom(){
        return this.selectedInterval == 'custom'
    }
  },
  methods: {
    ...mapActions("fields", [
        "getFieldsByProperty",
    ]),
    ...mapActions("reports", [
        "findMonitoringReport"
    ]),
    updatePayload(){
        const payload = {
            propertyId: this.getPropertySelected._id,
            cropId: this.getCropSelected._id,
            fieldIdList: this.selectedFields,
            startDate: this.periodSend.startDate,
            endDate: this.periodSend.endDate,
            pestilenceTypes: this.selectedPestilenceTypes,
            includePhotos: this.selectedIncludePhotos
        }
        return payload
    },
    async updateFilter(){
        this.periodSend = calculatePeriodInterval(this.selectedInterval, this.getCropSelected)
        const payload = this.updatePayload()
        await this.getFieldsByProperty(payload)
    },
    validateFilter({ startDate, endDate }) {
        if (!endDate) {
            throw new Error("Informe a data final");
        }
        if (!startDate) {
            throw new Error("Informe a data inicial");
        }
        if (new Date(endDate) < new Date(startDate)) {
            throw new Error("A data final não pode ser menor que a inicial");
        }
        if (this.dateDiffInDays(new Date(startDate), new Date(endDate)) > 180) {
            throw new Error("O intervalo entre das datas não deve ser maior que 6 meses");
        }
    },
    async generatePDF() {
        try {
            this.isLoadingPDF = true

            const payload = this.updatePayload();
            this.validateFilter(payload);

            const { propertyId, cropId, fieldIdList, startDate, endDate, pestilenceTypes, includePhotos } = payload;
            const response = await this.findMonitoringReport({ propertyId, cropId, fieldIdList, startDate, endDate, pestilenceTypes, includePhotos })

            if (response.status === 200 && response.data.pdf) {
                this.$vToastify.success('PDF gerado com sucesso!')
                this.goToPage(response.data.pdf)
            }

            this.isLoadingPDF = false
        } catch (error) {
            this.isLoadingPDF = false
            this.$vToastify.error(error.message, "Monitoramento")
        }
    },
    goToPage(url) {
        window.open(url, '_blank')
    }
  },
  watch: {
    async getPropertySelected() {
        await this.updateFilter()
    },
    async getCropSelected() {
        await this.updateFilter()
    },
    async selectedInterval(){
        await this.updateFilter()
    }
  },
};
</script>

<style scoped lang="sass">
.report-monitoring
    height: 100%
    grid-gap: 10px

    .report-container
        display: flex
        align-items: center
        justify-content: center
        position: relative
        padding: 20px 0
        width: 100%

        &__btn
            position: absolute
            left: 0

        &__title
            font-size: 16px
            margin: 0

    .filter-container
        display: flex
        justify-content: center
        align-items: flex-end
        padding: 10px 0
        flex-wrap: wrap
        border: $border
        border-radius: $border-radius

        &__item
            flex-grow: 1
            padding: 10px

            &--hint-text
                width: 100%
                color: $color-blue

        &__action
            display: flex
            grid-gap: 10px
            justify-content: center
            min-width: 350px


        &__date
            display: flex
            grid-gap: 10px
            min-width: 350px

            .datePicker
                width: 100%

            #date
                height: 40px

    .no-content
        display: flex
        justify-content: center
        align-items: center
        height: 100px
        color: $color-grey
        grid-gap: 10px
</style>
