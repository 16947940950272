<template>
    <div class="user-container">
        <div class="user-container__header">
            <span class="title">Usuários</span>
            <div class="actions">
                <div class="search-container">
                    <input
                        class="search-container__input app-input"
                        placeholder="Pesquise os usuários"
                        v-model="searchWord"
                        @keyup.enter="filterUsers"
                        type="text"
                    />
                    <button
                        class="search-container__clear clickable"
                        v-show="searchWord.length > 0"
                        @click="cleanSearch"
                    >
                        <i class="fal fa-times-circle" />
                    </button>
                    <button
                        class="search-container__search"
                        @click="filterUsers"
                    >
                        <i class="fas fa-search" />
                    </button>
                </div>
                <button
                    class="btn btn-primary btn--flat"
                    @click="openModalCreateUser()"
                >
                    <span>Novo usuário</span>
                </button>
            </div>
        </div>

        <div v-if="!loading" class="user-container__content">
            <table class="user-table">
                <tr>
                    <th>Nome</th>
                    <th>Email</th>
                    <th>Telefone</th>
                    <th>Tipo de Usuário</th>
                    <th class="user-table-header__actions">Ações</th>
                </tr>
                <tr v-for="user in usersList" :key="user._id">
                    <td>{{ user.name }} {{user.lastname }}</td>
                    <td>{{ user.email }}</td>
                    <td>{{ user.phone }}</td>
                    <td> {{ formatTextUserType(user) }}</td>
                    <td>
                        <div class="user-table-content__actions">
                            <button
                                @click="openModalEditUser(user)"
                                class="btn btn--flat"
                            >
                                <i class="fas fa-edit"></i>
                                <span>Editar</span>
                            </button>
                            <button
                                type="checkbox"
                                class="btn btn--flat"
                                @click="activeDeactiveUser(user)"
                            >
                                <i :class="user.active ? 'fas fa-check-square' : 'far fa-square'" />
                                <span> {{ user.active ? "Desativar" : "Reativar" }} </span>
                            </button>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
        <div v-else-if="loading" class="no-content">
            <span class="spinner-border" />
        </div>
        <div v-else class="user-container__content">
            <div class="text-center p-2" style="background-color: #f5f5f5">
                <span class="mx-auto">Não possui dados</span>
            </div>
        </div>
        <modal-user
            v-if="showModalUser"
            :open="showModalUser"
            :data="user"
            @close="closeModalEditUser()"
            @inserted="listUsers"
        />
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ModalUser from "@/views/configurations/users/components/ModalUser.vue";

export default {
    name: "Users",
    components: {
        ModalUser,
    },
    data() {
        return {
            usersList: [],
            showModalUser: false,
            searchWord: "",
            loading: false,
            user: {},
        };
    },
    computed: {
        ...mapGetters("users", ["profile", "getUsers"]),
        ...mapGetters("property", ["getPropertySelected"]),
        
    },
    async mounted() {
        await this.listUsers();
    },
    methods: {
        ...mapActions("users", ["listUser", "updateUser"]),
        ...mapActions("property", ["listProperty"]),
        openModalCreateUser() {
            this.user = {
                properties: [],
                name: "",
                lastname: "",
                email: "",
                password: "",
                role: [],
                featureList: [],
                phone: "",
                emailNotification: false,
                phoneNotification: false,
            },
            this.showModalUser = true
        },
        formatTextUserType(user){
            const hashRole = {
                "admin": "Gestor Agrointeli",
                "farmer-admin": "Gerente da Conta",
                "farmer": "Usuário"
            }
            return user.role.includes('farmer-admin') ? hashRole['farmer-admin'] : hashRole['farmer']
        },
        openModalEditUser(user) {
            this.user = { ...user };
            
            const formatPropertyBeforeEdit = this.user.properties.map(e =>{
                return e.property._id
            })

            this.user.password = undefined
            
            this.user.properties = formatPropertyBeforeEdit
            this.showModalUser = true;
        },
        closeModalEditUser() {
            this.user = {}
            this.showModalUser = false;
        },
        async listUsers() {
            this.loading = true;

            const accountId = this.getPropertySelected.account._id;
            await this.listUser({ accountId });

            const userId = this.profile._id
            await this.listProperty({ accountId, userId });

            this.usersList = [
                ...this.getUsers.sort((a, b) =>
                    a.createdAt < b.createdAt ? 1 : -1
                ),
            ];

            this.loading = false;
        },
        async activeDeactiveUser(user) {
            const { _id, active } = {
                _id: user._id,
                active: !user.active,
            };
            const confirmActiveDeactive = await this.$vToastify.prompt({
                body: "Deseja confirmar está ação?",
                title: "ATENÇÃO!",
                answers: { Sim: true, Não: false },
            });
            if (confirmActiveDeactive) {
                await this.updateUser({ _id, active });
                await this.listUsers();
                const sucessText =
                    user.active == true ? "Desativado" : "Reativado";
                this.$vToastify.success(
                    `${sucessText} com sucesso`,
                    "Usuário"
                );
            } else return;
        },
        filterUsers() {
            this.usersList = this.getUsers.filter((user) => {
                return (
                    user.name
                        .toUpperCase()
                        .includes(this.searchWord.toUpperCase()) ||
                    user.lastname
                        .toUpperCase()
                        .includes(this.searchWord.toUpperCase()) ||
                    user.email
                        .toUpperCase()
                        .includes(this.searchWord.toUpperCase()) ||
                    user.phone
                        .toUpperCase()
                        .includes(this.searchWord.toUpperCase())
                );
            });
        },
        cleanSearch() {
            this.searchWord = "";
            this.usersList = [
                ...this.getUsers.sort((a, b) =>
                    a.createdAt < b.createdAt ? 1 : -1
                ),
            ];
        },
    },
    watch: {
        getUsers() {
            this.usersList = [...this.getUsers];
            this.usersList.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));
        },
        searchWord() {
            this.filterUsers();
        },
        getUserSelected() {
            this.searchWord = "";
        },
    },
};
</script>

<style scoped lang="sass">
.user-container
    display: flex
    flex-direction: column
    grid-gap: 6px
    width: 100%

    &__header
        .title
            font-size: 24px
            color: $theme-color-primary

        .actions
            display: flex
            justify-content: space-between

            .search-container
                display: flex
                position: relative

                &__input
                    width: 300px

                &__search
                    position: absolute
                    right: 8px
                    top: 8px
                    border: 0
                    background: none

                &__clear
                    position: absolute
                    right: 36px
                    top: 7px
                    border: 0
                    background: none

.user-table
    width: 100%

    &-header__actions
        width: 30%
        text-align: center

    &-content__actions
        display: flex
        grid-gap: 6px
        align-items: center
        justify-content: center

        .btn
            background: none

            &:hover
                background: $color-white-darken-1

            i
                color: $color-blue

.user-table th
    color: $color-blue
    padding: 0 10px
    font-size: 16px

.user-table tr
    border-radius: 20px
    height: 70px

.user-table td
    padding: 0 10px

.user-table tr:nth-child(even)
    background-color: #f2f2f2

.no-content
    display: flex
    justify-content: center
    align-items: center
    height: 100px
    color: $color-grey
    grid-gap: 10px

@media (max-width: 430px)
    .user-container__header .actions .search-container__input
        width: 200px
</style>
