<template>
    <div class="form-group comboBoxFields" :class="classParameter">
        
		<label v-if="title">{{title}}</label>
        
		<div class="input-group">
            <select v-model="objectSelected" class="form-control btn btn-outline-secondary">
                <option  value="" >{{msgFirstItem}}</option>
                <option               
                    v-for="object in listObjects"
                    :key="object._id"
                    :value="object"
                >
                    {{ object.attributeDisplay }}
                </option>
            </select>
		</div>
        
	</div>   
</template>
<script>
export default {
  name: "ComboBox",
  props: {
    title: {
      type: String,
    },
    classParameter: {
      type: String,
    },
    msgFirstItem: {
      type: String,
    },
    listObjectsParameter:{
      type: Array
    },
    attributeDisplay:{
      type: String
    }
  },
  data() {
    return {
      objectSelected: "",
      listObjects: this.listObjectsParameter,
    };
  },
  watch: {
    objectSelected() {
      this.$emit("objectSelectedList", { object: this.objectSelected });
    },
  },
};
</script>
<style scoped>
.comboBoxFields {
  margin-bottom: 10px;
  margin-top: 10px;  
}
</style>