<template>
    <div>
        <label
            class="app-label"
            v-show="label"
            :for="`${id}-input`"
        >
            {{ label }}
        </label>
        <div class="selectable-container">
            <input
                class="app-input"
                type="text"
                :id="`${id}-input`"
                :placeholder="placeholder"
                v-model="number"
                @blur="emitFormattedOutput"
            >
            <select
                class="app-select"
                :id="`${id}-select`"
                v-model="unitId"
            >
               <option
                    v-for="unit in formattedUnits"
                    :key="`${unit._id}-unit`"
                    :value="unit._id"
                >
                    {{ unit.label }}
                </option>
            </select>
            <select
                class="app-select"
                :id="`${id}-select`"
                v-model="dosageId"
                v-show="hasDosage"
            >
               <option
                    v-for="dosage in formattedDosages"
                    :key="`${dosage._id}-dosage`"
                    :value="dosage._id"
                >
                    {{ dosage.label }}
                </option>
           </select>
        </div>
    </div>
</template>

<script>
export default {
    name: "AppSelectorNumberUnit",
    props: {
        id: {
            type: String,
            required: true
        },
        label: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            required: true
        },
        value: {
            type: Object,
            default: () => {}
        },
        units: {
            type: Array,
            default: () => []
        },
        dosages: {
            type: Array,
            default: () => []
        },
        hasDosage: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            unitId: null,
            dosageId: null,
            number: ''
        }
    },
    computed: {
        formattedOutput() {
            const output = {
                unitId: this.unitId || null,
                value: this.unformatNumber(this.number)
            }

            if (this.hasDosage) {
                output.dosageId = this.dosageId || null
            }
            return output
        },
        formattedDosages() {
            return this.dosages.map(dosage => ({
                _id: dosage._id,
                label: `/${dosage.name}`,
                value: dosage._id
            }))
        },
        formattedUnits() {
            return this.units.map(unit => ({
                _id: unit._id,
                label: unit.name,
                value: unit._id
            }))
        },
        inputIsDifferentFromCurrentValue() {
            const { unitId, dosageId, value } = this.value
            if (this.unitId != unitId) {
                return true
            }
            if (this.hasDosage && this.dosageId != dosageId) {
                return true
            }

            //const formattedNumber = value ? this.formatNumber(value) : ''
            const formattedNumber = value ? formatNumberWithThreeDecimalPlaces(value) : ''
            if (this.number != formattedNumber) {
                return true
            }

            return false
        }
    },
    watch: {
        dosageId() {
            this.emitFormattedOutput()
        },
        unitId() {
            this.emitFormattedOutput()
        },
        value() {
            if (this.inputIsDifferentFromCurrentValue) {
                this.syncInput()
            }
        },
        number() {
            this.$nextTick(() => {
                this.number = this.formatInputNumberWithThreeDecimalPlaces(this.number)
                //this.number = this.formatInputNumber(this.number)
            })
        }
    },
    methods: {
        emitFormattedOutput() {
            this.$emit('input', this.formattedOutput)
        },
        syncInput() {
            const { unitId, dosageId, value } = this.value

            this.unitId = unitId || null
            this.dosageId = this.hasDosage && dosageId ? dosageId : null
            //this.number = value ? this.formatNumber(value) : ''
            this.number = value ? this.formatNumberWithThreeDecimalPlaces(value) : ''
        }
    },
    mounted() {
        this.syncInput()
    }
}
</script>

<style scoped lang="sass">
.selectable-container
    display: flex

    .app-input
        text-align: center
        border-top-right-radius: unset
        border-bottom-right-radius: unset

    .app-select
        border-radius: 0
        background-color: $color-grey-lighten-2
        width: 200px
        text-overflow: ellipsis
        white-space: nowrap
        overflow: hidden



</style>
