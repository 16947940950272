<template>
    <div class="map-container">
        <div class="map-container__container">
            <iframe :src="windyURL" />
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "MapWindy",
    computed: {
        ...mapGetters("property", ["getPropertySelected"]),
        ...mapGetters("weather", ["getWeather"]),
        windyURL() {
            return (
                "https://embed.windy.com/embed2.html" +
                `?lat=${this.getPropertySelected.latitude}` +
                `&lon=${this.getPropertySelected.longitude}` +
                `&zoom=11` +
                `&level=surface` +
                `&overlay=rain` +
                `&menu=` +
                `&message=` +
                `&marker=true` +
                `&calendar=` +
                `&pressure=` +
                `&type=map` +
                `&location=coordinates` +
                `&detail=` +
                `&detailLat=${this.getPropertySelected.latitude}` +
                `&detailLon=-${this.getPropertySelected.longitude}` +
                `&metricWind=default` +
                `&metricTemp=default` +
                `&radarRange=-1`
            );
        },
    },
};
</script>

<style lang="sass" scoped>
.map-container
    width: 100%
    height: 100%

    &__container
        width: 100%
        height: 100%

        iframe
            width: 100%
            height: 100%
            border: 0
</style>

