import API from "./API";
import querystring from 'querystring'

export default {
    get() {
        return API.get(`users/current`);
    },

    getAll(accountId, active) {
        const query = querystring.encode({
            accountId,
            ...active != undefined ? { active } : {}
        })
        return API.get(`users?${query}`)
    },

    createUser(user){
        return  API.post(`users`, user)
    },
    
    updateUser(user){
        return API.put(`users/${user._id}`, user)
    },

    auth(data) {
        const config = {
            headers: {
                'X-Application': 'web'
            }
        }
        return API.post(`users/login`, data, config);
    },
};
