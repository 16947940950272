<template>
    <div class="fields-container">
        <div class="fields-container__header">
            <span class="title">Talhões</span>
            <div class="actions">
                <div class="search-container">
                    <input class="search-container__input app-input" placeholder="Pesquise um talhão"
                           v-model="searchWord"
                           @keyup.enter="filterField" type="text"/>
                    <button class="search-container__clear clickable" v-show="searchWord.length > 0"
                            @click="cleanSearch">
                        <i class="fal fa-times-circle"/>
                    </button>
                    <button class="search-container__search" @click="filterField">
                        <i class="fas fa-search"/>
                    </button>
                </div>
                <span class="area-total">{{ usedArea }}</span>
                <button class="btn btn-primary btn--flat" @click="openModalCreateField">
                    <span>Novo talhão</span>
                </button>
            </div>
            <div>
                <div class="app-checkbox">
                    <input id="ignoreCropFilter" type="checkbox" v-model="ignoreCropFilter"/>
                    <label for="ignoreCropFilter"> Ignorar filtro de safra </label>
                </div>
            </div>
        </div>

        <div v-if="!loading && fieldsList.length > 0" class="fields-container__content">
            <table class="fields-table">
                <tr>
                    <th>Nome</th>
                    <th v-show="!ignoreCropFilter">Subárea (cultivares)</th>
                    <th>Tamanho (ha)</th>
                    <th class="fields-table-header__actions">Ações</th>
                </tr>
                <tr v-for="object in fieldsList" :key="object._id">
                    <td>{{ object.name }}</td>
                    <td v-show="!ignoreCropFilter">
                        <span v-show="object.subarea.length > 0" v-for="(objectSubArea, index) in object.subarea"
                              :key="index">
                          <span v-if="index > 0">{{ `, ${objectSubArea.seed}` }}</span>
                          <span v-else>{{ objectSubArea.seed }}</span>
                        </span>
                        <span v-show="object.subarea.length <=0">-</span>
                    </td>
                    <td>
                        <span v-if="object.size !== undefined">
                            {{ object.size.value | formatNumber }} {{ object.size.unity }}
                        </span>
                        <span v-else>-</span>
                    </td>
                    <td>
                        <div class="fields-table-content__actions">
                            <button @click="openModalEditField(object)"
                                    class="btn btn--flat">
                                <i class="fas fa-edit"></i>
                                <span>Editar</span>
                            </button>
                            <router-link class="btn btn--flat"
                                         :to="`/bens/talhoes/${object._id}/fotos`">
                                <i class="fas fa-images"></i>
                                <span>Fotos</span>
                            </router-link>
                            <router-link class="btn btn--flat"
                                         :to="`/bens/talhoes/${object._id}/visao-geral`">
                                <i class="material-icons">timeline</i>
                                <span>Visão Geral</span>
                            </router-link>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
        <div v-else-if="loading" class="no-content">
            <span class="spinner-border"/>
        </div>
        <div v-else class="fields-container__content">
            <table class="fields-table">
                <tr>
                    <th>Nome</th>
                    <th v-show="!ignoreCropFilter">Subárea (cultivares)</th>
                    <th>Tamanho (ha)</th>
                    <th class="fields-table-header__actions">Ações</th>
                </tr>
            </table>
            <div class="text-center p-2" style="background-color: #F5F5F5">
                <span class="mx-auto">Não possui dados</span>
            </div>
        </div>
        <modal-field
            :open="showModalField"
            v-if="showModalField"
            :data="field"
            @close="closeModalField"
            @inserted="listFieldSubAreas"
        />
    </div>
</template>

<script>
import subareaApi from "@/api/Subarea";
import {mapActions, mapGetters} from "vuex";
import ModalField from '@/views/configurations/fields/components/ModalField.vue'

export default {
    name: 'Field',
    components: {
        ModalField,
    },
    data() {
        return {
            ignoreCropFilter: false,
            field: {
                name: '',
                size: {
                    unity: '',
                    value: ''
                },
                subarea: []
            },
            loading: true,
            searchWord: '',
            fieldsList: [],
            showModalField: false,
        }
    },
    computed: {
        ...mapGetters("property", ["getPropertySelected", "getCropSelected", "getProperties"]),
        ...mapGetters("fields", ["allFields"]),
        usedArea() {
            const totalFieldsArea = this.allFields.reduce((partialSum, field) => partialSum + (this.returnIfExists(field, 'size.value') || 0),  0);

            const propertySelected = this.getProperties.find(property => this.getPropertySelected._id == property._id);

            const propertySelectedArea = this.returnIfExists(propertySelected, 'size') || 0;
            
            return `Área utilizada (ha): ${this.formatNumber(totalFieldsArea)}/${this.formatNumber(propertySelectedArea)}`;
        },
    },
    async mounted() {
        await this.listFieldSubAreas()
    },
    methods: {
        ...mapActions("fields", ["getFieldsByProperty"]),
        openModalCreateField() {
            this.field = {
                name: '',
                size: {
                    unity: 'hectare',
                    value: ''
                },
                subarea: []
            }
            this.showModalField = true
        },
        openModalEditField(field) {
            this.field = field
            this.showModalField = true
        },
        closeModalField() {
            this.showModalField = false
            this.field = {
                name: '',
                size: {
                    unity: 'hectare',
                    value: ''
                },
                subarea: []
            }
        },
        async listFieldSubAreas() {
            this.loading = true

            await this.getFieldsByProperty({ propertyId: this.getPropertySelected._id, cropId: this.ignoreCropFilter ? null : this.getCropSelected._id })
            const subareas = await subareaApi.findByPropertyAndCrop(this.getPropertySelected._id, this.getCropSelected._id)
            this.fieldsList = this.allFields

            this.fieldsList.map(field => {
                field.subarea = []
                subareas.filter(subarea => {
                    if (subarea.field._id == field._id) {
                        field.subarea.push(subarea)
                    }
                })
            })
            this.fieldsList.sort((a, b) => a.createdAt > b.createdAt ? -1 : 1)

            this.loading = false
        },
        async cleanSearch() {
            this.searchWord = ''
            await this.listFieldSubAreas()
        },
        filterField(){
            this.fieldsList = this.allFields.filter((field) => {
                return (
                    field.name
                        .toUpperCase()
                        .includes(this.searchWord.toUpperCase()) 
                );
            })
        }
    },
    watch: {
        async getPropertySelected() {
            await this.listFieldSubAreas()
        },
        async getCropSelected() {
            await this.listFieldSubAreas()
        },
        async ignoreCropFilter() {
            await this.listFieldSubAreas()
        },
        searchWord() {
            this.filterField();
        },
    }
}
</script>

<style scoped lang="sass">
.fields-container
    display: flex
    flex-direction: column
    grid-gap: 6px
    width: 100%

    &__header
        .title
            font-size: 24px
            color: $theme-color-primary

        .area-total
            font-size: 20px
            color: $theme-color-primary

        .actions
            display: flex
            justify-content: space-between

            .search-container
                display: flex
                position: relative

                &__input
                    width: 300px

                &__search
                    position: absolute
                    right: 8px
                    top: 8px
                    border: 0
                    background: none

                &__clear
                    position: absolute
                    right: 36px
                    top: 7px
                    border: 0
                    background: none


.fields-table
    width: 100%

    &-header__actions
        width: 30%
        text-align: center

    &-content__actions
        display: flex
        grid-gap: 6px
        align-items: center
        justify-content: center

        .btn
            background: none

            &:hover
                background: $color-white-darken-1

            i
                color: $color-blue

.fields-table th
    color: $color-blue
    padding: 0 10px
    font-size: 16px

.fields-table tr
    border-radius: 20px
    height: 70px

.fields-table td
    padding: 0 10px

.fields-table tr:nth-child(even)
    background-color: #f2f2f2

.no-content
    display: flex
    justify-content: center
    align-items: center
    height: 100px
    color: $color-grey
    grid-gap: 10px

@media (max-width: 430px)
    .fields-container__header .actions .search-container__input
        width: 200px

</style>
