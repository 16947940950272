<template>
    <Modal
        :open="open"
        :loading="loading"
        @close="$emit('close')"
    >
        <div
            class="app-modal-header"
            slot="header"
        >
            <i class="fas fa-flask-potion"></i>
            <span>
                {{ !data._id ? 'Novo produto' : 'Editar produto' }}
            </span>
        </div>
        <div
            class="app-modal-body"
            slot="body"
        >
            <div class="content">
                <div class="content__title">
                    Para adicionar, coloque os dados do produto.
                </div>
                <div class="content__item">
                    <label class="app-label">Nome</label>
                    <input
                        class="app-input"
                        placeholder="Nome do produto"
                        v-model="product.name"
                    />
                </div>
                <div
                    class="content__item"
                    v-if="!isUpdateModal()"
                >
                    <label
                        class="app-label"
                        for="product-unity"
                    >
                        Unidade
                    </label>
                    <select
                        id="product-unity"
                        v-model="product.unit"
                        class="app-select"
                    >
                        <option
                            selected
                            disabled
                            :value="null"
                        >
                            Selecione a unidade
                        </option>
                        <option
                            v-for="unit in productUnits"
                            :key="unit._id"
                            :value="unit._id"
                        >
                            {{ unit.name }}
                        </option>
                    </select>
                </div>
                <div class="content__item">
                    <multiple-selector
                        label="Culturas"
                        placeholder="Selecione as culturas"
                        :items="getCultures"
                        item-text="name"
                        item-value="_id"
                        v-model="product.cultures"
                    />
                </div>
                <div class="content__item">
                    <label
                        class="app-label"
                        for="product-category"
                    >
                        Categoria
                    </label>
                    <select
                        id="product-category"
                        v-model="selectedCategory"
                        class="app-select"
                    >
                        <option
                            disabled
                            selected
                            value=""
                        >
                            Selecione a categoria
                        </option>
                        <option
                            v-for="productCategory in getProductCategories"
                            :key="productCategory._id"
                            :value="productCategory._id"
                        >
                            {{ productCategory.name }}
                        </option>
                    </select>
                </div>
                <div
                    class="content__item"
                    v-if="getProductSubCategoriesByProductCategoryId(selectedCategory).length > 0"
                >
                    <label
                        class="app-label"
                        for="product-category"
                    >
                        Sub Categoria
                    </label>
                    <select
                        id="product-category"
                        v-model="selectedSubCategory"
                        class="app-select"
                    >
                        <option
                            disabled
                            selected
                            value=""
                        >
                            Selecione a subcategoria
                        </option>
                        <option
                            v-for="productSubCategory in getProductSubCategoriesByProductCategoryId(selectedCategory)"
                            :key="productSubCategory._id"
                            :value="productSubCategory._id"
                        >
                            {{ productSubCategory.name }}
                        </option>
                    </select>
                </div>
            </div>
            <CheckboxGroup
                v-if="editProduct == false"
                class="content__title"
                :title="'Propriedades onde está disponível'"
                :items="userProperties"
                :value="propertiesIds"
                @input="propertiesIds = $event"
            />
        </div>
        <div
            class="app-modal-footer"
            slot="footer"
        >
            <button
                class="btn btn-primary btn--flat"
                @click="saveProduct"
                :disabled="saving || loading"
            >
                <span
                    v-if="saving"
                    class="spinner-border"
                    role="status">
                </span>
                <span v-else>
                    <span>Salvar</span>
                    <i class="ml-2 fas fa-save"/>
                </span>
            </button>
        </div>
    </Modal>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Modal from "@/components/systemUI/Modal.vue"
import MultipleSelector from '@/components/commons/MultipleSelector.vue'
import CheckboxGroup from '@/components/commons/CheckboxGroup.vue'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid';

export default {
    name: 'ModalProduct',
    props: {
        data: Object,
        open: Boolean,
        editProduct: Boolean,
    },
    components: {
        Modal,
        MultipleSelector,
        CheckboxGroup
    },
    data() {
        return {
            loading: false,
            saving: false,
            product: {
                name: "",
                unit: null,
                property: null,
                cultures: [],
                productCategory: ""
            },
            selectedCategory: "",
            selectedSubCategory: "",
            userProperties: [],
            propertiesIds: [],
            uuid: uuidv4(),
        };
    },
    computed: {
        ...mapGetters("product-categories", ["getProductCategories"]),
        ...mapGetters("cultures", ["getCultures"]),
        ...mapGetters("units", ["getUnits"]),
        ...mapGetters("property", ["getPropertySelected"]),
        ...mapGetters("users", ["profile"]),

        productUnits() {
            return this.getUnits.filter(unit => unit.unitOf.includes('product'))
        },
        filteredProperties(){
            return this.profile.properties.filter(property => property.property.active)
        },
    },
    async mounted() {
        this.loading = true;

        await this.findProductCategories();
        await this.findCultures();
        await this.findUnits();
        await this.profileProperties();

        this.formatInput()

        this.loading = false;
    },
    watch: {
        selectedCategory() {
            this.selectedSubCategory = ""
            const productSubCategories = this.getProductSubCategoriesByProductCategoryId(this.selectedCategory)
            if (productSubCategories.length > 0) {
                const productSubCategoryIsOnProductSubCategoriesList = productSubCategories.find(productSubCategory => this.data.productCategory && productSubCategory._id == this.data.productCategory._id)
                if (this.data.productCategory && this.data.productCategory.parentProductCategory && productSubCategoryIsOnProductSubCategoriesList) {
                    this.selectedSubCategory = this.data.productCategory._id
                } else {
                    this.selectedSubCategory = productSubCategories[0]._id
                }
            }
        }
    },
    methods: {
        ...mapActions("product-categories", ["findProductCategories"]),
        ...mapActions("cultures", ["findCultures"]),
        ...mapActions("units", ["findUnits"]),
        ...mapActions("new-products", ["createNewProduct", "updateNewProduct"]),
        isUpdateModal() {
            return !!this.data._id
        },
        getProductSubCategoriesByProductCategoryId(productCategoryId) {
            const productCategory = this.getProductCategories.find(productCategory => productCategory._id == productCategoryId)
            return productCategory ? productCategory.productSubCategories : []
        },
        isUpdateModal() {
            return !!this.data._id
        },
        formatInput() {
            if (this.isUpdateModal()) {
                const hasProductCategory = !!this.data.productCategory
                if (hasProductCategory) {
                    const isSubCategory = !!this.data.productCategory.parentProductCategory
                    this.selectedCategory = this.data.productCategory._id
                    this.selectedSubCategory = ""

                    if (isSubCategory) {
                        this.selectedCategory = this.data.productCategory.parentProductCategory._id
                        this.selectedSubCategory = this.data.productCategory._id
                    }
                }

                this.product.name = this.data.name || ''
                this.product.unit = this.data.unit._id || null
                this.product.cultures = this.data.cultures && this.data.cultures.length > 0 ? this.data.cultures.map(culture => culture.culture._id) : []
            }
        },
        formatOutput() {
            return {
                propertyIdList: this.propertiesIds,
                name: this.product.name,
                productCategory: this.selectedSubCategory ? this.selectedSubCategory : this.selectedCategory,
                unit: this.product.unit,
                cultures: this.product.cultures.map(culture => ({ culture }))
            }
        },
        validateNewProduct(newProduct) {
            if (!newProduct.name) {
                throw new Error('O produto precisa ter um nome');
            }
            if (!newProduct.cultures || !newProduct.cultures.length) {
                throw new Error('Selecione a(s) cultura(s) em que o produto será utilizado.');
            }
            if (!newProduct.productCategory) {
                throw new Error('Selecione uma categoria/subcategoria');
            }
            if (!newProduct.unit) {
                throw new Error('Selecione uma unidade');
            }
        },
        async saveProduct() {
            try {
                const newProduct = this.formatOutput()
                this.validateNewProduct(newProduct)

                this.saving = true

                let response = null
                if (this.isUpdateModal()) {
                    const newProductId = this.data._id
                    response = await this.updateNewProduct({ newProductId, newProduct })
                } else {
                    response = await this.createNewProduct(newProduct)
                }

                this.saving = false

                const responseContainsError = response instanceof Error
                if (!responseContainsError) {
                    this.$vToastify.success(response.data.message, "Produto");
                    this.$emit('save')
                    this.$emit('close')
                }

            } catch (error) {
                this.$vToastify.error(error.message, "Produto");
            }
        },
        profileProperties() {
            this.userProperties = this.filteredProperties.map((property) => {
                return {
                    label: property.property.name,
                    value: property.property._id,
                    _id: uuidv4()
                }
            })
        }
    }
};
</script>

<style scoped lang="sass">
.content
    display: flex
    flex-wrap: wrap

    &__title
        padding: 4px
        width: 100%

    &__item
        padding: 4px
        width: 50%

@media (max-width: 500px)
    .content
        &__item
            width: 100%


</style>
