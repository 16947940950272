const formatNumber = number => {
    if (number === 0) {
        return '0,00'
    }

    if (!number) {
        return ''
    }
    if (typeof number === 'number') {
        number = number.toFixed(2)
        number = String(number)
    }
    return number
        .replace(/\.(\d)$/, ',$10')
        .replace(/\.(\d{2})$/, ',$1')
        .replace(/^(\d+)$/, '$1,00')
        .replace(/(\d)(?=(\d{3})+(?:,\d+)?$)/g, '$1.')
}

const formatNumberWithThreeDecimalPlaces = number => {
    if (number === 0) {
        return '0,000'
    }

    if (!number) {
        return ''
    }
    if (typeof number === 'number') {
        number = number.toFixed(3)
        number = String(number)
    }
    return number
        .replace(/\.(\d)$/, ',$10')
        .replace(/\.(\d{3})$/, ',$1')
        .replace(/^(\d+)$/, '$1,00')
        .replace(/(\d)(?=(\d{3})+(?:,\d+)?$)/g, '$1.')
}

const formatInputNumber = number => {
    if (typeof number === 'number') {
        number = number.toFixed(2)
        number = String(number)
    }
    return number
        .replace(/[^0-9]/g, '')
        .replace(/^(\d)$/, '0$1')
        .replace(/(\d{2})$/, ',$1')
        .replace(/^(,\d{2})$/, '0$1')
        .replace(/^0+(\d)+/, '$1')
        .replace(/(\d)(?=(\d{3})+(?:,\d+)?$)/g, '$1.')
}

const formatInputNumberWithThreeDecimalPlaces = number => {
    if (typeof number === 'number') {
        //number = number.toFixed(2)
        number = String(number)
    }
    return number
        .replace(/[^0-9]/g, '')
        .replace(/^(\d)$/, '0$1')
        .replace(/(\d{1,3})$/, ',$1')
        .replace(/^(,\d{2})$/, '0$1')
        .replace(/^0+(\d)+/, '$1')
        .replace(/(\d)(?=(\d{3})+(?:,\d+)?$)/g, '$1.')
}

const unformatNumber = number => {
    if (!number) {
        return null
    }
    if (typeof number === 'number') {
        number = String(number)
    }
    number = number
        .replace(/\./g, '')
        .replace(/,/g, '.')
    return Number(number)
}

export { formatNumber, formatInputNumber, unformatNumber, formatInputNumberWithThreeDecimalPlaces, formatNumberWithThreeDecimalPlaces }
