const mixin = {
    methods: {
        formatNumber(number) {
            return this.$options.filters.formatNumber(number)
        },
        formatNumberWithThreeDecimalPlaces(number) {
            return this.$options.filters.formatNumberWithThreeDecimalPlaces(number)
        },
        formatInputNumber(number) {
            return this.$options.filters.formatInputNumber(number)
        },
        formatInputNumberWithThreeDecimalPlaces(number) {
            return this.$options.filters.formatInputNumberWithThreeDecimalPlaces(number)
        },
        unformatNumber(number) {
            return this.$options.filters.unformatNumber(number)
        }
    }
}

export default  mixin