import Weather from '@/api/Weather'

const state = {
    weather: []
}

const getters = {
    getWeather: state => state.weather
}

const actions = {
    findWeatherHistorical: async ({ commit }, { fieldsIds, startDate, endDate }) => {
        commit('setWeather', [])
        const historical = await Weather.findWeatherHistorical(fieldsIds, startDate, endDate)
        if (!(historical instanceof Error)) {
            commit('setWeather', historical.data.data)
        }
    },
    findWeatherForecast: async ({ commit }, payload) => {
        const { propertyId, fieldsIds, startDate, endDate } = payload
        commit('setWeather', [])
        const forecast = await Weather.findWeatherForecast(propertyId, fieldsIds, startDate, endDate)
        if (!(forecast instanceof Error)) {
            commit('setWeather', forecast.data.data)
        }
    },
    getWatherReports: async ({ commit }, propertyId) => {
        commit('setWeather', [])
        const reports = await Weather.getWatherReports(propertyId)
        return reports
    }
}

const mutations = {
    setWeather: (state, weather) => {
        state.weather = weather
    }
}

export default { state, getters, mutations, actions, namespaced: true }