import Vue from 'vue'
import VueRouter from 'vue-router'

import Panel from '@/views/panel/Panel.vue'

import Weather from '@/views/weather/Weather.vue'
import MapWindy from '@/views/weather/map-windy/MapWindy.vue'
import Forecast from '@/views/weather/forecast/Forecast.vue'

import Recommendation from '@/views/recommendations/Recommendation.vue'

import Planning from '@/views/planning/Planning.vue'

import Prague from '@/views/Pragues/index.vue'

import Satellite from '@/views/satellite/Satellite.vue'

import Report from '@/views/reports/Report.vue'
import ReportNDVIs from '@/views/reports/ndvis/ReportNDVIs.vue'
import ReportPhotos from '@/views/reports/photos/ReportPhotos.vue'
import ReportCrops from '@/views/reports/crops/ReportCrops.vue'
import ReportServiceOrder from '@/views/reports/service-order/ReportServiceOrder.vue'
import ReportMonitoring from '@/views/reports/monitoring/ReportMonitoring.vue'

import Notifications from '@/views/Notifications/index.vue'

import Configuration from '@/views/configurations/Configuration.vue'
import Property from '@/views/configurations/properties/Property.vue'
import User from '@/views/configurations/users/User.vue'
import Crop from '@/views/configurations/crops/Crop.vue'
import Field from '@/views/configurations/fields/Field.vue'
import FieldOverview from '@/views/configurations/fields/overview/FieldOverview.vue'
import FieldPhotos from '@/views/configurations/fields/photos/FieldPhotos.vue'
import Machinery from '@/views/configurations/machineries/Machinery.vue'
import Operator from '@/views/configurations/operators/Operator.vue'

import Stock from '@/views/stock/Stock.vue'
import MySpecificProducts from '@/views/stock/my-specific-products/MySpecificProducts.vue'
import CurrentStock from '@/views/stock/current-stock/CurrentStock.vue'
import Movement from '@/views/stock/movement/Movement.vue'
import ApproveExits from '@/views/stock/approve-exits/ApproveExits.vue'
import History from '@/views/stock/history/History.vue'


import Login from '@/views/login/Login.vue'

import NotAuthorized from '@/views/not-authorized/NotAuthorized.vue'

import store from '@/store'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: () => '/painel'
    },
    {
        path: '/painel',
        name: 'Panel',
        component: Panel
    },
    {
        path: '/tempo',
        name: 'Weather',
        component: Weather,
        meta: {
            requirePermission: true,
            permission: 'climate'
        },
        children: [
            {
                path: 'mapa-windy',
                name: 'MapWindy',
                component: MapWindy,
            },
            {
                path: 'previsao-do-tempo',
                name: 'Forecast',
                component: Forecast,
            },
            {
                path: '',
                redirect: 'mapa-windy'
            }
        ]
    },
    {
        path: '/recomendacao',
        name: 'Recommendation',
        component: Recommendation,
        meta: {
            requirePermission: true,
            permission: 'recommendation'
        },
    },
    {
        path: '/planejamento',
        name: 'Planning',
        component: Planning,
        meta: {
            requirePermission: true,
            permission: 'planner'
        }
    },
    {
        path: '/pragas',
        name: 'Prague',
        component: Prague,
        meta: {
            requirePermission: true,
            permission: 'mip'
        }
    },
    {
        path: '/satelites',
        name: 'Satellite',
        component: Satellite,
        meta: {
            requirePermission: true,
            permission: 'ndvi'
        }
    },
    {
        path: '/relatorios',
        name: 'Report',
        component: Report,
        meta: {
            requirePermission: true,
            permission: 'report'
        },
        children: [
            {
                path: 'ndvi',
                name: 'ReportNDVIs',
                component: ReportNDVIs,
                meta: {
                    requirePermission: true,
                    permission: 'ndvi'
                }
            },
            {
                path: 'fotos',
                name: 'ReportPhotos',
                component: ReportPhotos,
                meta: {
                    requirePermission: true,
                    permission: 'report'
                }
            },
            {
                path: 'safras',
                name: 'ReportCrops',
                component: ReportCrops,
                meta: {
                    requirePermission: true,
                    permission: 'planner'
                }
            },
            {
                path: 'ordem-de-servico',
                name: 'ReportServiceOrder',
                component: ReportServiceOrder,
                meta: {
                    requirePermission: true,
                    permission: 'planner'
                }
            },
            {
                path: 'monitoramento',
                name: 'ReportMonitoring',
                component: ReportMonitoring,
                meta: {
                    requirePermission: true,
                    permission: 'mip'
                }
            }
        ],
    },
    {
        path: '/estoque',
        name: 'Stock',
        component: Stock,
        meta: {
            requirePermission: true,
            permission: 'stock'
        },
        children: [
            {
                path: 'meus-produtos-especificos',
                name: 'MySpecificProducts',
                component: MySpecificProducts,
                meta: {
                    requirePermission: true,
                    permission: 'stock'
                }
            },
            {
                path: 'estoque-atual',
                name: 'CurrentStock',
                component: CurrentStock,
                meta: {
                    requirePermission: true,
                    permission: 'stock'
                }
            },
            {
                path: 'movimentacoes',
                name: 'Movement',
                component: Movement,
                meta: {
                    requirePermission: true,
                    permission: 'stock'
                }
            },
            {
                path: 'aprovar-saidas',
                name: 'ApproveExits',
                component: ApproveExits,
                meta: {
                    requirePermission: true,
                    permission: 'stock'
                }
            },
            {
                path: 'historico',
                name: 'History',
                component: History,
                meta: {
                    requirePermission: true,
                    permission: 'stock'
                }
            },
            {
                path: '',
                redirect: 'estoque-atual'
            }
        ]
    },
    {
        path:'/bi',
        beforeEnter() { window.open('https://app.powerbi.com/view?r=eyJrIjoiZDBkNDU1YzgtYThiNS00ZTljLTlhZDQtNzM3NDNiMGMwNzQxIiwidCI6IjIzNTRhOTVhLWY0ZWEtNGQ2Ni1hZDIzLTRmOTYyYWMxYmMxNCJ9', '_blank') },
        meta: {
            requirePermission: true,
            permission: 'bi'
        }
    },
    {
        path: '/notificacoes',
        name: 'Notifications',
        component: Notifications
    },
    {
        path: '/bens',
        name: 'Configuration',
        component: Configuration,
        children: [
            {
                path: 'propriedades',
                name: 'Property',
                component: Property,
                meta: {
                    requirePermission: true,
                    permission: 'farmer-admin'
                }
            },
            {
                path: 'usuarios',
                name: 'Users',
                component: User,
                meta: {
                    requirePermission: true,
                    permission: 'farmer-admin'
                }
            },
            {
                path: 'maquinarios',
                name: 'Machinery',
                component: Machinery
            },
            {
                path: 'operadores',
                name: 'Operator',
                component: Operator
            },
            {
                path: 'safras',
                name: 'Crop',
                component: Crop
            },
            {
                path: 'talhoes',
                name: 'Field',
                component: Field
            },
            {
                path: '',
                redirect: 'safras'
            }
        ]
    },
    {
        path: '/bens/talhoes/:id/visao-geral',
        name: 'FieldOverview',
        component: FieldOverview
    },
    {
        path: '/bens/talhoes/:id/fotos',
        name: 'FieldPhotos',
        component: FieldPhotos
    },
    {
        path: '/login',
        component: Login,
        name: 'Login'
    },
    {
        path: '/nao-autorizado',
        component: NotAuthorized,
        name: 'NotAuthorized'
    }
]

const router = new VueRouter({
    mode: 'history',
    routes,
})

router.beforeEach((to, from, next) => {
    if (!to.path.match(/^\/login(\/)?$/)) {
        const token = localStorage.getItem('token')

        if (!token) {
            return next({path: '/login'})
        }
    }
    return next()
})

router.beforeEach((to, from, next) => {
    if (to.meta.requirePermission) {
        const requiredPermission = to.meta.permission
        const userPermissions = store.state.users.user.featureList
        const userPermissionsRole = store.state.users.user.role

        if (!userPermissions.includes(requiredPermission) && !userPermissionsRole.includes(requiredPermission)) {
            return next({path: '/nao-autorizado'})
        }
    }
    return next()
})

export default router
