const mixin = {
    methods: {
        hasPermission(permission) {
            const userPermissions = this.$store.state.users.user.featureList

            return userPermissions.includes(permission)
        },
        hasPermissionRole(permission) {
            const userPermissionsRole = this.$store.state.users.user.role

            return userPermissionsRole.includes(permission)
        },
        hasAccess(path) {
            const applicationRoutes = this.$router.options.routes
            const userPermissions = this.$store.state.users.user.featureList

            const match = applicationRoutes.find(route => route.path === path)

            if (match && match.meta && match.meta.requirePermission) {
                if (!userPermissions.includes(match.meta.permission)) {
                    return false
                }
            }

            return true
        },
        hasChildAccess(path, children) {
            const applicationRoutes = this.$router.options.routes
            const userPermissions = this.$store.state.users.user.featureList

            const matchParent = applicationRoutes.find(route => route.path === path && route.children)
            const match = matchParent.children.find(route => route.path === children)
            

            if (match && match.meta && match.meta.requirePermission) {
                if (!userPermissions.includes(match.meta.permission)) {
                    return false
                }
            }

            return true
        }
    }
}

export default  mixin