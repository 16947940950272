<template>
    <div>
        <Modal :open="open" :loading="loading" @close="$emit('close')">
            <div class="app-modal-header" slot="header">
                <span>{{ "Histórico" }}</span>
            </div>
            <div class="app-modal-body" slot="body">
                <div class="content">
                    <div class="content__item">
                        <label class="app-label">Categoria:</label>
                        {{
                            formatProductCategory(
                                stockProduct.product.productCategory
                            ) || "-"
                        }}
                    </div>
                    <div class="content__item">
                        <label class="app-label">Subcategoria:</label>
                        {{
                            formatProductSubCategory(
                                stockProduct.product.productCategory
                            ) || "-"
                        }}
                    </div>
                    <div class="content__item content__item--full-width">
                        <label class="app-label">Produto:</label>
                        {{ stockProduct.product.name }}
                    </div>
                    <div class="content__item content__item--full-width">
                        <label class="app-label"
                            >Propriedade selecionada:</label
                        >
                        {{ this.getPropertySelected.name }}
                    </div>
                    <div
                        v-if="!loading"
                        class="content__item content__item--full-width"
                    >
                        <table class="stock-products-history-table">
                            <tr>
                                <th>Data</th>
                                <th>Tipo</th>
                                <th>Quantidade calculada</th>
                                <th>Quantidade movimentada</th>
                                <th>Saldo</th>
                                <th class="stock-products-history-table-header__actions">
                                    Ações
                                </th>
                            </tr>
                            <tr
                                v-for="stockRegister in stockRegisters"
                                :key="stockRegister._id"
                            >
                                <td>
                                    {{ stockRegister.date | formatDate }}
                                </td>
                                <td>
                                    {{ translateAction(stockRegister.action) }}
                                </td>
                                <td>
                                    {{ getDisplayTextStockRegisterCalculatedTotalQuantity(stockRegister) }}
                                </td>
                                <td :style="getTextColor(stockRegister)">
                                    {{ getDisplayTextStockRegisterRealTotalQuantity(stockRegister) }}
                                </td>
                                <td>
                                    {{ getDisplayTextStockRegisterBalance(stockRegister) }}
                                </td>
                                <td>
                                    <div
                                        class="stock-products-history-table-content__actions"
                                    >
                                        <button
                                            @click="openModalHistoryDetail(stockRegister)"
                                            class="
                                                btn btn--flat
                                                btn-primary--outlined
                                            "
                                        >
                                            <i class="fas fa-eye"></i>
                                            <span>Visualizar</span>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </table>
                        <div
                            v-show="stockRegisters.length == 0"
                            class="no-content"
                        >
                            <i class="fa fa-exclamation-circle fa-2x" />
                            <span class=""
                                >Não foram encontradas movimentações desse
                                produto</span
                            >
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
        <modal-history-detail
            v-if="showModalHistoryDetail"
            :open="showModalHistoryDetail"
            @close="closeModalHistoryDetail"
            :data="selectedStockRegister"
        />
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import Modal from "@/components/systemUI/Modal.vue";
import ModalHistoryDetail from "@/components/stock/ModalHistoryDetail.vue";

import _ from "lodash";

export default {
    name: "ModalStockProductHistory",
    props: {
        data: Object,
        open: Boolean,
    },
    components: {
        Modal,
        ModalHistoryDetail,
    },
    data() {
        return {
            loading: false,
            showModalHistoryDetail: false,
            stockProduct: {
                product: {
                    name: "",
                    productCategory: {
                        name: "",
                        parentProductCategory: null,
                    },
                    unit: {
                        name: "",
                    },
                },
            },
            selectedStockRegister: {},
            stockRegisters: [],
        };
    },
    computed: {
        ...mapGetters("property", ["getPropertySelected"]),
        ...mapGetters("stock-register", ["getStockRegisters"]),
        ...mapGetters("subareas", ["getSubareas"]),
        ...mapGetters("unit-conversions", ["getUnitConversions"])
    },
    methods: {
        ...mapActions("stock-register", ["findStockRegisters"]),
        ...mapActions("unit-conversions", ["findUnitConversions"]),
        ...mapActions("subareas", ["findSubareas"]),
        openModalHistoryDetail(stockRegister) {
            this.selectedStockRegister = stockRegister;
            this.showModalHistoryDetail = true;
        },
        closeModalHistoryDetail() {
            this.showModalHistoryDetail = false;
        },
        getStockRegisterRealTotalQuantity(stockRegister) {
            return stockRegister.realTotalQuantity;
        },
        getStockRegisterCalculatedTotalQuantity(stockRegister) {
            return stockRegister.calculatedTotalQuantity;
        },
        getDisplayTextStockRegisterRealTotalQuantity(stockRegister) {
            const realTotalQuantity = this.getStockRegisterRealTotalQuantity(stockRegister);
            if (!realTotalQuantity && realTotalQuantity !== 0) {
                return "-";
            }

            const productUnit = this.returnIfExists(stockRegister, "stockProduct.product.unit.name");
            if (!productUnit) {
                return "-";
            }

            const sign = stockRegister.action === 'exit' ? '-' : '+';

            const realTotalQuantityFormatted = this.formatNumber(realTotalQuantity);

            return `${sign} ${realTotalQuantityFormatted} ${productUnit}`;
        },
        getTextColor(stockRegister) {
            return {
                color: (
                    stockRegister.action === 'exit'
                    ? 'red'
                    : 'green'
                )
            }
        },
        getDisplayTextStockRegisterCalculatedTotalQuantity(stockRegister) {
            const calculatedTotalQuantity = this.getStockRegisterCalculatedTotalQuantity(stockRegister);
            if (!calculatedTotalQuantity && calculatedTotalQuantity !== 0) {
                return "-";
            }

            const productUnit = this.returnIfExists(stockRegister, "stockProduct.product.unit.name");
            if (!productUnit) {
                return "-";
            }

            const calculatedTotalQuantityFormatted = this.formatNumber(calculatedTotalQuantity);

            return `${calculatedTotalQuantityFormatted} ${productUnit}`;
        },
        getDisplayTextStockRegisterBalance(stockRegister) {
            const stockRegisterBalance = stockRegister.balance || 0
            if (!stockRegisterBalance) {
                return '-'
            }

            const stockProductUnit = this.returnIfExists(stockRegister, "stockProduct.product.unit.name")
            if (!stockProductUnit) {
                return '-'
            }

            return `${this.formatNumber(stockRegisterBalance)} ${stockProductUnit}`;
        },
        translateAction(action) {
            const actions = {
                entrance: "Entrada",
                exit: "Saída",
                Entrada: "entrance",
                Saída: "exit",
            };
            return actions[action];
        },
        clearData() {
            Object.assign(this.$data, this.$options.data.apply(this));
        },
        formatInput() {
            this.stockProduct = _.cloneDeep(this.data);
        },
        async loadStockRegisters() {
            this.stockRegisters = [];

            await this.findStockRegisters({
                propertyId: this.getPropertySelected._id,
                stockProductId: this.stockProduct._id,
            });

            this.stockRegisters = this.getStockRegisters;
        }
    },
    async mounted() {
        this.formatInput();

        this.loading = true;

        await this.findUnitConversions();
        await this.loadStockRegisters();

        this.loading = false;
    },
};
</script>

<style scoped lang="sass">
.content
    display: flex
    flex-wrap: wrap

    &__item
        padding: 4px
        width: 50%

        &--full-width
            width: 100%

    .filter-content
        width: 100%
        border: 1px solid #d6d6d6
        border-radius: 12px
        margin: 4px
        padding: 10px 4px
        display: flex
        flex-wrap: wrap

        &__title
            padding: 4px
            color: #d6d6d6
            width: 100%

        &__item
            padding: 4px
            width: 50%

.stock-products-history-table
    width: 100%

    &-header__actions
        width: 30%
        text-align: center

    &-content__actions
        display: flex
        grid-gap: 6px
        align-items: center
        justify-content: center

        .btn
            background: none

            &:hover
                background: $color-white-darken-1

            i
                color: $color-blue


    th
        color: $color-blue
        padding: 0 10px
        font-size: 16px
        width: 16.6666%

    tr
        border-radius: 20px
        height: 70px

        &:nth-child(even)
            background-color: #f2f2f2

    td
        padding: 0 10px
        width: 16.6666%

.no-content
    display: flex
    justify-content: center
    align-items: center
    height: 100px
    color: $color-grey
    grid-gap: 10px

@media (max-width: 500px)
    .content
        &__item
            width: 100%
</style>
