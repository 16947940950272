<template>
    <div class="operators-container">
        <div class="operators-container__header">
            <span class="title">Operadores</span>
            <div class="actions">
                <div class="search-container app-input">
                    <input class="search-container__input" placeholder="Pesquise um operador"
                           v-model="searchWord" @keyup.enter="filterOperators" type="text"/>
                    <button class="search-container__clear clickable" v-show="searchWord.length > 0"
                            @click="clearSearch">
                        <i class="fal fa-times-circle"/>
                    </button>
                    <button class="search-container__search" @click="filterOperators">
                        <i class="fas fa-search"/>
                    </button>
                </div>
                <button class="btn btn-primary btn--flat" @click="openModalCreateOperators">
                    <span>Novo operador</span>
                </button>
            </div>
        </div>
        <div v-if="!loading" class="operators-container__content">
            <table class="operators-table">
                <tr>
                    <th>Nome</th>
                    <th>Propriedades</th>
                    <th class="operators-table-header__actions">Ações</th>
                </tr>
                <tr v-for="operator in operatorsShow" :key="operator._id">
                    <td> {{ operator.name }} </td>
                    <td> {{ operator.properties.map(property => property.property.name).join(', ') }} </td>
                    <td>
                        <div class="operators-table-content__actions">
                            <button @click="openModalEditOperators(operator)"
                                    class="btn btn--flat">
                                <i class="fas fa-edit"></i>
                                <span>Editar</span>
                            </button>
                             <button
                                type="checkbox"
                                class="btn btn--flat"
                                @click="activeDeactiveOperator(operator)"
                            >
                            <i :class="operator.active ? 'fas fa-check-square' : 'far fa-square'"></i>
                                <span>{{ operator.active ? 'Desativar' : 'Reativar' }}</span>
                            </button>
                        </div>
                    </td>
                </tr>
            </table>
            <div v-show="operatorsShow.length == 0" class="no-content">
                <i class="fa fa-exclamation-circle fa-2x"/>
                <span class="">Não foram encontrados operadores</span>
            </div>
        </div>
        <div v-else class="no-content">
            <span class="spinner-border" />
        </div>
        <modal-operator :open="showModalOperator" @close="closeModalOperator" :data="operatorData" @save="loadOperators"/>
    </div>
</template>

<script>

import { mapActions, mapGetters } from "vuex";
import ModalOperator from "@/views/configurations/operators/components/ModalOperator.vue"

export default {
    name: 'Operator',
    components: {
        ModalOperator
    },
    data() {
        return {
            loading: false,
            showModalOperator: false,
            operatorsShow: [],
            operatorData: {},
            searchWord: '',
        }
    },
    computed: {
        ...mapGetters("operators", [
            "operatorsState"
        ]),
        ...mapGetters("property",[
            "getPropertySelected"
        ]),
    },
    watch: {
        searchWord(){
            this.filterOperators()
        },
        getPropertySelected(){
            this.operatorsShow = []
            this.loadOperators()
        }
    },
    methods: {
        ...mapActions("operators", [
            "getOperators", "updateOperator"
        ]),
        async loadOperators(){
            this.loading = true

            const propertyId = this.getPropertySelected._id
            await this.getOperators({ propertyId })

            this.operatorsShow = [...this.operatorsState.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1)]

            this.loading = false
        },
        openModalCreateOperators(){
            this.operatorData = {}
            this.showModalOperator = true
        },
        openModalEditOperators(operator){
            this.operatorData = operator
            this.showModalOperator = true
        },
        closeModalOperator(){
            this.showModalOperator = false
        },
        async activeDeactiveOperator(operator){
            const { _id, active } = {
                _id: operator._id,
                active: !operator.active
            }
            const confirmActiveDeactive = await this.$vToastify.prompt({
                body: "Deseja confirmar está ação?",
                title: "ATENÇÃO!",
                answers: { Sim: true, Não: false },
            })
            if(confirmActiveDeactive){
                await this.updateOperator({_id, active})
                await this.loadOperators()
                const sucessText = operator.active == true ? 'Desativado' : 'Reativado'
                this.$vToastify.success(`${sucessText} com sucesso`, "Operador")
            } else return
        },
        filterOperators(){
            this.operatorsShow = this.operatorsState.filter((operator) => {
                return (
                    operator.name.toUpperCase().includes(this.searchWord.toUpperCase()) ||
                    operator.properties.some(property => property.property.name.toUpperCase().includes(this.searchWord.toUpperCase()))
                )
            })
        },
        async clearSearch() {
            this.searchWord = ''
            this.operatorsShow = [...this.operatorsState.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1)]
        }
    },
    async mounted(){
        await this.loadOperators()
    }
}
</script>

<style lang="sass" scoped>
.operators-container
    display: flex
    flex-direction: column
    grid-gap: 6px
    width: 100%

    &__header
        .title
            font-size: 24px
            color: $theme-color-primary

        .actions
            display: flex
            justify-content: space-between

            .search-container
                display: flex
                justify-content: space-between
                width: 300px

                &__input
                    width: 250px
                    border: 0

                &__search
                    border: 0
                    width: 25px
                    background: none

                &__clear
                    border: 0
                    width: 25px
                    background: none

.operators-table
    width: 100%

    &-header__actions
        width: 30%
        text-align: center

    &-content__actions
        display: flex
        grid-gap: 6px
        align-items: center
        justify-content: center

        .btn
            background: none

            &:hover
                background: $color-white-darken-1

            i
                color: $color-blue

    th
        color: $color-blue
        padding: 0 10px
        font-size: 16px
        width: 33.3333%

    tr
        border-radius: 20px
        height: 70px

        &:nth-child(even)
            background-color: #f2f2f2

    td
        padding: 0 10px
        width: 33.3333%

.no-content
    display: flex
    justify-content: center
    align-items: center
    height: 100px
    color: $color-grey
    grid-gap: 10px

@media (max-width: 430px)
    .operators-container__header .actions .search-container__input
        width: 200px

</style>
