<template>
    <div class="period-container" :class="classParameter">
      <select v-model="selectedInterval" class="app-select">
        <option disabled>Selecione o período</option>
        <option value="weekly">Semanal</option>
        <option value="monthly">Mensal</option>
        <option value="custom">Personalizado</option>
      </select>
    </div>
</template>
<script>

export default {
  name: "PeriodSelectPicker",
  props: {
    classParameter: {
      type: String,
    },
    selectedInterval: {
      type: String
    }
  },
  watch: {
    selectedInterval() {
      this.$emit("intervalSelected", { interval: this.selectedInterval });
    },
  },
};
</script>

<style scoped lang="sass">
.period-container
    display: flex
    justify-content: center
    align-items: center

    .btn
        border-color: $color-white-darken-2

    .calendar-btn
        height: 40px

</style>
