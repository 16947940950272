import Unity from '../../api/Unity';

const state = {
    unities: []
}

const getters = {
    getUnities: state => state.unities
}

const actions = {
    async findUnities({ commit }) {
        const response = await Unity.findUnities()
        const unities = response.data.data
        commit("SET_UNITIES", unities)
    }
}

const mutations = {
    SET_UNITIES: (state, unities) => state.unities = unities,
}

export default { state, getters, actions, mutations, namespaced: true }
