<template>
    <div class="app-card recommendation-filter">
        <multiple-selector label="Talhão(ões)" placeholder="Selecione o(s) talhão(ões)" :items="fields"
                           item-text="name" item-value="_id" v-model="filter.fieldsIds"/>

        <div class="item">
            <label class="app-label" for="operation">Operação</label>
            <select class="app-select" id="operation" v-model="filter.operation">
                <option value="" disabled>Selecione a operação</option>
                <option v-for="operation in operations" :key="operation" :value="operation">{{operation}}</option>
            </select>
        </div>

        <div class="item flex">
            <date-picker label="Data" :dataSent="filter.endDate"
                         @dateSelected="filter.endDate = $event.date"/>
        </div>

        <div class="action">
            <button class="btn btn-primary btn--flat" @click="search" :disabled="loading">
                <span v-if="loading" class="spinner-border spinner-border-sm" role="status"/>
                <span v-else>Buscar</span>
            </button>
        </div>
    </div>
</template>

<script>
import DatePicker from '@/components/commons/DatePicker'
import MultipleSelector from '@/components/commons/MultipleSelector.vue'
export default {
    name: 'RecommendationFilter',
    components: {
        DatePicker,
        MultipleSelector
    },
    props: {
        fields: {
            type: Array
        },
        operations: {
            type: Array
        },
        loading: {
            type: Boolean
        }
    },
    data() {
        return {
            filter: {
                fieldsIds: [],
                operation: '',
                endDate: this.getTodayDate()
            }
        }
    },
    methods: {
        search() {
            this.$emit('search', this.filter)
        }
    }
}
</script>