<template>
    <div>
        <label class="app-label" :for="id">{{ label }}</label>
        <input class="app-input" v-model="input" v-bind="{id, placeholder}" type="text" @blur="send">
    </div>
</template>

<script>
export default {
    name: 'InputNumber',
    props: {
        id: { type: String, required: true },
        label: { type: String, required: false },
        placeholder: { type: String, required: false },
        data: { default: '' }
    },
    data() {
        return {
            input: this.data ? this.formatNumber(this.data) : '',
            formattedInput: ''
        }
    },
    watch: {
        data() {
            this.input = this.data ? this.formatNumber(this.data) : ''
        },
        input() {
            if (this.input != this.formattedInput) {
                this.formattedInput = this.formatInputNumber(this.input)
                this.input = this.formattedInput
            }
        }
    },
    methods: {
        send() {
            this.$emit('input', this.unformatNumber(this.input))
        }
    }
}
</script>