import Crop from '../../api/Crop';

const state = {
    crops: [],
    crop: {},
    fields: [],
    cultures: [],
    error: null
}

const getters = {
    allCrops: state => state.crops,
    allFields: state => state.fields,
    crop: state => state.crop,
    cultures: state => state.cultures,
    error: state => state.error
}

const actions = {

    setMyCrops({commit}, Crops) {
        commit("setCrops", Crops)
    },
    async getCrops({commit}) {
        await Crop.getAll().then(response => {
            commit("setCrops", response.data.data)
        })
    },
    async cloneCrop({ commit }, cropId) {
        const response = await Crop.cloneCrop(cropId);
        return response;
    },
    getCrop({commit}, id) {
        Crop.get(id).then(response => {
            commit("setCrop", response.data.data)
        })
    },
    async getCultures({commit}) {
        await Crop.cultures().then(response => {
            commit("setCultures", response.data.data)
        })
    },
    listFields({commit}, id) {
        Crop.getFieldsByCropAndProperty(id).then(response => {
            commit("setFields", response.data.data)
        })
    },
    clearListFields({commit}) {
        commit("clearFields")
    },
    async postCrop({commit}, crop) {
        const response = await Crop.post(crop)
        return response
    },
    async setCropAlteration({commit}, crop){
        commit("newCrop", crop)
    },
    async updateCrop({commit}, crop) {
        const response = await Crop.update(crop)
        return response
    },
    removeCrop({commit}, crop) {
        Crop.delete(crop._id).then(response => {
            commit("removeCrop", response.data.data)
        })
    }
}

const mutations = {
    setErrorCrop: (state, error) => state.error = error,
    setCrops: (state, crops) => state.crops = crops,
    setCrop: (state, crop) => state.crop = crop,
    setFields: (state, fields) => state.fields = fields,
    setCultures: (state, cultures) => state.cultures = cultures,
    newCrop: (state, crop) => state.crops.unshift(crop),
    updateCrop: (state, changedCrop) => {
        const index = state.crops.findIndex(elem => elem._id == changedCrop._id)
        if(index !== -1)
            state.crops.splice(index, 1, changedCrop)
    },
    removeCrop: (state, crop) => state.crops = state.crops.filter(elem => elem._id != crop._id),
    clearFields: (state) => state.fields = []
}

export default { state, getters, actions, mutations, namespaced: true }
