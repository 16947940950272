<template>
    <div class="recommendation-container">
        <recommendation-filter :fields="fields" :operations="operations" :loading="loading"
                               @search="findRecommendations"/>

        <recommendation-result v-show="results.length > 0" :date="filter" :results="results"/>
    </div>
</template>

<script>
import RecommendationFilter from '@/views/recommendations/components/RecommendationFilter.vue'
import RecommendationResult from '@/views/recommendations/components/RecommendationResult.vue'
import {operationsRecommendation} from '@/constants'
import {mapActions, mapGetters} from 'vuex'
import _ from 'lodash'
export default {
    name: 'Recommendation',
    components: {
        RecommendationFilter,
        RecommendationResult
    },
    data() {
        return {
            filter: {},
            fields: [],
            operations: Object.values(operationsRecommendation),
            loading: false,
            results: []
        }
    },
    computed: {
        ...mapGetters('property', ['getPropertySelected', 'getCropSelected']),
        ...mapGetters('fields', ['allFields']),
        ...mapGetters('weather', ['getWeather'])
    },
    async mounted() {
        await this.findFields()
    },
    methods: {
        ...mapActions('fields', ['getFieldsByPropertyByCrop']),
        ...mapActions('weather', ['findWeatherHistorical', 'findWeatherForecast']),
        async findFields() {
            await this.getFieldsByPropertyByCrop({
                propertyId: this.getPropertySelected._id,
                cropId: this.getCropSelected._id
            })
            this.fields = this.allFields
        },
        async findRecommendations(filter) {
            this.loading = true
            this.results = []
            this.filter = _.cloneDeep(filter)
            const valid = this.validateFilter()
            if (valid) {
                await this.findWeatherInformation()
                this.recommendOperations()
            }
            this.loading = false
        },
        async findWeatherInformation() {
            if (this.filter.endDate < this.formatDate(new Date(), 'default')) {
                await this.findWeatherHistorical(this.filter)
            } else {
                await this.findWeatherForecast(this.filter)
            }
        },
        recommendOperations() {
            this.getWeather.map(historical => {
                if (historical.weather.length < 1) {
                    return
                }

                const weather = historical.weather[historical.weather.length - 1]
                historical.temperature = weather.temperature
                historical.rainfall = weather.rainfall
                this.applyRecommendationRules(historical, weather)

                this.results.push(historical)
            })

            if (this.getWeather.length > 0 && this.results.length < 1) {
                this.$vToastify.error("Não foram encontrados dados climáticos para o talhão e dia selecionados.", "Ops...")
            }
        },
        applyRecommendationRules(historical, weather) {
            if (this.filter.operation == operationsRecommendation.SUBSOLAGEM) {
                const rainfall = historical.weather.filter(w => Number(w.rainfall) > 30)
                const rainfallSum = historical.weather.reduce((sum, w) => sum + Number(w.rainfall), 0)
                historical.rainfallAvr = rainfallSum / historical.weather.length

                if (rainfallSum > 0 && rainfall.length === 0) {
                    historical.operation = {
                        name: this.filter.operation,
                        recommendation: 'Realizar operação'
                    }
                } else {
                    historical.operation = {
                        name: this.filter.operation,
                        recommendation: 'Não realizar operação'
                    }
                }
            } else if (this.filter.operation == operationsRecommendation.CORRETIVO) {
                if (weather.rainfall <= 5) {
                    historical.operation = {
                        name: this.filter.operation,
                        recommendation: 'Realizar operação'
                    }
                } else {
                    historical.operation = {
                        name: this.filter.operation,
                        recommendation: 'Não realizar operação'
                    }
                }
            } else if (this.filter.operation == operationsRecommendation.ADUBACAO_NITROGENADA) {
                const rainfallSum = historical.weather.reduce((sum, w) => sum + Number(w.rainfall), 0)
                historical.rainfallAvr = rainfallSum / historical.weather.length

                if (rainfallSum > 0) {
                    historical.operation = {
                        name: this.filter.operation,
                        recommendation: 'Realizar operação'
                    }
                } else {
                    historical.operation = {
                        name: this.filter.operation,
                        recommendation: 'Não realizar operação'
                    }
                }
            } else if (this.filter.operation == operationsRecommendation.ADUBACAO_POTASSICA) {
                const rainfall = historical.weather.filter(w => Number(w.rainfall) > 5)
                const rainfallSum = historical.weather.reduce((sum, w) => sum + Number(w.rainfall), 0)
                historical.rainfallAvr = rainfallSum / historical.weather.length

                if (rainfall.length < 1) {
                    historical.operation = {
                        name: this.filter.operation,
                        recommendation: 'Realizar operação'
                    }
                } else {
                    historical.operation = {
                        name: this.filter.operation,
                        recommendation: 'Não realizar operação'
                    }
                }
            } else if (this.filter.operation == operationsRecommendation.PLANTIO_E_IRRIGACAO) {
                if (weather.rainfall <= 10) {
                    historical.operation = {
                        name: this.filter.operation,
                        recommendation: 'Realizar operação'
                    }
                } else {
                    historical.operation = {
                        name: this.filter.operation,
                        recommendation: 'Não Realizar operação'
                    }
                }
            } else if (this.filter.operation == operationsRecommendation.CONTROLE_DE_FORMIGAS) {
                if (weather.rainfall <= 4) {
                    historical.operation = {
                        name: this.filter.operation,
                        recommendation: 'Realizar operação no dia seguinte'
                    }
                } else if (weather.rainfall > 4 && weather.rainfall <= 10) {
                    historical.operation = {
                        name: this.filter.operation,
                        recommendation: 'Realizar operação 2 dias depois'
                    }
                } else if (weather.rainfall > 10 && weather.rainfall <= 15) {
                    historical.operation = {
                        name: this.filter.operation,
                        recommendation: 'Realizar operação 3 dias depois'
                    }
                } else if (weather.rainfall > 15) {
                    historical.operation = {
                        name: this.filter.operation,
                        recommendation: 'Realizar operação 4 dias depois'
                    }
                }
            } else if (this.filter.operation == operationsRecommendation.MATOCOMPETICAO) {
                if (weather.rainfall == 0) {
                    historical.operation = {
                        name: this.filter.operation,
                        recommendation: 'Realizar operação'
                    }
                } else {
                    historical.operation = {
                        name: this.filter.operation,
                        recommendation: 'Não Realizar operação'
                    }
                }
            }
        },
        validateFilter() {
            this.filter.startDate = this.filter.endDate

            if (this.filter.fieldsIds.length < 1) {
                this.$vToastify.error("Selecione pelo menos um talhão.", "Erro")
                return false
            }
            if (this.filter.operation.length < 1) {
                this.$vToastify.error("Selecione pelo menos uma operação.", "Erro")
                return false
            }
            if (!this.filter.endDate) {
                this.$vToastify.error("Selecione a data.", "Erro")
                return false
            }
            return this.validateOperations()
        },
        validateOperations() {
            if (this.isOperationSubsolagem()) {
                this.filter.startDate = this.getPreviousDate(this.filter.endDate, 60)
                return true
            }
            if (this.isOperationAdubacao() && this.isDateBetweenMonths(this.filter.startDate, 6, 9)) {
                this.$vToastify.error("Essa operação deve ser realizada entre Outubro e Maio.", "Erro")
                return false
            }
            if (this.isOperationAdubacao()) {
                this.filter.startDate = this.getFirstDayOfMonth(this.filter.endDate)
                return true
            }
            return true
        },
        isOperationSubsolagem() {
            return this.filter.operation === operationsRecommendation.SUBSOLAGEM
        },
        isOperationAdubacao() {
            return this.filter.operation === operationsRecommendation.ADUBACAO_NITROGENADA ||
                this.filter.operation === operationsRecommendation.ADUBACAO_POTASSICA
        }
    },
    watch: {
        async getPropertySelected() {
            await this.findFields()
        },
        async getCropSelected() {
            await this.findFields()
        }
    }
}
</script>

<style lang="sass">
.recommendation-container
    display: flex
    flex-direction: column
    grid-gap: 10px

    .item
        width: 100%

    .action
        padding-top: 20px

    .recommendation
        &-filter, &-result
            padding: 20px
            grid-gap: 6px
            flex-direction: column

</style>
