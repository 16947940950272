import Vue from 'vue'
import modal from 'vue-js-modal'
import App from './views/main/App.vue'
import router from './routes'
import store from './store'
import {Icon} from 'leaflet'
import 'leaflet/dist/leaflet.css'
import VueToastify from 'vue-toastify'
import Vuesax from 'vuesax'
import VueMask from 'v-mask'
import 'material-icons/iconfont/material-icons.css'
import 'vuesax/dist/vuesax.css'

import capitalize from '@/filters/capitalize'
import { formatDate, formatTime, formatDateTime } from '@/filters/date'
import { formatNumber, formatInputNumber, unformatNumber, formatInputNumberWithThreeDecimalPlaces, formatNumberWithThreeDecimalPlaces } from '@/filters/number'

import accessControl from '@/mixins/access-control'
import circularList from '@/mixins/circular-list'
import date from "@/mixins/date"
import number from '@/mixins/number'
import polygon from "@/mixins/polygon"
import productCategory from '@/mixins/product-category'
import stock from '@/mixins/stock'
import validator from '@/mixins/validator'
import uuid from '@/mixins/uuid'

import clickArrows from '@/directives/click-arrows'
import clickOutside from '@/directives/click-outside'

delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

Vue.config.productionTip = false

Vue.use(Vuesax)
Vue.use(VueToastify)
Vue.use(VueMask)
Vue.use(modal)

Vue.filter('capitalize', capitalize)
Vue.filter('formatDate', formatDate)
Vue.filter('formatTime', formatTime)
Vue.filter('formatDateTime', formatDateTime)
Vue.filter('formatNumber', formatNumber)
Vue.filter('formatInputNumber', formatInputNumber)
Vue.filter('formatInputNumberWithThreeDecimalPlaces', formatInputNumberWithThreeDecimalPlaces)
Vue.filter('unformatNumber', unformatNumber)
Vue.filter('formatNumberWithThreeDecimalPlaces', formatNumberWithThreeDecimalPlaces)

Vue.mixin(accessControl)
Vue.mixin(circularList)
Vue.mixin(date)
Vue.mixin(number)
Vue.mixin(polygon)
Vue.mixin(productCategory)
Vue.mixin(stock)
Vue.mixin(validator)
Vue.mixin(uuid)

Vue.directive('click-outside', clickOutside);
Vue.directive('click-arrows', clickArrows);

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app')