<template>
    <div class="button-dropdown">
        <div class="button-dropdown__activator" data-toggle="dropdown" aria-haspopup="true"
             aria-expanded="false">
            <img class="icon" v-show="icon" :src="`${iconSrc}`" alt=""/>
            <div class="divisor" v-show="icon"/>
            <div class="description" :class="{ 'description-loading': loading }">
                <strong v-show="!loading">{{ title }}</strong>
                <span v-show="!loading">{{ message }}</span>
                <div v-show="loading" class="spinner-border text-secondary" role="status"/>
            </div>
            <i class="action fas fa-angle-down"/>
        </div>
        <div class="button-dropdown__content dropdown-menu">
            <div class="input-group" v-show="searchTitle">
                <input type="text" class="input form-control" :placeholder="searchTitle" v-model="searchInput">
                <div class="input-group-append">
                    <span class="input-group-text">
                        <i class="fas fa-search"/>
                    </span>
                </div>
            </div>
            <div class="overflow">
                <a v-for="(item, i) in searchTitle ? searchArray(dropList, searchInput) : drop" :key="i"
                   class="dropdown-item" @click="optionsChanged(item)">
                    <span>{{ item.title }}</span>
                    <i v-if="searchTitle" class="star far fa-star"/>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Dropdown",
    props: {
        title: String,
        subtitle: String,
        search: String,
        dropList: Array,
        icon: {
            type: String,
            default: "",
        },
        loading: Boolean
    },
    data() {
        return {
            message: "",
            drop: [],
            searchInput: "",
            searchTitle: ""
        };
    },
    mounted() {
        this.message = this.subtitle;
        this.drop = this.dropList;
        this.searchTitle = this.search
    },
    watch: {
        subtitle: function (to) {
            this.message = to;
        },
        dropList: function (to) {
            if (to.length > 0) {
                this.drop = to;
            }
        },
        search: function (to) {
            this.searchTitle = to;
        }
    },
    computed: {
        iconSrc() {
            return `/img/icons/svg/${this.icon}.svg`;
        }
    },
    methods: {
        optionsChanged(change) {
            this.$emit("clickEvent", change);
        },
        searchArray(arr, text) {
            let filteredData = []
            for (let i = 0; i < arr.length; i++) {
                text = text.toLowerCase()
                let name = arr[i].title.toLowerCase()
                if (name.includes(text)) {
                    filteredData.push(arr[i])
                }
            }
            return filteredData
        },
    },
};
</script>

<style scoped lang="sass">
.button-dropdown
    position: relative
    vertical-align: middle

    display: flex
    justify-content: center
    align-items: center
    width: 250px
    height: 70px
    background: #fff
    border: 0.5px solid #d6d6d6
    border-radius: 4px
    padding: 6px
    color: $color-black
    font-size: 14px

    &__activator
        height: 100%
        width: 100%
        display: flex
        justify-content: space-evenly
        align-items: center
        grid-gap: 10px

        .icon
            height: 30px
            width: 30px

        .divisor
            width: 0.5px
            height: 100%
            background-color: #d6d6d6

        .description
            display: flex
            flex-direction: column
            min-width: 156px

            span
                overflow: hidden
                white-space: nowrap
                text-overflow: ellipsis

            &-loading
                font-size: 16px
                align-items: center

        .action
            padding-left: 8px

    &__content
        width: 100%

        .input-group
            width: 90%
            margin: auto auto 10px

        .input-group-text
            background-color: transparent
            border-left: 1px solid #ced4da

        .input
            font-size: 12px

            &:focus
                box-shadow: none
                border-color: #65a335

        .overflow
            overflow: auto
            max-height: 200px

        .dropdown-item
            display: inline-flex
            justify-content: space-between
            align-items: center
            white-space: unset
            font-size: 14px
            color: #565656

            &:active
                background-color: unset

        .star
            float: right

            &:hover
                color: rgba(233, 198, 0, 0.904)

@media (max-width: 1050px)
    .button-dropdown__content
        position: absolute
        z-index: 9999

</style>