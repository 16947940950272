/* eslint-disable no-unused-vars */
import Activities from '../../api/Activities';

const state = {
    activities: [],
    activityProducts: []
}

const getters = {
    getActivities: state => state.activities,
    getActivityProducts: state => state.activityProducts
}

const actions = {
    async setActivitiesStatus({ commit }, activities ) {
        commit("SET_ACTIVITIES", activities);
    },
    async findActivities({ commit }, { propertyId, cropId, fieldId, fieldIdList, startDate, endDate }) {
        const response = await Activities.findActivities({ propertyId, cropId, fieldId, fieldIdList, startDate, endDate })
        const activities = response.data.data
        commit("SET_ACTIVITIES", activities);
    },
    async findActivityProducts({ commit }, { propertyId }) {
        const response = await Activities.findActivityProducts({ propertyId })
        const activityProducts = response.data.data
        commit("SET_ACTIVITY_PRODUCTS", activityProducts);
    },
    async postPlanting({commit}, planting) {
        const response =  await Activities.postPlanting(planting);
        return response;
    },
    async updatePlanting({commit}, payload) {
        const { plantingId, planting } = payload
        const response = await Activities.putPlanting({ plantingId, planting })
        return response
    },
    async completePlanting({ commit }, payload) {
        const { plantingId, dateId } = payload
        await Activities.completePlanting({ plantingId, dateId })
    },
    async deactivatePlanting({ commit }, payload) {
        const { plantingId } = payload
        return await Activities.deactivatePlanting({ plantingId })
    },
    async postHarvest({commit}, harvest) {
        const response = await Activities.postHarvest(harvest);
        return response
    },
    async updateHarvest({commit}, payload) {
        const { harvestId, harvest } = payload
        const response = await Activities.putHarvest({ harvestId, harvest })
        return response
    },
    async completeHarvest({ commit }, payload) {
        const { harvestId, dateId } = payload
        await Activities.completeHarvest({ harvestId, dateId })
    },
    async deactivateHarvest({ commit }, payload) {
        const { harvestId } = payload
        return await Activities.deactivateHarvest({ harvestId })
    },
    async postPulverization({commit}, data) {
        const response = await Activities.postPulverization(data);
        return response;
    },
    async updatePulverization({commit}, payload) {
        const { pulverizationId, pulverization } = payload
        const response = await Activities.putPulverization({ pulverizationId, pulverization })
        return response
    },
    async completePulverization({ commit }, payload) {
        const { pulverizationId, dateId } = payload
        await Activities.completePulverization({ pulverizationId, dateId })
    },
    async deactivatePulverization({ commit }, payload) {
        const { pulverizationId } = payload
        return await Activities.deactivatePulverization({ pulverizationId })
    },
    async postSoilPreparation({commit}, data) {
        const response = await Activities.postSoilPreparation(data);
        return response;
    },
    async updateSoilPreparation({commit}, payload) {
        const { soilPreparationId, soilPreparation } = payload
        const response = await Activities.putSoilPreparation({ soilPreparationId, soilPreparation })
        return response
    },
    async completeSoilPreparation({ commit }, payload) {
        const { soilPreparationId, dateId } = payload
        await Activities.completeSoilPreparation({ soilPreparationId, dateId })
    },
    async deactivateSoilPreparation({ commit }, payload) {
        const { soilPreparationId } = payload
        return await Activities.deactivateSoilPreparation({ soilPreparationId })
    },
    async postSeedTreatment({commit}, data) {
        const response = await Activities.postSeedTreatment(data);
        return response;
    },
    async updateSeedTreatment({commit}, payload) {
        const { seedTreatmentId, seedTreatment } = payload
        const response = await Activities.putSeedTreatment({ seedTreatmentId, seedTreatment })
        return response
    },
    async completeSeedTreatment({ commit }, payload) {
        const { seedTreatmentId, dateId } = payload
        await Activities.completeSeedTreatment({ seedTreatmentId, dateId })
    },
    async deactivateSeedTreatment({ commit }, payload) {
        const { seedTreatmentId } = payload
        return await Activities.deactivateSeedTreatment({ seedTreatmentId })
    },
    async postFertilization({commit}, data) {
        const response = await Activities.postFertilization(data);
        return response;
    },
    async updateFertilization({commit}, payload) {
        const { fertilizationId, fertilization } = payload
        const response = await Activities.putFertilization({ fertilizationId, fertilization })
        return response
    },
    async completeFertilization({ commit }, payload) {
        const { fertilizationId, dateId } = payload
        await Activities.completeFertilization({ fertilizationId, dateId })
    },
    async deactivateFertilization({ commit }, payload) {
        const { fertilizationId } = payload
        return await Activities.deactivateFertilization({ fertilizationId })
    },
    async postOtherActivity({commit}, data) {
        const response = await Activities.postOtherActivity(data);
        return response;
    },
    async updateOtherActivity({commit}, payload) {
        const { otherActivityId, otherActivity } = payload
        const response = await Activities.putOtherActivity({ otherActivityId, otherActivity })
        return response
    },
    async completeOtherActivity({ commit }, payload) {
        const { otherActivityId, dateId } = payload
        await Activities.completeOtherActivity({ otherActivityId, dateId })
    },
    async deactivateOtherActivity({ commit }, payload) {
        const { otherActivityId } = payload
        return await Activities.deactivateOtherActivity({ otherActivityId })
    },
    async approveActivityProducts({ commit }, activityProducts) {
        const response = await Activities.approveActivityProducts(activityProducts)
        return response
    },
    async updateActivityProduct({ commit }, { activityProductId, value }) {
        const response = await Activities.updateActivityProduct({ activityProductId, value })
        return response
    }
}

const mutations = {
    SET_ACTIVITIES: (state, activities) => state.activities = activities,
    SET_ACTIVITY_PRODUCTS: (state, activityProducts) => state.activityProducts = activityProducts,
}

export default { state, getters, actions, mutations, namespaced: true }
