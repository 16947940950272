import API from './API';

export default {

    createStockRegister(stockRegister) {
        return API.post("/stock-registers", stockRegister)
    },

    findStockRegisters(propertyId, stockProductId) {
        return API.get("/stock-registers", {
            params: {
                propertyId,
                ...stockProductId ? { stockProductId } : {}
            }
        })
    },

    updateNotesStockRegisters(stockRegisterId, notes) {
        return API.put(`/stock-registers/${stockRegisterId}`, { notes: notes })
    }
}
