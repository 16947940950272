<template>
    <div>
        <Modal :open="open" :loading="loadingData" @close="$emit('close')">
            <div class="app-modal-header" slot="header">
                <div class="icon-planting"/>
                <span> {{ isUpdateModal() ? 'Edição de Plantio' : 'Novo Plantio' }} </span>
            </div>
            <div class="app-modal-body" slot="body">
                <div class="progress">
                    <div
                        class="progress-bar"
                        role="progressbar"
                        :style="`width: ${getPorcentPage()}%`"
                        :aria-valuenow="getPorcentPage()"
                        aria-valuemin="0"
                        aria-valuemax="100"
                    />
                    <div class="progress-counter" :style="`margin-left: ${getPorcentPage(true)}%`">
                        {{ page }}/{{ pages }}
                    </div>
                </div>
                <div>
                    <div v-show="page == 1">
                        <div>
                            <label
                                for="crop"
                                class="app-label subarea-selector__title"
                            >
                                Safra:
                            </label>
                            <span
                                id="crop"
                                class="subarea-selector__text"
                            >
                                {{ this.getCropSelected.name }}
                            </span>
                        </div>
                        <div class="content">
                            <div v-if="!isUpdateModal()">
                                <subarea-selector
                                    :uuid="isUpdateModal() ? 'planting_edit_subarea' : 'planting_create_subarea'"
                                    v-model="selectedSubareas"
                                />
                            </div>
                            <div v-else>
                                <label
                                    class="app-label"
                                    for="subareaSize"
                                >
                                    Área utilizada (máx: {{ subareaSizeValue }} ha):
                                </label>
                                <input-number
                                    id="subareaSize"
                                    :data="plantingData.area"
                                    @input="plantingData.area = $event"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="content" v-show="page == 2">
                        <div>
                            <label
                                class="app-label"
                                for="title"
                            >
                                Título
                            </label>
                            <input
                                class="app-input"
                                type="text"
                                id="title"
                                v-model="plantingData.title"
                                placeholder="Ex: 1ª Pulverização, 2ª Fungicida + 1ª Fertilizante"
                            >
                        </div>
                        <div>
                            <date-selector
                                :uuid="isUpdateModal() ? 'planting_edit_date' : 'planting_create_date' "
                                v-model="plantingData.date"
                            />
                        </div>
                        <div>
                            <operator-selector
                                :uuid="isUpdateModal() ? 'planting_edit_operator' : 'planting_create_operator' "
                                v-model="plantingData.operators"
                            />
                        </div>
                    </div>
                    <div class="content" v-show="page === 3">
                        <div>
                            <culture-phase-selector
                                v-model="plantingData.culturePhaseId"
                            />
                        </div>
                        <div class="row">
                            <div class="col-md-6 my-2">
                                <input-number-selectable
                                    id="spaceline"
                                    label="Espaçamento entre linhas"
                                    placeholder="Ex: 10"
                                    :items="['cm']"
                                    :item-selected="!plantingData.spaceline.unity ? 'cm' : plantingData.spaceline.unity"
                                    :value="plantingData.spaceline.value"
                                    @input="setValueUnity($event, plantingData, 'spaceline')"
                                />
                            </div>
                            <div class="col-md-6 my-2">
                                <input-number-selectable
                                    id="plantPopulation"
                                    label="Distribuição de Sementes"
                                    placeholder="Ex: 10"
                                    :items="['Sementes/m', 'Sementes/ha']"
                                    :item-selected="!plantingData.plantPopulation.unity ? 'Sementes/m' : plantingData.plantPopulation.unity"
                                    :value="plantingData.plantPopulation.value"
                                    @input="setValueUnity($event, plantingData, 'plantPopulation')"
                                />
                            </div>
                            <div class="col-md-6 my-2">
                                <input-number-selectable
                                    id="plantStand"
                                    label="Estande Final de Plantas Esperado"
                                    placeholder="Ex: 10"
                                    :items="['pL/m', 'pL/ha']"
                                    :item-selected="!plantingData.plantStand.unity ? 'pL/m' : plantingData.plantStand.unity"
                                    :value="plantingData.plantStand.value"
                                    @input="setValueUnity($event, plantingData, 'plantStand')"
                                />
                            </div>
                            <div class="col-md-6 my-2">
                                <input-number-selectable
                                    id="expectedProductivity"
                                    label="Produtividade esperada"
                                    placeholder="Ex: 5"
                                    :items="['Sacas por hectare(Sc/ha)', 'Tonelada por hectare(Ton/ha)','Quilos por hectare(Kg/ha)']"
                                    :item-selected="lengthenUnity(plantingData.expectedProductivity.unity)"
                                    :value="plantingData.expectedProductivity.value"
                                    @input="setValueUnity($event, plantingData, 'expectedProductivity')"
                                />
                            </div>
                        </div>
                        <div>
                            <stock-product-selector
                                :uuid="isUpdateModal() ? 'planting_edit_product' : 'planting_create_product'"
                                :cultureId="this.getCropSelected.culture"
                                v-model="plantingData.stockProducts"
                            />
                        </div>
                    </div>
                    <div class="content" v-show="page === 4">
                        <div>
                            <machinery-selector
                                :uuid="isUpdateModal() ? 'planting_edit_machinery' : 'planting_create_machinery' "
                                v-model="plantingData.machineries"
                            />
                        </div>
                        <div>
                            <label class="app-label" for="obs">Observações</label>
                            <textarea class="app-textarea" v-model="plantingData.notes" rows="4"
                                      id="obs"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="app-modal-footer" slot="footer">
                <button class="btn btn-primary--outlined btn--flat" v-show="!saving" :disabled="this.page === 1"
                        @click="prevPage">
                    <i class="far fa-arrow-alt-circle-left"/>
                    <span>Voltar</span>
                </button>
                <button class="btn btn-primary btn--flat" v-show="!saving" :disabled="loadingData" v-if="page < pages" @click="nextPage">
                    <span>Proximo</span>
                    <i class="far fa-arrow-alt-circle-right"></i>
                </button>
                <button class="btn btn-primary btn--flat" v-show="!saving" v-else @click="savePlanting">
                    <span>Salvar</span>
                    <i class="fas fa-save"/>
                </button>
                <div v-if="saving === true" style="width: 200px; display: flex; justify-content: center">
                    <div style="display: flex; justify-content: center">
                        <div style="margin-bottom: 6px;" class="spinner-border text-success" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                    <div style="margin-left: 10px; margin-top: 4px">Salvando</div>
                </div>
            </div>
        </Modal>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Droplist from "@/components/systemUI/Droplist.vue"
import InputNumberSelectable from "@/components/systemUI/InputNumberSelectable.vue"
import Modal from '@/components/systemUI/Modal'
import MachinerySelector from '@/components/activities/components/machineries/MachinerySelector.vue'
import StockProductSelector from '@/components/activities/components/stock-products/StockProductSelector.vue'
import SubareaSelector from '@/components/activities/components/subareas/SubareaSelector.vue'
import OperatorSelector from '@/components/activities/components/operators/OperatorSelector.vue'
import DateSelector from '@/components/activities/components/dates/DateSelector.vue'
import CulturePhaseSelector from '@/components/activities/components/culture-phases/CulturePhaseSelector.vue'
import InputNumber from '@/components/commons/InputNumber.vue'
import subareaApi from '@/api/Subarea'
import _ from 'lodash';

export default {
    name: 'ModalPlanting',
    props: {
        data: Object,
        event: Object,
        open: Boolean
    },
    components: {
        Droplist,
        InputNumberSelectable,
        Modal,
        SubareaSelector,
        StockProductSelector,
        MachinerySelector,
        OperatorSelector,
        DateSelector,
        CulturePhaseSelector,
        InputNumber
    },
    data() {
        return {
            page: 1,
            pages: 4,
            loadingData: false,
            saving: false,
            selectedSubareas: [],
            plantingData: {
                date: [],
                area: null,
                subareaId: "",
                title: "",
                operators: [],
                stockProducts:[],
                machineries: [],
                notes: "",
                culturePhaseId: null,
                spaceline: { value: null, unity: 'cm' },
                plantPopulation: { value: null, unity: 'Sementes/m' },
                plantStand: { value: null, unity: 'pL/m' },
                expectedProductivity: { value: null, unity: 'pL/m' },
            }
        };
    },
    computed: {
        ...mapGetters("property", ["getPropertySelected", "getCropSelected", "getCrops"]),
        ...mapGetters('subareas', ['getSubareas']),
        subareaSizeValue() {
            return this.returnIfExists(this.data, "subarea.size.value") || 0;
        }
    },
    watch: {
        async open() {
            this.clearData()
            if (this.open) {
                await this.loadData();
                if (this.isUpdateModal()) {
                    this.copyData();
                }
            }
        }
    },
    methods: {
        ...mapActions("activities", ['postPlanting', 'updatePlanting']),
        ...mapActions("units", ["findUnits"]),
        ...mapActions("unit-conversions", ["findUnitConversions"]),
        ...mapActions("culture-phases", ["findCulturePhases"]),
        ...mapActions('operators', ['setOperators', 'getOperators']),
        ...mapActions('machineries', ['setMachineries', 'getMachineries']),
        ...mapActions("product-categories", ['findProductCategories']),
        ...mapActions('stock-products', ['findStockProducts']),
        ...mapActions('subareas', ['setSubareas', 'findSubareas']),
        async loadData() {
            this.loadingData = true;

            const propertyId = this.getPropertySelected._id
            const cropId = this.getCropSelected._id

            await this.getOperators({ propertyId });
            await this.getMachineries({ propertyId });
            await this.findProductCategories({ propertyId });
            await this.findStockProducts({ propertyId });
            await this.findSubareas({ propertyId, cropId });
            await this.findCulturePhases();
            await this.findUnits();
            await this.findUnitConversions();

            this.loadingData = false;
        },
        setValueUnity(event, plantingData, key) {
            plantingData[key].value = event.input
            plantingData[key].unity = this.shortenUnity(event.select) || event.select
            return plantingData
        },
        shortenUnity(unity) {
            const unities = {
                'Sacas por hectare(Sc/ha)': 'Sc/ha',
                'Tonelada por hectare(Ton/ha)': 'Ton/ha',
                'Quilos por hectare(Kg/ha)': 'Kg/ha'
            };
            return unities[unity];
        },
        lengthenUnity(unity) {
            const unities = {
                'Sc/ha': 'Sacas por hectare(Sc/ha)',
                'Ton/ha': 'Tonelada por hectare(Ton/ha)',
                'Kg/ha': 'Quilos por hectare(Kg/ha)',
                'Sc': 'Sacas por talhão(Sc)',
                'Ton': 'Tonelada por talhão(Ton)',
                'Kg': 'Quilos por talhão(Kg)'
            };
            return unities[unity];
        },
        hasSelectedSubareas() {
            return this.selectedSubareas && this.selectedSubareas.length > 0;
        },
        hasSelectedDates() {
            return this.plantingData.date && this.plantingData.date.length > 0;
        },
        hasSelectedCrop() {
            return this.plantingData.subarea && this.plantingData.subarea.crop;
        },
        isUpdateModal() {
            return this.data && this.data._id
        },
        nextPage() {
            if (this.page == 1) {
                if (!this.hasSelectedSubareas() && !this.isUpdateModal()) {
                    return this.$vToastify.info("Selecione uma safra e um talhão", "Atenção!")
                }

                if (this.isUpdateModal() && (this.plantingData.area || 0) > this.subareaSizeValue) {
                    return this.$vToastify.info(`A área não pode ser maior que a subarea ${this.subareaSizeValue}ha.`, "Atenção!")
                }
            }

            if (this.page === 2) {
                if (!this.hasSelectedDates()) {
                    return this.$vToastify.info("Selecione pelo menos uma data", "Atenção!")
                }
            }

            if (this.page < this.pages) {
                this.page++;
            }

        },
        prevPage() {
            if (this.page <= this.pages && this.page > 1) {
                this.page--;
            }
        },
        getPorcentPage(counter = false) {
            if (!counter) {
                return (this.page / this.pages) * 100;
            } else {
                if (this.page === this.pages) {
                    return (this.page / this.pages) * 100 - 4;
                } else {
                    return (this.page / this.pages) * 100 - 2;
                }
            }
        },
        async savePlanting() {
            this.saving = true;
            if (this.isUpdateModal()) {
                await this.updatePlanting({ plantingId: this.plantingData._id, planting: this.plantingData })
                    .then(() => {
                        this.saving = false;
                        this.$vToastify.success("Atividade salva com sucesso!", "Plantio")
                        this.$emit('done')
                    })
                    .catch(() => {
                        this.saving = false;
                        this.$vToastify.error("Não foi possivel criar a atividade, tente novamente!", "Plantio")
                    });

            } else {
                const requests = this.selectedSubareas.map(subareaId => {
                    const subarea = this.getSubareas.find(subarea => subarea._id == subareaId)
                    this.plantingData.area = subarea.area != null ? subarea.area : subarea.size.value

                    this.plantingData.subareaId = subareaId
                    return this.postPlanting(this.plantingData)
                })
                await Promise.all(requests)
                    .then(() => {
                        this.saving = false;
                        this.$vToastify.success("Atividade salva com sucesso!", "Plantio")
                        this.$emit('done')
                    })
                    .catch(() => {
                        this.saving = false;
                        this.$vToastify.error("Não foi possivel criar a atividade, tente novamente!", "Plantio")
                    });
            }
        },
        copyData() {
            const subareaId = this.returnIfExists(this.data, "subarea._id") || null
            const culturePhaseId = this.returnIfExists(this.data, "culturePhase._id") || null

            const dataClone = _.cloneDeep(this.data)
            this.plantingData = {
                _id: dataClone._id,
                date: dataClone.date,
                subareaId: subareaId,
                culturePhaseId: culturePhaseId,
                operators: dataClone.operators,
                machineries: dataClone.machineries,
                spaceline: dataClone.spaceline,
                plantPopulation: dataClone.plantPopulation,
                plantStand: dataClone.plantStand,
                expectedProductivity: dataClone.expectedProductivity,
                notes: dataClone.notes,
                area: dataClone.area,
                title: dataClone.title,
                stockProducts: dataClone.stockProducts.map(stockProduct => ({
                    stockProductId: stockProduct.stockProduct._id,
                    quantity: {
                        value: stockProduct.quantity.value,
                        unitId: stockProduct.quantity.unit._id,
                        dosageId: stockProduct.quantity.dosage._id
                    },
                    approved: stockProduct.approved
                }))
            }
        },
        clearData() {
            this.setSubareas([]);
            this.setOperators([]);
            this.setMachineries([]);
            Object.assign(this.$data, this.$options.data.apply(this));
        }
    },
    async mounted() {
        this.clearData()
        if (this.open) {
            await this.loadData();
            if (this.isUpdateModal()) {
                this.copyData();
            }
        }
    }
};
</script>

<style scoped lang="sass">
.icon-planting
    mask: url("/img/icons/svg/planting.svg") no-repeat 100% 100%
    @include icon-mask-sm

.container
    padding-left: 0
    padding-right: 0

.flex-container
    grid-gap: 0

.content
    display: flex
    flex-direction: column
    grid-gap: 20px

    .description
        font-size: 16px

    .loading
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        color: $color-blue

    .label-description
        margin-left: 6px
        font-size: 10px

.checkbox-group-label
    width: 100%
    padding: 4px


.checkbox-group
    display: flex
    width: 100%
    flex-wrap: wrap

    &__item
        width: 33.333%
        display: flex
        align-items: center
        padding: 6px 6px 6px 0px
</style>

<style scoped>
.form-check label {
    color: #2A2A2A;
    font-size: 15px;
    font-weight: 400;
}

input[type='radio'] {
    -webkit-appearance: none;
}

.form-check label:before {
    content: " ";
    display: inline-block;
    position: relative;
    top: 5px;
    margin: 0 5px 0 0;
    width: 20px;
    height: 20px;
    border-radius: 11px;
    border: 2px solid #356E2E;
    background-color: transparent;
}

input[type=radio]:checked + label:after {
    border-radius: 11px;
    width: 12px;
    height: 12px;
    position: absolute;
    top: 9px;
    left: 24px;
    content: " ";
    display: block;
    background: #356E2E;
}

.justify-center {
    display: flex;
    justify-content: center;
}

.input-filter {
    width: 100%;
    padding: 7px;
}

.drop-item .input-group-text {
    color: #3C7B34;
    background-color: rgba(60, 123, 52, 0.21);
    border: 1px solid rgba(188, 186, 186, 1);
}

.drop-item .input-group {
    margin-bottom: 20px;
}

.switch {
    position: relative;
    display: inline-block;
    width: 53px;
    height: 27px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: #356E2E;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

</style>
