<template>
    <div class="properties-container">
        <div class="properties-container__header">
            <span class="title">Propriedades</span>
            <div class="actions">
                <div class="search-container">
                    <input
                        class="search-container__input app-input"
                        placeholder="Pesquise uma propriedade"
                        v-model="searchWord"
                        @keyup.enter="filterProperties"
                        type="text"
                    />
                    <button
                        class="search-container__clear clickable"
                        v-show="searchWord.length > 0"
                        @click="cleanSearch"
                    >
                        <i class="fal fa-times-circle" />
                    </button>
                    <button
                        class="search-container__search"
                        @click="filterProperties"
                    >
                        <i class="fas fa-search" />
                    </button>
                </div>
                <span class="area-total">{{ usedArea }}</span>
                <button
                    class="btn btn-primary btn--flat"
                    @click="openModalCreateProperty()"
                >
                    <span>Nova propriedade</span>
                </button>
            </div>
        </div>

        <div v-if="!loading" class="properties-container__content">
            <table class="properties-table">
                <tr>
                    <th>Nome</th>
                    <th>Tamanho (ha)</th>
                    <th>País</th>
                    <th>Estado</th>
                    <th class="properties-table-header__actions">Ações</th>
                </tr>
                <tr v-for="property in propertiesList" :key="property._id">
                    <td>{{ property.name }}</td>
                    <td>{{ property.size | formatNumber }}</td>
                    <td>{{ property.country || "Brasil" }}</td>
                    <td>{{ property.state }}</td>
                    <td>
                        <div class="properties-table-content__actions">
                            <button
                                @click="openModalEditProperty(property)"
                                class="btn btn--flat"
                            >
                                <i class="fas fa-edit"></i>
                                <span>Editar</span>
                            </button>
                            <button
                                type="checkbox"
                                class="btn btn--flat"
                                @click="activeDeactiveProperty(property)"
                            >
                                <i
                                    :class="
                                        property.active
                                            ? 'fas fa-check-square'
                                            : 'far fa-square'
                                    "
                                ></i>
                                <span>{{
                                    property.active ? "Desativar" : "Reativar"
                                }}</span>
                            </button>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
        <div v-else-if="loading" class="no-content">
            <span class="spinner-border" />
        </div>
        <div v-else class="properties-container__content">
            <div class="text-center p-2" style="background-color: #f5f5f5">
                <span class="mx-auto">Não possui dados</span>
            </div>
        </div>
        <modal-property
            v-if="showModalProperty"
            :open="showModalProperty"
            :data="property"
            @close="closeModalEditProperty"
            @inserted="listProperties"
        />
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ModalProperty from "@/views/configurations/properties/components/ModalProperty.vue";

export default {
    name: "Property",
    components: {
        ModalProperty,
    },
    data() {
        return {
            propertiesList: [],
            showModalProperty: false,
            searchWord: "",
            loading: false,
            property: {},
            total: 0,
        };
    },
    computed: {
        ...mapGetters("property", ["getPropertySelected", "getProperties"]),
        ...mapGetters("users", ["profile"]),
        usedArea() {
            const totalPropertiesArea = this.getProperties.reduce((partialSum, property) => partialSum + (this.returnIfExists(property, 'size') || 0), 0);
            const accountAreaLimit = this.returnIfExists(this.getProperties, '0.account.areaLimit') || 0;
            
            return `Área utilizada (ha): ${this.formatNumber(totalPropertiesArea)}/${this.formatNumber(accountAreaLimit)}`;
        },
    },
    async mounted() {
        await this.listProperties();
    },
    methods: {
        ...mapActions("property", ["listProperty", "updateProperty"]),
        ...mapActions("users", ["getUser"]),
        openModalCreateProperty() {
            (this.property = {
                name: "",
                country: "",
                state: "",
                size: "",
                coordinates: {
                    latitude: "",
                    longitude: "",
                },
            }),
                (this.showModalProperty = true);
        },
        openModalEditProperty(property) {
            this.property = { ...property };
            this.showModalProperty = true;
        },
        closeModalEditProperty() {
            this.showModalProperty = false;
        },
        async listProperties() {
            this.loading = true;

            const accountId = this.getPropertySelected.account._id;
            const userId = this.profile._id;
            await this.listProperty({ accountId, userId });

            this.propertiesList = [
                ...this.getProperties.sort((a, b) =>
                    a.createdAt < b.createdAt ? 1 : -1
                ),
            ];

            this.loading = false;
        },
        async activeDeactiveProperty(property) {
            const { _id, active } = {
                _id: property._id,
                active: !property.active,
            };
            const confirmActiveDeactive = await this.$vToastify.prompt({
                body: "Deseja confirmar está ação?",
                title: "ATENÇÃO!",
                answers: { Sim: true, Não: false },
            });
            if (confirmActiveDeactive) {
                await this.updateProperty({ _id, active });
                await this.listProperties();
                this.getUser();
                const sucessText =
                    property.active == true ? "Desativada" : "Reativada";
                this.$vToastify.success(
                    `${sucessText} com sucesso`,
                    "Propriedade"
                );
            } else return;
        },
        filterProperties() {
            this.propertiesList = this.getProperties.filter((property) => {
                return (
                    property.name
                        .toUpperCase()
                        .includes(this.searchWord.toUpperCase()) ||
                    property.state
                        .toUpperCase()
                        .includes(this.searchWord.toUpperCase()) ||
                    property.country
                        .toUpperCase()
                        .includes(this.searchWord.toUpperCase())
                );
            });
        },
        cleanSearch() {
            this.searchWord = "";
            this.propertiesList = [
                ...this.getProperties.sort((a, b) =>
                    a.createdAt < b.createdAt ? 1 : -1
                ),
            ];
        },
    },
    watch: {
        getProperties() {
            this.propertiesList = [...this.getProperties];
            this.propertiesList.sort((a, b) =>
                a.createdAt > b.createdAt ? -1 : 1
            );
        },
        searchWord() {
            this.filterProperties();
        },
        getPropertySelected() {
            this.searchWord = "";
        },
    },
};
</script>

<style scoped lang="sass">
.properties-container
    display: flex
    flex-direction: column
    grid-gap: 6px
    width: 100%

    &__header
        .title
            font-size: 24px
            color: $theme-color-primary

        .area-total
            font-size: 20px
            color: $theme-color-primary

        .actions
            display: flex
            justify-content: space-between

            .search-container
                display: flex
                position: relative

                &__input
                    width: 300px

                &__search
                    position: absolute
                    right: 8px
                    top: 8px
                    border: 0
                    background: none

                &__clear
                    position: absolute
                    right: 36px
                    top: 7px
                    border: 0
                    background: none

.properties-table
    width: 100%

    &-header__actions
        width: 30%
        text-align: center

    &-content__actions
        display: flex
        grid-gap: 6px
        align-items: center
        justify-content: center

        .btn
            background: none

            &:hover
                background: $color-white-darken-1

            i
                color: $color-blue

.properties-table th
    color: $color-blue
    padding: 0 10px
    font-size: 16px

.properties-table tr
    border-radius: 20px
    height: 70px

.properties-table td
    padding: 0 10px

.properties-table tr:nth-child(even)
    background-color: #f2f2f2

.no-content
    display: flex
    justify-content: center
    align-items: center
    height: 100px
    color: $color-grey
    grid-gap: 10px

@media (max-width: 430px)
    .properties-container__header .actions .search-container__input
        width: 200px
</style>
