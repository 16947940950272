<template>
    <div class="report-photos">
        <div class="report-photos__header">
            <router-link class="btn btn-primary btn--rounded btn--flat" tag="a" to="/relatorios">
                <i class="fa fa-arrow-left"/>
                <span>Voltar</span>
            </router-link>
            <h2 class="title">Relatório Fotos Gerais</h2>
        </div>
        <div class="report-photos__filter filter-container">
            <div class="filter-container__item">
                <label class="app-label">
                    Período
                </label>
                <period-select-picker
                    :selectedInterval="selectedInterval"
                    @intervalSelected="selectedInterval = $event.interval"
                />
            </div>
            <div class="filter-container__item filter-container__item--multiple-selector">
                <multiple-selector
                    label="Talhão(ões)"
                    placeholder="Selecione o(s) talhão(ões)"
                    :items="allFields"
                    item-text="name"
                    item-value="_id"
                    v-model="selectedFields"
                />
            </div>
            <div class="filter-container__item">
                <label class="app-label">
                    Fotos por página
                </label>
                <select
                    class="app-select"
                    v-model="selectedFormat"
                >
                    <option value="block">
                        1 (uma)
                    </option>
                    <option value="grid">
                        +1 (organizadas por ponto)
                    </option>
                </select>
            </div>
            <div class="filter-container__item filter-container__date">
                <date-picker
                    :disabled="!selectedCustom"
                    :dataSent="periodSend.startDate"
                    @dateSelected="periodSend.startDate = $event.date"
                    label="Data inicial"
                />
                <date-picker
                    :disabled="!selectedCustom"
                    :dataSent="periodSend.endDate"
                    @dateSelected="periodSend.endDate = $event.date"
                    label="Data final"
                />
            </div>
            <div class="filter-container__item filter-container__action">
                <button
                    class="btn btn-primary--outlined btn--flat"
                    @click="generateReport"
                >
                    <span
                        v-if="loadingPhotos"
                        class="spinner-border spinner-border-sm"
                        role="status"
                    />
                    <span v-else>
                        <i class="fa fa-download mr-2"/>
                        <span>
                            Gerar relatorio
                        </span>
                    </span>
                </button>
                <button
                    class="btn btn-primary--outlined btn--flat"
                    :disabled="photos.length == 0"
                    id="fazenda"
                    @click="generatePDF()"
                >
                    <span
                        v-if="loadingPDF"
                        class="spinner-border spinner-border-sm"
                        role="status"
                    />
                    <span v-else>
                        <i class="fa fa-file-pdf mr-2"/>
                        <span>
                            Baixar PDF
                        </span>
                    </span>
                </button>
            </div>
        </div>
        <div class="report-photos__content">
            <modal-photos
                v-show="photos.length > 0"
                :photos="photos"
            />
            <div
                class="no-content"
                v-show="!loadingPhotos && photos.length < 1"
            >
                <i class="fa fa-exclamation-circle fa-2x"/>
                <span class="">
                    Sem imagens para esse talhão
                </span>
            </div>
        </div>
    </div>
</template>

<script>

import DatePicker from "@/components/commons/DatePicker.vue";
import PeriodSelectPicker from "@/components/commons/PeriodSelectPicker.vue";
import ComboBox from "@/components/commons/ComboBox.vue";
import ModalPhotos from "@/components/commons/ModalPhotos.vue";
import Photo from "@/api/Photo";
import MultipleSelector from '@/components/commons/MultipleSelector.vue';
import { calculatePeriodInterval } from "@/utils/calculatePeriodInterval";
import { mapGetters, mapActions } from "vuex";

export default {
    name: 'ReportPhotos',
    components: {
        DatePicker,
        MultipleSelector,
        PeriodSelectPicker,
        ModalPhotos,
        ComboBox
    },
    data() {
        return {
            loadingPhotos: false,
            loadingPDF: false,
            selectedInterval: "weekly",
            formatReport: ["grid", "block"],
            selectedFormat: "block",
            selectedField: "",
            selectedFields: [],
            photos: [],
            periodSend: {
                custom: false,
                startDate: "",
                endDate: ""
            },
            photosReportPayload: {
                propertyId: "",
                cropId: "",
                startDate: "",
                endDate: "",
                fieldIdList: []
            }
        };
    },

  computed: {
    ...mapGetters("fields", [
      "allFields"
    ]),
     ...mapGetters("photos", [
        "getAllPhotos"
    ]),
    ...mapGetters("property", [
      "getPropertySelected",
      "getCropSelected",
      "getPropertyFields"
    ]),
    selectedCustom(){
        return this.selectedInterval == 'custom'
    }
  },

  async mounted() {
    this.selectedInterval = "weekly"
    await this.updateFilter()
  },

  watch: {
    async getPropertySelected() {
        await this.updateFilter()
        await this.listPropertyFields()
    },
    async getCropSelected() {
      await this.updateFilter()
    },
    async selectedInterval(){
      await this.updateFilter()
    }
  },
  methods: {
    ...mapActions("fields", [
      "getFieldsByProperty"
    ]),
    ...mapActions("photos", [
      "findPhotos"
    ]),
    async listPropertyFields() {
        await this.listFields(this.getPropertySelected._id)
        this.fields = this.getPropertyFields
    },
    updatePayload(){
        const payload = {
            propertyId: this.getPropertySelected._id,
            cropId: this.getCropSelected._id,
            fieldId: this.selectedField == "all" ? "" : this.selectedField._id,
            startDate: this.periodSend.startDate,
            endDate: this.periodSend.endDate,
            fieldIdList: this.selectedFields,
            template: this.selectedFormat
        }
        return payload
    },
    async updateFilter(){
        this.periodSend = calculatePeriodInterval(this.selectedInterval, this.getCropSelected)
        const payload = this.updatePayload()
        await this.getFieldsByProperty(payload)
    },
    async generateReport() {
        this.loadingPhotos = true

        const payload = this.updatePayload()
        await this.findPhotos(payload)
        this.photos = this.getAllPhotos
        this.savedPayload = { ...payload }

        this.loadingPhotos = false
    },
    async generatePDF(){
        this.loadingPDF = true

        const { propertyId, cropId, fieldIdList, subareaId, startDate, endDate, template } = this.updatePayload()
        const response = await Photo.getPhotosReport(propertyId, cropId, fieldIdList, subareaId, startDate, endDate, template)

        if (response.status === 200 && response.data.pdf) {
            this.$vToastify.success('PDF gerado com sucesso!')
            this.goToPage(response.data.pdf)
        } else {
            this.$vToastify.error(response.data.message, 'Erro')
        }

        this.loadingPDF = false
    },
    goToPage(url){
        window.open(url, '_blank')
    }

  },

};
</script>

<style scoped lang="sass">
.report-photos
    height: 100%
    grid-gap: 10px

    &__header
        display: flex
        align-items: center
        justify-content: center
        position: relative
        padding: 20px 0
        width: 100%

        .btn
            position: absolute
            left: 0

        .title
            font-size: 16px
            margin: 0

    &__filter
        display: flex
        grid-gap: 6px
        align-items: flex-end
        padding: 20px 0
        width: 100%

    .filter-container
        display: flex
        justify-content: center
        align-items: flex-end
        padding: 10px 0
        flex-wrap: wrap
        border: $border
        border-radius: $border-radius

        &__item
            flex-grow: 1
            padding: 10px

            &--multiple-selector
                width: 200px

        &__action
            display: flex
            grid-gap: 10px
            justify-content: center
            min-width: 350px

        &__date
            display: flex
            grid-gap: 10px
            min-width: 350px

            .datePicker
                width: 100%

            #date
                height: 40px

    .no-content
        display: flex
        justify-content: center
        align-items: center
        height: 100px
        color: $color-grey
        grid-gap: 10px
</style>
