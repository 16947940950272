<template>
    <Modal
        :open="open"
        :loading="loading"
        @close="$emit('close')"
    >
        <div
            class="app-modal-header"
            slot="header"
        >
            <i class="fas fa-flask-potion"/>
            <span>
                {{ 'Editar quantidade a ser usada' }}
            </span>
        </div>
        <div
            class="app-modal-body"
            slot="body"
        >
            <div class="content">
                <div class="content__item">
                    <label class="app-label">
                        Produto:
                    </label>
                    <span>
                        {{ displayTextStockProductName }}
                    </span>
                </div>
                <div class="content__item">
                    <label class="app-label">
                        Atividade:
                    </label>
                    <span>
                        {{ displayTextStockProductActivityType }}
                    </span>
                </div>
                <div class="content__item">
                    <label class="app-label">
                        Quantidade total calculada:
                    </label>
                    <span>
                        {{ displayTextStockProductCalculatedTotalQuantity }}
                    </span>
                </div>
                <div class="content__item">
                    <label class="app-label">
                        Quantidade no estoque:
                    </label>
                    <span>
                        {{ displayTextStockProductQuantity }}
                    </span>
                </div>
                <div class="content__item">
                    <input-number
                        id="activity-product-quantity"
                        :label="displayTextInputNumberQuantityLabel"
                        placeholder="Informe a quantidade"
                        :data="activityProduct.realTotalQuantity"
                        @input="activityProduct.realTotalQuantity = $event"/>
                </div>
            </div>
        </div>
        <div
            class="app-modal-footer"
            slot="footer"
        >
            <button
                class="btn btn-primary btn--flat"
                @click="saveActivityProduct"
            >
                <span>
                    <span>
                        Salvar
                    </span>
                    <i class="ml-2 fas fa-save"/>
                </span>
            </button>
        </div>
    </Modal>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Modal from "@/components/systemUI/Modal.vue"
import InputNumber from "@/components/commons/InputNumber"
import _ from 'lodash'

export default {
    name: 'ModalFixRealTotalQuantity',
    props: {
        data: Object,
        open: Boolean,
    },
    components: {
        Modal,
        InputNumber
    },
    data() {
        return {
            loading: false,
            activityProduct: {
                stockProduct: {
                    product: {
                        name: '',
                        unit: ''
                    },
                    quantity: 0
                },
                realTotalQuantity: 0
            }
        };
    },
    computed: {
        displayTextStockProductName() {
            return this.returnIfExists(this.activityProduct, "stockProduct.product.name") || "-"
        },
        displayTextStockProductActivityType() {
            const activityType = this.returnIfExists(this.activityProduct, "activity.type")
            return this.translateActivityType(activityType) || '-'
        },
        displayTextStockProductCalculatedTotalQuantity() {
            const stockProductCalculatedTotalQuantity = this.returnIfExists(this.activityProduct, "calculatedTotalQuantity")
            if (!stockProductCalculatedTotalQuantity) {
                return '-'
            }

            const stockProductUnit = this.returnIfExists(this.activityProduct, "stockProduct.product.unit.name")
            if (!stockProductUnit) {
                return this.formatNumber(stockProductCalculatedTotalQuantity)
            }

            return `${this.formatNumber(stockProductCalculatedTotalQuantity)} ${stockProductUnit}`
        },
        displayTextStockProductQuantity() {
            const stockProductQuantity = this.returnIfExists(this.activityProduct, "stockProduct.quantity")
            if (!stockProductQuantity) {
                return '-'
            }

            const stockProductUnit = this.returnIfExists(this.activityProduct, "stockProduct.product.unit.name")
            if (!stockProductUnit) {
                return this.formatNumber(stockProductQuantity)
            }

            return `${this.formatNumber(stockProductQuantity)} ${stockProductUnit}`
        },
        displayTextInputNumberQuantityLabel() {
            const stockProductQuantityUnitName = this.returnIfExists(this.activityProduct, "stockProduct.product.unit.name")

            if (!stockProductQuantityUnitName) {
                return 'Quantidade total usada:'
            }

            return `Quantidade total usada (${stockProductQuantityUnitName}):`
        }
    },
    methods: {
        clearData() {
            Object.assign(this.$data, this.$options.data.apply(this));
        },
        translateActivityType(activityType) {
            const translatedActivityTypes = {
                "planting": "Plantio",
                "pulverization": "Pulverização",
                "harvest": "Colheita",
                "soilPreparation": "Preparo de solo",
                "seedTreatment": "Tratamento de sementes",
                "fertilization": "Fertilização",
                "otherActivity": "Outra atividade"
            }
            return translatedActivityTypes[activityType]
        },
        formatInput() {
            this.activityProduct = _.cloneDeep(this.data)
        },
        saveActivityProduct() {
            if (!this.activityProduct.realTotalQuantity) {
                return this.$vToastify.error('É necessário digitar a quantidade para editar o produto', 'Produto');
            }

            this.$vToastify.success("Quantidade total alterada com sucesso", "Produtos");
            this.$emit('save', this.activityProduct)
            this.$emit('close')
        }
    },
    mounted() {
        this.formatInput()
    }
};
</script>

<style scoped lang="sass">
.content
    display: flex
    flex-wrap: wrap

    &__item
        padding: 4px
        width: 50%

        &--full-width
            width: 100%

@media (max-width: 500px)
    .content
        &__item
            width: 100%


</style>
