<template>
  <div class="polygon-editor">
    <div class="polygon-editor__container polygon-container">
      <div class="polygon-container__map">
        <div class="polygon-actions">
          <a class="polygon-actions__actions" title="importar KML">
            <label for="kml">
                <i class="fa fa-cloud-upload" />
            </label>
            <input
              id="kml"
              type="file"
              ref="fileKML"
              accept=".kml"
              @change="readKML"
            />
          </a>
          <a class="polygon-actions__actions" title="Salvar">
            <i @click="saveEditedPolygon()" class="fa fa-save" />
          </a>
          <a class="polygon-actions__actions" title="Excluir Polígono">
            <i @click="removePolygon()" class="fa fa-trash" />
          </a>
        </div>
        <div class="coordinate-inputs">
            <input v-model="latitude" type="number" placeholder="Latitude" />
            <input v-model="longitude" type="number" placeholder="Longitude" />
            <button @click="centerMapOnCoordinates">Localizar</button>
        </div>
        <div class="polygon-container__options polygon-options">
          <div class="polygon-options__area">
            {{ displayTextPolygonArea }}
          </div>
          <a
            class="polygon-options__option"
            @click="closeModalFieldPolygonEditor"
          >
            <span>
              <i class="fas fa-times" />
            </span>
          </a>
        </div>
        <l-map
          class="polygon-map"
          ref="polygonsMap"
          :zoom="14"
          :options="{
            zoomControl: false
          }"
        >
        </l-map>
      </div>
    </div>
  </div>
</template>

<script>
import {LMap, LPolygon, LTileLayer} from "vue2-leaflet";
import L from 'leaflet';
import BingMapPlugin from "@/utils/bingMapPlugin.js";
import { drawPolygon } from "@/utils/drawPolygons";

import LDraw from 'leaflet-draw'

import { DOMParser} from 'xmldom';
import toGeoJson from '@mapbox/togeojson';

import "leaflet-draw/dist/leaflet.draw.css"

import { mapGetters } from "vuex";

import _ from "lodash";


export default {
    name: 'ModalFieldPolygonEditor',
    props: {
        data: Object,
        polygons: Array
    },
    components: {
        LMap,
        LTileLayer,
        LPolygon,
        LDraw
    },
    data() {
        return {
            polygonsEdited: {
                latlngs: []
            },
            drawnItems: null
        }
    },
    computed: {
        ...mapGetters("property", ["getPropertySelected"]),
        polygonsEditedFormatted() {
            if (!this.polygonsEdited.latlngs) {
                return []
            }
            return this.polygonsEdited.latlngs.map(latlng => ({ latitude: latlng[0], longitude: latlng[1] }))
        },
        polygonsEditedArea() {
            return this.getPolygonArea(this.polygonsEditedFormatted)
        },
        displayTextPolygonArea() {
            const area = this.formatNumber(this.polygonsEditedArea)

            return `Área utilizada: ${area} ha`
        },
        editedPolygonArray(){
            const newArrayPolygon = this.polygonsEdited.latlngs.map(({
                lat: latitude,
                lng: longitude
            }) => ({ latitude, longitude }))
            return newArrayPolygon
        },
        editedPolygonArrayBeforeEmit(){
            const newArrayPolygon = this.polygonsEdited.latlngs.map(({
                0: latitude,
                1: longitude
            }) => ({ latitude, longitude }))
            return newArrayPolygon
        }
    },
    watch: {
        polygons() {
            this.resetPolygons()
        },
        "polygonsEdited.latlngs"() {
            this.updateMapCenter();
        }
    },
    methods: {
        saveEditedPolygon(){
            if(this.polygonsEdited.latlngs.length > 0) {
                this.$emit('polygon', this.editedPolygonArrayBeforeEmit)
                this.$emit('area', this.polygonsEditedArea.toFixed(2))
                this.$emit('close')
            } else {
                this.$emit('close')
            }
        },
        closeModalFieldPolygonEditor() {
            this.$emit('close')
        },
        getMapCenter() {
            if (!this.polygonsEdited || this.polygonsEdited.latlngs <= 3) {
                const { latitude, longitude } = this.getPropertyCoordinates(
                    this.getPropertySelected.coordinates.latitude,
                    this.getPropertySelected.coordinates.longitude
                )
                return {
                    latitude: latitude,
                    longitude: longitude
                }
            }

            const latitude = this.polygonsEdited.latlngs[0][0];
            const longitude = this.polygonsEdited.latlngs[0][1];
            return { latitude, longitude }
        },
        updateMapCenter() {
            const coordinates = this.getMapCenter();

            coordinates.zoom = 14

            if (!coordinates || !coordinates.latitude || !coordinates.longitude) {
                coordinates.latitude = -11.8831661
                coordinates.longitude = -60.1766577
                coordinates.zoom = 4
            }

            this.$nextTick(() => {
                this.$refs.polygonsMap.mapObject.setView([ coordinates.latitude, coordinates.longitude ], coordinates.zoom)
            })
        },
        transformToGeoJson(kmlString) {
            try {
                const kml = (new DOMParser()).parseFromString(kmlString, 'text/xml');
                const geojson = toGeoJson.kml(kml);
                return geojson
            } catch (error) {
                throw new Error('Ocorreu algum erro no arquivo KML. Evite enviar estilos ou polígonos não lineares.')
            }
        },
        getFeature(geojson) {
            return geojson.features && geojson.features.length > 0
                ? geojson.features[0]
                : null
        },
        getCoordinates(feature) {
            return feature.geometry && feature.geometry.coordinates && feature.geometry.coordinates.length > 0
                ? feature.geometry.coordinates[0]
                : null
        },
        transformGeoJsonToPolygons(geojson) {
            if (!geojson) {
                throw new Error("Algo deu errado com o arquivo KML");
            }

            const feature = this.getFeature(geojson);
            if (!feature) {
                throw new Error("Algo deu errado na propriedade 'feature' do GeoJson");
            }

            const coordinates = this.getCoordinates(feature);
            if (!coordinates) {
                throw new Error("Algo deu errado na propriedade 'coordinates' do GeoJson");
            }

            return coordinates.map(coordinate => ({ latitude: coordinate[1], longitude: coordinate[0] }))
        },
        readKML(e) {
            const name = e.srcElement.files[0].name;
            const extension = name.split(".")[1];
            if ('kml' != extension) {
                alert("O arquivo enviado não é do tipo kml.");
                this.$emit("input", "");
                return;
            }
            const fileReader = new FileReader();
            const file = this.$refs.fileKML.files[0];
            fileReader.readAsText(file);
            fileReader.onload = (event) => {
                try {
                    const kmlString = event.target.result;
                    const geojson = this.transformToGeoJson(kmlString);
                    const polygons = this.transformGeoJsonToPolygons(geojson);

                    this.validatePolygons(polygons)

                    this.polygonsEdited = drawPolygon({ polygons });

                    this.removeAllLayers();
                    this.addPolygonsToLayer(polygons);
                } catch (error) {
                    this.$vToastify.error(error.message)
                }
            }
        },
        resetPolygons() {
            if(this.polygons && this.polygons.length > 0 ) {
                let array = []
                array = _.cloneDeep(this.polygons).map(p => p)
                this.polygonsEdited = drawPolygon({ polygons: array })
            }
        },
        configureToolbarDrawPolygon(){
            this.configureMap();
            this.configureInteractionTexts();
            this.configureDrawnItems();
            this.configureDrawControl();
            this.configureCreatePolygon();
            this.configureEditPolygon();
            this.updateMapCenter();
        },
        configureMap() {
            const mapDraw = this.$refs.polygonsMap.mapObject;
            
            const bingLayer = new BingMapPlugin({
                bingMapsKey: process.env.VUE_APP_BING_MAPS_KEY,
                imagerySet: 'AerialWithLabels',
            });
            
            bingLayer.addTo(mapDraw);
            bingLayer.bringToFront();
        },
        configureInteractionTexts() {
            const handlersTextPolygon = L.drawLocal.draw.handlers.polygon.tooltip
            const tolbarTextPolygon = L.drawLocal.draw.toolbar
            const handlersEditPolygon = L.drawLocal.edit.handlers.edit.tooltip
            const toolbarEditPolygon = L.drawLocal.edit.toolbar

            toolbarEditPolygon.buttons.edit = "Editar polígono"
            toolbarEditPolygon.buttons.editDisabled = "Não ha polígonos para editar"
            toolbarEditPolygon.actions.save.text = "Salvar"
            toolbarEditPolygon.actions.save.title = "Salvar alterações"

            handlersEditPolygon.text = "Arraste os pontos para editar o polígono"
            handlersEditPolygon.subtext = "Clique em cancelar para voltar as aterações"

            tolbarTextPolygon.actions.text = "Cancelar"
            tolbarTextPolygon.buttons.polygon = "Desenhar polígono"
            tolbarTextPolygon.finish.text = "Finalizar"
            tolbarTextPolygon.undo.text = "Deletar o último ponto"

            handlersTextPolygon.start = "Clique para desenhar o polígono"
            handlersTextPolygon.cont = "Escolha o próximo ponto"
            handlersTextPolygon.end = "Clique no primeiro ponto para fechar polígono"
        },
        configureDrawnItems() {
            const mapDraw = this.$refs.polygonsMap.mapObject;

            const drawnItems = new L.FeatureGroup()
            this.drawnItems = drawnItems

            mapDraw.addLayer(drawnItems)
        },
        configureDrawControl() {
            const mapDraw = this.$refs.polygonsMap.mapObject;

            const drawControl = new L.Control.Draw({
                position: 'topright',
                draw: {
                    polyline: false,
                    polygon: {
                        allowIntersection: false,
                        shapeOptions: {
                            polylineID: false,
                            weight: 3,
                            fillOpacity: 0.2,
                            strkeOpacity: 0.2,
                            lineCap: 'round',
                            lineJoin: 'round',
                            opacity: 1,
                        },
                    },
                    rectangle: false,
                    circle: false,
                    marker: false,
                    circlemarker: false
                },
                edit: {
                    featureGroup: this.drawnItems,
                    remove: false
                }
            })

            mapDraw.addControl(drawControl);
        },
        configureCreatePolygon(){
            const mapDraw = this.$refs.polygonsMap.mapObject;

            mapDraw.on('draw:created', (e) => {
                if(this.polygonsEdited.latlngs.length == 0){
                    const { layer } = e;

                    const latlngs = _.cloneDeep(layer._latlngs[0]);
                    latlngs.push(_.cloneDeep(layer._latlngs[0][0]));

                    const polygons = latlngs.map(latlng => ({ latitude: latlng.lat, longitude: latlng.lng }));

                    this.validatePolygons(polygons);
                    this.polygonsEdited = drawPolygon({ polygons });

                    this.addPolygonsToLayer(polygons);
                } else {
                    this.removeAllLayers();
                    this.addPolygonsToLayer(this.polygonsEditedFormatted);
                    return this.$vToastify.info('Você pode cadastrar apenas 1 polígono por talhão', "Atenção!")
                }
            })
        },
        configureEditPolygon() {
            const mapDraw = this.$refs.polygonsMap.mapObject;
            mapDraw.on('draw:edited', (e) => {
                try {
                    const layers = e.layers;
                    layers.eachLayer((layer) => {
                        const latlngs = _.cloneDeep(layer._latlngs[0])
                        latlngs.push(_.cloneDeep(layer._latlngs[0][0]))

                        const polygons = latlngs.map(latlng => ({ latitude: latlng.lat, longitude: latlng.lng }))
                        this.validatePolygons(polygons)
                        this.polygonsEdited = drawPolygon({ polygons })
                    })
                } catch (error) {
                    this.removeAllLayers();
                    this.addPolygonsToLayer(this.polygonsEditedFormatted);
                    this.$vToastify.error(error.message);
                }
            })
        },
        async removePolygon(){
            const confirmRemove = await this.$vToastify.prompt({
                body: "Deseja realmente excluir o polígono?",
                title: "ATENÇÃO!",
                answers: { Sim: true, Não: false },
            })

            if(confirmRemove) {
                this.removeAllLayers();
                this.polygonsEdited.latlngs = []
                this.$emit("area", this.polygonsEditedArea.toFixed(2))
                this.$emit("polygon", this.editedPolygonArrayBeforeEmit)
            }
        },
        addPolygonsToLayer(polygons) {
            if (polygons.length === 0) {
                return
            }

            const formattedPolygonsToLayer = polygons.map(({ latitude, longitude }) => [ latitude, longitude ]);
            const polygonLayer = L.polygon(formattedPolygonsToLayer);
            this.drawnItems.addLayer(polygonLayer);
        },
        removeAllLayers() {
            this.drawnItems.eachLayer(layer => {
                this.drawnItems.removeLayer(layer);
            });
        },
        centerMapOnCoordinates() {
            if (this.latitude && this.longitude) {
                const coordinates = [this.latitude, this.longitude];
                const zoomLevel = 14;
                this.$refs.polygonsMap.mapObject.setView(coordinates, zoomLevel);
            } else {
                this.$vToastify.error("Insira coordenadas válidas!");
            }
        },
    },
    mounted() {
        this.resetPolygons();
        this.configureToolbarDrawPolygon();
        this.addPolygonsToLayer(this.polygonsEditedFormatted);
    }
};
</script>

<style scoped lang="sass">
.polygon-editor
    background: rgba(0, 0, 0, 0.5)
    width: 100vw
    height: 100vh
    position: absolute
    display: flex
    align-items: center
    justify-content: center
    overflow: auto
    z-index: 9999
    top: 0
    left: 0

    &__container
        width: 80%
        max-width: 1000px
        height: 80%

.polygon-container
    background: rgba(0, 0, 0, 0.5)

    &__options
        position: absolute
        top: 0
        left: 0
        height: 40px
        width: 100%
        background: rgba(0, 0, 0, 0.5)
        z-index: 999

    &__map
        height: 100%
        width: 100%
        position: relative

.polygon-map
    width: 100%
    height: 100%

.polygon-actions
    position: absolute
    right: 0
    top: 150px
    z-index: 999

    &__actions
        display: flex
        justify-content: center
        align-items: center
        margin: 10px 10px 0 0
        width: 30px
        height: 30px
        border-radius: 2px
        background: white
        cursor: pointer

        & *
            margin: 0

            &:hover
                cursor: pointer

.coordinate-inputs
    position: absolute
    right: 0
    top: 70.5vh
    z-index: 999
    display: flex
    justify-content: flex-end
    padding: 10px

    input
        margin-right: 5px
        border: 1px solid #ccc
        border-radius: 4px
        padding: 5px
        width: 100px

    button
        border: none
        border-radius: 4px
        padding: 5px 10px
        background-color: $theme-color-primary
        color: white
        cursor: pointer

        &:hover
            background-color: #0056b3

.polygon-options
    display: flex

    &__area
        flex: 1
        display: flex
        align-items: center
        justify-content: center
        padding: 0 12px
        color: rgba(255, 255, 255, 0.8) !important

    &__option
        display: flex
        align-items: center
        justify-content: center
        height: 100%
        padding: 0 12px
        text-align: center
        color: rgba(255, 255, 255, 0.8) !important

        & *
            margin: 0
            color: rgba(255, 255, 255, 0.8) !important

            &:hover
                cursor: pointer
                color: rgba(255, 255, 255, 1) !important

#kml
    display: none

.spacer
    flex: 1
</style>
<style>
.leaflet-div-icon {
	background: #fff;
	border: 1px solid #666;
	margin-left: -5px !important;
	margin-top: -5px !important;
	width: 10px !important;
	height: 10px !important;
}
.leaflet-draw-draw-polygon {
    margin-top: 40px !important;
}
.leaflet-draw-toolbar {
    border: 0 !important;
}
</style>
