<template>
    <div class="sidebar-container"
         :class="{'sidebar-container--closed': sidebarClosed,'sidebar-container--visible': sidebarVisible}">
        <div class="sidebar-container__logo">
            <img class="sidebar-container__logo--img" v-if="accountLogo" :src="accountLogo" alt=""/>
            <img class="sidebar-container__logo--img default-logo" v-else src="img/icons/svg/agrointeli-logo.svg" alt=""/>
        </div>
        <div class="sidebar-container__routes">
            <div class="list-group list-group-flush">
                <router-link
                    tag="a"
                    class="list-group-item list-group-item-action"
                    active-class="selected"
                    v-for="item in menuItems"
                    v-show="hasAccess(item.path)"
                    :key="item.path"
                    :to="item.path"
                >
                    <img class="icon" :src="`/img/icons/svg/${item.icon}.svg`" alt=""/>
                    <div v-if="!sidebarClosed">{{ item.text }}</div>
                </router-link>
            </div>
        </div>
        <div class="sidebar-container__expand navbar-toggler clickable" @click="toggleSidebarVisibility">
            <i v-show="sidebarVisible" class="fas fa-caret-left"/>
            <i v-show="!sidebarVisible" class="fas fa-caret-right"/>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "sidebar",
    data() {
        return {
            accountLogo: '',
            sidebarClosed: false,
            sidebarVisible: false,
            menuItems: [
                {text: "Painel", icon: "dashboard", path: "/painel"},
                {text: "Tempo", icon: "clima", path: "/tempo"},
                {text: "Recomendação", icon: "recomendacao", path: "/recomendacao"},
                {text: "Planejamento", icon: "planejamento", path: "/planejamento"},
                {text: "Monitoramento", icon: "monitoramento", path: "/pragas"},
                {text: "Satélite", icon: "satellite", path: "/satelites"},
                {text: "Relatórios", icon: "graph", path: "/relatorios"},
                {text: "Estoque", icon: "estoque", path: "/estoque"},
                {text: "Inteligência", icon: "inteligencia-bi", path: "/bi"}
            ],
        }
    },
    computed: {
        ...mapGetters("users", ["logo"]),
    },
    mounted() {
        this.setLogo()
        this.$emit('resize')
    },
    watch: {
        logo(){
            this.setLogo()
        }
    },
    methods: {
        toggleSidebarVisibility() {
            this.sidebarVisible = !this.sidebarVisible
            this.sidebarClosed = false
            this.$emit('resize')
        },
        setLogo() {
            this.accountLogo = this.logo
        }
    }
}
</script>

<style scoped lang="sass">
.sidebar-container
    background: $color-blue !important
    display: flex
    flex-direction: column
    min-height: 100%
    width: 7rem
    -webkit-transition: margin 0.25s ease-out
    -moz-transition: margin 0.25s ease-out
    -o-transition: margin 0.25s ease-out
    transition: margin 0.25s ease-out

    &--closed
        width: 80px

    &__expand
        display: none

    &__logo
        display: flex
        justify-content: center
        align-items: center
        height: 82px
        background: $color-blue
        border-bottom: 1px solid rgba(0, 0, 0, .125)

        &--img
            object-fit: contain
            justify-self: center
            align-self: center
            max-width: 96%
            max-height: 96%

        .default-logo
            width: 40px

    &__routes
        height: calc(100vh - 82px)
        overflow: auto

        .list-group-item
            height: 90px
            display: flex
            flex-direction: column
            align-items: center
            justify-content: center
            background: $color-blue !important
            color: $color-white
            font-size: 14px
            font-weight: 100

        .list-group-item:hover
            background: $color-blue-normal-2 !important

        .selected
            background: $color-blue-normal-3 !important

        .icon
            height: 30px
            width: 30px
            object-fit: contain
            filter: brightness(0) invert(1)

@media (max-width: 768px)
    .sidebar-container
        margin-left: -7rem

        &--visible
            margin-left: unset

            .sidebar-container__expand
                left: 7rem

        &__logo
            display: none

        &__routes
            height: 100vh

        &__expand
            display: block
            position: absolute
            z-index: 99999
            top: 6px
            left: 2px
            color: rgba(0, 0, 0, .5)

</style>
