import Subarea from '../../api/Subarea';

const state = {
    subareas: []
};

const getters = {
    getSubareas: (state) => state.subareas
};

const actions = {
    async findSubareas({ commit }, { propertyId, cropId, fieldId }) {
        const response = await Subarea.findSubareas({ propertyId, cropId, fieldId })
        const subareas = response.data.data
        commit("SET_SUBAREAS", subareas)
    },
    async createSubarea({ commit }, subarea) {
        const response =  await Subarea.createSubarea(subarea);
        return response;
    },
    async updateSubarea({ commit }, { subareaId, subarea }) {
        const response =  await Subarea.updateSubarea(subareaId, subarea);
        return response;
    },
    async deleteSubarea({ commit }, subareaId) {
        const response =  await Subarea.deleteSubarea(subareaId);
        return response;
    },
    setSubareas({ commit }, subareas) {
        commit("SET_SUBAREAS", subareas);
    },

    async setMySubareas({ commit }, subareas) {
        await commit("SET_SUBAREAS", subareas);
    },

    async cleanMySubareas({ commit }, subareas) {
        subareas = []
        await commit("SET_SUBAREAS", subareas);
    },
    async findSubareasByFieldId({ commit }, fieldId) {
        commit('SET_SUBAREAS', [])
        const response = await Subarea.finByFieldId(fieldId)
        if (!(response instanceof Error)) {
            commit('SET_SUBAREAS', response.data.data)
        }
    }
};

const mutations = {
    SET_SUBAREAS: (state, subareas) => (state.subareas = subareas),
};

export default { state, getters, actions, mutations, namespaced: true };
